import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Avatar, Stack } from '@mui/material';
import { CreateCompanyTeamMemberDto } from 'tdc-web-backend/company-team-member/schemas';
import { CompanyClientDto, CreateCompanyClientDto } from 'tdc-web-backend/company-client/schemas';
import { CompanyDto, UpdateCompanyDto } from 'tdc-web-backend/companies/schemas';
import { ReactComponent as PlusIcon } from '../../../../../../../assets/icons/button-icons/PlusIcon-Seller-EditModal.svg';
import { ReactComponent as ThreePeopleIcon } from '../../../../../../../assets/img/public-pages/threeUsers.svg';
import { secondaryBlue, primaryLight } from '../../../../../../../utils/color';
import ConfirmModal from '../../../../../../../components/confirm-modal/ConfirmModal';
import CustomInput from '../../../../../../../components/custom-input/CustomInput';
import AvatarEditorField from '../../../../../../../components/custom-inputs/AvatarEditorField';
import CustomButton from '../../../../../../../components/button/CustomButton';
import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import CustomChip from '../../../../../../../components/custom-chip/CustomChip';
import useCreate from '../../../../../../../utils/hooks/crud-hooks/useCreate';
import useDeleteOne from '../../../../../../../utils/hooks/crud-hooks/useDeleteOne';
import { useRefresh } from '../../../../../../../utils/hooks/crud-hooks/useRefresh';
import useUpdate from '../../../../../../../utils/hooks/crud-hooks/useUpdate';
import CustomFormHelperText from '../../../../../../../components/custom-input/CustomFormHelperText';

interface CompanyClientsEditModalProps {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CompanyClientsEditModal = ({
  isEditModalOpen,
  setIsEditModalOpen,
}: CompanyClientsEditModalProps) => {
  const { companyClients, companyData } = useSellerHomescreenContext();
  const refresh = useRefresh();
  const cropRef = useRef<any>(null);
  const [remountAvatar, setRemountAvatar] = useState(0);

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      // circularly references itself if not typecasted as any
      clients: (companyClients as any) ?? [],
      name: '',
    },
  });

  const {
    handleSubmit,
    watch,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = formMethods;

  const clients = getValues('clients');
  const hasErrors = Object.keys(errors).length > 0;

  const { mutate: createCompanyClient, isLoading: isCreateCompanyClientLoading } = useCreate<
    CompanyClientDto,
    CreateCompanyClientDto
  >({
    resource: 'company-client',
  });

  const { mutate: updateCompany, isLoading: isUpdateCompanyLoading } = useUpdate<
    CompanyDto,
    UpdateCompanyDto
  >({
    resource: 'companies',
  });

  const { mutate: deleteCompanyClient } = useDeleteOne({ resource: 'company-client' });

  //
  const handleRemoveClient = (clientId: CompanyClientDto['id']) => {
    if (clients.length === 1) {
      setError('clients', { message: 'You need to have at least one new client.' });
      return;
    }

    deleteCompanyClient(
      { id: clientId },
      {
        onSuccess: () => {
          setValue(
            'clients',
            clients.filter((client: CompanyClientDto) => client.id !== clientId),
          );

          refresh();
        },
      },
    );
  };

  const handleAddClient = () => {
    if (getValues('name') === '') {
      setError('name', { message: 'This field is required' });
      return;
    }

    const dataUrl = cropRef?.current?.getImage().toDataURL();
    const newClientName = watch('name');

    createCompanyClient(
      { avatar: dataUrl, name: newClientName },
      {
        onSuccess: (data) => {
          const {
            data: { id, avatar, name },
          } = data;

          // reset input values
          setValue('name', '');
          setRemountAvatar((prev) => prev + 1);

          setValue('clients', [...clients, { id, avatar, name }]);
        },
      },
    );
  };

  const onSubmit = (data: Partial<CreateCompanyTeamMemberDto>) => {
    const clientsIds = getValues('clients').map((client: CompanyClientDto) => client.id);

    updateCompany(
      { id: companyData?.id as string, data: { clients: clientsIds } },
      {
        onSuccess: (data) => {
          setIsEditModalOpen(false);
          refresh();
        },
      },
    );
  };

  return (
    <ConfirmModal
      width="40%"
      title="Clients"
      confirmButtonText="Save"
      isLoading={isUpdateCompanyLoading || isCreateCompanyClientLoading}
      isModalOpen={isEditModalOpen}
      setIsModalOpen={setIsEditModalOpen}
      automaticallyCloseModalOnButtonClick={false}
      onConfirm={handleSubmit(onSubmit)}
      iconProps={{
        icon: <ThreePeopleIcon fill={secondaryBlue[700]} />,
        wrapperBackgroundColor: primaryLight[100],
      }}
      message={
        <FormProvider {...formMethods}>
          <Stack spacing={6} sx={{ overflowY: 'auto', maxHeight: '380px', pr: 1 }}>
            <AvatarEditorField
              key={remountAvatar}
              cropRef={cropRef}
              avatar=""
              withOpenFileExplorerButton
            />

            <Stack spacing={1} alignItems={errors?.name ? 'center' : 'end'} direction="row">
              <CustomInput
                name="name"
                input={<CustomInput.Text />}
                placeholder="Client name"
                label="Client company name"
                sx={{ minHeight: 'none', width: '70%' }}
              />

              <CustomButton
                onClick={handleAddClient}
                variant="primaryLight"
                sx={{
                  width: '30%',
                  height: '47px',
                }}
                disabled={isCreateCompanyClientLoading || isUpdateCompanyLoading}
                startIcon={<PlusIcon />}
              >
                Add Client
              </CustomButton>
            </Stack>

            <Stack direction="row" flexWrap="wrap" gap={1}>
              {clients.map((client: CompanyClientDto) => (
                <CustomChip
                  key={client.id}
                  styleVariant="rounded"
                  avatar={<Avatar alt={`${client.name}-client`} src={client.avatar as any} />}
                  label={client.name}
                  onDelete={() => handleRemoveClient(client.id)}
                />
              ))}
            </Stack>

            <CustomFormHelperText error={hasErrors} sx={{ mt: '2rem !important' }}>
              {errors?.clients?.message}
            </CustomFormHelperText>
          </Stack>
        </FormProvider>
      }
    />
  );
};

export default CompanyClientsEditModal;
