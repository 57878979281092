import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from '@mui/material';
import { BaseCaseStudy } from 'tdc-web-backend/case-studies/schemas';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EnumCaseStudyStatus } from 'tdc-web-backend/enums/enums';
import CustomButton from '../../../../components/button/CustomButton';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/project-icons/ArrowRightIcon.svg';
import { ReactComponent as Info } from '../../../../assets/icons/info.svg';
import { primaryDark, primaryLight } from '../../../../utils/color';
import StatusChipField from '../../../../components/StatusChipField/StatusChipField';
import { truncate } from '../../../../utils/helpers';
import CaseStudiesDetailsDrawer from '../CaseStudiesDetails/CaseStudiesDetailsDrawer';
import CustomDropdown from '../../../../components/custom-dropdown/CustomDropdown';
import DeleteCaseStudyModal from './DeleteCaseStudyModal';
import CustomTooltip from '../../../../components/tooltip/CustomTooltip';
import StarRating from '../../../../components/star-rating/StarRating';
import RatingBadge from '../../../../components/rating-badge/RatingBadge';

type CaseStudiesCardItemProps = {
  item: BaseCaseStudy;
};

const CaseStudiesCardItem = ({ item }: CaseStudiesCardItemProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [showReviewForm, setShowReviewForm] = useState(false);

  const showReqReviewBtn = item.status === EnumCaseStudyStatus.Published;
  const showRating = item.status === EnumCaseStudyStatus.Verified;

  const EditTooltip = () => {
    return (
      <CustomTooltip
        placement="top"
        title={
          'The case study has been edited. Changes need to be approved before being be applied.'
        }
      >
        <Stack pr={1}>
          <Info fill={primaryDark[500]} height={'16px'} />
        </Stack>
      </CustomTooltip>
    );
  };

  const navigate = useNavigate();
  return (
    <Card
      sx={{
        boxShadow: '6px 6px 15px rgba(56, 69, 114, 0.1)',
        borderRadius: '12px',
        position: 'relative',
        height: '19rem',
      }}
    >
      <CardActionArea
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        disableRipple
        onClick={() => setIsDrawerOpen(true)}
      >
        <CardMedia component="img" height="190px" image={item?.file} alt="Case study card" />
        <CardContent sx={{ height: '5rem', width: '100%' }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body3" color="primaryDark.500">
              {truncate(item?.title, 100)}
            </Typography>
            <CustomDropdown
              menuItemsContents={[
                {
                  key: 'edit',
                  text: 'Edit',
                  onClick: () => navigate(`./edit/${item?.id}`),
                  disabled: item.status === EnumCaseStudyStatus.Pending,
                },
                // {
                //   key: 'delete',
                //   text: 'Delete',
                //   onClick: () => {
                //     setIsOpenDeleteModal(true);
                //   },
                // },
              ]}
            />
          </Stack>
        </CardContent>
        <CardActions
          sx={{
            p: 2,
            pt: 0,
            width: '100%',
            height: '10%',
          }}
        >
          {showReqReviewBtn && (
            <CustomButton
              variant="primaryText"
              onClick={(e) => {
                e.stopPropagation();
                setShowReviewForm(true);
                setIsDrawerOpen(true);
              }}
              endIcon={<ArrowRightIcon fill={primaryLight[500]} />}
            >
              request review
            </CustomButton>
          )}
          {showRating && <StarRating showSingle rating={item?.score as number} />}
        </CardActions>
      </CardActionArea>
      <StatusChipField
        text={item?.status}
        chipProps={{
          onDelete: item.edit ? () => {} : undefined,
          deleteIcon: <EditTooltip />,
        }}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          borderRadius: 0,
          borderBottomRightRadius: '3px',
        }}
      />
      <DeleteCaseStudyModal
        setIsOpen={setIsOpenDeleteModal}
        isOpen={isOpenDeleteModal}
        id={item.id}
      />
      <CaseStudiesDetailsDrawer
        setShowReviewForm={setShowReviewForm}
        showReviewForm={showReviewForm}
        setIsOpen={setIsDrawerOpen}
        open={isDrawerOpen}
        item={item}
      />
    </Card>
  );
};
export default CaseStudiesCardItem;
