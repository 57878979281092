import { Stack, Typography } from '@mui/material';
import PunctationMarkedText from '../../../../components/public-screen-components/PunctationMarkedText';
import { LAYOUT_DEFAULTS } from '../../../../utils/constants';

const YouAreYouGet = () => (
  <Stack
    spacing={{ xs: 8, md: 5 }}
    direction={{ xs: 'column', md: 'row' }}
    justifyContent="space-between"
    sx={{ ...LAYOUT_DEFAULTS, mt: 3, mb: 14 }}
  >
    <Stack spacing={3}>
      <Typography sx={{ typography: { xs: 'publicHeading4', lg: 'publicHeading3' } }}>
        You Are...
      </Typography>

      <PunctationMarkedText
        content={[
          'A business that spends USD2,000+ per month on paid social ads',
          'Need Agency Ad accounts with no limitations for unlimited scaling',
          'Promoting white hat and gray hat products',
        ]}
      />
    </Stack>

    <Stack spacing={3}>
      <Typography sx={{ typography: { xs: 'publicHeading4', lg: 'publicHeading3' } }}>
        You Get...
      </Typography>

      <PunctationMarkedText
        content={[
          'On-hand guidance for achieving your advertising goals \n \n',
          'Dedicated media buyer that helps you scale and meet compliance standards',
          'Agency ad accounts without daily spending limits so you can scale without limitations.',
        ]}
      />
    </Stack>
  </Stack>
);
export default YouAreYouGet;
