/* eslint-disable no-param-reassign */
import {
  Button as MuiButton,
  Stack,
  SxProps,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { memo, useEffect, useState } from 'react';
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  format,
  getDaysInMonth,
  isLastDayOfMonth,
} from 'date-fns';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
  TooltipProps,
} from 'recharts';
import { v1 as uuidv1 } from 'uuid';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { EnumCurrency } from 'tdc-web-backend/enums/enums';
import { formatCurrency } from '../../../../utils/helpers';
import CustomNoRowsOverlay from '../../../../components/custom-no-rows-overlay/CustomNoRowsOverlay';
import theme from '../../../../utils/theme';

export interface RevenueChartProps {
  milestones: MilestoneDto[];
  currency: EnumCurrency;
  sx?: SxProps<Theme> | undefined;
}

interface GraphMilestoneI {
  id?: string;
  name?: string;
  date: Date | null;
  paid: number;
  unpaid: number;
  paidMilestones?: string;
  unpaidMilestones?: string;
}

interface DateInterval {
  month: Date[];
  halfyear: Date[];
  year: Date[];
}

const RevenueChart = ({ sx, currency, milestones }: RevenueChartProps) => {
  const [array, setArray] = useState<GraphMilestoneI[]>([]);
  const [dateIntervals, setDateIntervals] = useState<DateInterval | null>(null);
  // by default, daysInterval is 30 days
  // 6M is half year days
  // 1Y is 36(5)6 days
  const [intervalType, setIntervalType] = useState<string>('month');

  const CustomTooltip = ({ payload, label, active }: TooltipProps<number, string>) => {
    if (payload && active && payload.length) {
      const date = new Date(label);
      let arrayOfPaidMilestoneNames: GraphMilestoneI[] = [];
      let arrayOfUnpaidMilestoneNames: GraphMilestoneI[] = [];

      if (payload[0].payload?.paidMilestones) {
        arrayOfPaidMilestoneNames = payload[0]?.payload?.paidMilestones?.split(',');
      }

      if (payload[0].payload?.unpaidMilestones) {
        arrayOfUnpaidMilestoneNames = payload[0]?.payload?.unpaidMilestones?.split(',');
      }

      return (
        <MuiButton sx={{ py: 2, opacity: 1 }} size="small" variant="contained">
          <Stack spacing={1} alignItems="start" justifyContent="space-between">
            {/* current date */}
            <Stack spacing={1} alignItems="start">
              <Typography component="span" fontWeight="600" color="grey.300">
                {format(date, 'MMM d, yyyy')}
              </Typography>
            </Stack>

            {/* paid milestones */}
            {arrayOfPaidMilestoneNames.length > 0 && (
              <Stack alignItems="start">
                <Stack alignItems="start">
                  <Typography component="span" fontWeight="600">
                    Paid Milestones:
                  </Typography>

                  <Stack alignItems="start">
                    {arrayOfPaidMilestoneNames.map((name) => (
                      <Typography>{name}</Typography>
                    ))}
                  </Stack>

                  <Typography>{payload[0]?.payload?.name}</Typography>
                </Stack>

                {payload[0].payload.paid !== 0 && (
                  <Typography>
                    Payment: {formatCurrency(payload[0].payload.paid, currency as EnumCurrency)}
                  </Typography>
                )}
              </Stack>
            )}

            {/* single paid milestone */}
            {payload[0].payload.name && payload[0].payload.unpaid === 0 && (
              <Stack alignItems="start">
                <Typography component="span" fontWeight="600" alignItems="start">
                  Paid Milestone:
                </Typography>

                <Typography alignItems="start">{payload[0].payload.name}</Typography>

                {payload[0].payload.paid !== 0 && (
                  <Typography>
                    Payment: {formatCurrency(payload[0].payload.paid, currency as EnumCurrency)}
                  </Typography>
                )}
              </Stack>
            )}

            {/* unpaid milestones */}
            {arrayOfUnpaidMilestoneNames.length > 0 && (
              <Stack alignItems="start">
                <Stack alignItems="start">
                  <Typography component="span" fontWeight="600" alignItems="start">
                    Unpaid Milestones:
                  </Typography>

                  <Stack alignItems="start">
                    {arrayOfUnpaidMilestoneNames.map((name) => (
                      <Typography>{name}</Typography>
                    ))}
                  </Stack>
                </Stack>

                {payload[1].payload.unpaid !== 0 && (
                  <Typography>
                    Expected payment:{' '}
                    {formatCurrency(payload[1].payload.unpaid, currency as EnumCurrency)}
                  </Typography>
                )}
              </Stack>
            )}

            {/* single unpaid milestone */}
            {payload[0].payload.name && payload[0].payload.paid === 0 && (
              <Stack alignItems="start">
                <Typography component="span" fontWeight="600" alignItems="start">
                  Unpaid Milestone:
                </Typography>

                <Typography alignItems="start">{payload[0].payload.name}</Typography>

                {payload[0].payload.unpaid !== 0 && (
                  <Typography>
                    Expected payment:{' '}
                    {formatCurrency(payload[0].payload.unpaid, currency as EnumCurrency)}
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
        </MuiButton>
      );
    }

    return null;
  };

  const getAllDaysInCurrentMonth = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();

    const daysInCurrentMonth = getDaysInMonth(new Date(year, month));

    const allDatesInCurrentMonth = eachDayOfInterval({
      start: new Date(year, month, 1),
      end: new Date(year, month, daysInCurrentMonth),
    });

    return allDatesInCurrentMonth;
  };

  const getSixMonthsInterval = () => {
    const date = new Date();
    // offset in milliseconds
    // const timezoneOffset = date.getTimezoneOffset() * 60000;

    const twoMonthsBackTarget = new Date(date.getFullYear(), date.getMonth() - 2, date.getDate());
    const threeMonthsAheadTarget = new Date(
      date.getFullYear(),
      date.getMonth() + 3,
      date.getDate(),
    );

    // it includes the current month
    const twoMonthsBackAndTheCurrentMonth = eachMonthOfInterval({
      start: new Date(twoMonthsBackTarget),
      end: new Date(),
    });

    const twoMonthsAhead = eachMonthOfInterval({
      // current year's current month + 1 because we don't want to include
      // the current month which exists in the twoMonthsBackAndTheCurrentMonth variable
      start: new Date(date.getFullYear(), date.getMonth() + 1),
      end: new Date(threeMonthsAheadTarget),
    });

    const sixMonthsPeriod = twoMonthsBackAndTheCurrentMonth.concat(twoMonthsAhead);

    const allTheDatesBetweenStartAndEndOfSixMonthsPeriod = eachDayOfInterval({
      start: new Date(sixMonthsPeriod[0]),
      end: new Date(sixMonthsPeriod[sixMonthsPeriod.length - 1]),
    });

    return allTheDatesBetweenStartAndEndOfSixMonthsPeriod;
  };

  const getAllYearDatesInterval = () => {
    const firstDateOfTheYear = new Date(new Date().getFullYear(), 0, 1);
    const lastDateOfTheYear = new Date(new Date().getFullYear(), 11, 31);

    const allTheDatesBetweenStartAndEndOfYearPeriod = eachDayOfInterval({
      start: new Date(firstDateOfTheYear),
      end: new Date(lastDateOfTheYear),
    });

    return allTheDatesBetweenStartAndEndOfYearPeriod;
  };

  useEffect(() => {
    // get all the different date intervals on init render,
    // rather than calculating those on change of intervalType
    // every time
    setDateIntervals({
      month: getAllDaysInCurrentMonth(),
      halfyear: getSixMonthsInterval(),
      year: getAllYearDatesInterval(),
    });
  }, []);

  useEffect(() => {
    // this variable includes all the milestones
    const allMilestonesArray: GraphMilestoneI[] = [];

    if (milestones && dateIntervals !== null) {
      // use intervalType as key from dateIntervals's type
      dateIntervals[intervalType as keyof typeof dateIntervals].forEach(
        (date: Date, dateIndex: number) => {
          milestones.forEach((milestone) => {
            // if (milestone.paid === null) {
            //   return;
            // }

            // add paid milestones
            // paid is here used as actual date when payment was actually done
            if (format(date, 'P') === format(new Date(milestone?.paid as Date), 'P')) {
              allMilestonesArray.push({
                id: milestone.id,
                name: milestone.name,
                date,
                paid: milestone.budget,
                unpaid: 0,
              });
            }

            // add not paid milestones
            if (
              milestone?.paid === null &&
              // end is here used as (last?) expected date when paymend should arrive
              format(date, 'P') === format(new Date(milestone.end), 'P')
            ) {
              allMilestonesArray.push({
                id: milestone.id,
                name: milestone.name,
                date,
                paid: 0,
                unpaid: milestone.budget,
              });
            }
          });

          // days when no milestone is paid or planned to end
          // do not push if in allMilestonesArray is already date
          // OR do not duplicate dates
          if (allMilestonesArray[dateIndex]?.date !== date) {
            allMilestonesArray.push({
              // id: milestones[],
              // name: milestone[],
              date,
              paid: 0,
              unpaid: 0,
            });
          }
        },
      );

      const mergedTargetAndDuplicatesMilestones: GraphMilestoneI[] = [];

      const milestonesWhoseDateIsNotRepeated = allMilestonesArray.filter(
        (milestone, i, milestonesArray) => {
          const targetAndDuplicatesMilestones: GraphMilestoneI[] = [];

          // if a milestone's date is repeated
          // for each milestone, check if milestone's date is repeated twice in a row
          // OR in other words, find first index in the array whose date is equal to a milestone
          // date and compare that index to the current iterative milestone's index;
          // if they are not equal, it means that for the current iterative
          // milestone date is repeated
          if (
            milestonesArray.findIndex(
              (milestoneArrItem) => milestoneArrItem.date === milestone.date,
            ) !== i
          ) {
            // we never want to mutate the array directly, so we make a copy of it
            const copiedMilestonesArray = [...milestonesArray];

            // since milestones are sorted one after another, in chronological order,
            // for a duplicated milestone we can ALWAYS be certain that its previous milestone
            // is one with the same date, so in it we want to "merge" the duplicated milestone
            const targetMilestone = copiedMilestonesArray[i - 1];

            // remaining array starting from target milestone
            // const slicedArr = array.slice(targetMilestone);
            const slicedMilestonesArray = copiedMilestonesArray.slice(
              copiedMilestonesArray.findIndex((copiedItem) => copiedItem.date === milestone.date),
            );

            slicedMilestonesArray.forEach((milestone) => {
              // from the remaining array of duplicate milestones, get all the duplicates for
              // the current target milestone
              if (milestone.date === targetMilestone.date) {
                targetAndDuplicatesMilestones.push(milestone);
              }
            });

            const nonUndefinedDuplicates = targetAndDuplicatesMilestones.filter(
              (duplicate) => duplicate.name !== undefined,
            );

            const mergedTargetAndDuplicatesItem = nonUndefinedDuplicates.reduce(
              (result, current, index: number) => {
                if (current.unpaid === 0 && current.name) {
                  result.paidMilestones += `${current.name}, `;
                } else if (current.paid === 0 && current.name) {
                  result.unpaidMilestones += `${current.name}, `;
                }

                result.id = uuidv1();
                result.date = current.date;
                result.paid += current.paid;
                result.unpaid += current.unpaid;

                if (nonUndefinedDuplicates.length - 1 === index && result.unpaidMilestones) {
                  // remove hanging ', '
                  result.unpaidMilestones = result.unpaidMilestones.replace(/,\s*$/, '');
                }

                if (nonUndefinedDuplicates.length - 1 === index && result.paidMilestones) {
                  result.paidMilestones = result.paidMilestones.replace(/,\s*$/, '');
                }

                return result;
              },
              {
                id: '',
                unpaidMilestones: '',
                paidMilestones: '',
                date: null,
                paid: 0,
                unpaid: 0,
              },
            );

            mergedTargetAndDuplicatesMilestones.push(mergedTargetAndDuplicatesItem);
          }

          // return milestones whose date is not repeated
          return (
            milestonesArray.findIndex(
              (milestoneArrItem) => milestoneArrItem.date === milestone.date,
            ) === i
          );
        },
      );

      // in milestonesWhoseDateIsNotRepeated find indices at which date
      // is same as the date in the milestonesWhoseDateIsNotRepeated array
      // SO,
      // indices are indices at which in milestonesWhoseDateIsNotRepeated array
      // mergedTargetAndDuplicatesMilestones needs to get set
      // eslint-disable-next-line operator-linebreak
      const indicesOfWhereToPutMergedTargetAndDuplicatesMilestones =
        mergedTargetAndDuplicatesMilestones.map((mutatedMilestone) =>
          milestonesWhoseDateIsNotRepeated.findIndex((item) => item.date === mutatedMilestone.date),
        );

      const finalMilestones = [...milestonesWhoseDateIsNotRepeated];

      // then in the milestonesWhoseDateIsNotRepeated array
      // for each index check if indices' milestone is not undefined
      // OR in other words
      finalMilestones.forEach((item, index) => {
        // if not out of indicesOfWhereToPutMergedTargetAndDuplicatesMilestones
        if (indicesOfWhereToPutMergedTargetAndDuplicatesMilestones[index] !== undefined) {
          // target
          finalMilestones[indicesOfWhereToPutMergedTargetAndDuplicatesMilestones[index]] =
            mergedTargetAndDuplicatesMilestones[index];
        }
      });

      setArray(finalMilestones);
    }
  }, [milestones, intervalType, dateIntervals]);

  // get the greatest amount of paid/unpaid and use that as
  // yaxis datakey
  const getGreatestAmountOfPaidOrUnpaid = () => {
    const greatestPaidOrUnpaidMilestone: { paid: number; unpaid: number } = {
      paid: 0,
      unpaid: 0,
    };

    // first get all milestones whose paid and unpaid are not 0
    // then go through each of them and basically filter to find
    // the greatest amount of paid or unpaid by the help of
    // helper variable greatestPaidOrUnpaidMilestone
    array
      .filter((el) => el.paid !== 0 || el.unpaid !== 0)
      .forEach((item) => {
        if (item.paid > greatestPaidOrUnpaidMilestone?.paid) {
          greatestPaidOrUnpaidMilestone.paid = item.paid;
        }

        if (item.unpaid > greatestPaidOrUnpaidMilestone?.unpaid) {
          greatestPaidOrUnpaidMilestone.unpaid = item.unpaid;
        }
      });

    // get the highest value of paid/unpaid
    if (greatestPaidOrUnpaidMilestone) {
      if (greatestPaidOrUnpaidMilestone.paid > greatestPaidOrUnpaidMilestone.unpaid) {
        return 'paid';
      }

      return 'unpaid';
    }

    return null;
  };

  const determineIfAllArePaid = () => array.every((item) => item.unpaid === 0);

  const handleChange = (event: React.MouseEvent<HTMLElement>, newIntervalType: string) => {
    // enforce that at least one button must be active
    if (newIntervalType !== null) {
      setIntervalType(newIntervalType);
    }
  };

  const toggleButtonStyles: SxProps<Theme> = {
    py: 0.1,
    px: 1.2,
    border: 'none',
    color: 'primaryDark.500',
    fontWeight: 600,
  };

  const toggleButtonActiveStyles: SxProps<Theme> = {
    color: 'primaryLight.500',
    boxShadow: '6px 6px 15px rgba(56, 69, 114, 0.1)',
  };

  const AreaDotRadius = 2.25;

  const isGreaterThanLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const [responsiveContainerWidth, setResponsiveContainerWidth] = useState('100%');

  useEffect(() => {
    if (isGreaterThanLarge) {
      setResponsiveContainerWidth('100%');
    } else {
      setResponsiveContainerWidth('93%');
    }
  }, [isGreaterThanLarge]);

  return (
    <Stack sx={{ ...sx, position: 'relative' }} width="100%" height="100%" justifyContent="end">
      <ToggleButtonGroup
        color="secondary"
        value={intervalType}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        sx={{
          position: 'absolute',
          top: -40,
          left: 0,
          right: 0,
          margin: 'auto',
          width: 'fit-content',
          gap: 0,
          border: `1px solid ${theme.palette.secondaryBlue[100]}`,
          justifyContent: 'center',
          display: milestones?.length ? 'block' : 'none',
        }}
      >
        <ToggleButton
          value="month"
          sx={{
            ...toggleButtonStyles,
            borderRight: `1px solid ${theme.palette.secondaryBlue[100]}`,
            '&.Mui-selected': { ...toggleButtonActiveStyles },
          }}
        >
          1M
        </ToggleButton>

        <ToggleButton
          value="halfyear"
          sx={{
            ...toggleButtonStyles,
            borderRight: `1px solid ${theme.palette.secondaryBlue[100]}`,
            '&.Mui-selected': { ...toggleButtonActiveStyles },
          }}
        >
          6M
        </ToggleButton>

        <ToggleButton
          value="year"
          sx={{ ...toggleButtonStyles, '&.Mui-selected': { ...toggleButtonActiveStyles } }}
        >
          1Y
        </ToggleButton>
      </ToggleButtonGroup>

      <ResponsiveContainer width={responsiveContainerWidth} height="90%">
        <AreaChart
          data={array.length > 0 ? array : []}
          style={{
            fontSize: '1rem',
            fontFamily: theme.typography.fontFamily,
            // marginLeft: -10,
          }}
          margin={{
            top: 20,
            right: 10,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id="paid" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#7F57FF" stopOpacity={0.8} />
              <stop offset="100%" stopColor="#7F57FF" stopOpacity={0} />
            </linearGradient>

            <linearGradient id="unpaid" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#808080" stopOpacity={0.8} />
              <stop offset="100%" stopColor="#808080" stopOpacity={0} />
            </linearGradient>
          </defs>

          <Legend
            wrapperStyle={{
              fontFamily: theme.typography.fontFamily,
              fontSize: '1rem',
              display: milestones?.length ? 'block' : 'none',
            }}
            verticalAlign="bottom"
            height={15}
          />

          <CartesianGrid opacity={0.3} vertical={false} />
          <Tooltip content={<CustomTooltip />} wrapperStyle={{ outline: 'none', zIndex: 35 }} />

          {currency && (
            <YAxis
              dataKey={getGreatestAmountOfPaidOrUnpaid() as string}
              axisLine={false}
              tickLine={false}
              // tickCount={4}
              tick={{ fill: theme.palette.primaryDark[400] }}
              // this will return the currency in the k (kilo) format; e.g. $7.5k
              tickFormatter={(value) =>
                value.toLocaleString('en-US', {
                  style: 'currency',
                  currency,
                  notation: 'compact',
                  minimumFractionDigits: 0,
                })
              }
              dx={-5}
              style={{ fontSize: '0.875rem', fontWeight: 600 }}
            />
          )}

          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            padding={{ left: 25, right: 25 }}
            tick={{ fill: theme.palette.primaryDark[400] }}
            scale="point"
            tickMargin={15}
            height={50}
            // show all ticks
            interval={0}
            tickFormatter={(value) => {
              // value is date

              if (intervalType === 'month') {
                if (value.getDate() === 1) {
                  return format(value, 'MMM d').toUpperCase();
                }

                // if not february
                if (value.getMonth() !== 1) {
                  if (value.getDate() % 10 === 0 && value.getDate() !== 30) {
                    return format(value, 'MMM d').toUpperCase();
                  }

                  if (
                    value.getDate() % 10 === 0 &&
                    value.getDate() === 30 &&
                    isLastDayOfMonth(value)
                  ) {
                    return format(value, 'MMM d').toUpperCase();
                  }

                  if (value.getDate() === 31 && isLastDayOfMonth(value)) {
                    return format(value, 'MMM d').toUpperCase();
                  }
                }

                // if february
                if (value.getMonth() === 1) {
                  if (value.getDate() % 10 === 0) {
                    return format(value, 'MMM d').toUpperCase();
                  }

                  if (value.getDate() === 28 || value.getDate() === 29) {
                    return format(value, 'MMM d').toUpperCase();
                  }
                }
              }

              if (intervalType === 'halfyear') {
                if (value.getDate() === 1) {
                  return format(value, 'MMM d').toUpperCase();
                }
              }

              if (intervalType === 'year') {
                if (value.getMonth() === 0 && value.getDate() === 1) {
                  return format(value, 'MMM d').toUpperCase();
                }

                if (value.getMonth() === 3 && value.getDate() === 1) {
                  return format(value, 'MMM d').toUpperCase();
                }

                if (value.getMonth() === 6 && value.getDate() === 1) {
                  return format(value, 'MMM d').toUpperCase();
                }

                if (value.getMonth() === 9 && value.getDate() === 1) {
                  return format(value, 'MMM d').toUpperCase();
                }

                if (value.getMonth() === 11 && value.getDate() === 31) {
                  return format(value, 'MMM d').toUpperCase();
                }
              }

              return '';
            }}
            // style={{ fontSize: '0.875rem' }}
            style={{ fontSize: '0.875rem', fontWeight: 600 }}
          />

          <Area
            dataKey="paid"
            legendType="circle"
            type="monotone"
            stroke="#7F57FF"
            fillOpacity={1}
            isAnimationActive={false}
            strokeWidth={1.5}
            fill="url(#paid)"
            activeDot={{ r: AreaDotRadius, stroke: '#32009A', fill: '#32009A' }}
            dot={(payload) => {
              if (payload) {
                if (payload.payload.paid !== 0) {
                  if (intervalType === 'halfyear') return <></>;
                  if (intervalType === 'year') return <></>;
                  return (
                    <circle
                      key={payload.cx}
                      r={AreaDotRadius}
                      stroke="#32009A"
                      fill="#32009A"
                      strokeWidth={AreaDotRadius}
                      cx={payload.cx}
                      cy={payload.cy}
                    />
                  );
                }

                return <></>;
              }

              return <></>;
            }}
          />

          {/* if all milestones are paid -> then hide this area chart! */}
          {!determineIfAllArePaid() && (
            <Area
              dataKey="unpaid"
              legendType="circle"
              isAnimationActive={false}
              activeDot={(payload) => {
                if (payload) {
                  if (payload.payload.unpaid !== 0) {
                    return (
                      <circle
                        key={payload.cx}
                        r={AreaDotRadius}
                        stroke="grey"
                        fill="grey"
                        strokeWidth={AreaDotRadius}
                        cx={payload.cx}
                        cy={payload.cy}
                      />
                    );
                  }

                  return <></>;
                }

                return <></>;
              }}
              type="monotone"
              stroke="#808080"
              fillOpacity={1}
              strokeWidth={1.5}
              fill="url(#unpaid)"
            />
          )}
        </AreaChart>
      </ResponsiveContainer>

      {!milestones?.length && <CustomNoRowsOverlay />}
    </Stack>
  );
};

export default memo(RevenueChart);
