import { Card, Typography } from '@mui/material';
import { EventProps } from 'react-big-calendar';
import { secondaryBlue, secondaryGreen } from '../../utils/color';
import { EnumEventType } from 'tdc-web-backend/enums/enums';
import { CalendarEventDto } from 'tdc-web-backend/events/schemas';

export const colorsMap = new Map([
  [EnumEventType.Event, secondaryGreen],
  [EnumEventType.Milestone, secondaryBlue],
  [EnumEventType.Payment, secondaryBlue],
  [EnumEventType.Task, secondaryBlue],
  [EnumEventType.Subtask, secondaryBlue],
]);

const CalendarCustomEvent = (events: EventProps<CalendarEventDto>) => {
  const { title, event } = events;
  return (
    <Card
      sx={{
        margin: '0 1',
        background: colorsMap.get(event.type ?? EnumEventType.Event)?.[100],
        color: colorsMap.get(event.type ?? EnumEventType.Event)?.[700],
        p: '0.2rem 0.6rem',
      }}
      elevation={0}
    >
      <Typography
        variant="body3"
        overflow={'hidden'}
        textOverflow={'ellipsis'}
        width={'100%'}
        display={'block'}
      >
        {title}
      </Typography>
    </Card>
  );
};

export default CalendarCustomEvent;
