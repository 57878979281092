import { useState } from 'react';
import { Avatar, Divider, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { BaseUserDto } from 'tdc-web-backend/users/shemas';
import CustomButton from '../../../../../components/button/CustomButton';
import CustomInput from '../../../../../components/custom-input/CustomInput';
import BaseInputLabel from '../../../../../components/custom-inputs/BaseInputLabel';
import AvatarEditorModal from '../Members/Member/components/AvatarEditorModal';

export interface UserBasicDetailsProps {
  userData: (BaseUserDto & { avatar: string }) | null;
  disabled?: boolean;
}

const UserBasicDetails = ({ userData, disabled = false }: UserBasicDetailsProps) => {
  const [openBadge, setBadgeOpen] = useState(false);

  const form = useFormContext();

  return (
    <>
      <Stack
        direction={{ xs: 'column', gr: 'row' }}
        spacing={3}
        justifyContent="cener"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Stack width={{ xs: '100%', gr: '28%' }} gap={2.5}>
          <Avatar
            src={form.getValues('avatar') === '' ? userData?.avatar : form.getValues('avatar')}
            alt="Company logo"
            sx={{
              height: '120px',
              width: '120px',
              boxShadow: '6px 6px 15px 0px rgba(151, 71, 255, 0.25)',
            }}
          />
          <CustomButton
            variant="secondary"
            sx={{
              height: '2.8125rem',
              width: '100%',
              maxWidth: '136px',
              marginTop: '20px !important',
            }}
            onClick={() => setBadgeOpen(true)}
            disabled={disabled}
          >
            Change foto
          </CustomButton>
        </Stack>
        <Stack width={{ xs: '100%', gr: '36%' }} spacing={3}>
          <CustomInput
            input={<CustomInput.Text />}
            placeholder="Enter your first name"
            name="firstName"
            label="First Name"
            disabled={disabled}
          />
          <CustomInput
            input={<CustomInput.Text />}
            placeholder="Enter your last name"
            name="lastName"
            label="Last Name"
            disabled={disabled}
          />
        </Stack>
        <Stack width={{ xs: '100%', gr: '36%' }} spacing={3}>
          <CustomInput
            input={<CustomInput.Text />}
            placeholder="Enter your job title"
            name="jobTitle"
            label="Job Title"
            disabled={disabled}
          />
          <Stack>
            <BaseInputLabel text="Email" sx={{ fontSize: '0.75rem !important' }} />
            <Typography variant="bodyMedium3">{userData?.email}</Typography>
          </Stack>
        </Stack>
      </Stack>
      {openBadge && userData && (
        <AvatarEditorModal userData={userData} open={openBadge} setOpen={setBadgeOpen} />
      )}
    </>
  );
};

export default UserBasicDetails;
