import { createContext, useState, ReactNode, useMemo, useCallback, useEffect } from 'react';
import { ChangePasswordDto, LoginRequestDto, LoginResponseDto } from 'tdc-web-backend/auth/schemas';
import { BaseUserDto, UpdateUserDto } from 'tdc-web-backend/users/shemas';
import { UserRoles } from 'tdc-web-backend/enums/enums';
import { AcceptInvitationDto } from 'tdc-web-backend/user-invitation/schemas';
import { AxiosError, AxiosResponse } from 'axios';
import useCreate from '../hooks/crud-hooks/useCreate';
import axiosInstance from '../../api/axios';
import useUpdate from '../hooks/crud-hooks/useUpdate';

export enum EnumAuthLoginErrorType {
  ServerResponseError = 'ServerResponseError',
  UserNotVerified = 'UserNotVerified',
  NoAccessToken = 'NoAccessToken',
  NoUserData = 'NoUserData',
  InvalidToken = 'InvalidToken',
}
interface IAuthMutateOptions {
  onSuccess?: (data: AxiosResponse) => void;
  onError?: (error: { error: AxiosError | string; type: EnumAuthLoginErrorType }) => void;
}
interface IAuthData {
  userData: (BaseUserDto & { avatar: string }) | null;
  accessToken: string | null;
}

interface IAuthContextValue {
  authData: IAuthData;
  login: (data: LoginRequestDto, options?: IAuthMutateOptions) => void;
  acceptInvitation: (data: AcceptInvitationDto, options?: IAuthMutateOptions) => void;
  logout: () => void;
  isLoadingLogin: boolean;
  isLoadingAcceptTerms: boolean;
  isLoadingUpdateUser: boolean;
  isLoadingChangePassword: boolean;
  isLoadingAcceptInvitation: boolean;
  isLoggedIn: boolean;
  hasRole: (role: UserRoles) => boolean;
  acceptTerms: (options?: IAuthMutateOptions) => void;
  updateUser: (data?: UpdateUserDto, options?: IAuthMutateOptions) => void;
  changePassword: (data: ChangePasswordDto, options?: IAuthMutateOptions) => void;
}

export const AuthContext = createContext<IAuthContextValue>({
  authData: {
    userData: null,
    accessToken: null,
  },
  login: () => null,
  acceptInvitation: () => null,
  logout: () => null,
  isLoadingLogin: false,
  isLoadingAcceptTerms: false,
  isLoadingUpdateUser: false,
  isLoadingChangePassword: false,
  isLoadingAcceptInvitation: false,
  isLoggedIn: false,
  hasRole: () => false,
  acceptTerms: () => null,
  updateUser: () => null,
  changePassword: () => null,
});

const getStoredAuthData = () => {
  const accessToken = localStorage.getItem('AccessToken');
  const userDataString = localStorage.getItem('userData');
  const userData = userDataString ? JSON.parse(userDataString) : null;

  if (!accessToken) {
    return { userData: null, accessToken: null };
  }

  return { userData, accessToken };
};

const setAuthDataToLocalStorage = (
  accessToken: string,
  userData: BaseUserDto & { avatar: string },
) => {
  localStorage.setItem('AccessToken', accessToken);
  localStorage.setItem('userData', JSON.stringify(userData));
};

const clearAuthData = () => {
  localStorage.removeItem('AccessToken');
  localStorage.removeItem('userData');
};

interface IAuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [authData, setAuthData] = useState<IAuthData>(getStoredAuthData());
  const { mutate: mutateLogin, isLoading: isLoadingLogin } = useCreate<
    LoginResponseDto,
    LoginRequestDto
  >({
    resource: '/auth/login',
  });

  const { mutate: mutateAcceptInvitation, isLoading: isLoadingAcceptInvitation } = useCreate<
    LoginResponseDto,
    AcceptInvitationDto
  >({
    resource: '/user-invitation/accept-invitation',
  });

  const { mutate: mutateAcceptTerms, isLoading: isLoadingAcceptTerms } = useUpdate<
    BaseUserDto & { avatar: string },
    Record<string, never>
  >({
    resource: 'users',
  });

  const { mutate: mutateUpdateUser, isLoading: isLoadingUpdateUser } = useUpdate<
    BaseUserDto & { avatar: string },
    UpdateUserDto
  >({
    resource: 'users',
  });

  const { mutate: mutateChangePassword, isLoading: isLoadingChangePassword } = useCreate<
    Record<string, never>,
    ChangePasswordDto
  >({
    resource: 'auth/change-password',
  });

  const login = useCallback(
    (data: LoginRequestDto, options?: IAuthMutateOptions) => {
      mutateLogin(data, {
        onSuccess: (response) => {
          const { token, user } = response.data;
          if (user.roles.includes(UserRoles.Verified)) {
            setAuthDataToLocalStorage(token, user);
            setAuthData({ userData: user, accessToken: token });
            options?.onSuccess?.(response);
          } else {
            options?.onError?.({
              type: EnumAuthLoginErrorType.UserNotVerified,
              error: 'User not verified',
            });
          }
        },
        onError: (error) => {
          options?.onError?.({ type: EnumAuthLoginErrorType.ServerResponseError, error });
        },
      });
    },
    [mutateLogin],
  );

  const acceptInvitation = useCallback(
    (data: AcceptInvitationDto, options?: IAuthMutateOptions) => {
      mutateAcceptInvitation(data, {
        onSuccess: (response) => {
          const { token, user } = response.data;
          if (user.roles.includes(UserRoles.Verified)) {
            setAuthDataToLocalStorage(token, user);
            setAuthData({ userData: user, accessToken: token });
            options?.onSuccess?.(response);
          } else {
            options?.onError?.({
              type: EnumAuthLoginErrorType.UserNotVerified,
              error: 'User not verified',
            });
          }
        },
        onError: (error) => {
          let errorMessage = '';

          if (error.response?.data?.message) {
            errorMessage = error.response.data.message;
          } else {
            errorMessage = 'Server error';
          }

          options?.onError?.({
            type: EnumAuthLoginErrorType.ServerResponseError,
            error: errorMessage,
          });
        },
      });
    },
    [mutateAcceptInvitation],
  );

  const acceptTerms = useCallback(
    (options?: IAuthMutateOptions) => {
      if (!authData.userData) {
        options?.onError?.({
          type: EnumAuthLoginErrorType.NoUserData,
          error: 'No user data',
        });
        return;
      }
      mutateAcceptTerms(
        { id: authData.userData.id, suffix: 'acceptTerms' },
        {
          onSuccess: (response) => {
            if (authData.accessToken) {
              setAuthDataToLocalStorage(authData.accessToken, response.data);
              setAuthData({ userData: response.data, accessToken: authData?.accessToken });
              options?.onSuccess?.(response);
            } else {
              options?.onError?.({
                type: EnumAuthLoginErrorType.NoUserData,
                error: 'No access token',
              });
            }
          },
          onError: (error) => {
            options?.onError?.({
              type: EnumAuthLoginErrorType.ServerResponseError,
              error: error as AxiosError,
            });
          },
        },
      );
    },
    [mutateAcceptTerms, authData],
  );

  const updateUser = useCallback(
    (data?: UpdateUserDto, options?: IAuthMutateOptions) => {
      if (!authData.userData) {
        options?.onError?.({
          type: EnumAuthLoginErrorType.NoUserData,
          error: 'No user data',
        });
        return;
      }
      mutateUpdateUser(
        { id: authData.userData.id, data: { ...data } },
        {
          onSuccess: (response) => {
            if (authData.accessToken) {
              setAuthDataToLocalStorage(authData.accessToken, response.data);
              setAuthData({ userData: response.data, accessToken: authData?.accessToken });

              options?.onSuccess?.(response);
            } else {
              options?.onError?.({
                type: EnumAuthLoginErrorType.NoAccessToken,
                error: 'No access token',
              });
            }
          },
          onError: (error) => {
            options?.onError?.({
              type: EnumAuthLoginErrorType.ServerResponseError,
              error: error as AxiosError,
            });
          },
        },
      );
    },
    [mutateAcceptTerms, authData],
  );
  const changePassword = useCallback(
    (data: ChangePasswordDto, options?: IAuthMutateOptions) => {
      if (!authData.userData) {
        options?.onError?.({
          type: EnumAuthLoginErrorType.NoUserData,
          error: 'No user data',
        });
        return;
      }
      mutateChangePassword(data, {
        onSuccess: (response) => {
          options?.onSuccess?.(response);
        },
        onError: (error) => {
          options?.onError?.({
            type: EnumAuthLoginErrorType.ServerResponseError,
            error: error as AxiosError,
          });
        },
      });
    },
    [mutateChangePassword, authData],
  );

  const logout = useCallback(() => {
    clearAuthData();
    setAuthData({ userData: null, accessToken: null });
  }, []);

  useEffect(() => {
    const syncWithLocalStorage = (event: StorageEvent) => {
      if (event.key === 'userData' || event.key === 'AccessToken') {
        setAuthData(getStoredAuthData());
      }
    };

    window.addEventListener('storage', syncWithLocalStorage);

    return () => {
      window.removeEventListener('storage', syncWithLocalStorage);
    };
  }, []);

  useEffect(() => {
    if (!authData.accessToken) return;
    axiosInstance.defaults.headers.common.Authorization = authData.accessToken
      ? `Bearer ${authData.accessToken}`
      : '';
  }, [authData.accessToken]);

  const isLoggedIn = !!authData.accessToken;
  const hasRole = useCallback(
    (role: UserRoles) => !!authData.userData?.roles.includes(role),
    [authData.userData?.roles],
  );

  const contextValue = useMemo(
    () => ({
      authData,
      login,
      acceptInvitation,
      logout,
      isLoadingAcceptTerms,
      isLoadingLogin,
      isLoadingUpdateUser,
      isLoadingChangePassword,
      isLoadingAcceptInvitation,
      isLoggedIn,
      hasRole,
      acceptTerms,
      updateUser,
      changePassword,
    }),
    [
      authData,
      isLoadingLogin,
      isLoadingAcceptTerms,
      isLoadingUpdateUser,
      isLoadingChangePassword,
      isLoadingAcceptInvitation,
      login,
      acceptInvitation,
      logout,
      isLoggedIn,
      hasRole,
      acceptTerms,
      updateUser,
      changePassword,
    ],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
