import { useNavigate } from 'react-router-dom';
import { Box, Card, Stack, Typography } from '@mui/material';
import howToHire1 from '../../../../assets/img/timed-projects/how-to-hire-1.png';
import howToHire2 from '../../../../assets/img/timed-projects/how-to-hire-2.png';
import howToHire3 from '../../../../assets/img/timed-projects/how-to-hire-3.png';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/project-icons/ArrowRightIcon.svg';
import { primaryLight, secondaryBlue } from '../../../../utils/color';
import CustomButton from '../../../../components/button/CustomButton';
import NewProjectCard from './NewProjectCard';

const TimeProjectsListCards = () => {
  const navigate = useNavigate();
  return (
    <Stack direction="row" spacing={3} maxHeight="30%" width="100%">
      <Card
        elevation={0}
        sx={{
          width: '50%',
          height: '100%',
          border: '1px solid',
          borderColor: 'secondaryBlue.100',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack
          direction="row"
          pl={2}
          pr={2}
          minHeight="30%"
          height="30%"
          width="100%"
          justifyContent="space-evenly"
          sx={{
            backgroundColor: secondaryBlue[50],
          }}
        >
          <Box
            component="img"
            src={howToHire1}
            height="100%"
            width="100%"
            sx={{ objectFit: 'contain' }}
          />
          <Box
            component="img"
            src={howToHire2}
            height="100%"
            width="100%"
            sx={{ objectFit: 'contain' }}
          />
          <Box
            component="img"
            src={howToHire3}
            height="100%"
            width="100%"
            sx={{ objectFit: 'contain' }}
          />
        </Stack>
        <Stack pt={1.5} p={2}>
          <Typography variant="heading5" color="primaryDark.600" mb={0.6}>
            How to hire
          </Typography>
          <Typography variant="body4" color="primaryDark.600">
            Click on create new project
          </Typography>
          <Typography variant="body4" color="primaryDark.600">
            Browse and select best pitches
          </Typography>
          <Typography variant="body4" color="primaryDark.600">
            Schedule meeting with sellers
          </Typography>
          <Typography variant="body4" color="primaryDark.600">
            Choose one seller for project
          </Typography>

          <CustomButton
            onClick={() => navigate('/how-to-hire')}
            variant="primaryText"
            endIcon={<ArrowRightIcon fill={primaryLight[500]} width="10px" />}
            disableRipple
            sx={{
              pt: 1,
            }}
          >
            Learn more
          </CustomButton>
        </Stack>
      </Card>
      <NewProjectCard />
    </Stack>
  );
};

export default TimeProjectsListCards;
