import { Stack, Typography } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ContractDto } from 'tdc-web-backend/contracts/schemas';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import { EnumProjectStatus } from 'tdc-web-backend/enums/enums';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import Button from '../../../../components/button/Button';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import CustomReferenceField from '../../../../components/custom-fields/CustomReferenceField';
import AdminAnnexList from './AdminAnnexList';
import CancelContractModal from './modals/CancelContractModal';
import AdminContractGeneralDetailsCard from './AdminContractDetailsCard';
import useReferenceOne from '../../../../utils/hooks/crud-hooks/useReferenceOne';
import useAuth from '../../../../utils/hooks/useAuth';

const AdminContractDetailScreen = () => {
  const { projectId } = useParams();
  const {
    authData: { userData },
  } = useAuth();

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const { data: contractsResponse } = useGetMany<ContractDto>({
    resource: `/contracts?project=${projectId}`,
    enabled: !!userData,
  });
  const { data: projectResponse } = useReferenceOne<ProjectDto>({
    resource: 'projects',
    id: projectId,
    enabled: !!userData,
  });

  const cancellationRequests = projectResponse?.cancellationRequests;

  const requesterId = cancellationRequests?.length ? cancellationRequests?.[0].requester.id : '';

  const { data: requesterCompany } = useReferenceOne<CompanyDto>({
    resource: 'companies',
    id: requesterId,
    enabled: false,
  });

  const contractData = contractsResponse?.data.results;
  const annexes = contractData?.filter((contract) => contract.name !== 'Contract');
  const contract = contractData?.filter((contract) => contract.name === 'Contract')?.[0];

  return (
    <>
      <Stack spacing={3} sx={{ mt: 4 }}>
        <Stack spacing={2} direction="row">
          <Stack width="50%">
            <Typography variant="h2" sx={{ mb: 2 }}>
              {projectResponse?.name}
            </Typography>
            <Typography variant="caption">Buyer:</Typography>
            <CustomReferenceField
              resource="companies"
              id={projectResponse?.buyer}
              displayName="name"
              variant="body1"
            />
            <Typography variant="caption">Seller:</Typography>
            <CustomReferenceField
              variant="body1"
              resource="companies"
              id={projectResponse?.seller}
              displayName="name"
            />
            <Stack direction="row" spacing={4} sx={{ mt: 4 }} justifyContent="spaceBetween">
              <Button
                variant="outlined"
                disabled={
                  projectResponse?.cancellationRequests.length === 0 ||
                  projectResponse?.status === EnumProjectStatus.Canceled
                }
                startIcon={<DeleteOutlineOutlinedIcon />}
                onButtonClick={() => setIsCancelModalOpen(true)}
                sx={{
                  borderRadius: '100px',
                  fontWeight: 500,
                }}
              >
                Cancel requested
              </Button>
            </Stack>
          </Stack>
          <AdminContractGeneralDetailsCard data={contract as ContractDto} />
        </Stack>
        <Stack spacing={2}>
          <Typography variant="h4">Annexes</Typography>
          <AdminAnnexList rows={annexes ?? []} />
        </Stack>
      </Stack>
      <CancelContractModal
        data={contract as ContractDto}
        setIsOpen={setIsCancelModalOpen}
        isOpen={isCancelModalOpen}
        title="Cancel the contract"
        paragraph={`${
          requesterCompany?.name ?? ''
        } has requested to cancel the contract. Are you sure you want to cancel the contract?`}
      />
    </>
  );
};

export default AdminContractDetailScreen;
