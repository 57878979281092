import { Box, Typography } from '@mui/material';

const ErrorMessage = ({ message = '' }: { message?: string }) => (
  <Box
    sx={{
      borderRadius: '4px',
      borderColor: 'secondaryPink.700',
      backgroundColor: 'secondaryPink.100',
      p: 4,
      mt: '4rem',
    }}
  >
    <Typography
      variant="headline5"
      sx={{
        color: 'secondaryPink.500',
      }}
    >
      {message}
    </Typography>
  </Box>
);

export default ErrorMessage;
