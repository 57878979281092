import { Stack, Typography } from '@mui/material';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import { EnumCurrency } from 'tdc-web-backend/enums/enums';
import { PaymentsList } from '../../Project/payments/PaymentsList';
import RecentPayments from './RecentPayments';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import PaymentLinearProgressCard from './PaymentLinearProgressCard';
import RevenueChart from '../../ProjectDetail/RevenueChart/RevenueChart';
import DashboardCard from '../../../../components/dashboard-card/EndToEndCard.tsx/DashboardCard';
import useAuth from '../../../../utils/hooks/useAuth';

const AllPaymentsScreen = () => {
  const {
    authData: { userData },
  } = useAuth();

  const { data: paymentsData } = useGetMany<MilestoneDto>({
    resource: `/milestones?company=${userData?.membership?.company}`,
  });
  const { data: projects } = useGetMany<ProjectDto>({
    resource: `/projects?company=${userData?.membership?.company}`,
  });

  return (
    <>
      <Stack spacing={5} sx={{ py: 2 }}>
        <Stack spacing={0.2}>
          <Typography variant="h4">Payments</Typography>
        </Stack>
        <Stack spacing={4} direction="row">
          <DashboardCard
            mainCardClassName="react-joyride-step-1-payments"
            headline="Total balance"
            sx={{ width: '49%' }}
            renderComponent={
              <RevenueChart
                milestones={paymentsData?.data.results as MilestoneDto[]}
                currency={paymentsData?.data.results[0]?.currency as EnumCurrency}
              />
            }
            renderComponentSx={{ height: '100%' }}
          />

          <DashboardCard
            mainCardClassName="react-joyride-step-2-payments"
            headline="Payment summary"
            sx={{ width: '49%' }}
            renderComponent={<RecentPayments rows={paymentsData?.data.results ?? []} />}
          />
        </Stack>

        <PaymentLinearProgressCard payments={paymentsData?.data.results as MilestoneDto[]} />
        <PaymentsList payments={projects?.data.results || []} />
      </Stack>
    </>
  );
};

export default AllPaymentsScreen;
