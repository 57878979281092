import { Stack, Typography, Button as MUIButton, SxProps, Theme } from '@mui/material';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EnumProjectMilestoneStatus } from 'tdc-web-backend/enums/enums';
import { MilestoneSnakeDto } from 'tdc-web-backend/milestones/schemas';
import { TaskDto } from 'tdc-web-backend/tasks/schemas';
import { primaryDark, secondaryBlue } from '../../../../../utils/color';
import StatusChipField from '../../../../../components/StatusChipField/StatusChipField';
import RecurringMilestoneDropdown from '../Recurring/RecurringMilestoneDropdown';
import { formatDateFns } from '../../../../../utils/helpers';
import CustomTooltip from '../../../../../components/tooltip/CustomTooltip';

interface CarouselItemProps {
  isMouseDown: boolean;
  setIsMouseDown: Dispatch<SetStateAction<boolean>>;
  item: MilestoneSnakeDto;
  setTaskId: Dispatch<SetStateAction<string | null>>;
  determineWhichTaskToShow: (tasks: TaskDto[]) => string | null;
  sx?: SxProps<Theme> | undefined;
  carouselItemWidth: number;
}

const CarouselItem = ({
  isMouseDown,
  setIsMouseDown,
  item,
  setTaskId,
  determineWhichTaskToShow,
  sx,
  carouselItemWidth,
}: CarouselItemProps) => {
  const navigate = useNavigate();
  const params = useParams();

  const doesRecurringMilestoneIdMatchParam = item.relatedMilestones.some(
    (relatedMilestone: MilestoneSnakeDto['relatedMilestones']) =>
      relatedMilestone.id === params.milestoneId,
  );

  const doesMilestoneIdMatchParam = item.id === params.milestoneId;

  //
  const determineBackgroundColor = useCallback(
    (item: MilestoneSnakeDto) => {
      if (item.relatedMilestones.length > 0) {
        const shouldColor = doesRecurringMilestoneIdMatchParam;

        if (shouldColor) {
          return secondaryBlue[50];
        }
      }

      if (item.relatedMilestones.length === 0) {
        const shouldColor = doesMilestoneIdMatchParam;

        if (shouldColor) {
          return secondaryBlue[50];
        }
      }

      if (
        item.status === EnumProjectMilestoneStatus.Completed ||
        item.status === EnumProjectMilestoneStatus.Approved
      ) {
        return primaryDark[150];
      }

      return '#fff';
    },
    [doesRecurringMilestoneIdMatchParam, doesMilestoneIdMatchParam, item],
  );

  const determineBorderColor = useCallback(
    (item: MilestoneSnakeDto) => {
      if (item.relatedMilestones.length > 0) {
        const shouldColor = doesRecurringMilestoneIdMatchParam;

        if (shouldColor) {
          return secondaryBlue[100];
        }
      }

      if (item.relatedMilestones.length === 0) {
        const shouldColor = doesMilestoneIdMatchParam;

        if (shouldColor) {
          return secondaryBlue[100];
        }
      }

      if (
        item.status === EnumProjectMilestoneStatus.Completed ||
        item.status === EnumProjectMilestoneStatus.Approved
      ) {
        return 'transparent';
      }

      return secondaryBlue[100];
    },
    [doesRecurringMilestoneIdMatchParam, doesMilestoneIdMatchParam, item],
  );

  return (
    <MUIButton
      disableRipple={isMouseDown}
      onClick={() => {
        // timeout is here to let the button do its ripple
        // effect fully, and then trigger the onClick()
        // which sets the activeItem inside the SingleSlideCarousel component
        setTimeout(() => {
          // if milestone has relatedMilestones (recurring)
          if (item.relatedMilestones && item.relatedMilestones.length > 0) {
            // if one of those relatedMilestones is currently
            // active (in progress)
            if (item.currentMilestone) {
              navigate(`/dashboard/end-to-end/milestone/${item.currentMilestone}`);

              if (item && item.tasks.length > 0) {
                setTaskId(determineWhichTaskToShow(item.tasks));
              }
            }

            // if none of those relatedMilestones is currently active,
            // navigate to the first one
            navigate(`/dashboard/end-to-end/milestone/${item.relatedMilestones[0]?.id}`);

            if (item && item.relatedMilestones[0].tasks.length > 0) {
              setTaskId(determineWhichTaskToShow(item.relatedMilestones[0].tasks));
            }
          } else {
            // if milestone has no relatedMilestones
            navigate(`/dashboard/end-to-end/milestone/${item.id}`);

            if (item && item.tasks) {
              setTaskId(determineWhichTaskToShow(item.tasks));
            }
          }
        }, 200);
      }}
      key={item?.id}
      sx={{
        display: 'block',
        flex: 1,
        p: 2,
        backgroundColor: determineBackgroundColor(item),
        borderRadius: '10px',
        border: '1px solid',
        borderColor: determineBorderColor(item),
        height: 127,
        boxShadow:
          doesRecurringMilestoneIdMatchParam || doesMilestoneIdMatchParam
            ? '1px 10px 30px 0px rgba(56, 69, 114, 0.10)'
            : 'none',
        minWidth: carouselItemWidth,
        maxWidth: carouselItemWidth,
        '&:hover': {
          backgroundColor:
            doesRecurringMilestoneIdMatchParam || doesMilestoneIdMatchParam
              ? secondaryBlue[50]
              : primaryDark[150],
          borderColor:
            doesRecurringMilestoneIdMatchParam || doesMilestoneIdMatchParam
              ? secondaryBlue[100]
              : secondaryBlue[200],
        },
        ...sx,
      }}
    >
      <Stack height="100%" justifyContent="space-between">
        {/* milestone name */}
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <CustomTooltip title={item.name} placement="top">
            <Typography
              color={
                doesRecurringMilestoneIdMatchParam || doesMilestoneIdMatchParam
                  ? 'primaryLight.500'
                  : item.status === EnumProjectMilestoneStatus.Completed
                  ? 'secondaryGreen.700'
                  : 'primaryDark.600'
              }
              sx={{
                alignSelf: 'flex-start',
                typography: { md: 'body2', lg: 'body1' },
                textDecoration:
                  item.status === EnumProjectMilestoneStatus.Completed ? 'line-through' : 'none',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {item.name}
            </Typography>
          </CustomTooltip>
          {item.relatedMilestones && item.relatedMilestones?.length > 0 ? (
            <RecurringMilestoneDropdown
              milestone={item}
              setIsMouseDown={setIsMouseDown}
              setTaskId={setTaskId}
              determineWhichTaskToShow={determineWhichTaskToShow}
            />
          ) : (
            <StatusChipField text={item.status} />
          )}
        </Stack>

        <Stack spacing={0.75}>
          {/* tasks */}
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="heading5" color="primaryDark.500">
              Tasks
            </Typography>

            <Typography variant="heading4" color="primaryDark.600">
              {item.tasksCount}
            </Typography>
          </Stack>

          {/* start */}
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="heading5" color="primaryDark.500">
              Start date
            </Typography>

            <Typography variant="heading4" color="primaryDark.600">
              {formatDateFns(item.start, '-')}
            </Typography>
          </Stack>

          {/* due date */}
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="heading5" color="primaryDark.500">
              End date
            </Typography>

            <Typography variant="heading4" color="primaryDark.600">
              {formatDateFns(item.end, '-')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </MUIButton>
  );
};

export default CarouselItem;
