import Stack from '@mui/system/Stack/Stack';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';
import ControlledTextInput from '../../../../components/custom-inputs/ControlledTextInput';
import CustomInput from '../../../../components/custom-input/CustomInput';
import useAuth from '../../../../utils/hooks/useAuth';

const Admin = () => {
  const {
    authData: { userData: user },
  } = useAuth();

  return (
    <Stack>
      <BaseInputLabel text="account administrator" />
      <Stack flexDirection="row" mt={1}>
        <Stack
          width="100%"
          pr={3}
          sx={{ borderRight: '1px solid', borderColor: 'secondaryBlue.100' }}
        >
          <ControlledTextInput
            name="admin.email"
            placeholder={user?.email}
            label="User email"
            disabled
          />
        </Stack>

        <Stack width="100%" pl={3}>
          <CustomInput
            input={<CustomInput.Text />}
            name="admin.jobTitle"
            placeholder="Enter job title"
            label="Job title"
            required
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Admin;
