import { Box, Stack, Tabs, Tab } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

const tabStyle = {
  '&:hover': {
    color: 'primaryLight.500',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: 'primaryLight.500',
  },
};

export interface LinkTabsItem {
  value: string;
  label: string;
  to: string;
}

export interface LinkTabsProps {
  tabs: LinkTabsItem[];
}

const LinkTabs = ({ tabs }: LinkTabsProps) => {
  const location = useLocation();
  const currentTab = location.pathname.split('/').pop() || tabs[0].value;

  return (
    <Stack spacing={3}>
      <Box>
        <Tabs
          value={currentTab}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTabs-indicator': {
              backgroundColor: 'primaryLight.500',
            },
          }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              component={Link}
              to={tab.to}
              sx={{ ...tabStyle }}
            />
          ))}
        </Tabs>
      </Box>
    </Stack>
  );
};

export default LinkTabs;
