import { useNavigate, useParams } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { BaseUserDto } from 'tdc-web-backend/users/shemas';
import BackButton from '../../../../../../components/button/BackButton';
import useGetOne from '../../../../../../utils/hooks/crud-hooks/useGetOne';
import LoadingIndicator from '../../../../../../components/loading-indicator/LoadingIndicator';
import Error from '../../../../../../components/error/Error';
import MemberForm from './components/MemberForm';

const Member = () => {
  const navigate = useNavigate();
  const { userId } = useParams();

  const {
    data: userDataResponse,
    isLoading: isLoadingGetUser,
    isSuccess,
  } = useGetOne<BaseUserDto & { avatar: string }>({
    resource: `users/${userId}`,
    enabled: !!userId,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    staleTime: 0,
  });

  const userData = userDataResponse?.data;

  return (
    <Stack spacing={3}>
      <BackButton onClick={() => navigate('..', { relative: 'path' })} />
      <Typography variant="heading3" color="primaryDark.500" sx={{ pt: '1rem' }}>
        Account
      </Typography>
      {isLoadingGetUser ? (
        <LoadingIndicator />
      ) : isSuccess && userData ? (
        <MemberForm userData={userData} />
      ) : (
        <Error />
      )}
    </Stack>
  );
};

export default Member;
