import { Grid, Typography, useMediaQuery } from '@mui/material';
import { height, Stack } from '@mui/system';
import useGetMany from '../../../../../utils/hooks/crud-hooks/useGetMany';
import { BlogDto } from 'tdc-web-backend/blogs/schemas';
import BlogCard from '../../../Blog/components/BlogCard';
import theme from '../../../../../utils/theme';
import LoadingIndicator from '../../../../../components/loading-indicator/LoadingIndicator';

const BlogArchiveFeatured = () => {
  const {
    data: response,
    refetch,
    isLoading,
  } = useGetMany<BlogDto>({
    resource: `/blogs?featuredPosition=one&featuredPosition=two&featuredPosition=three`,
  });
  const blogs = response?.data.results;
  const positionOne = blogs?.find((blog) => blog.featuredPosition === 'one');
  const positionTwo = blogs?.find((blog) => blog.featuredPosition === 'two');
  const positionThree = blogs?.find((blog) => blog.featuredPosition === 'three');
  const isLessThanSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {(isLoading || (positionOne && positionTwo && positionThree)) && (
        <Stack sx={{ pb: 2.5 }}>
          <Typography variant="publicBody1" color="primaryDark.900" sx={{ mb: '1rem', ml: 1 }}>
            Featured Blogs
          </Typography>

          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <Grid
              container
              gridAutoRows="max-content"
              sx={{ background: 'transparent' }}
              spacing={2}
            >
              <Grid item bg={5} xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <BlogCard blogData={positionOne as BlogDto} featured orientation="vertical" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item bg={7} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <BlogCard
                      blogData={positionTwo as BlogDto}
                      orientation={isLessThanSm ? 'vertical' : 'horizontal'}
                      featured
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BlogCard
                      blogData={positionThree as BlogDto}
                      orientation={isLessThanSm ? 'vertical' : 'horizontal'}
                      featured
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Stack>
      )}
    </>
  );
};

export default BlogArchiveFeatured;
