/* eslint-disable import/no-unresolved */
import { Box, Stack, Typography } from '@mui/material';
import { FeaturedCaseStudiesDto } from 'tdc-web-backend/featured-case-studies/schemas';
import { BaseCaseStudy } from 'tdc-web-backend/case-studies/schemas';
import CenterCarousel, { SliderWrapperItem } from '../components/CenterCarousel';
import BrowseByService from '../components/BrowseByService';
import { PUBLIC_APPBAR_HEIGHT } from '../../../../layout/public-layout/navigation/Navigation';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import HeroSection from '../components/HeroSection';
import CaseStudiesPerCategory from '../components/CaseStudiesPerCategory';
import LaptopSection from '../components/LaptopSection';
import CustomReferenceField from '../../../../components/custom-fields/CustomReferenceField';
import CaseStudyCard from '../components/CaseStudyCard';
import LoadingIndicator from '../../../../components/loading-indicator/LoadingIndicator';
import { getPublicImageUrl } from '../../../../utils/helpers';
import { EnumPublicPagesImage } from '../../../../utils/enums';

const CaseStudyArchive = () => {
  const { data: response, isLoading } = useGetMany<FeaturedCaseStudiesDto>({
    resource: `/featured-case-studies`,
  });

  const featuredSections = response?.data.results;

  const mainSection = featuredSections?.find((section) => section.main === true);
  const restSections = featuredSections?.filter((section) => section.main !== true);

  return (
    <Stack overflow="hidden" maxWidth="100%" alignSelf="center" mt={`${PUBLIC_APPBAR_HEIGHT}px`}>
      <HeroSection
        imgUrl={getPublicImageUrl(EnumPublicPagesImage.CaseStudyArchiveHero)}
        title="Case studies"
        description="Explore top projects from industry-leading digital service sellers"
        link="/case-study-subarchive"
        linkText="View all"
      />
      <Stack direction="column" justifyContent="center" alignItems="center" pb="2rem" spacing={1}>
        <Typography variant="publicHeading4">{mainSection?.title}</Typography>
        <Typography
          variant="publicBody5Light"
          sx={{ textAlign: 'center' }}
          maxWidth={{ xs: '90%', sm: '60%', md: '58%', lg: '50%', xl: '30%' }}
        >
          Discover the best of the best in the world of digital projects. Get inspired by stories of
          creativity, innovation and results-driven success.
        </Typography>
      </Stack>
      <Box sx={{ px: '0.625rem', mb: { xs: '5rem', gr: '7.5rem' } }}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <CenterCarousel
            data={mainSection?.caseStudies}
            renderCustomComponent={(data) =>
              data?.map((caseStudy: Partial<BaseCaseStudy>) => (
                <CustomReferenceField
                  resource="case-studies"
                  key={caseStudy?.id}
                  displayName={caseStudy?.title as string}
                  id={caseStudy?.id as string}
                  renderComponent={(item) => (
                    // eslint-disable-next-line no-use-before-define
                    <SliderWrapperItem key={item?.id}>
                      <CaseStudyCard data={item} />
                    </SliderWrapperItem>
                  )}
                />
              ))
            }
          />
        )}
      </Box>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {restSections?.[0].caseStudies.length > 0 && (
            <CaseStudiesPerCategory
              sectionTitle={restSections?.[0].title as string}
              bgcolor="secondaryOrange.50"
              data={restSections?.[0]}
            />
          )}
        </>
      )}
      <LaptopSection
        imgUrl={getPublicImageUrl(EnumPublicPagesImage.CaseStudyArchiveMacbook)}
        title="Be inspired"
        description="Browse project case studies from the world's leading digital service sellers and see what you could achieve for your business."
        link="/case-study-subarchive"
        linkText="View all case studies"
      />
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {restSections?.[1].caseStudies.length > 0 && (
            <CaseStudiesPerCategory
              sectionTitle={restSections?.[1].title as string}
              data={restSections?.[1]}
            />
          )}
        </>
      )}
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {restSections?.[2].caseStudies.length > 0 && (
            <CaseStudiesPerCategory
              sectionTitle={restSections?.[2].title as string}
              bgcolor="secondaryOrange.50"
              data={restSections?.[2]}
            />
          )}
        </>
      )}
      <BrowseByService link="/case-study-subarchive" type="case_study_archive" />
    </Stack>
  );
};
export default CaseStudyArchive;
