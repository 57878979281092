import { useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { BlogDto } from 'tdc-web-backend/blogs/schemas';
import { EnumBlogFeaturedPosition } from 'tdc-web-backend/enums/enums';
import { Divider, Stack, Typography } from '@mui/material';
import { primaryLight } from '../../../utils/color';
import useGetMany from '../../../utils/hooks/crud-hooks/useGetMany';
import CustomInput from '../../../components/custom-input/CustomInput';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';
import CustomButton from '../../../components/button/CustomButton';

type FeturedBlogs = {
  one: BlogDto | null;
  two: BlogDto | null;
  three: BlogDto | null;
};

type FeturedBlogIds = {
  one: string;
  two: string;
  three: string;
};

const getUpdatedDataSources = (allBlogs: BlogDto[], featuredBlogs: FeturedBlogIds) => {
  const selectDataSources = allBlogs.reduce(
    (acc, blog) => {
      if (blog.id === featuredBlogs.one) {
        acc.one.set(blog.id, blog.headline);
      } else if (blog.id === featuredBlogs.two) {
        acc.two.set(blog.id, blog.headline);
      } else if (blog.id === featuredBlogs.three) {
        acc.three.set(blog.id, blog.headline);
      } else {
        acc.one.set(blog.id, blog.headline);
        acc.two.set(blog.id, blog.headline);
        acc.three.set(blog.id, blog.headline);
      }
      return acc;
    },
    {
      one: new Map<string, string>([]),
      two: new Map<string, string>([]),
      three: new Map<string, string>([]),
    },
  );
  return selectDataSources;
};

const getDataSources = (allBlogs: BlogDto[]) => {
  const featuredBlogs: FeturedBlogs = {
    one: null,
    two: null,
    three: null,
  };

  const selectDataSources = allBlogs.reduce(
    (acc, blog) => {
      if (blog.featuredPosition === EnumBlogFeaturedPosition.One) {
        acc.one.set(blog.id, blog.headline);
        featuredBlogs.one = blog;
      } else if (blog.featuredPosition === EnumBlogFeaturedPosition.Two) {
        acc.two.set(blog.id, blog.headline);
        featuredBlogs.two = blog;
      } else if (blog.featuredPosition === EnumBlogFeaturedPosition.Three) {
        featuredBlogs.three = blog;
        acc.three.set(blog.id, blog.headline);
      } else {
        acc.one.set(blog.id, blog.headline);
        acc.two.set(blog.id, blog.headline);
        acc.three.set(blog.id, blog.headline);
      }
      return acc;
    },
    {
      one: new Map<string, string>([]),
      two: new Map<string, string>([]),
      three: new Map<string, string>([]),
    },
  );
  return { selectDataSources, featuredBlogs };
};

const FeaturedBlogs = () => {
  const [dataSources, setDatasources] = useState({
    one: new Map<string, string>([]),
    two: new Map<string, string>([]),
    three: new Map<string, string>([]),
  });

  const form = useForm({
    defaultValues: {
      one: '',
      two: '',
      three: '',
    },
    mode: 'onSubmit',
  });

  const { data: response, refetch } = useGetMany<BlogDto>({
    resource: `/blogs?limit=1000`,
  });

  const { mutate: create, isLoading } = useCreate({ resource: 'blogs/featured' });

  const allBlogs = response?.data.results;

  useEffect(() => {
    const resetForm = async () => {
      if (allBlogs) {
        const { selectDataSources, featuredBlogs } = getDataSources(allBlogs);
        setDatasources(selectDataSources);
        form.reset({
          one: featuredBlogs.one?.id || '',
          two: featuredBlogs.two?.id || '',
          three: featuredBlogs.three?.id || '',
        });
        form.trigger();
        // setShowContent(true);
      }
    };
    resetForm();
  }, [allBlogs]);

  const onPublish = (formData: FieldValues) => {
    create(formData);
  };

  useEffect(() => {
    const subscription = form.watch((value, { name, type }) => {
      if (allBlogs) {
        return setDatasources(
          getUpdatedDataSources(allBlogs as BlogDto[], value as FeturedBlogIds),
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [form.watch]);

  return (
    <FormProvider {...form}>
      <form>
        <Stack
          sx={{
            padding: '1.5rem 1.5rem 0 1.5rem',
            borderRadius: '8px',
            border: '1px solid',
            borderColor: primaryLight[50],
            minHeight: '100%',
            alignItems: 'stretch',
          }}
          divider={<Divider orientation="horizontal" />}
        >
          <Stack
            sx={{ height: '61px', pr: '1.5rem' }}
            direction="row"
            justifyContent="space-between"
          >
            <Typography variant="headline3" sx={{ marginTop: '6px' }} color="#384572">
              Featured blogs
            </Typography>
            <CustomButton
              variant="primary"
              sx={{ height: '2.8125rem' }}
              onClick={form.handleSubmit(onPublish)}
              loading={isLoading}
            >
              publish
            </CustomButton>
          </Stack>
          <Stack spacing={3} sx={{ py: '2rem' }} maxWidth="53rem" height="100%">
            <CustomInput
              input={<CustomInput.Select />}
              name="one"
              placeholder="Position one"
              choices={dataSources?.one}
              label="Position one"
              disabled={isLoading}
              sx={{
                width: '100%',
                '& .MuiInputBase-root': {
                  borderTopLeftRadius: '0px',
                  borderBottomLeftRadius: '0px',
                },
              }}
              required
            />
            <CustomInput
              input={<CustomInput.Select />}
              name="two"
              placeholder="Position two"
              choices={dataSources?.two}
              label="Position two"
              disabled={isLoading}
              sx={{
                width: '100%',
                '& .MuiInputBase-root': {
                  borderTopLeftRadius: '0px',
                  borderBottomLeftRadius: '0px',
                },
              }}
              required
            />
            <CustomInput
              input={<CustomInput.Select />}
              name="three"
              placeholder="Position three"
              choices={dataSources?.three}
              label="Position three"
              disabled={isLoading}
              sx={{
                width: '100%',
                '& .MuiInputBase-root': {
                  borderTopLeftRadius: '0px',
                  borderBottomLeftRadius: '0px',
                },
              }}
              required
            />
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default FeaturedBlogs;
