import { Box, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { UserInvitationExtendedDto } from 'tdc-web-backend/user-invitation/schemas';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import Link from '../../../../components/link/Link';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';
import CustomInput from '../../../../components/custom-input/CustomInput';
import useAuth from '../../../../utils/hooks/useAuth';
import CustomButton from '../../../../components/button/CustomButton';
import SignInLink from './SignInLink';
import InvalidToken from './InvalidToken';

interface AcceptFormProps {
  invitationData: UserInvitationExtendedDto;
  token: string;
}

const AcceptForm = ({ invitationData, token }: AcceptFormProps) => {
  const [showPass, setShowPass] = useState<Record<string, boolean>>({
    password: false,
    repeatPassword: false,
  });
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const { acceptInvitation, isLoadingAcceptInvitation } = useAuth();

  const formMethods = useForm({
    defaultValues: {
      jobTitle: invitationData.jobTitle || '',
      firstName: invitationData.firstName || '',
      lastName: invitationData.lastName || '',
      confirmPassword: '',
      password: '',
      TermsAndConditions: false,
    },
    mode: 'onChange',
  });
  const { watch, handleSubmit, getValues } = formMethods;

  const onSubmit = () => {
    acceptInvitation(
      {
        token: token as string,
        jobTitle: getValues('jobTitle'),
        password: getValues('password'),
        firstName: getValues('firstName'),
        lastName: getValues('lastName'),
      },
      {
        onError: (error) => {
          setErrorMsg(error.error as string);
        },
      },
    );
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {errorMsg ? (
          <InvalidToken message={errorMsg} />
        ) : (
          <Stack spacing={3}>
            <Box
              sx={{
                // border: '1px solid',
                borderColor: 'primaryDark.400',
                borderRadius: '4px',
                backgroundColor: 'primaryLight.50',
                p: 1,
              }}
            >
              <Typography
                variant="body3"
                sx={{
                  lineHeight: '1.25rem',
                  color: 'primaryDark.600',
                }}
              >
                You've been invited by{' '}
                <Link
                  sx={{ fontFamily: 'Gellix-SemiBold' }}
                  to={`mailto:${invitationData.sentBy.email}`}
                >
                   {invitationData.sentBy.email}
                </Link>{' '}
                to join their business on Spona. Create an account to log in to your new dashboard.
              </Typography>
            </Box>

            <Stack spacing={0.25}>
              <BaseInputLabel text="Email" sx={{ mb: 0 }} />

              <Typography variant="bodyMedium2" color="primaryDark.800">
                {invitationData.email}
              </Typography>
            </Stack>

            <CustomInput
              input={<CustomInput.Text />}
              name="firstName"
              label="First name"
              disabled={isLoadingAcceptInvitation}
              required
            />

            <CustomInput
              input={<CustomInput.Text />}
              name="lastName"
              label="Last name"
              disabled={isLoadingAcceptInvitation}
              required
            />

            <CustomInput
              input={<CustomInput.Text />}
              name="jobTitle"
              label="Job title"
              disabled={isLoadingAcceptInvitation}
              required
            />

            <CustomInput
              input={<CustomInput.Text />}
              type={showPass.password ? 'text' : 'password'}
              name="password"
              label="Password"
              disabled={isLoadingAcceptInvitation}
              required
              defaultHelperText="Your password must be a minimum of 8 characters in length and contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character."
              placeholder="••••••••"
              adornment={{
                icon: showPass.password ? (
                  <VisibilityIcon sx={{ color: 'primaryDark.400', width: 22, height: 22 }} />
                ) : (
                  <VisibilityOffIcon sx={{ color: 'primaryDark.400', width: 22, height: 22 }} />
                ),
                onClick: () => {
                  setShowPass({ ...showPass, password: !showPass.password });
                },
              }}
              validationRegEx={{
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/,
                message:
                  'Your password must be a minimum of 8 characters in length and contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character.',
              }}
              rules={{
                maxLength: {
                  value: 127,
                  message:
                    'Password is too long. Please choose a password with fewer than 128 characters',
                },
              }}
            />

            <CustomInput
              input={<CustomInput.Text />}
              type={showPass.repeatPassword ? 'text' : 'password'}
              name="confirmPassword"
              label="Repeat password"
              disabled={isLoadingAcceptInvitation}
              required
              placeholder="••••••••"
              adornment={{
                icon: showPass.repeatPassword ? (
                  <VisibilityIcon sx={{ color: 'primaryDark.400', width: 22, height: 22 }} />
                ) : (
                  <VisibilityOffIcon sx={{ color: 'primaryDark.400', width: 22, height: 22 }} />
                ),
                onClick: () => {
                  setShowPass({ ...showPass, repeatPassword: !showPass.repeatPassword });
                },
              }}
              validate={() =>
                watch('confirmPassword') === watch('password') || 'Passwords do not match'
              }
              rules={{
                maxLength: {
                  value: 127,
                  message:
                    'Password is too long. Please choose a password with fewer than 128 characters',
                },
              }}
            />

            <CustomInput
              input={<CustomInput.UserAgreement />}
              name="TermsAndConditions"
              disabled={isLoadingAcceptInvitation}
              required
              customRequiredMessage="Agree to terms to continue"
            />

            <Stack
              spacing={2}
              direction={{ xs: 'column-reverse', lg: 'row' }}
              alignSelf="center"
              width="100%"
              justifyContent="space-between"
            >
              <SignInLink disabled={isLoadingAcceptInvitation} />
              <CustomButton
                type="submit"
                loading={isLoadingAcceptInvitation}
                variant="primary"
                sx={{ width: { xs: '100%', lg: '55%' } }}
              >
                Create Account
              </CustomButton>
            </Stack>
          </Stack>
        )}
      </form>
    </FormProvider>
  );
};
export default AcceptForm;
