import { useNavigate, useParams } from 'react-router-dom';
import { ProjectRequestDto } from 'tdc-web-backend/project-requests/schemas';
import { Box, Divider, Stack, Typography } from '@mui/material';
import useReferenceOne from '../../../../utils/hooks/crud-hooks/useReferenceOne';
import { primaryLight } from '../../../../utils/color';
import RequestOverview from './components/RequestOverview';
import CreateProject from './components/CreateProject';
import CustomButton from '../../../../components/button/CustomButton';

const ProjectRequest = () => {
  const navigate = useNavigate();
  const params = useParams();
  const projectRequestId = params?.projectRequestId || '';

  const { data: requestData } = useReferenceOne<ProjectRequestDto>({
    id: projectRequestId,
    resource: 'project-requests',
    enabled: !!projectRequestId,
  });

  const projectCreated = requestData?.project !== null;

  return (
    <Stack
      sx={{
        padding: '1.5rem 1.5rem 0 1.5rem',
        borderRadius: '8px',
        border: '1px solid',
        borderColor: primaryLight[50],
        minHeight: '100%',
        alignItems: 'stretch',
      }}
      divider={<Divider orientation="horizontal" />}
    >
      <Stack sx={{ height: '61px' }} direction="row" justifyContent="space-between">
        <Typography variant="headline3" sx={{ marginTop: '6px' }} color="#384572">
          Project request
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Box sx={{ width: '100%', padding: '1.5em 1.5em 1.5em 0', maxWidth: '1200px' }}>
          <Stack spacing={2}>
            {requestData && projectCreated && (
              <CustomButton
                variant="secondary"
                withNextArrow
                onClick={() => navigate(`../projects/${requestData?.project}`)}
                sx={{ width: 'fit-content' }}
              >
                Open project
              </CustomButton>
            )}
            <RequestOverview data={requestData} />
            {requestData && !projectCreated && <CreateProject projectRequestData={requestData} />}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};

export default ProjectRequest;
