import { Box, Stack, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { useEffect, useState } from 'react';
import {
  EnumProjectContractStatus,
  EnumProjectMilestoneStatus,
  EnumProjectStatus,
  PaymentStatus,
} from 'tdc-web-backend/enums/enums';
import { secondaryBlue, secondaryGreen, secondaryPink, secondaryYellow } from '../../utils/color';
import {
  mapProjectContractStatus,
  mapProjectMilestoneStatus,
  mapProjectPaymentStatus,
  mapProjectStatus,
  mapPublicationStatus,
  mapTempStatusEnum,
} from '../../utils/enums';
import { shadeColor } from '../../utils/helpers';
import { TempStatusEnum } from '../../utils/enums';

// TODO: refactor for real
export interface TaskStatusProps {
  text?: TempStatusEnum | EnumProjectStatus | PaymentStatus | any;
  sx?: SxProps<Theme> | undefined;
  fontSize?: string | number;
  componentReturnType?: string;
  className?: string;
}

export function TaskStatus({
  text,
  fontSize = '10px !important',
  sx,
  className,
  componentReturnType = 'default',
}: TaskStatusProps) {
  const [backgroundColor, setBackgroundColor] = useState('');
  const [borderColor, setBorderColor] = useState('');
  const [color, setColor] = useState('');
  const [mapToGetTheValueFrom, setMapToGetTheValueFrom] = useState<Map<string, string> | null>(
    null,
  );

  useEffect(() => {
    if (Object.values<string>(TempStatusEnum).includes(text)) {
      setMapToGetTheValueFrom(mapTempStatusEnum);
    } else if (Object.values<string>(EnumProjectStatus).includes(text)) {
      setMapToGetTheValueFrom(mapProjectStatus);
    } else if (Object.values<string>(PaymentStatus).includes(text)) {
      setMapToGetTheValueFrom(mapProjectPaymentStatus);
    } else if (Object.values<string>(EnumProjectContractStatus).includes(text)) {
      setMapToGetTheValueFrom(mapProjectContractStatus);
    } else if (Object.values<string>(EnumProjectMilestoneStatus).includes(text)) {
      setMapToGetTheValueFrom(mapProjectMilestoneStatus);
    }
  }, [text]);

  useEffect(() => {
    switch (text) {
      // STATUS BLUE #DFE0FF
      case EnumProjectStatus.InProgress:
      case EnumProjectMilestoneStatus.UnderReview:
      case EnumProjectContractStatus.Negotiation:
      case TempStatusEnum.In_progress:
      case TempStatusEnum.InProgress:
      case TempStatusEnum.Pending:
      case TempStatusEnum.Active:
        if (componentReturnType === 'default') {
          setBackgroundColor('#DFE0FF');
          setColor('#202578');
          break;
        }

        setBackgroundColor(secondaryBlue['700']);
        setBorderColor(secondaryBlue['100']);
        break;
      // STATUS GREEN #D1F6D0
      case EnumProjectContractStatus.Active:
      case EnumProjectContractStatus.Accepted:
      case PaymentStatus.Paid:
      case EnumProjectStatus.Completed:
      case EnumProjectMilestoneStatus.Approved:
      case TempStatusEnum.Paid:
        if (componentReturnType === 'default') {
          setBackgroundColor('#D1F6D0');
          setColor('#015733');
          break;
        }

        setBackgroundColor(secondaryGreen['500']);
        setBorderColor(secondaryGreen['200']);
        setColor(secondaryGreen['200']);
        break;
      // STATUS YELLOW #F8EBCB
      case EnumProjectStatus.Pending:
      case PaymentStatus.Late:
      case PaymentStatus.InProgress:
        if (componentReturnType === 'default') {
          setBackgroundColor('#F8EBCB');
          setColor('#553F05');
          break;
        }

        setBackgroundColor(secondaryYellow['700']);
        setBorderColor(secondaryYellow['200']);
        setColor(secondaryYellow['200']);
        break;
      // STATUS RED #FFB4A9
      // STATUS PURPlE #E8DEFF
      case EnumProjectContractStatus.Draft:
      case TempStatusEnum.Draft:
        if (componentReturnType === 'default') {
          setBackgroundColor('#E8DEFF');
          setColor('#202578');
          break;
        }

        setBackgroundColor(secondaryPink['700']);
        setBorderColor(secondaryPink['200']);
        setColor(secondaryYellow['200']);
        break;
      // STATUS GREY #C5C7C3
      case TempStatusEnum.Cancelled:
      case EnumProjectStatus.Canceled:
      case EnumProjectContractStatus.Canceled:
        setBackgroundColor('#C5C7C3');
        setColor('#2D312E');
        break;
      default:
        if (componentReturnType === 'default') {
          setBackgroundColor('#C5C7C3');
          setColor('#202578');
        }

        setBackgroundColor(secondaryGreen['500']);
        break;
    }
  });

  const style = {
    padding: '.25em .875em',
    borderRadius: '1em',
    backgroundColor: `${backgroundColor}`,
  };

  return (
    // NOTE: ternary operator is here as a temporary solution
    // until we get rid of this component completely or refactor it
    // so it renders only dot (following the new design)
    <>
      {componentReturnType === 'default' ? (
        <Stack direction="row" className={className || undefined}>
          <Box sx={style}>
            {mapToGetTheValueFrom && (
              <Typography
                sx={{ fontSize, color: color || shadeColor(backgroundColor, -140), ...sx }}
              >
                {mapToGetTheValueFrom.get(text)}
              </Typography>
            )}
          </Box>
        </Stack>
      ) : (
        // dot indicator
        <Box
          sx={{
            backgroundColor: backgroundColor !== '' ? backgroundColor : 'secondaryGreen.500',
            borderRadius: '50%',
            width: 18,
            height: 18,
            border: '3px solid',
            borderColor: borderColor !== '' ? borderColor : 'secondaryGreen.200',
          }}
        />
      )}
    </>
  );
}
