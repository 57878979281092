import { Alert, Box, Divider, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { BaseUserDto, UpdateUserDto } from 'tdc-web-backend/users/shemas';
import { MembershipRole } from 'tdc-web-backend/enums/enums';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import UserBasicDetails from '../../../components/UserBasicDetails';
import CustomButton from '../../../../../../../components/button/CustomButton';
import useUpdate from '../../../../../../../utils/hooks/crud-hooks/useUpdate';
import MembershipRoles from '../../components/MembershipRoles';

export interface MemberFormProps {
  userData: BaseUserDto & { avatar: string };
}

const MemberForm = ({ userData }: MemberFormProps) => {
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const form = useForm({
    defaultValues: {
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      jobTitle: userData?.jobTitle,
      membershipRole: userData?.membership?.role,
      avatar: '',
    },
  });

  const { mutate: updateUser, isLoading: isLoadingUpdateUser } = useUpdate<
    BaseUserDto & { avatar: string },
    UpdateUserDto
  >({
    resource: 'users',
  });

  const onSubmit = () => {
    updateUser(
      {
        id: userData.id,
        data: {
          firstName: form.getValues('firstName'),
          lastName: form.getValues('lastName'),
          jobTitle: form.getValues('jobTitle'),
          membershipRole: form.getValues('membershipRole') as MembershipRole,
          ...(form.getValues('avatar') !== '' ? { avatar: form.getValues('avatar') } : {}),
        },
      },
      {
        onSuccess: () => navigate('..', { relative: 'path' }),
        onError: () => setError('Something went wrong'),
      },
    );
  };

  useEffect(() => {
    form.reset({
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      jobTitle: userData?.jobTitle,
      membershipRole: userData?.membership?.role,
      avatar: '',
    });
  }, [userData]);

  return (
    <FormProvider {...form}>
      <Box component="form" sx={{ mt: '1.5rem' }}>
        <Stack spacing={3}>
          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}
          <UserBasicDetails userData={userData} disabled={isLoadingUpdateUser} />
          <Divider />
          <MembershipRoles disabled={isLoadingUpdateUser} />
          <Divider />
          <Stack direction="row" justifyContent="flex-end">
            <CustomButton
              variant="primary"
              sx={{
                height: '2.8125rem',
                width: '100%',
                maxWidth: '120px',
                marginTop: '22px !important',
              }}
              onClick={form.handleSubmit(onSubmit)}
              loading={isLoadingUpdateUser}
            >
              Save
            </CustomButton>
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  );
};

export default MemberForm;
