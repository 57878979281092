import { TypographyVariant, Typography, Stack, Box } from '@mui/material';
import SponaLogoLightImage from '../../assets/img/public-pages/spona-logo-light.svg';
import { ExtendedTypographyOptions } from '../../utils/theme';

const Title = ({
  text,
  variant = 'heading2',
}: {
  text: string;
  variant?: keyof ExtendedTypographyOptions | TypographyVariant;
}) => (
  <Typography variant={variant as TypographyVariant} color="primaryDark.600">
    {text}
  </Typography>
);

// FIXME: just a note: this component is used only on one instance
// for now (in the ProjectCard component),
// but it should get added everywhere where it could be in the future
const CompanyAvatarAndName = ({
  companyAvatar,
  companyAvatarWidth = 44,
  companyAvatarHeight = 44,
  companyName,
  companyNameTypographyVariant = 'headline4',
}: {
  companyAvatar: string;
  companyName: string;
  companyAvatarWidth?: string | number;
  companyAvatarHeight?: string | number;
  companyNameTypographyVariant?: keyof ExtendedTypographyOptions | TypographyVariant;
}) => (
  <Stack direction="row" spacing={1.5} alignItems="center">
    <Box
      sx={{
        width: companyAvatarWidth,
        height: companyAvatarHeight,
        borderRadius: '4px',
        backgroundImage:
          companyAvatar === '' ? `url(${SponaLogoLightImage})` : `url(${companyAvatar})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
      }}
    />

    <Title variant={companyNameTypographyVariant} text={companyName} />
  </Stack>
);

export default CompanyAvatarAndName;
