import { Dispatch, SetStateAction } from 'react';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import CustomModal from '../../../../components/modal/CustomModal';
import { ReactComponent as ClipboardCheckIcon } from '../../../../assets/icons/layout-icons/ClipboardCheckIcon.svg';
import { secondaryBlue } from '../../../../utils/color';
import CustomButton from '../../../../components/button/CustomButton';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';
import { useFormContext } from 'react-hook-form';

type CaseStudyReviewModalProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
};

const CaseStudyReviewModal = ({ setIsOpen, isOpen }: CaseStudyReviewModalProps) => {
  const { getValues } = useFormContext();

  return (
    <CustomModal open={isOpen} onClose={() => setIsOpen(false)} width="45%" disablePortal>
      <Stack spacing={3}>
        <Stack spacing={2} direction="row" alignItems="center">
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              bgcolor: '#E5E8FF',
              width: 'fit-content',
              height: 'fit-content',
              borderRadius: '6px',
              p: 2,
            }}
          >
            <ClipboardCheckIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />
          </Stack>
          <Typography variant="heading3" color="primaryDark.600">
            Case study review request
          </Typography>
        </Stack>
        <Divider />
        <Typography color="primaryDark.500" pb={2}>
          Your buyer contact will be notified of the review request as soon as it is submitted. The
          review's authenticity will need to be verified by Spona before it is published on the
          platform.
        </Typography>
        <Divider />
        <Stack sx={{ justifyContent: 'end' }} spacing={2} direction="row">
          <CustomButton variant="secondary" onClick={() => setIsOpen(false)}>
            Cancel
          </CustomButton>
          <CustomButton variant="primary" type="submit">
            Submit
          </CustomButton>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default CaseStudyReviewModal;
