import { Divider, Stack, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import { CreateChannelDto } from 'tdc-web-backend/channels/schemas';
import { FormProvider, useForm } from 'react-hook-form';
import { BaseUserDto } from 'tdc-web-backend/users/shemas';
import { ReactComponent as ChatBubbleIcon } from '../../../assets/icons/chat-icons/chat3-fill.svg';
import CustomButton from '../../../components/button/CustomButton';
import CustomModal from '../../../components/modal/CustomModal';
import { useRefresh } from '../../../utils/hooks/crud-hooks/useRefresh';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';
import ControlledTextInput from '../../../components/custom-inputs/ControlledTextInput';
import ResourceSelectInput from '../ResourceSelectInput';
import FramedIcon from '../FramedIcon';
import useAuth from '../../../utils/hooks/useAuth';

type FormData = {
  name: string;
  users: string[];
};

type CreateChannelModalProps = {
  open: boolean;
  onClose?: () => void;
  spaceId: string;
};

export default ({ open, onClose, spaceId }: CreateChannelModalProps) => {
  const {
    authData: { userData },
  } = useAuth();
  const refresh = useRefresh();
  const methods = useForm<FormData>({
    defaultValues: {
      name: '',
      users: [],
    },
  });

  const { mutate } = useCreate<AxiosResponse, CreateChannelDto>({
    resource: 'channels',
  });
  const onSubmit = (data: FormData) => {
    mutate(
      {
        name: data.name,
        users: data.users,
        space: spaceId,
      },
      {
        onSuccess: () => {
          refresh();
          methods.reset();
          onClose?.();
        },
      },
    );
  };

  return (
    <>
      <CustomModal open={open} onClose={onClose}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Stack direction="row" alignItems="center" spacing={2} width="100%">
                <FramedIcon Icon={ChatBubbleIcon} variant="blue" />
                <Typography variant="heading3">Create a new channel</Typography>
              </Stack>
              <Divider />
              <Stack direction="column" spacing={3}>
                <Stack direction="row">
                  <Stack direction="column" sx={{ width: '100%' }} spacing={3}>
                    <ControlledTextInput
                      placeholder="New channel"
                      name="name"
                      required
                      label="Channel name"
                    />
                    <ResourceSelectInput<BaseUserDto>
                      name="users"
                      resource="users"
                      multiple
                      filter={{ space: spaceId }}
                      choiceFilter={(users) => users.filter((user) => user.id !== userData?.id)}
                      choiceLabel={(user) => `${user.firstName} ${user.lastName}`}
                      label="Participants"
                      placeholder="Add Participants"
                    />
                  </Stack>
                </Stack>
              </Stack>
              <Divider />
              <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
                <CustomButton variant="secondary" onClick={onClose}>
                  Cancel
                </CustomButton>
                <CustomButton type="submit">Create</CustomButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </CustomModal>
    </>
  );
};
