import { Stack, useMediaQuery } from '@mui/material';
import SponaInNumbers from '../../../components/public-screen-components/SponaInNumbers';
import Opinions from './components/Opinions';
import OurBenefits from './components/OurBenefits';
import OurPeople from './components/OurPeople';
import WorkWithUsHero from './components/WorkWithUsHero';
import { LAYOUT_DEFAULTS } from '../../../utils/constants';
import theme from '../../../utils/theme';
import MetaTags from '../../../components/seo/MetaTags';

const metaTags = {
  description: `Interested in working at Spona? Here's where to find out what it's like to work at Spona and learn about new job openings.`,
};

const WorkWithUs = () => {
  const isLessThanMedium = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <MetaTags {...metaTags} />
      <Stack overflow="hidden" width="100%">
        <WorkWithUsHero />

        {isLessThanMedium && (
          <SponaInNumbers
            sx={{
              height: 'auto',
              mb: { xs: 14 },
              ...LAYOUT_DEFAULTS,
            }}
          />
        )}

        <OurBenefits />

        <OurPeople />

        <Opinions />
      </Stack>
    </>
  );
};
export default WorkWithUs;
