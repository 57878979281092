import { Box, IconButton, Paper, Snackbar, Stack, SxProps, Theme, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { EnumContactRequestType } from 'tdc-web-backend/enums/enums';
import { useState } from 'react';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { ReactComponent as SuccessAlertIcon } from '../../../../assets/icons/layout-icons/SuccessAlertIcon.svg';
import { ReactComponent as ErrorAlertIcon } from '../../../../assets/icons/layout-icons/ErrorAlertIcon.svg';
import ControlledTextInput from '../../../../components/custom-inputs/ControlledTextInput';
import ControlledSelectInput from '../../../../components/custom-inputs/ControlledSelectInput';
import CustomButton from '../../../../components/button/CustomButton';
import useCreate from '../../../../utils/hooks/crud-hooks/useCreate';
import ClipboardButton from '../../../../components/public-screen-components/ClipboardButton';
import { secondaryGreen, secondaryPink } from '../../../../utils/color';

export enum SnackbarType {
  Error = 'information',
  Success = 'feature-request',
  None = '',
}

const requestChoices = new Map([
  [EnumContactRequestType.FeatureRequest, 'Feature request'],
  [EnumContactRequestType.Information, 'Information'],
  [EnumContactRequestType.JoinRequest, 'Join request'],
  [EnumContactRequestType.Problem, 'Problem'],
  [EnumContactRequestType.Other, 'Other'],
]);

const defaultFormValues = {
  name: '',
  description: '',
  request: '',
  website: '',
  email: '',
};

const ContactUsForm = ({ sx }: { sx?: SxProps<Theme> }) => {
  const [snackbarType, setSnackbarType] = useState(SnackbarType.None);

  const MySnackbar = () => {
    const isSuccsess = snackbarType !== SnackbarType.Error;

    return (
      <Snackbar
        open={snackbarType !== SnackbarType.None}
        autoHideDuration={4500}
        onClose={() => setSnackbarType(SnackbarType.None)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Paper
          elevation={0}
          sx={{
            width: '100%',
            borderRadius: '10px',
            py: 1.25,
            px: 1.5,
            background: isSuccsess ? secondaryGreen[100] : secondaryPink[100],
          }}
        >
          <Stack spacing={1} direction="row" alignItems="center">
            {isSuccsess ? (
              <SuccessAlertIcon fill={secondaryGreen[700]} style={{ width: 22, height: 22 }} />
            ) : (
              <ErrorAlertIcon fill={secondaryPink[700]} style={{ width: 22, height: 22 }} />
            )}

            <Typography
              variant="heading4"
              component="span"
              color="primaryDark.600"
              sx={{ cursor: 'pointer', textTransform: 'unset' }}
            >
              {isSuccsess
                ? 'Thank you for your inquiry, we will respond shortly!'
                : 'Oops, something went wrong. Please try again later.'}
            </Typography>

            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSnackbarType(SnackbarType.None)}
            >
              <CancelRoundedIcon sx={{ fontSize: '1.3rem', color: 'primaryDark.600' }} />
            </IconButton>
          </Stack>
        </Paper>
      </Snackbar>
    );
  };

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: defaultFormValues,
  });

  const { mutate: contactRequest } = useCreate({ resource: '/contact-requests/' });

  const {
    formState: { isValid },
    handleSubmit,
    reset,
  } = formMethods;

  const handleFormSubmit = (data: any) => {
    contactRequest(
      { ...data },
      {
        onSuccess() {
          setSnackbarType(SnackbarType.Success);
          reset();
        },
        onError() {
          setSnackbarType(SnackbarType.Error);
        },
      },
    );
  };

  return (
    <Stack spacing={5} sx={{ ...sx }}>
      <Typography sx={{ typography: { xs: 'publicHeading3', lg: 'publicHeading1' } }}>
        Talk to us
      </Typography>

      <ClipboardButton text="connect@spona.io" />

      <FormProvider {...formMethods}>
        <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
          <Stack direction="column" spacing={3}>
            <ControlledTextInput
              placeholder="Enter your email"
              name="email"
              required
              label="e-mail"
              type="email"
            />

            <ControlledTextInput
              placeholder="Enter your first and last name"
              name="name"
              required
              label="name"
            />

            <ControlledTextInput placeholder="Enter your website" name="website" label="website" />

            <ControlledSelectInput
              choices={requestChoices}
              label="request"
              placeholder="Select your request"
              name="request"
            />

            <ControlledTextInput
              variant="outlined"
              name="description"
              placeholder="Let us know how we can help you"
              label="description"
              multiline
              minRows={5}
              maxRows={8}
            />

            <CustomButton
              sx={{ width: { xs: '100%', md: 'fit-content' } }}
              type="submit"
              disabled={!isValid}
            >
              SUBMIT
            </CustomButton>
          </Stack>
        </Box>
      </FormProvider>

      <MySnackbar />
    </Stack>
  );
};

export default ContactUsForm;
