/* eslint-disable no-use-before-define */
import { useState, MouseEvent, FunctionComponent, SVGProps, lazy, Suspense } from 'react';
import { Box } from '@mui/system';
import { Badge, IconButton, Menu, Skeleton, Stack, Typography } from '@mui/material';
import { NotificationDto } from 'tdc-web-backend/notifications/schemas';
import { EnumNotificationCategory } from 'tdc-web-backend/enums/enums';
import useGetMany from '../../utils/hooks/crud-hooks/useGetMany';
import Link from '../link/Link';
import { ReactComponent as ContentIcon } from '../../assets/icons/notifications/ContentNotebookIcon.svg';
import { ReactComponent as MarketplaceIcon } from '../../assets/icons/notifications/MarketplaceCartIcon.svg';
import { ReactComponent as AchievementsIcon } from '../../assets/icons/notifications/AchievementTrophyIcon.svg';
import { ReactComponent as PremiumIcon } from '../../assets/icons/notifications/PremiumCrownIcon.svg';
import { ReactComponent as EndToEndIcon } from '../../assets/icons/notifications/E2EFolderIcon.svg';
import { ReactComponent as TDAIcon } from '../../assets/icons/notifications/TDANotificationIcon.svg';
import { ReactComponent as NotificationBellIcon } from '../../assets/icons/layout-icons/NotificationBellIcon.svg';
import useCreate from '../../utils/hooks/crud-hooks/useCreate';
import { NotificationCardRemaining } from './NotificationCardSmall';

const NotificationCardSmall = lazy(() => import('./NotificationCardSmall'));

export const mapNotificationCategoryIcons = new Map([
  [EnumNotificationCategory.Content, ContentIcon],
  [EnumNotificationCategory.Marketplace, MarketplaceIcon],
  [EnumNotificationCategory.E2EManagment, EndToEndIcon],
  [EnumNotificationCategory.Achievements, AchievementsIcon],
  [EnumNotificationCategory.TDCNotifications, TDAIcon],
  [EnumNotificationCategory.Premium, PremiumIcon],
]);

const markAllStyle = {
  transition: '0.3s all ease',
  textDecoration: 'underline',
  cursor: 'pointer',
  '&:hover': {
    color: 'primaryLight.300',
  },
};

const NotificationMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { data: notificationsResponse, refetch } = useGetMany<NotificationDto>({
    resource: 'notifications?orderByCreatedOn=desc&limit=100',
    // refetchInterval: 8000,
  });
  const { mutate } = useCreate({ resource: '/notifications/mark-all-as-seen' });

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const markAllAsRead = () => {
    mutate(
      {},
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };
  const notifications = notificationsResponse?.data.results;
  const unseenNotifications = () => notifications?.filter((item) => item.seen === null);

  const displayedNotifications = notifications?.slice(0, 6);

  return (
    <>
      <Badge
        color="secondary"
        variant="dot"
        overlap="circular"
        invisible={unseenNotifications()?.length === 0}
        sx={{
          '& .MuiBadge-badge': {
            border: '2px solid #E3DBFC',
            borderRadius: '100%',
            width: '12px',
            height: '12px',
          },
        }}
      >
        <IconButton
          size="large"
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          className="react-joyride-step-4"
          sx={{
            border: '1px solid #BFC6D7',
            bgcolor: open ? 'secondaryBlue.100' : 'none',
          }}
        >
          <NotificationBellIcon fill="#79829B" />
        </IconButton>
      </Badge>
      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        // onScrollCapture={handleScrolls}
        PaperProps={{
          elevation: 0,
          sx: {
            mt: 0.5,
            width: '25%',
            borderRadius: '12px',
            boxShadow: '6px 6px 15px rgba(56, 69, 114, 0.1)',
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ pb: 1, px: 1 }}
            alignItems="end"
          >
            <Typography
              variant="body3"
              fontWeight={600}
              color="primaryDark.500"
              sx={markAllStyle}
              onClick={markAllAsRead}
            >
              Mark all as read
            </Typography>
            <Link to="../dashboard/notifications">
              <Typography color="primaryLight.500" variant="heading4">
                View all
              </Typography>
            </Link>
          </Stack>
          <Stack sx={{ padding: 0 }}>
            {notifications?.length === 0 ? (
              <Typography
                color="primary"
                variant="body1"
                fontSize={14}
                sx={{ p: 3, textAlign: 'center' }}
              >
                0 notifications
              </Typography>
            ) : (
              displayedNotifications?.map((notification: NotificationDto) => (
                <Suspense key={notification.id} fallback={<FallbackNotif />}>
                  <NotificationCardSmall
                    data={notification}
                    Icon={
                      mapNotificationCategoryIcons.get(notification.category) as FunctionComponent<
                        SVGProps<SVGSVGElement> & {
                          title?: string | undefined;
                        }
                      >
                    }
                  />
                </Suspense>
              ))
            )}
            <NotificationCardRemaining unread={unseenNotifications()?.slice(6).length ?? 0} />
          </Stack>
        </Stack>
      </Menu>
    </>
  );
};

export default NotificationMenu;

const FallbackNotif = () => (
  <Stack width="100%" direction="row" spacing={1} sx={{ p: 2 }}>
    <Skeleton height={40} width={40} variant="rectangular" />
    <Stack flex={1} spacing={0.5}>
      <Skeleton variant="text" sx={{ fontSize: '14px' }} />
      <Skeleton variant="text" sx={{ fontSize: '12px' }} width="100px" />
    </Stack>
  </Stack>
);
