import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { mapService } from 'tdc-web-backend/enums/enums';
import {
  mapProviderScope,
  mapTechLanguage,
  mapTechPlatform,
  mapTechTool,
} from '../../../../utils/enums';
import ControlledRadioInput from '../../../../components/custom-inputs/ControlledRadioInput';
import CustomInput from '../../../../components/custom-input/CustomInput';

const Seller = () => (
  <>
    <Box sx={{ display: 'flex' }}>
      <CustomInput
        input={<CustomInput.Autocomplete />}
        sx={{ flex: 1, alignSelf: 'flex-start' }}
        label="required service"
        placeholder="Select required skills"
        name="skills.services"
        choices={mapService}
        multiple
        withChips
        fullWidth
        required
      />

      <Divider orientation="vertical" sx={{ borderColor: '#E5E8FF', margin: '0 24px' }} flexItem />

      <CustomInput
        input={<CustomInput.Autocomplete />}
        sx={{ flex: 1, alignSelf: 'flex-start' }}
        label="tech platforms"
        placeholder="Select preferred platforms"
        name="skills.platforms"
        choices={mapTechPlatform}
        multiple
        withChips
        fullWidth
      />
    </Box>

    <Box sx={{ display: 'flex' }}>
      <CustomInput
        input={<CustomInput.Autocomplete />}
        sx={{ mt: 3, flex: 1, alignSelf: 'flex-start' }}
        label="tech tools"
        placeholder="Select preferred software or tools"
        name="skills.tools"
        choices={mapTechTool}
        multiple
        withChips
        fullWidth
      />

      <Divider orientation="vertical" sx={{ borderColor: '#E5E8FF', margin: '0 24px' }} flexItem />

      <CustomInput
        input={<CustomInput.Autocomplete />}
        sx={{ mt: 3, flex: 1, alignSelf: 'flex-start' }}
        label="programming languages"
        placeholder="Select languages"
        name="skills.languages"
        choices={mapTechLanguage}
        multiple
        withChips
        fullWidth
      />
    </Box>

    <Divider orientation="horizontal" sx={{ borderColor: '#E5E8FF', margin: '40px 0' }} flexItem />

    {/* TODO: create new CustomInput.RadioInput component */}
    <ControlledRadioInput
      name="skills.providerScope"
      label="select your preferred seller location:"
      options={mapProviderScope}
      required
    />
  </>
);

export default Seller;
