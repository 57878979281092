import { Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectDto, ProjectHealthDto } from 'tdc-web-backend/projects/schemas';
import {
  EnumCurrency,
  EnumProjectStatus,
  EnumScreenList,
  EnumTutorialStatus,
} from 'tdc-web-backend/enums/enums';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { ContractDto } from 'tdc-web-backend/contracts/schemas';
import Joyride from 'react-joyride';
import { useEffect } from 'react';
import { NotificationDto } from 'tdc-web-backend/notifications/schemas';
import { ProjectHealthCard } from '../ProjectHealth/ProjectHealthCard';
import PaymentsAndMilestones from '../PaymentsAndMilestones/PaymentsAndMilestones';
import useGetOne from '../../../../utils/hooks/crud-hooks/useGetOne';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import GeneralProjectDetails from '../GeneralProjectDetails/GeneralProjectDetails';
import { ReactComponent as ContractDocumentIcon } from '../../../../assets/icons/project-icons/ContractDocumentIcon.svg';
import StatusChipField from '../../../../components/StatusChipField/StatusChipField';
import CustomList, { ColumnDefs } from '../../../../components/custom-list/list/CustomList';
import Link from '../../../../components/link/Link';
import CustomScrollFade from '../../../../components/custom-scroll-fade/CustomScrollFade';
import useReferenceOne from '../../../../utils/hooks/crud-hooks/useReferenceOne';
import RequestPaymentModal from '../modals/RequestPaymentModal';
import { secondaryBlue } from '../../../../utils/color';
import useJoyride from '../../../../utils/hooks/joyride-hooks/useJoyride';
import { ActiveContractScreenJoyrideSteps } from '../../../../utils/hooks/joyride-hooks/ProjectDetailScreenJoyride';
import JoyrideTooltip from '../../../../utils/hooks/joyride-hooks/JoyrideTooltip';
import BannerNotificationMapper from '../../../../components/notifications/bannerNotifications/BannerNotificationMapper';
import DashboardCard from '../../../../components/dashboard-card/EndToEndCard.tsx/DashboardCard';
import { formatDateFns } from '../../../../utils/helpers';
import useAuth from '../../../../utils/hooks/useAuth';

const ProjectDetailScreen = () => {
  const params = useParams();
  const { joyride, runJoyride, tutorials, updateJoyride, update } = useJoyride();

  const {
    authData: { userData },
  } = useAuth();
  const navigate = useNavigate();
  const { projectId } = useParams();

  const { data: projectData } = useReferenceOne<ProjectDto>({
    resource: 'projects',
    id: params.projectId,
  });

  const { data: floatingNotifications } = useGetMany<NotificationDto>({
    resource: `notifications/banner?screen=project_detail`,
  });

  useEffect(() => {
    if (
      !tutorials?.includes(EnumTutorialStatus.ActiveProjectDetail) &&
      projectData?.status === EnumProjectStatus.InProgress
    ) {
      runJoyride();
      update(EnumTutorialStatus.ActiveProjectDetail);
    }
  }, [projectData?.status]);

  const { data: milestoneResponse } = useGetMany<MilestoneDto>({
    resource: `milestones/snake?project=${params.projectId}`,
    enabled: !!params.projectId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const { data: contractsResponse } = useGetMany<ContractDto>({
    resource: `contracts/?project=${params.projectId}&company=${userData?.membership?.company}`,
    enabled: !!userData,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const { data: projectHealth } = useGetOne<ProjectHealthDto>({
    resource: `projects/${projectData?.id}/health`,
    enabled: !!projectData?.id,
    refetchOnWindowFocus: false,
  });

  const columns: ColumnDefs<ContractDto>[] = [
    {
      field: 'name',
      icon: <ContractDocumentIcon fill={secondaryBlue[500]} />,
    },
    {
      field: 'created',
      valueFormatter: (params) => {
        if (params.created !== null) {
          return formatDateFns(params.created);
        }

        return '-';
      },
      columnsStyle: { textAlign: 'end', fontWeight: 400 },
    },
    {
      field: 'status',
      align: 'end',
      renderColumn: (params) => (
        <StatusChipField
          className="react-joyride-step-1-active-project"
          text={params.status}
          componentReturnType="chip"
        />
      ),
    },
  ];

  const projectContract = projectData?.latestContract;

  return (
    <Stack spacing={3} sx={{ width: '100%' }}>
      {/* project name, cooperation with ,health card & payment card */}
      {tutorials?.includes(EnumTutorialStatus.ProjectDetail) && (
        <Joyride
          steps={ActiveContractScreenJoyrideSteps()}
          run={joyride.run}
          scrollDuration={400}
          scrollToFirstStep
          showSkipButton
          disableScrolling={
            joyride.steps[joyride.stepIndex] && joyride.steps[joyride.stepIndex].disableScrolling
          }
          stepIndex={joyride.stepIndex}
          callback={(e) => updateJoyride(e)}
          continuous
          tooltipComponent={(props) => JoyrideTooltip(props)}
        />
      )}
      <BannerNotificationMapper screen={EnumScreenList.ProjectDetail} />
      <Stack spacing={4} direction="column" justifyContent="space-between">
        {/* project name, cooperation with, description, budget  */}
        <GeneralProjectDetails data={projectData as ProjectDto} />

        {/* health card & contracts card */}
        <Stack direction={{ md: 'column', lg: 'row' }} spacing={3} width="100%">
          <DashboardCard
            mainCardClassName="react-joyride-step-8-project"
            headline="Health"
            sx={{ width: { md: '100%' } }}
            renderComponent={<ProjectHealthCard health={projectHealth?.data} />}
          />

          <DashboardCard
            headline="Contracts"
            viewAllClassName="react-joyride-step-3-project"
            sx={{ width: { md: '100%' }, pb: 1.5 }}
            renderComponentSx={{ height: '100%' }}
            link={projectContract ? `./contract/${projectContract}` : './contract'}
            linkStartIcon={<ContractDocumentIcon fill={secondaryBlue[500]} />}
            linkText={projectContract ? 'Edit contract' : 'New contract'}
            renderComponent={
              <Stack height="100%">
                <CustomList<ContractDto>
                  items={contractsResponse?.data.results.slice(0, 3) ?? []}
                  columns={columns}
                  onClick={(data) => {
                    navigate('../contracts', { state: data });
                  }}
                />
                <Link to="../contracts" sx={{ display: 'flex', justifyContent: 'end' }}>
                  <Typography
                    className="react-joyride-step-4-project"
                    variant="heading4"
                    color="primaryLight.500"
                    textAlign="right"
                    sx={{ py: 1, pr: 1, pt: 1 }}
                  >
                    View all
                  </Typography>
                </Link>
              </Stack>
            }
          />
        </Stack>
      </Stack>

      {/* contract card & payment card */}
      <DashboardCard
        headline="Payment overview"
        mainCardClassName="react-joyride-step-13-project"
        sx={{ position: 'relative', width: '100%', border: 'none' }}
        headlineEndElement={<RequestPaymentModal data={milestoneResponse?.data.results ?? []} />}
        renderComponent={
          <PaymentsAndMilestones
            projectId={projectData?.id as string}
            currency={projectData?.currency as EnumCurrency}
          />
        }
      />
      <CustomScrollFade />
    </Stack>
  );
};

export default ProjectDetailScreen;
