import { Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import ControlledTextInput from '../../../../components/custom-inputs/ControlledTextInput';
import { primaryDark, secondaryBlue } from '../../../../utils/color';
import { ReactComponent as StarInCloudIcon } from '../../../../assets/icons/project-icons/StarInCloudIcon.svg';
import { ReactComponent as ArrowLeftShortFill } from '../../../../assets/icons/layout-icons/ArrowLeftShortFill.svg';
import CustomButton from '../../../../components/button/CustomButton';
import CaseStudyReviewModal from './CaseStudyReviewModal';
import { Dispatch, SetStateAction, useState } from 'react';
import { CaseStudyContentDto } from 'tdc-web-backend/case-studies-content/schemas';
import useCreate from '../../../../utils/hooks/crud-hooks/useCreate';
import { useRefresh } from '../../../../utils/hooks/crud-hooks/useRefresh';

type CaseStudiesReviewFormProps = {
  data: CaseStudyContentDto;
  setShowReviewForm: Dispatch<SetStateAction<boolean>>;
};

const CaseStudiesReviewForm = ({ data, setShowReviewForm }: CaseStudiesReviewFormProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const refresh = useRefresh();
  const { mutate: requestReview } = useCreate({
    resource: 'case-studies/' + data.caseStudy + '/request-review',
  });
  const form = useForm({
    defaultValues: {
      companyName: '',
      firstName: '',
      email: '',
      companyWebsite: '',
      lastName: '',
      jobTitle: '',
    },
    mode: 'all',
  });

  const submitReview = (data: any) => {
    requestReview(
      { ...data },
      {
        onSuccess: () => {
          setIsModalOpen(false);
          setShowReviewForm(false);
          refresh();
        },
      },
    );
  };

  return (
    <Stack pt={4} spacing={1} pb={4}>
      <Stack pl={2} direction={'row'} spacing={1} alignItems={'center'}>
        <StarInCloudIcon fill={primaryDark[500]} />
        <Typography variant="heading5" color={'primaryDark.500'}>
          request review
        </Typography>
      </Stack>
      <Typography variant="body3" pb={2} pl={2} color={'primaryDark.500'}>
        Please fill in information about your partner company.
      </Typography>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(submitReview)}>
          <Stack direction={'row'} width="100%">
            <Stack
              width="100%"
              px={2}
              spacing={4}
              sx={{
                borderRight: `1px solid ${secondaryBlue[100]}`,
              }}
            >
              <ControlledTextInput
                name="companyName"
                key="companyName"
                label="Company name"
                placeholder="Company name"
                required
              />
              <ControlledTextInput
                required
                name="firstName"
                key="firstName"
                label="first Name"
                placeholder="First name"
              />
              <ControlledTextInput
                required
                name="email"
                key="email"
                label="Email"
                placeholder="Email"
                type="email"
              />
            </Stack>
            <Stack width="100%" px={2} spacing={4}>
              <ControlledTextInput
                required
                name="companyWebsite"
                key="companyWebsite"
                label="company WEBSITE"
                placeholder="Company website"
              />
              <ControlledTextInput
                required
                name="lastName"
                key="lastName"
                label="last name"
                placeholder="Last name"
              />
              <ControlledTextInput
                required
                name="jobTitle"
                key="jobTitle"
                label="job title"
                placeholder="Job title"
              />
            </Stack>
          </Stack>
          <CaseStudyReviewModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
          <Stack direction={'row'} my={4} justifyContent={'end'} spacing={2}>
            <CustomButton
              variant="secondary"
              onClick={() => setShowReviewForm(false)}
              startIcon={<ArrowLeftShortFill fill={primaryDark[700]} width={'12px'} />}
            >
              Back to case study
            </CustomButton>
            <CustomButton disabled={!form.formState.isValid} onClick={() => setIsModalOpen(true)}>
              proceed
            </CustomButton>
          </Stack>
        </form>
      </FormProvider>
    </Stack>
  );
};

export default CaseStudiesReviewForm;
