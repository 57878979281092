import { Grid, Stack, Typography } from '@mui/material';
import { ReactComponent as Clock } from '../../../../assets/img/public-pages/clock-round.svg';
import { ReactComponent as Coffee } from '../../../../assets/img/public-pages/coffee.svg';
import { ReactComponent as Hat } from '../../../../assets/img/public-pages/hat.svg';
import { ReactComponent as House } from '../../../../assets/img/public-pages/house.svg';
import { ReactComponent as Paw } from '../../../../assets/img/public-pages/paw.svg';
import { ReactComponent as Weights } from '../../../../assets/img/public-pages/weights.svg';
import { LAYOUT_DEFAULTS } from '../../../../utils/constants';

interface ItemsProps {
  id: string;
  icon: JSX.Element;
  title: string;
  content: string;
}

const itemsData: ItemsProps[] = [
  {
    id: 'item1',
    icon: <Clock />,
    title: 'Flexible hours',
    content:
      'Our workday starts between 7 and 9 AM, so you can tailor your day however suits you best.',
  },
  {
    id: 'item2',
    icon: <House />,
    title: 'Hybrid model',
    content: 'Guaranteed 2 days per week remote, but you can stay in longer if necessary.',
  },
  {
    id: 'item3',
    icon: <Paw />,
    title: 'Pet-friendly',
    content: 'Bring your furry friend to the office - we don’t mind!',
  },
  {
    id: 'item4',
    icon: <Hat />,
    title: 'Educational budget',
    content: 'We encourage you to enhance your skill set or simply pursue your interests.',
  },
  {
    id: 'item5',
    icon: <Coffee />,
    title: 'Stocked kitchen',
    content: 'Fight the afternoon munchies with some coffee and snacks from our stocked kitchen.',
  },
  {
    id: 'item6',
    icon: <Weights />,
    title: 'Stay healthy',
    content: 'Your MultiSport membership card is 100% covered.',
  },
];

const OurBenefits = () => (
  <Stack spacing={{ xs: 0 }} sx={{ ...LAYOUT_DEFAULTS, mb: 16 }}>
    <Typography variant="publicHeading3">Our Benefits</Typography>

    <Grid container rowSpacing={7}>
      {itemsData.map(({ id, icon, title, content }: ItemsProps) => (
        <Grid key={id} item xs={12} sm={6} md={4} lg={4}>
          <Stack spacing={2} alignItems={{ xs: 'center', sm: 'start' }}>
            {icon}

            <Typography variant="publicBody5" color="primaryDark.600">
              {title}
            </Typography>

            <Typography
              variant="publicBody5Regular"
              sx={{ width: { xs: '100%', md: '75%' }, textAlign: { xs: 'center', sm: 'unset' } }}
            >
              {content}
            </Typography>
          </Stack>
        </Grid>
      ))}
    </Grid>
  </Stack>
);

export default OurBenefits;
