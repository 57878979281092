import Stack from '@mui/material/Stack';
import { Box, SxProps, Theme } from '@mui/system';
import { useLocation } from 'react-router-dom';
import { getPublicImageUrl } from '../../../utils/helpers';
import { EnumPublicPagesImage } from '../../../utils/enums';

export interface ConfirmAccountLeftSideSectionProps {
  sx?: SxProps<Theme> | undefined;
}

const ConfirmAccountLeftSideSection = ({ sx }: ConfirmAccountLeftSideSectionProps) => {
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const company = queryParams.get('company');

  return (
    <Stack
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
        ...sx,
      }}
    >
      <Box
        component="img"
        sx={{
          height: '100%',
          objectFit: 'cover',
          content: {
            xs: `url(${
              company === 'seller'
                ? getPublicImageUrl(EnumPublicPagesImage.ConfirmAccountSellerxl)
                : getPublicImageUrl(EnumPublicPagesImage.ConfirmAccountBuyerxl)
            })`,
            lg: `url(${
              company === 'seller'
                ? getPublicImageUrl(EnumPublicPagesImage.ConfirmAccountSeller)
                : getPublicImageUrl(EnumPublicPagesImage.ConfirmAccountBuyer)
            })`,
          },
        }}
      />
    </Stack>
  );
};

export default ConfirmAccountLeftSideSection;
