import { Typography, Stack } from '@mui/material';

interface BillingProps {
  // Add your props here if needed
}

const Billing = () => (
  <Stack>
    <Typography variant="body1">Not implemented yet.</Typography>
  </Stack>
);

export default Billing;
