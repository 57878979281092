import Stack from '@mui/material/Stack';
import AboutUsHero from './components/AboutUsHero';
import SponaInNumbers from '../../../components/public-screen-components/SponaInNumbers';
import WhoAreWe from './components/WhoAreWe';
import OurJourney from './components/OurJourney';
import { LAYOUT_DEFAULTS } from '../../../utils/constants';
import MetaTags from '../../../components/seo/MetaTags';

const metaTags = {
  title: `Spona - Global B2B Platform - Learn more About Us`,
  description: `Spona is a global B2B platform that helps businesses streamline the process of hiring and managing digital service sellers. Find out more and work with us.`,
};

const AboutUs = () => (
  <>
    <MetaTags {...metaTags} />
    <Stack width="100%" overflow="hidden">
      <AboutUsHero />

      <SponaInNumbers
        sx={{ ...LAYOUT_DEFAULTS, mb: { lg: '5.65rem', md: '5.65rem', xs: '2.5rem' }, p: '2rem' }}
      />

      <OurJourney />

      <WhoAreWe />
    </Stack>
  </>
);

export default AboutUs;
