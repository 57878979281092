import { useState, useEffect } from 'react';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { CompanyRole } from 'tdc-web-backend/enums/enums';
import useReferenceOne from './crud-hooks/useReferenceOne';
import useAuth from './useAuth';

const useUserCompany = () => {
  const {
    authData: { userData },
  } = useAuth();
  const [companyRoles, setCompanyRoles] = useState<CompanyDto | null>(null);

  const { data: companyData } = useReferenceOne<CompanyDto>({
    resource: 'companies',
    id: userData?.membership?.company,
    enabled: !!userData && !!userData.membership?.company,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    setCompanyRoles(companyData ?? null);
  }, [companyData]);

  return companyRoles;
};

export default useUserCompany;
