/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import { LoginResponseDto } from 'tdc-web-backend/auth/schemas';
import { TimedProjectPitchContentCustomDto } from 'tdc-web-backend/timed-project-pitch-contents/schemas';
import { useTheme } from '@mui/material/styles';
import { EnumCurrency, mapCountry } from 'tdc-web-backend/enums/enums';
import JpgIcon from '../assets/icons/file-upload/jpg.svg';
import LinkIcon from '../assets/icons/file-upload/link.svg';
import MovIcon from '../assets/icons/file-upload/mov.svg';
import Mp3Icon from '../assets/icons/file-upload/mp3.svg';
import PdfIcon from '../assets/icons/file-upload/pdf.svg';
import PptIcon from '../assets/icons/file-upload/ppt.svg';
import TxtIcon from '../assets/icons/file-upload/txt.svg';
import UnknownIcon from '../assets/icons/file-upload/unknown.svg';
import WordIcon from '../assets/icons/file-upload/word.svg';
import XlsxIcon from '../assets/icons/file-upload/xlsx.svg';
import ZipIcon from '../assets/icons/file-upload/zip.svg';
import { PitchesPanelData } from './types';
import ga4 from 'react-ga4';
import { EnumPublicPagesImage, mapPublicPagesImagesUrls } from './enums';
import { format as formatFns } from 'date-fns';

/* eslint-disable no-mixed-operators */
/**
 * Blame me and Alisa equally, if it was not for her, this dream of mine could never become a
 * reality.
 * @param object input object that needs to be cleaned up
 * @param propName target prop that needs to be removed from the object
 */
export const removePropRecursively = (obj: NonNullable<object>, propName: string): void => {
  if (obj !== null && obj !== undefined) {
    if (propName in obj) {
      // eslint-disable-next-line no-param-reassign
      delete obj[propName as keyof typeof obj]; // TypeScript is dumb
    }

    Object.keys(obj).forEach((k) => {
      if (typeof obj[k as keyof typeof obj] === 'object' && obj !== null) {
        removePropRecursively(obj[k as keyof typeof obj] as NonNullable<object>, propName);
      }
    });
  }
};

// Function to set first letter to uppercase
export const capitaliseString = (string: string | undefined) =>
  string ? string.charAt(0).toUpperCase() + string.slice(1) : string;

/**
 * Creates a deep copy of the input object
 * In the future, we might consider implementing structuredClone() once the support improves
 * as it is a far superior alternative to the JSON.parse(JSON.stringify()) approach
 * More info: https://developer.mozilla.org/en-US/docs/Web/API/structuredClone
 * @param obj Object to be copied
 * @returns Copied object
 */
export function deepCopy<Type>(obj: Type): Type {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Converts a date to human-readable format
 * @param date String representation of the date to be converted
 * @returns Converted date
 */
export const convertDate = (date: any, undefinedReturnValue = '-'): string => {
  if (date) {
    // Old format
    // const newDate = new Date(date);
    // return newDate.toDateString().substring(3);
    const today = new Date(date);
    const yyyy = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const mm = month < 10 ? `0${month}` : month;
    const dd = day < 10 ? `0${day}` : day;

    return `${mm}.${dd}.${yyyy}.`;
  }
  return undefinedReturnValue;
};

export const convertDateNoYear = (date: any): string => {
  if (date) {
    // Old format
    // const newDate = new Date(date);
    // return newDate.toDateString().substring(3);
    const today = new Date(date);
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const mm = month < 10 ? `0${month}` : month;
    const dd = day < 10 ? `0${day}` : day;

    return `${mm}.${dd}`;
  }
  return '-';
};

// format date in dd.mm.yyyy. format
// use case : takes up less space in input field
// TODO: This one will be removed after recurring milestones are merged
export const simpleDateFormatter = (date: any): string => {
  if (date) {
    const today = new Date(date);
    const yyyy = today.getFullYear();
    const mm = today.getMonth() + 1;
    const dd = today.getDate();

    return `${mm}.${dd}.${yyyy}.`;
  }
  return '-';
};

export const convertDateToISO = (date: string): string => {
  const newDate = new Date(date);
  const iso = newDate.toISOString();

  return iso;
};

export const shortenDate = (date: string): string => {
  const convertedDate = date.toString().split('T')[0];

  return convertedDate;
};

export const dateToLocalDateString = (date: any): string =>
  `${date.getFullYear().toString().padStart(4, '0')}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

/**
 * Calculates how much days are between startDate and endDate;
 * @param startDate & @param endDate are Date @objects from which
 * number of days is calculated.
 * @returns number of days between startDate & endDate
 */
export const calculateDiffBetweenDates = (startDate: Date, endDate: Date): number => {
  const msPerDay = 1000 * 60 * 60 * 24;

  const utc1 = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

  return Math.floor((utc2 - utc1) / msPerDay);
};

/**
 * Formats the date using the date-fns library
 * @param {string|null} date - Date string to be formatted
 * @param {string} format - Date format
 * @param {string} onNull - Value to be returned if date is null
 * @returns {string} Formatted date
 */
export const formatDateFns = (
  date: string | Date | null | undefined,
  onNull = '',
  format = 'MMM dd, yyyy',
) => {
  if (!date) {
    return onNull;
  }
  const newDate = new Date(date);
  return formatFns(newDate, format);
};

/**
 *
 * @returns {string} - Returns the date format for the platform
 */
export const getPlatformFormat = () => 'MMM dd, yyyy';

/**
 * Opens the provided URL in a new tab
 * @param url URL to be opened in the new tab
 */
export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

/**
 * Gets a parameter from the URL
 * @param paramName param to be fetched from the URL
 * @returns returns the parameter value
 */
export const getParam = (paramName: string): string | null => {
  const { search } = window.location;
  const param = new URLSearchParams(search).get(paramName);
  return param;
};

/**
 *
 * @param b64Data
 * @param contentType
 * @param sliceSize
 * @returns
 */
export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  let data = b64Data;
  if (/^data:[^;]+;base64,/.test(data)) {
    data = data.replace(/^data:[^;]+;base64,/, '');
  }

  const byteCharacters = atob(data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

// Function to calculate reading time
export function getReadingTime(text: string): number {
  const wpm = 225; // average adult reading speed (words per minute)
  const words = text.trim().split(/\s+/).length;
  const time = Math.ceil(words / wpm);
  return time;
}

// Use this to shade color according to its background

export function shadeColor(color: string, amount: number) {
  return `#${color
    .replace(/^#/, '')
    .replace(/../g, (color: string) =>
      `0${Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)}`.substr(-2),
    )}`;
}

// Use this to access the Hex value from theme if you can't do it inline
// and you need to make it dynamic.
export const getHexFromTheme = (color: string) => {
  const theme = useTheme();
  const colorSplit = color.split('.');
  const group = theme.palette[colorSplit[0] as keyof typeof theme.palette];
  const colorSelect = group[colorSplit[1] as keyof typeof group];
  return colorSelect;
};

/**
 * Returns true or false depending if the user has access to the admin module
 * @param {String} accessToken JWT Access Token
 * @return {Boolean}
 */
export function hasAdminAccess(accessToken: string | null): boolean {
  if (!accessToken) {
    return false;
  }
  return true;
}

export function handleDownload(fileUrl: string, fileName: string) {
  axios({
    url: fileUrl,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${fileName}`);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
}

export const debounceWithQueue = <T>(
  fn: (a: T[]) => void,
  wait: number,
  { maxSize = Infinity, ...debounceOptions } = {},
) => {
  const args: T[] = [];

  const runFn = () => {
    fn(args);
    args.length = 0;
  };

  const debounced = _.debounce(runFn, wait, debounceOptions);

  const cancel = () => {
    debounced.cancel();

    args.length = 0;
  };

  const queuedDebounce = (a: T) => {
    args.push(a);

    if (args.length >= maxSize) debounced.flush();
    else debounced();
  };

  queuedDebounce.cancel = cancel;

  queuedDebounce.flush = debounced.flush;

  return queuedDebounce;
};

// Since the full list of currencies is not finalized, this is just a placeholder
export const formatCurrency = (value: number, currency: EnumCurrency) => {
  switch (currency) {
    // Overrides are reserved for exotic stuff like bitcoin, etherium, etc
    case EnumCurrency.Eth:
    case EnumCurrency.Btc:
      return `${value} ${currency}`;
    default:
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'usd',
      }).format(value);
  }
};

export const getBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });

export const isEmailValid = (email: string) => {
  const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return regex.test(email);
};

// Function that returns time between given time and now
export const timeSince = (date: Date) => {
  const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return interval > 2 ? `${Math.floor(interval)} years` : `${Math.floor(interval)} year`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return interval > 2 ? `${Math.floor(interval)} months` : `${Math.floor(interval)} month`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return interval > 2 ? `${Math.floor(interval)} days` : `${Math.floor(interval)} day`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return interval > 2 ? `${Math.floor(interval)} hours` : `${Math.floor(interval)} hour`;
  }
  interval = seconds / 60;
  return interval > 2 ? `${Math.floor(interval)} minutes` : `${Math.floor(interval)} minute`;
};

export function parseIntlNumber(value: string) {
  if (value) {
    return parseFloat(value.replaceAll(',', ''));
  }
  return 0;
}

export function truncate(str: string, numOfLetters: number, useWordBoundary?: boolean) {
  if (str.length <= numOfLetters) {
    return str;
  }
  const subString = str.slice(0, numOfLetters - 1);
  // eslint-disable-next-line prefer-template
  return (useWordBoundary ? subString.slice(0, subString.lastIndexOf(' ')) : subString) + '\u2026';
}
// eslint-disable-next-line max-len
export const countriesSorted = () =>
  new Map([...Array.from(mapCountry.entries()).sort((a, b) => a[1].localeCompare(b[1]))]);

export const nFormatter = (num: number) => {
  if (num >= 1000000000) {
    return `${(num / 1000000000).toFixed(1).replace(/\.0$/, '')}B`;
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
  }
  return num.toFixed(1) === '0.0' ? '0' : num.toFixed(1);
};

export const addMonths = (date: Date, months: number) =>
  new Date(date.setMonth(date.getMonth() + months));

export const addDays = (date: Date, days: number) => new Date(date.setDate(date.getDate() + days));

export const formatAmount = (newValue: string, maxTwoDecimalPlaces: boolean = false) => {
  const value = newValue.replaceAll(',', '');
  if (value === '') {
    return '';
  }
  const regex1 = /^\d*\.?(?:\d*)?$/;
  const regex2 = /^\d*\.?(?:\d{0,2})?$/;
  const regex = maxTwoDecimalPlaces ? regex2 : regex1;

  if (regex.test(value)) {
    const splitted = value.split('.');
    splitted[0] = splitted[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const joined = splitted.join('.');
    if (joined.length > 1 && joined[0] === '0' && joined[1] !== '.') {
      return joined.substring(1);
    }
    return joined;
  }
  return null;
};

// This function formats bytes in appropriate format
// if it is 2500000 it will bi displayed in MB
// if it is 250000000 it will be displayed in GB
// ...ect
export const formatBytes = (bytes: number) => {
  if (!+bytes) return '0 Bytes';
  const k = 1024;
  const sizes = ['bytes', 'kb', 'mb'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(0))}${sizes[i]}`;
};

export const convertDateToTime = (date: Date | undefined) => {
  const dateObject = date ? new Date(date) : new Date();
  return dateObject?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

export const iconUrlForFile = (fileName: string) => {
  const fileExtension = fileName.split('.').pop();

  switch (fileExtension) {
    case 'link':
      return LinkIcon;
    case 'txt':
      return TxtIcon;
    case 'mp3':
      return Mp3Icon;
    case 'mov':
      return MovIcon;
    case 'zip':
      return ZipIcon;
    case 'pdf':
      return PdfIcon;
    case 'doc':
    case 'docx':
      return WordIcon;
    case 'xls':
    case 'xlsx':
      return XlsxIcon;
    case 'ppt':
    case 'pptx':
    case 'keynote':
      return PptIcon;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'svg':
      return JpgIcon;
    default:
      return UnknownIcon;
  }
};

export const getFileTypeFromUrl = (url: string): string => {
  // extract the file extension from the URL
  const extension = url.split('.').pop();

  // Map file extensions to MIME types
  const extensionToMimetype: Record<string, string> = {
    pdf: 'application/pdf',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    mp3: 'audio/mpeg',
    mp4: 'video/mp4',
    zip: 'application/zip',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    svg: 'image/svg+xml',
    keynote: 'application/x-iwork-keynote-sffkey',
    // add more file extensions and MIME types as needed
  };

  // look up the MIME type based on the extension
  const mimeType = extensionToMimetype[extension?.toLowerCase() ?? ''];

  // Return the MIME type if found, otherwise return an empty string
  return mimeType || '';
};

export const dndFindContainer = (sections: PitchesPanelData, id: string): string => {
  if (id in sections) {
    return id;
  }

  const container = Object.keys(sections).find((key) =>
    sections[key].find((item: TimedProjectPitchContentCustomDto) => item.id === id),
  );
  return container as string;
};

export const dndGetPitchById = (
  pitches: PitchesPanelData,
  id: string,
): TimedProjectPitchContentCustomDto | undefined => {
  for (const k in pitches) {
    const ftas = pitches[k].find((pitch: TimedProjectPitchContentCustomDto) => pitch.id === id);
    if (ftas) {
      return ftas;
    }
  }
};

export const dndGroupPitches = (
  pitches: TimedProjectPitchContentCustomDto[] | undefined,
  options: {
    groupBy: 'status';
    groups: { [key: string]: (string | number | null)[] };
  },
): PitchesPanelData => {
  const groups = Object.keys(options.groups);
  const grouped: PitchesPanelData = groups.reduce(
    (acc: { [key: string]: TimedProjectPitchContentCustomDto[] }, group: string) => {
      acc[group] = [];
      return acc;
    },
    {},
  );
  if (!pitches) {
    return grouped;
  }

  for (let i = 0; i < pitches.length; i += 1) {
    if (pitches[i as number][options.groupBy]) {
      for (let j = 0; j < groups.length; j += 1) {
        if (options.groups[groups[j]].includes(pitches[i][options.groupBy])) {
          grouped[groups[j]].push(pitches[i]);
          break;
        }
      }
    }
  }
  return grouped;
};

export const calculateProjectProgress = (
  tasksCount: number,
  milestoneCount: number,
  tasksCompletedCount: number,
  tasksCanceledCount: number,
  milestoneCompletedCount: number,
  milestoneApprovedCount: number,
) =>
  tasksCount + milestoneCount === 0
    ? 0
    : ((tasksCompletedCount + milestoneCompletedCount + 0.8 * milestoneApprovedCount) /
        (tasksCount - tasksCanceledCount + milestoneCount)) *
      100;
/**
 * helper function for Multistep component
 * @param currentStep current step of the Multistep
 * @param helperTexts array of helper texts for each individual step;
 * if empty string is passed in an array, helper text for that step will not render
 * @returns helper text or null if empty string was passed inside the helperTexts array
 * for that step
 */
export const printHelperTextForSpecificCurrentStep = (
  currentStep: number,
  helperTexts: string[],
): string | null => {
  const baseCondition = helperTexts[currentStep - 1] === '' ? null : helperTexts[currentStep - 1];

  return baseCondition;
};

export function parseBooleanString(value: string | null): boolean | null | string {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  if (value === null) {
    // Sometimes the radio group can be optional, allow this case
    return null;
  }
  return value;
}

// function that takes in text as parameter
// and returns the first word of the text,
// and rest of the text's words
export const splitFirstWord = (text: string) => {
  // split by one or more spaces
  const words = text.trim().split(' ');

  // remove and get the first word
  const firstWord = words.shift();

  // join the remaining words back into a string
  const restOfWords = words.join(' ');

  return {
    firstWord,
    restOfWords,
  };
};

export const stripTags = (html: string) => {
  const parseHTML = new DOMParser().parseFromString(html, 'text/html');
  return parseHTML.body.textContent || '';
};

export const updateGoogleAnalytics = (enable: boolean) => {
  window[`ga-disable-${process.env.REACT_APP_GOOGLE_ANALYTIS_MEASUREMENT_ID}`] = !enable;

  if (enable && process.env.REACT_APP_GOOGLE_ANALYTIS_MEASUREMENT_ID) {
    ga4.initialize(process.env.REACT_APP_GOOGLE_ANALYTIS_MEASUREMENT_ID as string);
    ga4.send('pageview');
  }
};

export const urlSearchParamsStringMapper = (array: any[], key: string) => {
  const search = new URLSearchParams(array.map((value) => [key, value]));
  return search.toString();
};

export const getPublicImageUrl = (enumObject: EnumPublicPagesImage) =>
  `https://tdc-production.ams3.cdn.digitaloceanspaces.com/_public/pages/${mapPublicPagesImagesUrls.get(
    enumObject,
  )}`;

export const smoothScroll = (
  element: HTMLElement,
  start: number,
  end: number,
  duration = 300,
  onProgress?: (current: number) => void,
) => {
  const startTime = performance.now();
  const change = end - start;
  const targetElement = element;

  const animation = (currentTime: number) => {
    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / duration, 1);

    // Easing function
    const easeOutCubic = (t: number) => 1 - (1 - t) ** 3;
    const currentPosition = start + change * easeOutCubic(progress);

    targetElement.scrollLeft = currentPosition;
    onProgress?.(currentPosition);

    if (progress < 1) {
      requestAnimationFrame(animation);
    }
  };

  requestAnimationFrame(animation);
};
