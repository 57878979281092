import { Typography, Stack, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../components/button/CustomButton';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/project-icons/ArrowRightIcon.svg';
import { LAYOUT_DEFAULTS } from '../../../../utils/constants';
import { getPublicImageUrl } from '../../../../utils/helpers';
import { EnumPublicPagesImage } from '../../../../utils/enums';

const LetSponaHandleIt = () => {
  const navigate = useNavigate();

  return (
    // main row
    <Stack
      spacing={{ xs: 0, lg: 4 }}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ ...LAYOUT_DEFAULTS, mb: 10, mt: { xs: -5, sm: 0 } }}
    >
      {/* left column */}
      <Box
        src={getPublicImageUrl(EnumPublicPagesImage.LetSponaHandleIt)}
        component="img"
        sx={{ display: { xs: 'none', md: 'block' }, ml: '-22px' }}
        alt="Illustration of multiple cards representing Spona sellers in different colors"
      />

      {/* right column */}
      <Stack spacing={{ xs: 3, md: 6 }} sx={{ maxWidth: { xs: '100%', lg: '60%' } }}>
        <Typography sx={{ typography: { xs: 'publicHeading4', lg: 'publicHeading3' } }}>
          Let Spona Handle it
        </Typography>

        <Stack spacing={2}>
          <Typography variant="publicBody2Regular" color="common.black">
            Enlist an advisor
          </Typography>

          <Box
            src={getPublicImageUrl(EnumPublicPagesImage.LetSponaHandleItSmall)}
            component="img"
            sx={{ display: { xs: 'block', md: 'none' } }}
            alt="Illustration of multiple cards representing Spona sellers in different colors"
          />

          <Typography variant="publicBody4Regular" color="common.black">
            Have a project in mind, but you’re not sure how to proceed?  Save your time and energy
            with Spona Connect and let us find the perfect fit for your project. 
          </Typography>
        </Stack>

        <Stack spacing={2}>
          <Typography variant="publicBody2Regular" color="common.black">
            Get matched
          </Typography>

          <Typography variant="publicBody4Regular" color="common.black">
            Give your Spona advisor your main project requirements, including timeline, budget and
            desired expertise. They will submit your project and search our community of more than a
            thousand vetted digital service sellers to find the best candidates for you. Choose one
            seller and get started on your project right away.
          </Typography>
        </Stack>

        <CustomButton
          onClick={() => navigate('./spona-connect')}
          sx={{ maxWidth: 'fit-content' }}
          endIcon={<ArrowRightIcon fill="#fff" />}
        >
          Join Spona Connect
        </CustomButton>
      </Stack>
    </Stack>
  );
};

export default LetSponaHandleIt;
