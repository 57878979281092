import { Avatar, Badge, IconButton, Link, Stack, Typography } from '@mui/material';
import CustomChip from '../../../../../../components/custom-chip/CustomChip';
import { primaryDark, primaryLight } from '../../../../../../utils/color';
import { ReactComponent as IconFacebook } from '../../../../../../assets/img/public-pages/facebook-icon-seller-social.svg';
import { ReactComponent as IconLinkedin } from '../../../../../../assets/img/public-pages/linkedin-icon-seller-social.svg';
import { ReactComponent as IconTwitter } from '../../../../../../assets/img/public-pages/x-icon-seller-social.svg';
import { ReactComponent as PenIcon } from '../../../../../../assets/icons/pencil-2.svg';
import { ReactComponent as SponaLogo } from '../../../../../../assets/img/logo/spona-logo-purple.svg';

interface BasicInfoProps {
  author: string;
  publishDate: string;
  categories: string[];
}

const BasicInfo = ({ author, publishDate, categories }: BasicInfoProps) => (
  <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 3, md: 5 }}>
      <Stack direction="column" spacing={0.5}>
        <Typography variant="body2" color="primaryDark.500">
          Author
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={<SponaLogo fill={primaryLight[500]} />}
          >
            <Avatar
              src={''}
              alt="Company logo"
              sx={{
                border: '1px solid #BFC6D7',
                boxShadow: '6px 6px 15px 0px #9747FF40',
                height: '32px',
                width: '32px',
              }}
            />
          </Badge>
          <Typography variant="bodyMedium2" color="secondaryGray.900">
            {author}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="column" spacing={1.25}>
        <Typography variant="body2" color="primaryDark.500">
          Published on:
        </Typography>
        <Typography variant="bodyMedium2" color="secondaryGray.900">
          {publishDate}
        </Typography>
      </Stack>
      <Stack direction="column" spacing={0} justifyContent="flex-end">
        <Stack
          direction="row"
          gap={2}
          flexWrap="wrap"
          overflow="hidden"
          sx={{
            maxHeight: 'max-content',
            mt: '22px',
          }}
        >
          {categories?.map((item: string) => (
            <CustomChip
              label={item}
              sx={{
                pointerEvents: 'none',
                color: primaryDark[600],
                fontSize: '0.75rem',
                fontWeight: 600,
                height: '30px',
              }}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
    <Stack direction="row" sx={{ marginTop: '24px' }} spacing={2}>
      <Link
        href={`https://www.linkedin.com/sharing/share-offsite/?url={${window.location.href}}`}
        target="_blank"
        rel="noopener"
      >
        <Avatar sx={{ width: '24px', height: '24px' }}>
          <IconLinkedin />
        </Avatar>
      </Link>
      <Link
        href={`https://twitter.com/share?url=${window.location.href}`}
        target="_blank"
        rel="noopener"
      >
        <Avatar sx={{ width: '24px', height: '24px' }}>
          <IconTwitter />
        </Avatar>
      </Link>
      <Link
        href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
        target="_blank"
        rel="noopener"
      >
        <Avatar sx={{ width: '24px', height: '24px' }}>
          <IconFacebook />
        </Avatar>
      </Link>
    </Stack>
  </Stack>
);

export default BasicInfo;
