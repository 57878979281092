/* eslint-disable no-unused-expressions */
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { UserInvitationExtendedDto } from 'tdc-web-backend/user-invitation/schemas';
import { useLocation } from 'react-router-dom';
import useGetOne from '../../../utils/hooks/crud-hooks/useGetOne';
import LoadingIndicator from '../../../components/loading-indicator/LoadingIndicator';
import AcceptForm from './components/AcceptForm';

import InvalidToken from './components/InvalidToken';

const AcceptInvitation = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get('token');
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false);

  const { data: invitatioData, isLoading: isGetDataLoading } = useGetOne<UserInvitationExtendedDto>(
    {
      resource: `user-invitation/by-token?token=${token}`,
      enabled: !!token,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        setIsTokenValid(true);
      },
      onError: () => {
        setIsTokenValid(false);
      },
    },
  );

  return (
    <Stack spacing={3} sx={{ pb: 4, minHeight: '90%' }}>
      <Typography
        variant="headline1"
        sx={{
          color: 'primaryDark.600',
        }}
      >
        Sign Up
      </Typography>
      <>
        {token ? (
          isGetDataLoading ? (
            <LoadingIndicator />
          ) : isTokenValid && invitatioData ? (
            <AcceptForm invitationData={invitatioData.data} token={token} />
          ) : (
            <InvalidToken />
          )
        ) : (
          <InvalidToken />
        )}
      </>
    </Stack>
  );
};

export default AcceptInvitation;
