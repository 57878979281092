import Box from '@mui/material/Box';
import { Outlet, useNavigate } from 'react-router-dom';
import { CompanyApprovalStatus, CompanyRole } from 'tdc-web-backend/enums/enums';
import Main from './Main';
import GoToBiggerScreenModal from '../../components/go-to-bigger-screen-modal/GoToBiggerScreenModal';
import useUserCompany from '../../utils/hooks/useUserCompany';

const Dashboard = ({
  showSidebar = true,
  showChat,
  padding = true,
  bgColor = '#FFF',
}: {
  showSidebar?: boolean;
  showChat?: boolean;
  bgColor?: string;
  padding?: boolean;
}) => {
  const navigate = useNavigate();

  const userCompany = useUserCompany();
  const isUserBuyer = userCompany?.roles?.includes(CompanyRole.Buyer);

  const disablelayout =
    userCompany?.status === CompanyApprovalStatus.Pending ||
    userCompany?.status === CompanyApprovalStatus.Rejected;

  return (
    <Box
      sx={{
        paddingBottom: '80px',
        backgroundColor: bgColor,
        minHeight: '100vh',
        pointerEvents: disablelayout && !isUserBuyer ? 'none' : 'inherit',
      }}
    >
      <GoToBiggerScreenModal onClick={() => navigate('/', { replace: true })} />
      <Main showSidebar={showSidebar} showChat={showChat} padding={padding} outlet={<Outlet />} />
    </Box>
  );
};

export default Dashboard;
