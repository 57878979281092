import { Stack, Typography } from '@mui/material';
import { PUBLIC_APPBAR_HEIGHT } from '../../../../layout/public-layout/navigation/Navigation';
import { LAYOUT_DEFAULTS } from '../../../../utils/constants';

export interface HowToManageHeroProps {
  image?: string;
  bgcolor?: string;
  title: string;
  subtitle: string;
  showDotsPattern?: boolean;
}

const HowToManageHero = ({
  image,
  bgcolor,
  title,
  subtitle,
  showDotsPattern = false,
}: HowToManageHeroProps) => (
  <Stack
    sx={{
      position: 'relative',
      background: image ? `url(${image})` : bgcolor,
      height: '100vh',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right',
      backgroundSize: 'cover',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        background: showDotsPattern
          ? 'radial-gradient(rgba(255, 255, 255, 0.4) 1px, transparent 1px)'
          : 'none',
        backgroundSize: showDotsPattern ? '60px 60px' : 'auto',
      },
    }}
    justifyContent="center"
  >
    <Stack spacing={4} sx={{ pt: `${PUBLIC_APPBAR_HEIGHT}px`, ...LAYOUT_DEFAULTS }}>
      <Typography
        sx={{
          typography: { xs: 'publicHeadline9', lg: 'publicHeadline6' },
          maxWidth: { xs: '100%', xxl: '80%' },
        }}
        color="common.white"
        whiteSpace="pre-wrap"
      >
        {title}
      </Typography>

      <Typography
        variant="publicBody3Regular"
        color="common.white"
        maxWidth={{ xs: '100%', lg: '45%' }}
      >
        {subtitle}
      </Typography>
    </Stack>
  </Stack>
);

export default HowToManageHero;
