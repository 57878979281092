import { CardMedia, Divider, Stack, Typography } from '@mui/material';
import { CaseStudyContentDto } from 'tdc-web-backend/case-studies-content/schemas';
import { Box } from '@mui/system';
import CustomButton from '../../../components/button/CustomButton';
import useReferenceOne from '../../../utils/hooks/crud-hooks/useReferenceOne';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDateFns } from '../../../utils/helpers';
import { primaryDark } from '../../../utils/color';

import { EnumTimedProjectPitchStatus, mapCountry } from 'tdc-web-backend/enums/enums';
import BaseInputLabel from '../../../components/custom-inputs/BaseInputLabel';
import { CaseStudyEditorMenu } from '../../../components/editor/menu-option-lists/menu-item-lists';
import Editor from '../../../components/editor/Editor';
import ChipGroup from '../../Dashboard/CaseStudies/CaseStudiesDetails/CaseStudyChipGroup';
import { ReactComponent as TrophyIcon } from '../../../assets/icons/layout-icons/trophy2-fill.svg';
import { ReactComponent as BullseyeIcon } from '../../../assets/icons/layout-icons/BullseyeIcon.svg';
import { ReactComponent as BriefcaseCheckIcon } from '../../../assets/icons/layout-icons/BriefcaseCheckIcon.svg';
import { ReactComponent as LightBulbIcon } from '../../../assets/icons/layout-icons/LightBulbIcon.svg';
import useUpdate from '../../../utils/hooks/crud-hooks/useUpdate';
import { useRefresh } from '../../../utils/hooks/crud-hooks/useRefresh';
import { TimedProjectPitchContentDto } from 'tdc-web-backend/timed-project-pitch-contents/schemas';

const PitchingDetails = () => {
  const { pitchId } = useParams();
  const refresh = useRefresh();
  const navigate = useNavigate();

  const { data } = useReferenceOne<TimedProjectPitchContentDto>({
    id: pitchId,
    resource: 'timed-project-pitch-contents',
    enabled: !!pitchId,
  });

  const { mutate: approve, isLoading } = useUpdate({ resource: '/timed-project-pitches' });

  const handlePitch = (action: string) => {
    approve(
      {
        id: data?.timedProjectPitch,
        suffix: action,
      },
      {
        onSuccess: () => {
          refresh();
          navigate('../');
        },
      },
    );
  };

  return (
    <Box>
      <Stack p={6}>
        <Typography variant="heading3" color="primaryDark.600">
          {data?.timedProjectContentCurrent.body.overview.name}
        </Typography>
        <Typography variant="body3" pt={4} color="primaryDark.500">
          {formatDateFns(data?.published, '---')}
        </Typography>
        <Stack direction="row" py={3} alignItems="center" justifyContent={'space-between'}>
          <Stack direction="row" gap={4} alignItems="center">
            <CustomButton
              loading={isLoading}
              variant="secondary"
              onClick={() => handlePitch('reject')}
              disabled={data?.status !== EnumTimedProjectPitchStatus.Pending}
            >
              Reject
            </CustomButton>
            <CustomButton
              disabled={data?.status !== EnumTimedProjectPitchStatus.Pending}
              loading={isLoading}
              onClick={() => handlePitch('approve')}
            >
              Approve
            </CustomButton>
          </Stack>
        </Stack>
        <Divider />
        <Stack width="100%">
          {/* <CardMedia
            sx={{ m: 4, borderRadius: '3px', maxWidth: '100%' }}
            component="video"
            src={data?.video}
          /> */}
          <Divider />
          {data?.overview?.description && (
            <Stack py={2}>
              <Stack direction="row" spacing={1} px={3}>
                <TrophyIcon fill={primaryDark[500]} />
                <BaseInputLabel text="description" />
              </Stack>
              <Editor
                items={CaseStudyEditorMenu}
                content={data?.overview.description}
                isEditable={false}
                key="description"
              />
            </Stack>
          )}
          <Divider />
          {data?.overview?.strategy && (
            <Stack py={2}>
              <Stack direction="row" spacing={1} px={3}>
                <BullseyeIcon fill={primaryDark[500]} />
                <BaseInputLabel text="strategy" />
              </Stack>
              <Editor
                items={CaseStudyEditorMenu}
                content={data?.overview?.strategy}
                isEditable={false}
                key="strategy"
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default PitchingDetails;
