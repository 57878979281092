import { Divider, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../../../components/modal/CustomModal';
import { secondaryBlue } from '../../../../utils/color';
import CustomButton from '../../../../components/button/CustomButton';
import { ReactComponent as RequestReviewIcon } from '../../../../assets/icons/project-icons/EnvelopeIcon.svg';

interface ForgotPasswordModalProps {
  open: boolean;
  closeModal: () => void;
}

const ForgotPasswordModal = ({ open, closeModal }: ForgotPasswordModalProps) => {
  const navigate = useNavigate();

  return (
    <CustomModal open={open} sx={{ width: { xs: '85%', lg: '45%' } }}>
      <Stack spacing={3}>
        <Stack spacing={2} direction="row" alignItems="center">
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              bgcolor: '#E5E8FF',
              width: 'fit-content',
              height: 'fit-content',
              borderRadius: '6px',
              p: 2,
            }}
          >
            <RequestReviewIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />
          </Stack>

          <Typography variant="heading3" color="primaryDark.600">
            Password Reset
          </Typography>
        </Stack>
        <Divider />

        <Typography color="primaryDark.500">
          You will receive an email from Spona shortly. Follow the link in the to reset your
          password.
        </Typography>

        <Divider />

        <Stack sx={{ justifyContent: 'end' }} spacing={2} direction="row">
          <CustomButton
            variant="primary"
            sx={{ width: { xs: '100%', md: '30%', lg: '22%' } }}
            onClick={() => {
              navigate('/login');
            }}
          >
            Continue
          </CustomButton>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default ForgotPasswordModal;
