import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { secondaryBlue } from '../../../utils/color';
import TextAndImage from '../../../components/public-screen-components/TextAndImage';
import HowMuchDoesItCost from './components/HowMuchDoesItCost';
import HowToManageHero from './components/HowToManageHero';
import TheFutureOfSmartManaging from './components/TheFutureOfSmartManaging';
import { textsAndImagesBuyer, theFutureOfSmartManagingTextAndIcons } from './utils';
import MetaTags from '../../../components/seo/MetaTags';
import BecomeAPartOfSpona from '../../../components/public-screen-components/BecomeAPartOfSpona';

const metaTags = {
  title: `Spona - The Ultimate Project Management Tool is here`,
  description: `End-to-End Project Management is our all-in-one project management tool. Sign contracts, Track project milestones get reports and pay, all in one place.`,
};

const HowToManageBuyer = () => {
  const navigate = useNavigate();

  return (
    <>
      <MetaTags {...metaTags} />
      <Stack overflow="hidden" width="100%">
        <HowToManageHero
          bgcolor={secondaryBlue[501]}
          title="The Ultimate Project Management Tool Is Here"
          subtitle="End-to-End Project Management is our all-in-one project management tool. It is designed to support successful project completion and smooth development with comprehensive features that make all phases of the process streamlined and convenient."
        />

        <Stack spacing={10} sx={{ mt: 12, mb: 18.75 }}>
          {textsAndImagesBuyer.map(({ title, content, image, contentOrder, contentButton, id }) => (
            <TextAndImage
              key={id}
              title={title}
              content={content}
              image={image}
              contentOrder={contentOrder}
              contentButton={contentButton}
            />
          ))}
        </Stack>

        <TheFutureOfSmartManaging
          textAndIcons={theFutureOfSmartManagingTextAndIcons(secondaryBlue[501])}
          bgcolor={secondaryBlue[101]}
        />
        <Box sx={{ mt: '9rem' }}>
          <BecomeAPartOfSpona
            title="See our pricing plans"
            subtitle="Choose the plan that fits your needs best and get started with Spona today."
            bgcolor="secondaryBlue.101"
            onButtonClick={() => navigate('/how-to-manage-buyer/pricing')}
            color="primaryDark.900"
            dividerColor="primaryDark.900"
          />
        </Box>
      </Stack>
    </>
  );
};

export default HowToManageBuyer;
