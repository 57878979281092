import { Box, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { MaterialsDto } from 'tdc-web-backend/timed-project-contents/schemas';
import { ReactComponent as CheckedIcon } from '../../../../assets/icons/project-icons/CheckedIcon.svg';
import { ReactComponent as CancelCircleIcon } from '../../../../assets/icons/project-icons/CancelCircleIcon.svg';
import { ReactComponent as ErrorAlertIcon } from '../../../../assets/icons/layout-icons/ErrorAlertIcon.svg';
import ControlledDropzoneFileInput from '../../../../components/custom-inputs/ControlledDropzoneFileInput/ControlledDropzoneFileInput';
import { formatBytes, iconUrlForFile, truncate } from '../../../../utils/helpers';
import { ReactComponent as FileUploadSvg } from '../../../../assets/icons/project-icons/FileUploadSvg.svg';
import useUploadMultipleFiles, {
  FileWithUploadProgress,
} from '../../../../utils/hooks/crud-hooks/useUploadMultipleFiles';
import { primaryDark, secondaryPink } from '../../../../utils/color';

type TMaterials = FileWithUploadProgress[] | [];
type TMaterialsMap = Record<string, FileWithUploadProgress>;

function updateFilesStatus(currentMaterials: TMaterials, nextMaterials: TMaterials) {
  if (!currentMaterials.length) return nextMaterials; // return new values if state is empty
  const uploadedFiles = {} as TMaterialsMap;

  // save current materials as a Map to prevent O(n2) complexity
  currentMaterials.forEach((material) => {
    uploadedFiles[material.file.name] = material;
  });

  // update existing values, add the new ones
  nextMaterials.forEach((material) => {
    const key = material.file.name;
    uploadedFiles[key] = material;
  });

  return Object.values(uploadedFiles);
}

const Materials = ({ resource }: { resource: string }) => {
  const {
    reset,
    getValues,
    setError,
    trigger,
    formState: { errors },
  } = useFormContext();

  const [files, setFiles] = useState<File[]>([]);

  const { isUploadingInProgress, isEveryFileFinishedUploading } = useUploadMultipleFiles({
    resource,
    files,
    onFilesStartUpload: (nextMaterials) => {
      const values = getValues();
      const currentMaterials = values.materialsPreview || [];
      const materialsPreview = updateFilesStatus(currentMaterials, nextMaterials);

      reset({
        ...values,
        materialsPreview,
      });
    },
    onAllFilesCompletedUpload: (response) => {
      if (response) {
        const values = getValues();
        const currentMaterials = values.materials || [];
        const nextMaterials = response.map((material) => material.data);
        reset({
          ...values,
          materials: [...currentMaterials, ...nextMaterials],
        });
      }
    },
  });

  useEffect(() => {
    const shouldDisable = isUploadingInProgress === true && isEveryFileFinishedUploading === false;

    if (shouldDisable) {
      setError('materialsPreview', {
        message: 'random msg',
        type: 'onChange',
      });
    }
  }, [errors, isUploadingInProgress, isEveryFileFinishedUploading]);

  const handleOnUpload = async (acceptedFiles: File[]) => {
    if (!acceptedFiles) return;
    setFiles(acceptedFiles);
    trigger();
  };

  const handleDeleteFile = (fileName: string) => {
    const materialsArray: MaterialsDto[] = getValues('materials');
    const materialsPreviewArray: FileWithUploadProgress[] = getValues('materialsPreview');

    const filteredMaterialsArray = materialsArray.filter(
      (material) => fileName !== material.fileName,
    );

    const filteredMaterialsPreviewArray = materialsPreviewArray.filter(
      (material) => fileName !== material.file.name,
    );

    reset({ ...getValues(), materials: filteredMaterialsArray });
    reset({ ...getValues(), materialsPreview: filteredMaterialsPreviewArray });
  };

  return (
    <Stack spacing={2} direction="row">
      <ControlledDropzoneFileInput
        name="materialsPreview"
        onUpload={handleOnUpload}
        dropzoneProps={{
          multiple: true,
        }}
        maxFileSize={10000000}
        sx={{ maxHeight: '170px', width: '50%' }}
      />

      <Stack spacing={1} width="50%">
        {getValues('materialsPreview') !== null &&
          getValues('materialsPreview')?.length > 0 &&
          getValues('materialsPreview').map((file: FileWithUploadProgress) => (
            <Stack
              key={file.file.name}
              spacing={1}
              width="100%"
              height="fit-content"
              sx={{
                p: 2,
                textAlign: 'center',
                backgroundColor: file.error
                  ? 'secondaryPink.100'
                  : file.isUploadInProgress
                  ? 'secondaryBlue.50'
                  : 'secondaryGreen.100',
                borderRadius: '0.5rem',
              }}
            >
              {/* file preview, title, remove button */}
              <Stack direction="row" justifyContent="space-between" alignItems="stretch">
                {/* file preview & file name, success icon/error icon, file size, remove button */}
                <Stack direction="row" alignItems="center" justifyContent="left" spacing={1.5}>
                  {/* file preview */}
                  {file.file.type === 'image/png' || file.file.type === 'image/jpeg' ? (
                    <Box
                      component="img"
                      src={URL.createObjectURL(file.file)}
                      sx={{
                        maxWidth: '3.5rem',
                        maxHeight: '3.5rem',
                        objectFit: 'contain',
                        borderRadius: '4px',
                        p: 0,
                      }}
                    />
                  ) : (
                    <Box
                      component="img"
                      sx={{
                        maxWidth: '3.5rem',
                        maxHeight: '3.5rem',
                        objectFit: 'contain',
                        borderRadius: '4px',
                        p: 0,
                      }}
                      src={(() => iconUrlForFile(file.file.name))()}
                    />
                  )}

                  {/* file name, success icon/error icon & file size */}
                  <Stack spacing={0.5} alignItems="start">
                    {/* file name, success icon/error icon */}
                    <Stack alignItems="center" direction="row">
                      <Typography variant="body3" color="primaryDark.600" fontWeight={600}>
                        {truncate(file.file.name, 30)}
                      </Typography>

                      {file.uploadProgress === 100 && !file.error && (
                        <IconButton sx={{ height: '10px', pointerEvents: 'none' }}>
                          <CheckedIcon />
                        </IconButton>
                      )}

                      {file.uploadProgress === 0 && file.error && (
                        <IconButton sx={{ height: '10px', pointerEvents: 'none' }}>
                          <ErrorAlertIcon fill={secondaryPink[500]} />
                        </IconButton>
                      )}
                    </Stack>

                    {/* file size */}
                    <Typography variant="body3" color="primaryDark.500">
                      {formatBytes(file.file.size)}
                    </Typography>
                  </Stack>
                </Stack>

                {/* remove button */}
                <IconButton
                  onClick={() => handleDeleteFile(file.file.name)}
                  disableRipple
                  disableTouchRipple
                  sx={{
                    alignSelf: 'start',
                    mb: 1,
                    p: 0,
                    pt: '2px',
                  }}
                >
                  <CancelCircleIcon fill={primaryDark[500]} />
                </IconButton>
              </Stack>

              {/* progress bar & upload progress */}
              {!file.error && (
                <Stack direction="row" width="100%" alignItems="center" spacing={1}>
                  <Box
                    sx={{
                      height: '4px',
                      width: '100%',
                      borderRadius: '1rem',
                    }}
                  >
                    <LinearProgress
                      value={file.uploadProgress || 0}
                      variant="determinate"
                      color="secondary"
                    />
                  </Box>

                  <Typography variant="heading5" color="primaryDark.500">
                    {file.uploadProgress}%
                  </Typography>
                </Stack>
              )}

              {/* error message */}
              {file.uploadProgress === 0 && file.error && (
                <Typography variant="body3" color="secondaryPink.500" sx={{ alignSelf: 'start' }}>
                  {file.error}
                </Typography>
              )}
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
};

export default Materials;
