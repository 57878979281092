import { Card, LinearProgress, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { CompanyRole, EnumCurrency, PaymentStatus } from 'tdc-web-backend/enums/enums';
import useUserCompany from '../../../../utils/hooks/useUserCompany';
import { formatCurrency } from '../../../../utils/helpers';

type Props = {
  payments: MilestoneDto[];
};

const PaymentLinearProgressCard = ({ payments }: Props) => {
  const userType = useUserCompany()?.roles;
  const isUserBuyer = userType?.includes(CompanyRole.Buyer);
  const paidPayments = payments
    ?.filter((payment) => payment.paymentStatus === PaymentStatus.Paid)
    .map((payment) => payment.budget);
  const unpaidPayments = payments
    ?.filter((payment) => payment.paymentStatus !== PaymentStatus.Paid)
    .map((payment) => payment.budget);

  const getPaymentAmount = (paymentsList: number[]) =>
    paymentsList?.length ? paymentsList?.reduce((a, b) => a + b) : 0;

  const getPaymentPercentage = () => {
    const percentage =
      getPaymentAmount(paidPayments) / getPaymentAmount(payments?.map((payment) => payment.budget));
    return Number.isNaN(percentage) ? 0 : percentage * 100;
  };

  return (
    <Card
      elevation={0}
      sx={{
        pt: 5,
        px: 3,
        pb: 3,
        borderRadius: '8px',
        width: '100%',
      }}
      className="react-joyride-step-3-payments"
    >
      <Stack direction="row" justifyContent="space-between" width="100%" sx={{ p: 1 }}>
        <Typography fontSize="1.2rem" fontWeight={600}>
          {isUserBuyer ? 'Paid' : 'Earned'}
        </Typography>
        <Typography fontSize="1.2rem" fontWeight={600}>
          {isUserBuyer ? 'Remaining' : 'Expected'}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Box sx={{ width: '100%' }}>
          <LinearProgress
            variant="determinate"
            value={getPaymentPercentage()}
            sx={{
              height: '0.6rem',
              borderRadius: '16px',
              backgroundColor: '#FFDAD4',
              '.MuiLinearProgress-barColorPrimary': {
                backgroundColor: '#52AF66',
              },
            }}
          />
        </Box>
      </Stack>
      {/* TODO - How to handle this currency? */}
      <Stack direction="row" justifyContent="space-between" width="100%" sx={{ p: 1 }}>
        <Typography fontWeight={400}>
          {formatCurrency(getPaymentAmount(paidPayments), EnumCurrency.Usd)}
        </Typography>
        <Typography fontWeight={400}>
          {formatCurrency(getPaymentAmount(unpaidPayments), EnumCurrency.Usd)}
        </Typography>
      </Stack>
    </Card>
  );
};

export default PaymentLinearProgressCard;
