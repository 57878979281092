import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { Stack } from '@mui/system';
import { Divider, IconButton, Typography } from '@mui/material';
import { CompanyDto, UpdateCompanyDto } from 'tdc-web-backend/companies/schemas';
import CustomModal from '../../../../components/modal/CustomModal';
import { secondaryBlue } from '../../../../utils/color';
import { ReactComponent as ImageIcon } from '../../../../assets/icons/project-icons/ImageIcon.svg';
import { ReactComponent as XIcon } from '../../../../assets/icons/layout-icons/XIcon.svg';
import CustomButton from '../../../../components/button/CustomButton';
import useUpdate from '../../../../utils/hooks/crud-hooks/useUpdate';
import useReferenceOne from '../../../../utils/hooks/crud-hooks/useReferenceOne';
import { useRefresh } from '../../../../utils/hooks/crud-hooks/useRefresh';
import AvatarEditorField from '../../../../components/custom-inputs/AvatarEditorField';
import useAuth from '../../../../utils/hooks/useAuth';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  userDropdown?: boolean;
};

const AvatarEditorModal = ({ open, setOpen, userDropdown = false }: Props) => {
  const [image, setImage] = useState<any>(null);
  const refresh = useRefresh();
  const {
    authData: { userData },
    updateUser,
    isLoadingUpdateUser,
  } = useAuth();

  const { data: companyData } = useReferenceOne<CompanyDto>({
    resource: 'companies',
    id: userData?.membership?.company,
    enabled: !!userData && !userDropdown,
  });

  const { mutate: updateCompany, isLoading } = useUpdate<CompanyDto, UpdateCompanyDto>({
    resource: '/companies',
  });

  const cropRef = useRef<any>(null);

  const handleSave = async () => {
    if (cropRef) {
      const dataUrl = cropRef?.current?.getImage().toDataURL();
      const options = {
        onSuccess: () => {
          refresh();
          setOpen(false);
          setImage(null);
        },
      };

      if (userDropdown) {
        updateUser(
          {
            avatar: dataUrl,
          },
          { ...options },
        );
      } else {
        updateCompany(
          {
            data: { avatar: dataUrl },
            id: companyData?.id as string,
          },
          {
            ...options,
          },
        );
      }
    }
  };

  return (
    <CustomModal open={open} onClose={() => setOpen(false)} width="50%">
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            <Stack
              width={40}
              height={40}
              mr={2}
              bgcolor="secondaryBlue.100"
              borderRadius={1}
              justifyContent="center"
              alignItems="center"
            >
              <ImageIcon width="18px" fill={secondaryBlue[700]} height="18px" />
            </Stack>
            {userDropdown ? (
              <Typography variant="heading5">Edit profile photo</Typography>
            ) : (
              <Typography variant="heading5">Edit company logo</Typography>
            )}
          </Stack>
          <IconButton sx={{ p: 0 }} disableRipple onClick={() => setOpen(false)}>
            <XIcon width="18px" fill={secondaryBlue[700]} height="18px" />
          </IconButton>
        </Stack>
        <Divider />
        <AvatarEditorField
          cropRef={cropRef}
          image={image}
          avatar={userDropdown ? userData?.avatar : companyData?.avatar}
        />
        <Divider />
        <Stack direction="row" sx={{ justifyContent: 'right' }} spacing={2}>
          <CustomButton type="button" variant="secondary">
            {userDropdown ? (
              <label>
                Change photo
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    setImage(e.target.files?.[0]);
                  }}
                />
              </label>
            ) : (
              <label>
                Change Logo
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    setImage(e.target.files?.[0]);
                  }}
                />
              </label>
            )}
          </CustomButton>
          <CustomButton
            onClick={handleSave}
            type="button"
            loading={userDropdown ? isLoadingUpdateUser : isLoading}
          >
            Apply
          </CustomButton>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default AvatarEditorModal;
