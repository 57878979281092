import { Stack, Typography } from '@mui/material';
import CustomButton from '../../../../../../components/button/CustomButton';
import { primaryLight } from '../../../../../../utils/color';
import { ReactComponent as ArrowRightIcon } from '../../../../../../assets/icons/project-icons/ArrowRightIcon.svg';
import { LAYOUT_DEFAULTS } from '../../../../../../utils/constants';

const GetDemoHire = () => (
  <Stack
    justifyContent="space-between"
    width="100%"
    height="100%"
    px={{ ...LAYOUT_DEFAULTS.mx }}
    p={{ lg: 10 }}
    py={10}
    direction={{ lg: 'row' }}
    gap={10}
    mt={10}
    bgcolor="primaryLight.50"
  >
    <Stack width="100%" gap={2}>
      <Typography variant="publicHeading3" color="primaryDark.900">
        Not sure which plan is right for you?
      </Typography>
      <Typography variant="publicBody4Regular" color="primaryDark.700">
        Schedule some time with a Spona advisor and let us talk you through the options
      </Typography>
      <CustomButton
        sx={{ width: 'fit-content' }}
        href={process.env.REACT_APP_CALENDLY_URL as string}
        endIcon={<ArrowRightIcon fill={primaryLight[50]} />}
      >
        Book your demo
      </CustomButton>
    </Stack>
  </Stack>
);
export default GetDemoHire;
