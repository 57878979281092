import { CircularProgress, Stack, SxProps, Theme } from '@mui/material';
import { primaryLight } from '../../utils/color';

export interface LoadingIndicatorProps {
  sx?: SxProps<Theme> | undefined;
  sxCp?: SxProps<Theme> | undefined;
}

const LoadingIndicator = ({ sx, sxCp }: LoadingIndicatorProps) => (
  <Stack sx={{ py: '5rem', ...sx }} direction="row" alignItems="center" justifyContent="center">
    <CircularProgress sx={{ color: primaryLight[300], ...sxCp }} />
  </Stack>
);

export default LoadingIndicator;
