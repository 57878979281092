import { Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import { useNavigate } from 'react-router-dom';
import useGetMany from '../../../../../utils/hooks/crud-hooks/useGetMany';
import { mapTempTaskStatus } from './ProjectTablePanelDataGrid';
import useTable from '../../../../../components/table/useTable';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import CustomButton from '../../../../../components/button/CustomButton';
import CustomChip from '../../../../../components/custom-chip/CustomChip';
import CustomReferenceField from '../../../../../components/custom-fields/CustomReferenceField';
import BaseTable from '../../../../../components/table/BaseTable';
import { formatDateFns } from '../../../../../utils/helpers';
import useAuth from '../../../../../utils/hooks/useAuth';

export enum ProjectListTab {
  All = 'all',
  Canceled = 'canceled',
  Completed = 'completed',
  In_progress = 'in_progress',
  Negotiation = 'negotiation',
  Pending = 'pending',
}

const tabs: ProjectListTab[] = [
  ProjectListTab.All,
  ProjectListTab.Canceled,
  ProjectListTab.Completed,
  ProjectListTab.In_progress,
  ProjectListTab.Negotiation,
  ProjectListTab.Pending,
];

const AdminProjectList = () => {
  const [selectedTab, setSelectedTab] = useState<ProjectListTab>(tabs[0]);
  const {
    authData: { userData },
  } = useAuth();
  const navigate = useNavigate();
  const projectUrl =
    selectedTab == tabs[0] ? '/projects' : `/projects?status=${selectedTab.toLocaleLowerCase()}`;

  const { data } = useGetMany<ProjectDto>({ resource: projectUrl, enabled: !!userData });
  const { data: counterData } = useGetMany<ProjectDto>({
    resource: '/projects',
    enabled: !!userData,
  });
  const projectArray = data?.data.results;

  const getDataCountForStatus = (status: string) => {
    const dataWithStatus = counterData?.data.results.filter((project) => project.status === status);
    return dataWithStatus?.length;
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      flex: 0.3,
      renderCell: (params: GridValueGetterParams) => (
        <Typography
          sx={{
            fontSize: '0.875rem',
            fontWeight: 600,
          }}
        >
          {params.row.name}
        </Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: false,
      flex: 0.3,
      renderCell: (params: GridValueGetterParams) => (
        <CustomChip label={mapTempTaskStatus.get(params.value)} />
      ),
    },
    {
      field: 'start',
      headerName: 'Start date',
      editable: false,
      flex: 0.3,
      renderCell: (params: GridValueGetterParams) => (
        <Typography sx={{ fontSize: '0.8rem' }}>{formatDateFns(params.value)}</Typography>
      ),
    },
    {
      field: 'end',
      headerName: 'End date',
      editable: false,
      flex: 0.3,
      renderCell: (params: GridValueGetterParams) => (
        <Typography sx={{ fontSize: '0.8rem' }}>{formatDateFns(params.value)}</Typography>
      ),
    },
    {
      field: 'buyer',
      headerName: 'Buyer',
      editable: false,
      flex: 0.3,
      renderCell: (params: GridValueGetterParams) => (
        <CustomReferenceField
          sx={{ fontSize: '0.8rem' }}
          resource="companies"
          id={params.value}
          displayName="name"
        />
      ),
    },
    {
      field: 'seller',
      headerName: 'Seller',
      editable: false,
      flex: 0.3,
      renderCell: (params: GridValueGetterParams) => (
        <CustomReferenceField
          sx={{ fontSize: '0.8rem' }}
          resource="companies"
          id={params.value}
          displayName="name"
        />
      ),
    },
  ];

  const { resourceLimit, ...props } = useTable({
    resourceUrl: 'projects',
  });

  return (
    <Box sx={{ width: '100%' }}>
      <Stack direction="column" spacing={1}>
        <BaseTable
          numberOfRowsPerPage={resourceLimit}
          columns={columns}
          onRowClick={(params) => navigate(`${params.id}` ?? `./${params.id}`)}
          withElements={{
            withTitle: 'Projects (e2e) (tabs & filters will be added)',
            withCta: (
              <CustomButton onClick={() => navigate('../create-project')}>Add project</CustomButton>
            ),
          }}
          {...props}
        />
      </Stack>
    </Box>
  );
};

export default AdminProjectList;
