import { AxiosResponse } from 'axios';
import { MaterialsDto } from 'tdc-web-backend/timed-project-contents/schemas';
import { Box, List, ListItemButton, Stack, Typography } from '@mui/material';
import { primaryDark } from '../../../utils/color';
import { iconUrlForFile } from '../../../utils/helpers';

const PreviewMaterials = ({ materials }: { materials: MaterialsDto[] }) => {
  if (!materials.length) return null;

  return (
    <List>
      {materials.map((material) => (
        <ListItemButton
          component="a"
          href={material.file || ''}
          sx={{
            px: 0,
          }}
          download
        >
          <Stack>
            <Stack mb={0.75} direction="row" alignItems="center">
              <Box
                width={10}
                height={10}
                sx={{
                  border: `2px solid ${primaryDark[300]}`,
                  borderRadius: '50%',
                  bgcolor: 'primaryDark.500',
                  mr: 1.25,
                }}
              />
              <Typography variant="body3" color="primaryDark.600">
                {material.fileName}
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" pl={1.25}>
              <Box
                component="img"
                sx={{ width: 30, height: 30 }}
                src={(() => iconUrlForFile(material?.file))()}
                mr={1.25}
              />
              <Typography variant="body3" fontWeight="600" color="primaryDark.600">
                {material.fileName}
              </Typography>
            </Stack>
          </Stack>
        </ListItemButton>
      ))}
    </List>
  );
};

export default PreviewMaterials;
