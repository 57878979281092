import { Typography, Stack, Divider, Box, Alert } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useState } from 'react';
import CustomButton from '../../../../components/button/CustomButton';
import useAuth from '../../../../utils/hooks/useAuth';
import NavigationBlocker from '../../../../components/navigation-blocker/NavigationBlocker';
import UserBasicDetails from './components/UserBasicDetails';

const Account = () => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const {
    authData: { userData },
    updateUser,
    isLoadingUpdateUser,
  } = useAuth();

  const formDefaultValues = {
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    jobTitle: userData?.jobTitle,
    avatar: '',
  };

  const form = useForm({
    defaultValues: {
      ...formDefaultValues,
    },
  });

  const onSubmit = () => {
    updateUser(
      {
        firstName: form.getValues('firstName'),
        lastName: form.getValues('lastName'),
        jobTitle: form.getValues('jobTitle'),
        ...(form.getValues('avatar') !== '' ? { avatar: form.getValues('avatar') } : {}),
      },
      {
        onSuccess: () => {
          form.reset(undefined, { keepValues: true });
          form.setValue('avatar', '');
          setError(null);
          setSuccess('User info updated successfully');
        },
        onError: () => {
          form.reset(formDefaultValues);
          setSuccess(null);
          setError('Something went wrong');
        },
      },
    );
  };

  return (
    <>
      {error && (
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" onClose={() => setSuccess(null)}>
          {success}
        </Alert>
      )}
      <Box sx={{ padding: '1.5rem' }}>
        <FormProvider {...form}>
          <form>
            <Stack spacing={5}>
              <Typography variant="heading3" color="primaryDark.500">
                user info
              </Typography>

              <UserBasicDetails userData={userData} disabled={isLoadingUpdateUser} />
              <Divider />
              <Stack direction="row" justifyContent="flex-end">
                <CustomButton
                  variant="primary"
                  sx={{
                    height: '2.8125rem',
                    width: '100%',
                    maxWidth: '120px',
                    marginTop: '22px !important',
                  }}
                  onClick={form.handleSubmit(onSubmit)}
                  loading={isLoadingUpdateUser}
                >
                  Save
                </CustomButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Box>

      <NavigationBlocker condition={form.formState.isDirty} />
    </>
  );
};

export default Account;
