import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { Stack } from '@mui/system';
import { Divider, IconButton, Typography } from '@mui/material';
import { BaseUserDto } from 'tdc-web-backend/users/shemas';
import { useFormContext } from 'react-hook-form';
import CustomModal from '../../../../../../../components/modal/CustomModal';
import { secondaryBlue } from '../../../../../../../utils/color';
import { ReactComponent as ImageIcon } from '../../../../../../../assets/icons/project-icons/ImageIcon.svg';
import { ReactComponent as XIcon } from '../../../../../../../assets/icons/layout-icons/XIcon.svg';
import CustomButton from '../../../../../../../components/button/CustomButton';
import AvatarEditorField from '../../../../../../../components/custom-inputs/AvatarEditorField';

type Props = {
  userData: BaseUserDto & { avatar: string };
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const AvatarEditorModal = ({ userData, open, setOpen }: Props) => {
  const [image, setImage] = useState<any>(null);
  const cropRef = useRef<any>(null);
  const form = useFormContext();

  const handleSave = async () => {
    if (cropRef) {
      const dataUrl = cropRef?.current?.getImage().toDataURL();
      form.setValue('avatar', dataUrl);
      setOpen(false);
      setImage(null);
    }
  };

  return (
    <CustomModal open={open} onClose={() => setOpen(false)} width="50%">
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            <Stack
              width={40}
              height={40}
              mr={2}
              bgcolor="secondaryBlue.100"
              borderRadius={1}
              justifyContent="center"
              alignItems="center"
            >
              <ImageIcon width="18px" fill={secondaryBlue[700]} height="18px" />
            </Stack>
            <Typography variant="heading5">Edit profile photo</Typography>
          </Stack>
          <IconButton sx={{ p: 0 }} disableRipple onClick={() => setOpen(false)}>
            <XIcon width="18px" fill={secondaryBlue[700]} height="18px" />
          </IconButton>
        </Stack>
        <Divider />
        <AvatarEditorField
          cropRef={cropRef}
          image={image}
          avatar={form.getValues('avatar') !== '' ? form.getValues('avatar') : userData.avatar}
        />
        <Divider />
        <Stack direction="row" sx={{ justifyContent: 'right' }} spacing={2}>
          <CustomButton type="button" variant="secondary">
            <label>
              Change photo
              <input
                type="file"
                hidden
                onChange={(e) => {
                  setImage(e.target.files?.[0]);
                }}
              />
            </label>
          </CustomButton>
          <CustomButton onClick={handleSave} type="button">
            Apply
          </CustomButton>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default AvatarEditorModal;
