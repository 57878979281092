import { Divider, Stack, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import { ChannelDto, UpdateChannelDto } from 'tdc-web-backend/channels/schemas';
import { FormProvider, useForm } from 'react-hook-form';
import { BaseUserDto } from 'tdc-web-backend/users/shemas';
import { useEffect } from 'react';
import { ReactComponent as ChatBubbleIcon } from '../../../assets/icons/chat-icons/chat3-fill.svg';
import CustomButton from '../../../components/button/CustomButton';
import CustomModal from '../../../components/modal/CustomModal';
import { useRefresh } from '../../../utils/hooks/crud-hooks/useRefresh';
import ControlledTextInput from '../../../components/custom-inputs/ControlledTextInput';
import ResourceSelectInput from '../ResourceSelectInput';
import FramedIcon from '../FramedIcon';
import useUpdate from '../../../utils/hooks/crud-hooks/useUpdate';
import useGetMany from '../../../utils/hooks/crud-hooks/useGetMany';

type FormData = {
  name: string;
  users: string[];
};

type EditChannelModalProps = {
  open: boolean;
  onClose?: () => void;
  channel: ChannelDto;
};

export default ({ open, onClose, channel }: EditChannelModalProps) => {
  const refresh = useRefresh();
  const methods = useForm<FormData>({
    defaultValues: {
      name: channel.name,
      users: [],
    },
  });

  const members =
    useGetMany<BaseUserDto>({ resource: `users?channel=${channel.id}` }).data?.data.results ?? [];

  useEffect(() => {
    methods.reset({
      users: members.map((user) => user.id),
    });
  }, [members]);

  const { mutate } = useUpdate<AxiosResponse, UpdateChannelDto>({
    resource: 'channels',
  });
  const onSubmit = (data: FormData) => {
    mutate(
      {
        id: channel.id,
        data: {
          name: data.name,
          users: data.users,
        },
      },
      {
        onSuccess: () => {
          refresh();
          methods.reset();
          onClose?.();
        },
      },
    );
  };

  return (
    <>
      <CustomModal open={open} onClose={onClose}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Stack direction="row" alignItems="center" spacing={2} width="100%">
                <FramedIcon Icon={ChatBubbleIcon} variant="blue" />
                <Typography variant="heading3">Edit channel</Typography>
              </Stack>
              <Divider />
              <Stack direction="column" spacing={3}>
                <Stack direction="row">
                  <Stack direction="column" width="100%" spacing={3}>
                    <ControlledTextInput
                      placeholder="New channel"
                      name="name"
                      required
                      label="Channel name"
                    />
                    <ResourceSelectInput<BaseUserDto>
                      name="users"
                      resource="users"
                      multiple
                      filter={{ space: channel.space }}
                      choiceLabel={(user) => `${user?.firstName} ${user?.lastName}`}
                      label="Participants"
                      placeholder="Add Participants"
                    />
                  </Stack>
                </Stack>
              </Stack>
              <Divider />
              <Stack spacing={2} direction="row" justifyContent="right">
                <CustomButton variant="secondary" onClick={onClose}>
                  Cancel
                </CustomButton>
                <CustomButton type="submit">Save</CustomButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </CustomModal>
    </>
  );
};
