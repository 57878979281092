import { Avatar, Box, SxProps, Theme } from '@mui/material';
import { useEffect, useState } from 'react';
import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';
import { primaryDark } from '../../../../utils/color';
import { ReactComponent as FileUploadSvg } from '../../../../assets/icons/project-icons/FileUploadSvg.svg';
import { getBase64 } from '../../../../utils/helpers';

interface CustomDroppableAreaProps {
  acceptedFiles: File[] | null;
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  renderCustomDroppableArea?: ({
    getRootProps,
    getInputProps,
  }: {
    getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
    getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  }) => JSX.Element;
  renderCustomDroppableAreaWidth?: string | number;
  renderCustomDroppableAreaHeight?: string | number;
}

const pickAnotherFileOnHoverStyles: SxProps<Theme> = {
  opacity: 0,
  display: 'flex',
  justifyContent: 'center',
  transition: 'all 0.15s ease',
  position: 'absolute',
  margin: '0 auto',
  bgcolor: 'rgba(255, 255, 255, 0.5)',
  borderRadius: '3px',
  cursor: 'pointer',
  left: 0,
  right: 0,
  bottom: '16px',
  width: '25%',
  py: '3px',
  px: '5px',
};

const CustomDroppableArea = ({
  acceptedFiles,
  getRootProps,
  getInputProps,
  renderCustomDroppableArea,
  renderCustomDroppableAreaWidth,
  renderCustomDroppableAreaHeight,
}: CustomDroppableAreaProps) => {
  const [base64String, setb64String] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      if (acceptedFiles !== null) {
        const base64 = await getBase64(acceptedFiles[0]);

        if (base64) {
          setb64String(base64 as string);
        }
      }
    })();
  }, [acceptedFiles]);

  return (
    <>
      {base64String !== null ? (
        <Box
          sx={{
            position: 'relative',
            borderRadius: '50%',
            height: '100%',
            '&:hover #child': {
              opacity: '1 !important',
            },
          }}
        >
          <Avatar
            src={base64String}
            sx={{
              width: renderCustomDroppableAreaWidth,
              height: renderCustomDroppableAreaHeight,
            }}
          />

          {/* element appearing on parent hover that enables opening file picker again
           and picking different file */}
          <Box
            {...getRootProps()}
            id="child"
            sx={{
              ...pickAnotherFileOnHoverStyles,
            }}
          >
            <input {...getInputProps()} />
            <FileUploadSvg style={{ width: 18, height: 18 }} fill={primaryDark[600]} />
          </Box>
        </Box>
      ) : (
        renderCustomDroppableArea?.({
          getRootProps,
          getInputProps,
        })
      )}
    </>
  );
};

export default CustomDroppableArea;
