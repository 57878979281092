import { useState } from 'react';
import { Stack, Typography, Alert, Box, Divider } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FormProvider, useForm } from 'react-hook-form';
import useAuth from '../../../../utils/hooks/useAuth';
import CustomInput from '../../../../components/custom-input/CustomInput';
import CustomButton from '../../../../components/button/CustomButton';
import NavigationBlocker from '../../../../components/navigation-blocker/NavigationBlocker';

export interface IShowPasswordState {
  currentPassword: boolean;
  newPassword: boolean;
  repeatPassword: boolean;
}

const Security = () => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [showPass, setShowPass] = useState<IShowPasswordState>({
    currentPassword: false,
    newPassword: false,
    repeatPassword: false,
  });
  const { changePassword, isLoadingChangePassword } = useAuth();

  const form = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      repeatPassword: '',
    },
  });

  const onSubmit = async () => {
    changePassword(
      {
        currentPassword: form.getValues('currentPassword'),
        newPassword: form.getValues('newPassword'),
      },
      {
        onSuccess: () => {
          form.reset();
          setError(null);
          setSuccess('Password updated successfully');
        },
        onError: () => {
          setSuccess(null);
          setError('Failed to update password');
        },
      },
    );
  };

  return (
    <>
      {error && (
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" onClose={() => setSuccess(null)}>
          {success}
        </Alert>
      )}
      <Box sx={{ padding: '1.5rem' }}>
        <FormProvider {...form}>
          <form>
            <Stack spacing={5}>
              <Stack spacing={0.5}>
                <Typography variant="heading3" color="primaryDark.500">
                  Change password
                </Typography>
                <Typography variant="body3" color="primaryDark.500">
                  Create a new password that is at least 8 characters long
                </Typography>
              </Stack>
              <Stack spacing={4} sx={{ maxWidth: '400px' }}>
                <CustomInput
                  input={<CustomInput.Text />}
                  type={showPass.currentPassword ? 'text' : 'password'}
                  name="currentPassword"
                  label="Current password"
                  required
                  placeholder="Enter your current password"
                  adornment={{
                    icon: showPass.currentPassword ? (
                      <VisibilityIcon sx={{ color: 'primaryDark.400', width: 22, height: 22 }} />
                    ) : (
                      <VisibilityOffIcon sx={{ color: 'primaryDark.400', width: 22, height: 22 }} />
                    ),
                    onClick: () => {
                      setShowPass({ ...showPass, currentPassword: !showPass.currentPassword });
                    },
                  }}
                  disabled={isLoadingChangePassword}
                />

                <CustomInput
                  input={<CustomInput.Text />}
                  type={showPass.newPassword ? 'text' : 'password'}
                  name="newPassword"
                  label="New password"
                  required
                  defaultHelperText="Your password must be a minimum of 8 characters in length and contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character."
                  placeholder="Enter new password"
                  adornment={{
                    icon: showPass.newPassword ? (
                      <VisibilityIcon sx={{ color: 'primaryDark.400', width: 22, height: 22 }} />
                    ) : (
                      <VisibilityOffIcon sx={{ color: 'primaryDark.400', width: 22, height: 22 }} />
                    ),
                    onClick: () => {
                      setShowPass({ ...showPass, newPassword: !showPass.newPassword });
                    },
                  }}
                  validationRegEx={{
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/,
                    message:
                      'Your password must be a minimum of 8 characters in length and contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character.',
                  }}
                  rules={{
                    maxLength: {
                      value: 127,
                      message:
                        'Password is too long. Please choose a password with fewer than 128 characters',
                    },
                  }}
                  disabled={isLoadingChangePassword}
                />

                <CustomInput
                  input={<CustomInput.Text />}
                  type={showPass.repeatPassword ? 'text' : 'password'}
                  name="repeatPassword"
                  label="Repeat new password"
                  required
                  placeholder="Repeat new password"
                  adornment={{
                    icon: showPass.repeatPassword ? (
                      <VisibilityIcon sx={{ color: 'primaryDark.400', width: 22, height: 22 }} />
                    ) : (
                      <VisibilityOffIcon sx={{ color: 'primaryDark.400', width: 22, height: 22 }} />
                    ),
                    onClick: () => {
                      setShowPass({ ...showPass, repeatPassword: !showPass.repeatPassword });
                    },
                  }}
                  validate={() =>
                    form.watch('repeatPassword') === form.watch('newPassword') ||
                    'Passwords do not match'
                  }
                  disabled={isLoadingChangePassword}
                />
              </Stack>
              <Divider />
              <Stack direction="row" justifyContent="flex-end">
                <CustomButton
                  variant="primary"
                  sx={{
                    height: '2.8125rem',
                    width: '100%',
                    maxWidth: '120px',
                    marginTop: '22px !important',
                  }}
                  onClick={form.handleSubmit(onSubmit)}
                  disabled={isLoadingChangePassword}
                >
                  Save
                </CustomButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Box>
      <NavigationBlocker condition={form.formState.isDirty} />
    </>
  );
};

export default Security;
