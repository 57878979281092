import { ReactNode } from 'react';
import MuiFormHelperText from '@mui/material/FormHelperText';
import { SxProps, Theme } from '@mui/material';
import { secondaryPink, primaryDark } from '../../utils/color';

interface CustomFormHelperTextProps {
  error: boolean;
  children: ReactNode;
  sx?: SxProps<Theme>;
}

const CustomFormHelperText = ({ error, children, sx }: CustomFormHelperTextProps) => (
  <MuiFormHelperText
    sx={{
      m: 0,
      mt: 0.5,
      color: error ? `${secondaryPink[500]} !important` : `${primaryDark[500]} !important`,
      ...sx,
    }}
  >
    {children}
  </MuiFormHelperText>
);

export default CustomFormHelperText;
