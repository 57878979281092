import { useState } from 'react';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import goran from '../../../../assets/img/public-pages/team/goran.png';
import lee from '../../../../assets/img/public-pages/team/lee.png';
import CustomButton from '../../../../components/button/CustomButton';
import ExecutiveCard from './ExecutiveCard';
import theme from '../../../../utils/theme';
import TeamMemberCard from './TeamMemberCard';
import { LAYOUT_DEFAULTS } from '../../../../utils/constants';
import { teamMembers } from '../utils';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const WhoAreWe = () => {
  const [currentTab, setTab] = useState(0);
  const isLessThanMedium = useMediaQuery(theme.breakpoints.down('md'));

  const TabButton = (buttonTitle: string, idx: number) => {
    const isCurrentTab = idx === currentTab;
    // const rightRadius: string = tabs.length - 1 === idx && isCurrentTab ? '4px' : '0px';
    // const leftRadius: string = idx === 0 && isCurrentTab ? '4px' : '0px';
    // const radius = `${leftRadius} ${rightRadius} ${rightRadius} ${leftRadius}`;

    return (
      <CustomButton
        // variant={isCurrentTab ? 'primaryLight' : 'secondary'}
        variant="secondary"
        sx={{
          // color: !isCurrentTab ? '#79829B' : '#6D51F6',
          borderTopRightRadius: isCurrentTab ? '4px' : '0px !important',
          borderBottomRightRadius: isCurrentTab ? '4px' : '0px !important',
          // borderTopLeftRadius: isCurrentTab ? '0px !important' : 'initial',
          // borderBottomLeftRadius: isCurrentTab ? '0px !important' : 'initial',

          // borderRadius:isCurrentTab ? '0px, 4px, 4px, 0px !important' : '0px',
          boxShadow: '6px 6px 15px 0px #3845721A',
        }}
        onClick={() => setTab(idx)}
      >
        {buttonTitle}
      </CustomButton>
    );
  };

  return (
    <Stack mt={12} mb={5} spacing={3} sx={{ ...LAYOUT_DEFAULTS }}>
      <Stack spacing={4}>
        <Typography variant="publicHeading3">Who are we?</Typography>

        <Typography width={{ lg: '75%', md: '75%', xs: '100%' }} variant="publicBody3Light">
          We are a team of diverse young professionals dedicated to innovating the business sphere
          by democratizing the way companies create. We are passionate about breaking geographical
          barriers of access and providing equal opportunity for growth, while ensuring that this
          process remains healthy and productive for all parties.
        </Typography>
      </Stack>

      <Stack direction="row">
        <CustomButton
          variant="secondary"
          sx={{
            borderTopRightRadius: '0px !important',
            color: currentTab === 0 ? 'primaryLight.500' : 'primaryDark.500',
            borderBottomRightRadius: '0px !important',
            boxShadow: currentTab === 0 ? '6px 6px 15px 0px #3845721A' : 'initial',
            borderColor: 'secondaryBlue.100',
          }}
          onClick={() => setTab(0)}
        >
          Executive
        </CustomButton>

        <CustomButton
          variant="secondary"
          sx={{
            borderTopLeftRadius: '0px !important',
            borderBottomLeftRadius: '0px !important',
            borderColor: 'secondaryBlue.100',
            color: currentTab === 1 ? 'primaryLight.500' : 'primaryDark.500',
            boxShadow: currentTab === 1 ? '6px 6px 15px 0px #3845721A' : 'initial',
          }}
          onClick={() => setTab(1)}
        >
          Team members
        </CustomButton>
      </Stack>

      <CustomTabPanel value={currentTab} index={0}>
        <Stack spacing={7} direction={isLessThanMedium ? 'column' : 'row'}>
          <ExecutiveCard
            img={goran}
            name="Goran Deak"
            linkedIn="https://www.linkedin.com/in/gorandeak/"
            content="Goran has more than 10 years of experience in marketing, IT management, consulting and sales. He’s climbed the corporate ladder, holding positions as entry level employee, department head and CTO of a large Croatian technology company. Prior to TDA, he was the owner of a successful 4 year-old Croatian digital agency with clients on 5 continents. Under Goran’s leadership, the agency developed the TDA platform and business model, then evolved it to the Spona platform of today."
            title="Founder & Chief Executive Officer"
            alt="Goran Deak, CEO at Spona"
          />

          <ExecutiveCard
            img={lee}
            name="Lehady Sani-Agatha"
            linkedIn="https://www.linkedin.com/in/lehady-sani-agatha/"
            content="Lehady is a full stack developer with experience in IT, problem-solving, leadership, and management. At Spona, Lehady has implemented competitive new technologies and optimized the company’s strategy, boosting efficiency and productivity. He’s also a former staff sergeant in the United States Air Force, has a BA in web and mobile computing from the Rochester Institute of Technology and a BS in criminal justice from Ashworth University."
            title="Chief Operating Officer & Chief Technology Officer"
            bgcolor="#FBECD7"
            alt="Lehady Sani-Agatha, COO and CTO at Spona"
          />
        </Stack>
      </CustomTabPanel>

      <CustomTabPanel value={currentTab} index={1}>
        <Grid container rowGap={5} columns={{ xs: 4, sm: 12, md: 12, lg: 12, xl: 12 }}>
          {teamMembers.map((item) => (
            <Grid
              item
              key={item.id}
              xs={4}
              sm={6}
              md={4}
              lg={2}
              xl={2}
              sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'start' } }}
            >
              <TeamMemberCard
                alt={item.alt}
                title={item.title}
                name={item.name}
                img={item.img}
                sx={item.sx}
              />
            </Grid>
          ))}
        </Grid>
      </CustomTabPanel>
    </Stack>
  );
};

export default WhoAreWe;
