import { Divider, Stack } from '@mui/material';
import {
  mapCountry,
  mapCurrency,
  mapIndustry,
  mapProjectSize,
  mapService,
} from 'tdc-web-backend/enums/enums';
import { mapTechLanguage, mapTechPlatform, mapTechTool } from '../../../../utils/enums';
import CustomInput from '../../../../components/custom-input/CustomInput';

const DetailsStep = () => (
  <Stack direction="row" spacing={5} width="100%">
    <Stack spacing={3} direction="column" width="50%" gap={1}>
      <CustomInput
        input={<CustomInput.Autocomplete />}
        name="details.services"
        label="services"
        required
        multiple
        withChips
        choices={mapService}
        placeholder="Select provided services"
      />

      <CustomInput
        input={<CustomInput.Autocomplete />}
        multiple
        withChips
        name="details.tools"
        label="Tech Tools"
        choices={mapTechTool}
        placeholder="Select used tools"
      />

      <CustomInput
        input={<CustomInput.Autocomplete />}
        multiple
        withChips
        name="details.platforms"
        label="Tech Platform"
        choices={mapTechPlatform}
        placeholder="Select used platforms"
      />

      <CustomInput
        input={<CustomInput.Autocomplete />}
        name="details.languages"
        multiple
        withChips
        label="Programming languages"
        choices={mapTechLanguage}
        placeholder="Select used languages"
      />
    </Stack>

    <Divider orientation="vertical" flexItem />

    <Stack spacing={3} direction="column" width="50%" gap={1}>
      <Stack direction="row">
        <Stack
          sx={{
            width: 'min-width',
          }}
        >
          <CustomInput
            input={<CustomInput.Select />}
            name="details.currency"
            choices={mapCurrency}
            label="Project size"
            required
          />
        </Stack>
        <Stack
          sx={{
            flex: 1,
          }}
        >
          <CustomInput
            input={<CustomInput.Select />}
            name="details.projectSize"
            required
            // This label is passed so that it applies the margin as if label was there and now it aligns with currency field
            label="&zwnj;"
            choices={mapProjectSize}
            placeholder="Select project size"
          />
        </Stack>
      </Stack>

      <CustomInput
        input={<CustomInput.Autocomplete />}
        multiple
        withChips
        name="details.geographyFocus"
        label="Target Market"
        required
        choices={mapCountry}
        placeholder="Select target market"
      />

      <CustomInput
        input={<CustomInput.Autocomplete />}
        name="details.industries"
        label="Client Industry"
        multiple
        required
        withChips
        choices={mapIndustry}
        placeholder="Select client industry"
      />
    </Stack>
  </Stack>
);

export default DetailsStep;
