import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Divider, IconButton, Modal, Paper, Stack, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useState } from 'react';
import { Box } from '@mui/system';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { CompanyRole, EnumProjectMilestoneStatus } from 'tdc-web-backend/enums/enums';
import Button from '../../../../components/button/Button';
import { useRefresh } from '../../../../utils/hooks/crud-hooks/useRefresh';
import InvoiceFileUpload from '../PaymentDetails/InvoiceFileUpload';
import useCreate from '../../../../utils/hooks/crud-hooks/useCreate';
import useUserCompany from '../../../../utils/hooks/useUserCompany';

type InvoiceModalProps = {
  data: MilestoneDto;
};

const InvoiceUploadModal = ({ data }: InvoiceModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string>('');
  const { mutate: upload } = useCreate({ resource: `/milestones/${data?.id}/seller-invoice` });
  const userType = useUserCompany()?.roles;
  const refresh = useRefresh();

  const handleUploadInvoice = () => {
    upload(
      {
        invoice: file,
      },
      {
        onSuccess: () => {
          setIsOpen(false);
          setFile(null);
          setFileName('');
          refresh();
        },
      },
    );
  };
  const handleDeleteFile = () => {
    setFile(null);
    setFileName('');
  };

  return (
    <>
      {userType?.[0] !== 'buyer' && !data.sellerInvoice ? (
        <IconButton
          sx={{
            background: '#7F57FF',
            color: '#fff',
            fontSize: '0.8rem',
          }}
          disabled={data.status !== EnumProjectMilestoneStatus.Approved}
          color="secondary"
          onClick={() => setIsOpen(true)}
        >
          <UploadFileOutlinedIcon />
        </IconButton>
      ) : (
        <a
          href={userType?.[0] === CompanyRole.Buyer ? data.buyerInvoice : data.sellerInvoice}
          target="_blank"
          rel="noreferrer"
        >
          <IconButton
            sx={{
              background: '#7F57FF',
              color: '#fff',
              fontSize: '0.8rem',
            }}
            disabled={userType?.[0] === 'buyer' && !data.buyerInvoice}
            color="secondary"
          >
            <SaveAltIcon href={data.sellerInvoice} />
          </IconButton>
        </a>
      )}
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Stack
          sx={{
            width: '50%',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper
              sx={{
                padding: '2em',
              }}
            >
              <Stack spacing={2}>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: '1.25em',
                      fontWeight: 500,
                    }}
                  >
                    Upload invoice
                  </Typography>
                  <Typography
                    sx={{
                      mb: 2,
                    }}
                  >
                    Please upload your invoice here. Don't forget that the invoice should be billed
                    to TDA. Here’s the information that you will need:
                  </Typography>
                </Stack>
                <Stack sx={{ mt: 2 }} spacing={1}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      fontSize="14px"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      Company name:
                    </Typography>
                    <Typography fontSize="14px">Top Digital Agency Limited</Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      fontSize="14px"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      Company VAT:
                    </Typography>
                    <Typography fontSize="14px">IE3613706OH</Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" sx={{ pb: 5 }}>
                    <Typography
                      fontSize="14px"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      Company address:
                    </Typography>
                    <Typography width="50%" textAlign="right" fontSize="14px">
                      9 Main Street, Co. Dublin, Blackrock, Dublin, A94 N6D0, Ireland
                    </Typography>
                  </Stack>
                  <InvoiceFileUpload setFile={setFile} setFileName={setFileName} />
                  {fileName && (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        p: 3,
                        mt: 1,
                        textAlign: 'center',

                        color: '#5C5F5C',
                        border: '1px solid #A9ACA8',
                        borderRadius: 1,
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="left"
                        width="50%"
                        gap={1}
                      >
                        <Box
                          sx={{
                            p: 1,
                            background: '#E1E3DF',
                            borderRadius: 1,
                          }}
                        >
                          <InsertDriveFileOutlinedIcon sx={{ fontSize: '1rem' }} />
                        </Box>
                        <Typography variant="caption">{fileName}</Typography>
                      </Stack>
                      <IconButton onClick={handleDeleteFile}>
                        <CancelOutlinedIcon />
                      </IconButton>
                    </Stack>
                  )}
                </Stack>
                <Divider />
                <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
                  <Button
                    variant="text"
                    onButtonClick={() => setIsOpen(false)}
                    sx={{
                      borderRadius: '100px',
                      fontWeight: 500,
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onButtonClick={handleUploadInvoice}
                    disabled={!fileName}
                    sx={{
                      borderRadius: '100px',
                      fontWeight: 500,
                    }}
                  >
                    Confirm
                  </Button>
                </Stack>
              </Stack>
            </Paper>
          </LocalizationProvider>
        </Stack>
      </Modal>
    </>
  );
};

export default InvoiceUploadModal;
