import { Stack } from '@mui/system';
import { Typography } from '@mui/material';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import E2EProjectCard from '../components/E2EProjectCard';
import NewProjectCard from '../../TimedProjects/TimedProjectsList/NewProjectCard';
import Link from '../../../../components/link/Link';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import ProjectPaymentGraphCard from '../components/ProjectPaymentGraphCard';
import PitchesList from '../components/PitchesList';
import EventsCard from '../components/EventsCard';
import EmptyCard from '../components/EmptyCard';
import useAuth from '../../../../utils/hooks/useAuth';

const BuyerHomescreen = () => {
  const {
    authData: { userData: user },
  } = useAuth();

  const { data: projectResponse, isLoading } = useGetMany<ProjectDto>({
    enabled: !!user,
    resource: `/projects?limit=2&company=${user?.membership?.company}`,
  });

  const projects = projectResponse?.data.results ?? [];

  return (
    <Stack width="100%" height="100%" gap={3} pb={6}>
      <Stack
        width={projects.length <= 1 ? '50%' : '100%'}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="heading3" color="primaryDark.500">
          END-TO-END project management
        </Typography>
        <Link
          to="/dashboard/end-to-end"
          sx={{
            display: 'flex',
            justifyContent: 'end',
            pointerEvents: projects.length <= 1 ? 'none' : 'inherit',
          }}
        >
          <Typography
            variant="heading4"
            color={projects.length <= 1 ? 'primaryLight.200' : 'primaryLight.500'}
          >
            View all
          </Typography>
        </Link>
      </Stack>
      {isLoading || (
        <Stack gap={2} width="100%" direction={{ xs: 'column', lg: 'row' }}>
          {projects.map((project) => (
            <E2EProjectCard project={project} />
          ))}
          {projects.length === 0 && <EmptyCard />}
          {projects.length <= 1 && <NewProjectCard imgHeight="50%" />}
        </Stack>
      )}
      <ProjectPaymentGraphCard />
      <PitchesList />
      <EventsCard />
    </Stack>
  );
};

export default BuyerHomescreen;
