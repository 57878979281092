import { Stack } from '@mui/system';
import AliceCarousel from 'react-alice-carousel';
import { useRef } from 'react';
import { CalendarEventDto } from 'tdc-web-backend/events/schemas';
import { addDays } from 'date-fns';
import theme from '../../../../utils/theme';
import SliderEventCard from './SliderEventCard';
import CustomButton from '../../../../components/button/CustomButton';
import { ReactComponent as ArrowLeft } from '../../../../assets/icons/arrow-left-short.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow-right-short.svg';
import { primaryLight } from '../../../../utils/color';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import CustomNoRowsOverlay from '../../../../components/custom-no-rows-overlay/CustomNoRowsOverlay';
import { dateToLocalDateString } from '../../../../utils/helpers';
import DashboardCard from '../../../../components/dashboard-card/EndToEndCard.tsx/DashboardCard';
import useAuth from '../../../../utils/hooks/useAuth';

const responsive = {
  [theme.breakpoints.values.xs]: { items: 1 },
  [theme.breakpoints.values.sm]: { items: 1 },
  [theme.breakpoints.values.md]: { items: 2 },
  [theme.breakpoints.values.bg]: { items: 2 },
  [theme.breakpoints.values.lg]: { items: 2 },
  [theme.breakpoints.values.xl]: { items: 2 },
  [theme.breakpoints.values.xxl]: { items: 4 },
};

const EventsCard = () => {
  const carousel = useRef(null);
  const {
    authData: { userData: user },
  } = useAuth();
  const startDate = dateToLocalDateString(new Date());
  const endDate = dateToLocalDateString(addDays(new Date(), 4));

  const { data: calendarEvent } = useGetMany<CalendarEventDto>({
    enabled: !!user,
    resource: `/calendar?user=${user?.id}&start=${startDate}&end=${endDate}`,
  });

  const events = calendarEvent?.data.results;

  return (
    <DashboardCard
      headline="Events"
      linkText="view all"
      renderComponentSx={{
        width: '100%',
      }}
      sx={{
        px: 0,
      }}
      headlineSx={{
        px: 2,
      }}
      linkSx={{
        px: 2,
      }}
      mainCardClassName="event_slider"
      link="/dashboard/calendar"
      renderComponent={
        <>
          {!events?.length && (
            <Stack position="relative" height="10rem">
              <CustomNoRowsOverlay type="events" />
            </Stack>
          )}
          <AliceCarousel
            ref={carousel}
            mouseTracking
            renderPrevButton={(e) => {
              const disable = e.isDisabled;
              return (
                <CustomButton
                  disabled={disable}
                  variant="primaryText"
                  sx={{ mr: 1 }}
                  startIcon={<ArrowLeft fill={disable ? primaryLight[300] : primaryLight[500]} />}
                >
                  Back
                </CustomButton>
              );
            }}
            renderNextButton={(e) => {
              const disable = e.isDisabled;
              return (
                <CustomButton
                  disabled={disable}
                  variant="primaryText"
                  endIcon={<ArrowRight fill={disable ? primaryLight[300] : primaryLight[500]} />}
                >
                  Next
                </CustomButton>
              );
            }}
            items={events?.map((event) => (
              <SliderEventCard event={event} />
            ))}
            infinite={false}
            paddingLeft={0}
            paddingRight={50}
            disableSlideInfo
            disableDotsControls
            responsive={responsive}
          />
        </>
      }
    />
  );
};

export default EventsCard;
