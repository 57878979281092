import Box from '@mui/material/Box';
import { CompanyRole } from 'tdc-web-backend/enums/enums';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import MultiStep from '../../../components/multisteps/Multistep';
import useMultiStep from '../../../components/multisteps/useMultiStep';
import User from './steps/User';
import Overview from './steps/Overview';
import Admin from './steps/Admin';
import { printHelperTextForSpecificCurrentStep } from '../../../utils/helpers';
import { helperTexts, initialSellerState } from './utils';
import { TPostRegistration, TUserType } from './interface';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';
import DetailsSeller from './steps/DetailsSeller';
import DetailsBuyer from './steps/DetailsBuyer';
import { DASHBOARD_WRAPPER_PADDING } from '../../Dashboard/Main';
import useAuth from '../../../utils/hooks/useAuth';

export const labels = ['User', 'Overview', 'Admin', 'Details'];

const PostRegistration = () => {
  const { mutate: create, isLoading: isCreateLoading } = useCreate({ resource: 'companies' });

  const navigate = useNavigate();
  const {
    authData: { userData: user },
    updateUser,
    isLoadingUpdateUser,
  } = useAuth();

  const membership = user?.membership;

  const { currentStep, steps, onStepChange, form } = useMultiStep<TPostRegistration>({
    labels,
    initialState: initialSellerState,
  });

  const userType: TUserType = form.watch('user');

  const detailsForUser =
    userType === CompanyRole.SellerService ? <DetailsSeller /> : <DetailsBuyer />;

  useEffect(() => {
    // if a user HAS company tied to him, don't let them get to create-company
    if (membership !== null) navigate('/dashboard');
  }, [membership, navigate]);

  const onSubmit = (data: any) => {
    const { tmpClients, ...companyDetails } = data.details;
    const { clients } = companyDetails;

    let clientsIds = [] as string[];
    if (userType === CompanyRole.SellerService)
      clientsIds = clients?.map(({ id }: { id: string }) => id);

    updateUser({ jobTitle: data.admin.jobTitle });

    create({
      ...companyDetails,
      ...(userType === CompanyRole.SellerService && { clients: clientsIds }),
      ...data.overview,
      roles: [data.user],
    });
  };

  return (
    <Box sx={{ width: '70%', p: `${DASHBOARD_WRAPPER_PADDING}px` }}>
      <MultiStep
        form={form}
        steps={steps}
        currentStep={currentStep}
        onChange={onStepChange}
        onSubmit={onSubmit}
        helperText={printHelperTextForSpecificCurrentStep(currentStep, helperTexts)}
        disableCancelButton
        isSubmitLoading={!!(isCreateLoading === true || isLoadingUpdateUser === true)}
      >
        <>
          {currentStep === 1 && <User />}
          {currentStep === 2 && <Overview />}
          {currentStep === 3 && <Admin />}
          {currentStep === 4 && detailsForUser}
        </>
      </MultiStep>
    </Box>
  );
};

export default PostRegistration;
