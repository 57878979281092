/* eslint-disable no-unused-expressions */
import { Stack, IconButton, Box, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left-short.svg';
import CustomLink from '../../components/link/Link';
import SponaLogo from '../../assets/img/logo/spona-purple.svg';
import { primaryDark } from '../../utils/color';
import RegisterAndLoginSharedSection from './components/RegisterAndLoginSharedSection';
import { PUBLIC_APPBAR_HEIGHT } from '../../layout/public-layout/navigation/Navigation';

interface AccountLayoutProps {
  accountSection: ReactNode;
  accountSectionWrapperSx?: SxProps<Theme>;
  leftSection?: ReactNode;
}

const AccountLayout = ({
  accountSection,
  accountSectionWrapperSx,
  leftSection = <RegisterAndLoginSharedSection />,
}: AccountLayoutProps) => (
  <Stack
    direction={{ xs: 'column', lg: 'row' }}
    sx={{
      height: { xs: 'auto', lg: '100vh' },
    }}
  >
    {/* left section */}
    <Stack
      sx={{
        width: { xs: '100%', lg: '50%' },
        height: { xs: '32vh', lg: '100%' },
        mt: { xs: `${PUBLIC_APPBAR_HEIGHT}px`, lg: 0 },
      }}
    >
      {leftSection}
    </Stack>

    {/* right section */}
    <Stack
      position="relative"
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: { xs: 'hidden', lg: 'auto' },
        width: { xs: '100%', lg: '50%' },
      }}
    >
      <IconButton
        href="/"
        sx={{
          display: { xs: 'none', lg: 'flex' },
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          left: { xs: 10, md: '2em' },
          top: '2em',
          border: '1px solid',
          borderColor: 'primaryDark.300',
          p: '16px',
        }}
      >
        <ArrowLeft style={{ height: '0.9rem', width: '0.9rem', fill: primaryDark[500] }} />
      </IconButton>

      <Stack
        spacing={1}
        sx={{
          width: { xs: '100%', lg: '60%', xxl: '45%' },
          px: { xs: '1rem', lg: 0 },
          ...accountSectionWrapperSx,
        }}
      >
        <CustomLink to="/" sx={{ width: 'fit-content' }}>
          <Box component="img" src={SponaLogo} sx={{ height: '30px', width: '130px' }} />
        </CustomLink>

        {accountSection}
      </Stack>
    </Stack>
  </Stack>
);

export default AccountLayout;
