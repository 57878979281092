import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { ReactComponent as CheckedIcon } from '../../../../assets/icons/project-icons/CheckedIcon.svg';
import { ReactComponent as CancelCircleIcon } from '../../../../assets/icons/project-icons/CancelCircleIcon.svg';
import { formatBytes, truncate } from '../../../../utils/helpers';
import { primaryDark } from '../../../../utils/color';

type CaseStudyFileUploadQueueProps = {
  name: string;
};

const CaseStudyFileUploadQueue = ({ name }: CaseStudyFileUploadQueueProps) => {
  const { setValue, control, trigger } = useFormContext();

  const handleDeleteFile = () => {
    setValue('file', undefined);
    trigger('file');
  };

  const file = useWatch({ control, name });
  const fileName = useWatch({ control, name: 'fileName' });

  return (
    <Stack
      width="50%"
      height="fit-content"
      sx={{
        p: 2,
        py: 1,
        mt: 1,
        textAlign: 'center',
        backgroundColor: 'secondaryGreen.100',
        borderRadius: '0.5rem',
      }}
      spacing={2}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="stretch">
        <Stack direction="row" alignItems="center" justifyContent="left" spacing={1}>
          <Box
            component="img"
            src={URL.createObjectURL(file)}
            sx={{
              maxWidth: '4rem',
              maxHeight: '4rem',
              objectFit: 'contain',
              borderRadius: '4px',
              p: 0,
            }}
          />
          <Stack alignItems="start" spacing={1}>
            <Stack alignItems="start" direction="row">
              <Typography variant="body3" color="primaryDark.600" fontWeight={600}>
                {fileName && truncate(fileName, 30)}
              </Typography>
              <IconButton sx={{ height: '10px', pointerEvents: 'none' }}>
                <CheckedIcon />
              </IconButton>
            </Stack>
            <Typography variant="body3" color="primaryDark.500">
              {formatBytes(file.size)}
            </Typography>
          </Stack>
        </Stack>
        <IconButton
          onClick={handleDeleteFile}
          disableRipple
          disableTouchRipple
          sx={{
            mb: 1,
            p: 0,
            pt: 0,
          }}
        >
          <CancelCircleIcon fill={primaryDark[500]} />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default CaseStudyFileUploadQueue;
