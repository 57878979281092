import { Menu, MenuItem, Stack, Typography } from '@mui/material';
import { Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/project-icons/ArrowDownIcon.svg';
import CustomButton from '../button/CustomButton';
import { primaryDark } from '../../utils/color';

type Props = {
  setSort: Dispatch<SetStateAction<string>>;
  sort: string;
  sortingMap: Map<string, string>;
};

const CustomSortingMenu = ({ setSort, sort, sortingMap }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant="heading5" color="primaryDark.500">
        Sort by
      </Typography>
      <CustomButton
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="primaryText"
        endIcon={<ArrowDownIcon fill={primaryDark[600]} />}
        sx={{
          color: 'primaryDark.600',
        }}
        onClick={handleClick}
      >
        {sortingMap.get(sort)}
      </CustomButton>
      <Menu
        anchorEl={anchorEl}
        disableScrollLock
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{ width: '100%' }}
      >
        {Array.from(sortingMap).map(([key, value]) => (
          <MenuItem>
            <Typography variant="heading5" onClick={() => setSort(key)} color="primaryDark.500">
              {value}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};
export default CustomSortingMenu;
