import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FiberManualRecord from '@mui/icons-material/FiberManualRecord';
import CustomModal from '../../../../components/modal/CustomModal';
import { ReactComponent as PencilIcon } from '../../../../assets/icons/pencil.svg';
import { secondaryBlue } from '../../../../utils/color';
import CustomButton from '../../../../components/button/CustomButton';

type ModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  setIsOpen: (value: boolean) => void;
  handleSubmitCreate: () => void;
};

const slides = [
  {
    title: 'Contract agreed',
    text: 'Both parties have now agreed to the terms of the contract. To complete the signing process you must continue through DocuSign.',
  },
  {
    title: 'DocuSign',
    text: 'Shortly you’ll receive an email notification from DocuSign. Please follow the instructions to complete the signing process. Once both parties have signed, we’ll notify you that your project has been activated.',
  },
];

const ContractSignModal = ({ isLoading, isOpen, setIsOpen, handleSubmitCreate }: ModalProps) => {
  const [sliderPage, setSliderPage] = useState<number>(0);
  const navigate = useNavigate();
  const { projectId } = useParams();
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <CustomModal open={isOpen} onClose={handleClose}>
      <Stack direction="column">
        <Stack direction="row" alignItems="center" mb={3}>
          <Stack
            width={40}
            height={40}
            mr={2}
            bgcolor="secondaryBlue.100"
            borderRadius={1}
            justifyContent="center"
            alignItems="center"
          >
            <PencilIcon width="20px" fill={secondaryBlue[700]} />
          </Stack>
          <Typography variant="heading4" color="primaryDark.600">
            {slides[sliderPage].title}
          </Typography>
        </Stack>
        <Stack
          py={2}
          sx={{
            borderTop: `1px solid ${secondaryBlue[100]}`,
            borderBottom: `1px solid ${secondaryBlue[100]}`,
          }}
        >
          <Typography color="primaryDark.500" variant="body2">
            {slides[sliderPage].text}
          </Typography>
        </Stack>
        <Stack gap={0.625} direction="row" justifyContent="center" my={3}>
          {slides.map((slide, i) => (
            <FiberManualRecord
              fontSize="small"
              sx={{ color: i === sliderPage ? 'secondaryBlue.500' : '#C5C7C3' }}
            />
          ))}
        </Stack>
        <Stack direction="row" gap={3} justifyContent="flex-end">
          <CustomButton
            onClick={sliderPage > 0 ? () => setSliderPage(sliderPage - 1) : handleClose}
            variant="secondary"
          >
            {sliderPage > 0 ? 'Back' : 'Cancel'}
          </CustomButton>
          <CustomButton
            onClick={
              sliderPage < slides.length - 1
                ? () => setSliderPage(sliderPage + 1)
                : handleSubmitCreate
            }
            variant="primary"
            loading={isLoading}
          >
            {sliderPage < slides.length - 1 ? 'Next' : 'Continue'}
          </CustomButton>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default ContractSignModal;
