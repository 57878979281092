import { useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { MessageDto } from 'tdc-web-backend/messages/schemas';
import { Paginated } from 'tdc-web-backend/common/schemas';
import Box from '@mui/material/Box';
import axios from '../../../api/axios';
import ChatMessage from '../Message/ChatMessage';
import useWebSocket from '../../../utils/hooks/useWebSocket';
import useAuth from '../../../utils/hooks/useAuth';

type ChatMessageListProps = {
  channelId: string;
};

const LoadingHeader = () => (
  <Box display="flex" justifyContent="center" p={1}>
    <Typography variant="heading5" color="primaryDark.500">
      Loading...
    </Typography>
  </Box>
);

const BeginningHeader = () => (
  <Box display="flex" justifyContent="center" p={1}>
    <Typography variant="heading5" color="primaryDark.500">
      This is the beginning of the channel.
    </Typography>
  </Box>
);

export default ({ channelId }: ChatMessageListProps) => {
  const {
    authData: { userData },
  } = useAuth();
  const [channelBeginningReached, setChannelBeginningReached] = useState(false);
  const [messageLoadTriggerVisible, setMessageLoadTriggerVisible] = useState(false);
  const [messages, setMessages] = useState<MessageDto[]>([]);
  const messageLoadTriggerObserver = useRef<HTMLDivElement>(null);

  useWebSocket({
    handlers: {
      'chat-message': (payload: MessageDto) => {
        if (payload.channel === channelId) {
          setMessages((messages) => [payload, ...messages]);
        }
      },
    },
  });

  useEffect(() => {
    if (!channelBeginningReached && messageLoadTriggerVisible) {
      axios
        .get<Paginated<MessageDto>>('messages', {
          params: {
            channel: channelId,
            beforeMessage: messages[messages.length - 1]?.id,
          },
        })
        .then((response) => {
          const loadedMessages = response.data.results;
          if (loadedMessages.length === 0) {
            setChannelBeginningReached(true);
          }
          setMessages((messages) => [...messages, ...loadedMessages]);
        });
    }
  }, [channelBeginningReached, messageLoadTriggerVisible, messages]);

  useEffect(() => {
    setMessages([]);
    setChannelBeginningReached(false);

    const observer = new IntersectionObserver((entries) => {
      setMessageLoadTriggerVisible(entries[0].isIntersecting);
    });

    if (messageLoadTriggerObserver.current) {
      observer.observe(messageLoadTriggerObserver.current);
    }

    return () => {
      if (messageLoadTriggerObserver.current) {
        observer.unobserve(messageLoadTriggerObserver.current);
      }
    };
  }, [channelId]);

  return (
    <Box
      px={2}
      height="100%"
      width="100%"
      overflow="auto"
      display="flex"
      flexDirection="column-reverse"
    >
      {messages.map((message, index) => (
        <ChatMessage
          key={message.id}
          name={`${message.user.firstName} ${message.user.lastName}`}
          created={message.created}
          ownMessage={userData?.id === message.user.id}
          body={message.body}
          collapse={messages[index + 1]?.user.id === message.user.id}
          attachments={message.attachments}
          special={message.special}
        />
      ))}
      <Box position="relative">
        {channelBeginningReached ? <BeginningHeader /> : <LoadingHeader />}
        <Box
          ref={messageLoadTriggerObserver}
          sx={{
            position: 'absolute',
            height: '600px',
            width: '100%',
            pointerEvents: 'none',
          }}
        />
      </Box>
    </Box>
  );
};
