import { SxProps, Theme, useMediaQuery } from '@mui/material';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from '../../layout/public-layout/Layout';
import theme from '../../utils/theme';
import AccountLayout from './AccountLayout';
import RegisterAndLoginSharedSection from './components/RegisterAndLoginSharedSection';
import PasswordChangeSection from './NewPassword/components/PasswordChangeSection';

interface AccountProps {
  checkForScreenWidth?: boolean;
  accountSection: ReactNode;
  accountSectionWrapperSx?: SxProps<Theme>;
  leftSection?: ReactNode;
}

const Account = ({
  checkForScreenWidth = true,
  leftSection = <RegisterAndLoginSharedSection />,
  accountSection,
  accountSectionWrapperSx,
}: AccountProps) => {
  const isLessThanLarge = useMediaQuery(theme.breakpoints.down('lg'));

  const { search, pathname } = useLocation();
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get('token');

  const isNewPasswordScreen = pathname === '/login' && token !== null;

  return (
    <>
      {checkForScreenWidth ? (
        isLessThanLarge ? (
          <Layout />
        ) : (
          <AccountLayout
            accountSection={accountSection}
            accountSectionWrapperSx={{
              ...accountSectionWrapperSx,
            }}
            leftSection={isNewPasswordScreen ? <PasswordChangeSection /> : leftSection}
          />
        )
      ) : (
        <AccountLayout
          accountSection={accountSection}
          accountSectionWrapperSx={{
            ...accountSectionWrapperSx,
          }}
          leftSection={isNewPasswordScreen ? <PasswordChangeSection /> : leftSection}
        />
      )}
    </>
  );
};

export default Account;
