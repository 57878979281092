import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Stack, Typography } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LAYOUT_DEFAULTS } from '../../../utils/constants';
import TabPanelContent from './components/TabPanelContent';
import termsOfService from './contents/TermsOfService';
import tabs from './contents/Tabs';
import { ReactComponent as ArrowUp } from '../../../assets/img/public-pages/capslock-fill.svg';
import { onLinkClickArray } from './utils';
import MetaTags from '../../../components/seo/MetaTags';
import CookiesBanner from '../../../components/cookies-banner-modal/CookiesBanner';
interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tcs-tabpanel-${index}`}
      aria-labelledby={`tcs-tabpanel-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const printAppropriateTitle = (value: number) => {
  switch (value) {
    case 0:
      return 'Terms of Service';
    case 1:
      return 'Privacy Policy';
    case 2:
      return 'Spona Digital Contract Terms';
    case 3:
      return 'Cookie Policy';
    default:
      return '';
  }
};
const metaTags = {
  description: `Terms of Service, Privacy Policy, Spona Digital Contract Terms, Cookie Policy`,
};

const TermsOfServicePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [bannerOpen, setBannerOpen] = useState<boolean>(false);

  const determineTabValue = (pathname: string) => {
    switch (pathname) {
      case '/terms-of-service':
        return 0;
      case '/terms-of-service/privacy-policy':
        return 1;
      case '/terms-of-service/spona-digital-contract-terms':
        return 2;
      case '/terms-of-service/cookie-policy':
        return 3;
      default:
        return 0;
    }
  };

  const [value, setValue] = useState<number>(determineTabValue(location.pathname));

  useEffect(() => {
    const switchToCorrectTab = () => {
      switch (location.pathname) {
        case '/terms-of-service':
          setValue(0);
          break;
        case '/terms-of-service/privacy-policy':
          setValue(1);
          break;
        case '/terms-of-service/spona-digital-contract-terms':
          setValue(2);
          break;
        case '/terms-of-service/cookie-policy':
          setValue(3);
          break;
        default:
          return 0;
      }

      return '';
    };

    switchToCorrectTab();
  }, [location.pathname]);

  const handleChangeTab = (value: number) => {
    switch (value) {
      case 0:
        navigate('/terms-of-service');
        setValue(value);
        break;
      case 1:
        navigate('privacy-policy');
        setValue(value);
        break;
      case 2:
        navigate('spona-digital-contract-terms');
        setValue(value);
        break;
      case 3:
        navigate('cookie-policy');
        setValue(value);
        break;
      default:
        navigate('terms-of-service');
    }
  };

  return (
    <>
      <CookiesBanner bannerOpen={bannerOpen} setBannerOpen={setBannerOpen} />
      <MetaTags {...metaTags} />
      <Stack
        sx={{
          ...LAYOUT_DEFAULTS,
          flexGrow: 1,
          mt: 16,
        }}
      >
        <Stack spacing={3} direction={{ xs: 'column', lg: 'row' }} justifyContent="start">
          <Tabs
            orientation="vertical"
            value={value}
            onChange={(e, newValue) => handleChangeTab(newValue)}
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
            sx={{ width: { xs: '100%', lg: '30%' } }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={tab.id}
                id={`vertical-tab-${index}`}
                aria-controls={`vertical-tabpanel-${index}`}
                label={tab.text}
                sx={{
                  maxWidth: '100%',
                  backgroundColor: index === value ? '#F6F7FF' : 'transparent',
                  borderRadius: '12px',
                  size: '20px',
                  padding: '0px 12px',
                  textAlign: 'start',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'start',
                  color: index === value ? '#6D51F6 !important' : '#384572',
                }}
              />
            ))}
          </Tabs>

          <Stack spacing={5} width="100%">
            <Stack spacing={3}>
              <Typography variant="headline1">{printAppropriateTitle(value)}</Typography>

              <Typography variant="headline4">Effective from January 2024</Typography>
            </Stack>

            {/* Terms of Service tab panel */}
            <TabPanel value={value} index={0}>
              <Stack spacing={8}>
                {termsOfService.map(({ title, introduction, content }, index) => (
                  <TabPanelContent
                    key={title}
                    title={title}
                    introduction={introduction}
                    content={content}
                    onLinkClickArray={onLinkClickArray}
                    setValue={setValue}
                  />
                ))}
              </Stack>
            </TabPanel>

            {/* Privacy Policy, Spona Digital Contract Terms, Cookie Policy tab panel */}
            <TabPanel value={value} index={1}>
              <Outlet />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Outlet />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Outlet context={[setBannerOpen]} />
            </TabPanel>
          </Stack>
        </Stack>

        {/* move to top button */}
        <Box
          sx={{
            cursor: 'pointer',
            border: '1px solid #BFC6D7',
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'end',
            borderRadius: '999px',
            justifyContent: 'center',
            alignItems: 'center',
            width: '2.8rem',
            height: '2.8rem',
          }}
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }
        >
          <ArrowUp fill="#79829B" width="1.45rem" height="1.45rem" />
        </Box>
      </Stack>
    </>
  );
};

export default TermsOfServicePage;
