import { Avatar, Divider, Stack, Typography } from '@mui/material';
import { ChannelDto } from 'tdc-web-backend/channels/schemas';
import { BaseUserDto } from 'tdc-web-backend/users/shemas';
import React from 'react';
import { ChatChannelType, UserRoles } from 'tdc-web-backend/enums/enums';
import useGetMany from '../../../utils/hooks/crud-hooks/useGetMany';
import { useChatNavigation } from '../useChatNavigation';
import useGetOne from '../../../utils/hooks/crud-hooks/useGetOne';
import { ReactComponent as HashIcon } from '../../../assets/icons/chat-icons/hash.svg';
import ChatInput from '../Input/ChatInput';
import AttachmentInfoDrawer from '../AttachmentInfoDrawer';
import FilesPopper from './FilesPopper';
import ChatMessageList from './ChatMessageList';
import ChannelDropdown from './ChannelMenu';
import MembersPopper from './MembersPopper';
import useAuth from '../../../utils/hooks/useAuth';

type ChannelViewProps = {
  channelId: string;
  floating?: true;
};

export default ({ channelId, floating }: ChannelViewProps) => {
  const {
    authData: { userData },
  } = useAuth();

  const channelResponse = useGetOne<ChannelDto>({
    resource: `channels/${channelId}`,
  });
  const channel = channelResponse.data?.data;

  const usersResponse = useGetMany<BaseUserDto>({
    resource: `users?channel=${channelId}`,
  });

  // Get the second user in case this is a 1-on-1 chat.
  const otherUser = usersResponse.data?.data.results.find((user) => user.id !== userData?.id);

  return (
    <Stack flex="1" justifyContent="flex-end" height="100%" direction="column">
      <Stack spacing={1} direction="column">
        <Stack px={3} pt={1} direction="row" gap={2} alignItems="center">
          {channel && otherUser ? (
            channel.type === ChatChannelType.Public ? (
              <>
                <HashIcon width={28} height={28} />
                <Typography color="primaryDark.600" variant={floating ? 'heading4' : 'heading2'}>
                  {channel.name}
                </Typography>
                {!floating && <ChannelDropdown channel={channel} />}
              </>
            ) : (
              <>
                <Avatar>{otherUser?.firstName[0]}</Avatar>
                <Typography color="primaryDark.600" variant={floating ? 'heading4' : 'heading2'}>
                  {otherUser?.firstName} {otherUser?.lastName}
                </Typography>
              </>
            )
          ) : null}
          {!floating && (
            <Stack direction="row" ml="auto" mr={2} gap={1} alignItems="stretch">
              {channel?.type === ChatChannelType.Public ? (
                <MembersPopper channelId={channelId} />
              ) : null}
              <FilesPopper channelId={channelId} />
            </Stack>
          )}
        </Stack>
        <Divider color="secondaryBlue.100" />
      </Stack>
      <Stack flex="1" direction="column" position="relative" overflow="hidden">
        <Stack gap={2} justifyContent="flex-end" direction="column" height="100%" overflow="hidden">
          <ChatMessageList channelId={channelId} />
          <ChatInput channelId={channelId} />
        </Stack>
        <AttachmentInfoDrawer />
      </Stack>
    </Stack>
  );
};
