import { Box } from '@mui/system';
import { IconButton, Stack, Typography } from '@mui/material';
import { NotificationDto } from 'tdc-web-backend/notifications/schemas';
import { useNavigate } from 'react-router-dom';
import { FunctionComponent, SVGProps } from 'react';
import { timeSince } from '../../utils/helpers';
import getNotificationRoute from './getNotificationRoute';
import { useRefresh } from '../../utils/hooks/crud-hooks/useRefresh';
import useUpdate from '../../utils/hooks/crud-hooks/useUpdate';

import { secondaryBlue, secondaryYellow } from '../../utils/color';
import { ReactComponent as InfoAlertIcon } from '../../assets/icons/layout-icons/InfoAlertIcon.svg';

interface NotificationCardProps {
  data: NotificationDto;
  Icon: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
}
interface NotificationCardRemainingProps {
  unread: number;
}

// These icons are only old and will be replaced when design gets updated

const NotificationCardSmall = ({ data, Icon }: NotificationCardProps) => {
  const navigate = useNavigate();
  const refresh = useRefresh();

  const { mutate: openNotification } = useUpdate<NotificationDto, unknown>({
    resource: 'notifications',
  });

  const notificationRoute = getNotificationRoute(data);
  const handleOpenNofitication = () => {
    // navigate(notificationRoute);
    openNotification(
      {
        id: data.id as string,
        suffix: 'seen',
      },
      {
        onSuccess: () => {
          refresh();
        },
      },
    );
  };
  return (
    <>
      <Box
        sx={{
          p: 2,
          borderBottom: '1px solid #E6E8FF',
          backgroundColor: !data.seen ? 'secondaryBlue.50' : 'transparent',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': {
            background: '#FFFBFF',
          },
        }}
        onClick={handleOpenNofitication}
      >
        <Stack direction="row" spacing={1}>
          <IconButton
            sx={{
              borderRadius: '4px',
              bgcolor: 'secondaryBlue.100',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': {
                bgcolor: 'secondaryBlue.100',
              },
            }}
          >
            <Icon fill={secondaryBlue[700]} />
          </IconButton>
          <Stack direction="column">
            <Typography flexWrap="wrap" color="primaryDark.600" variant="body2">
              {data.short}
            </Typography>
            {/* FIXME: variant for cooperation company name on figma is not standardized! */}
            {/* hence font override is used here for now */}
            <Typography variant="body3" color="primaryDark.400" fontWeight="600">
              {timeSince(data.created)} ago
            </Typography>
          </Stack>
        </Stack>
        {!data.seen && (
          <Box
            sx={{
              backgroundColor: 'primaryLight.500',
              borderRadius: '50%',
              minWidth: '12px',
              minHeight: '12px',
              width: '12px',
              height: '12px',
              border: '2px solid',
              borderColor: 'primaryLight.100',
            }}
          />
        )}
      </Box>
    </>
  );
};

export default NotificationCardSmall;

export const NotificationCardRemaining = ({ unread }: NotificationCardRemainingProps) => {
  const navigate = useNavigate();

  const handleOpenNofitications = () => {
    navigate('../dashboard/notifications');
  };
  return (
    <>
      <Box
        sx={{
          p: 2,
          backgroundColor: 'transparent',
          display: unread ? 'flex' : 'none',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': {
            background: '#FFFBFF',
          },
        }}
        onClick={handleOpenNofitications}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <IconButton
            sx={{
              borderRadius: '4px',
              bgcolor: 'secondaryYellow.100',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': {
                bgcolor: 'secondaryYellow.100',
              },
            }}
          >
            <InfoAlertIcon fill={secondaryYellow[700]} />
          </IconButton>
          <Typography flexWrap="wrap" color="primaryDark.600" variant="body2">
            {unread} more unread notifications
          </Typography>
        </Stack>
      </Box>
    </>
  );
};
