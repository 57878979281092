import { Dispatch, SetStateAction } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import ConfirmModal from '../../../../../components/confirm-modal/ConfirmModal';
import { primaryLight, secondaryBlue } from '../../../../../utils/color';
import useDeleteOne from '../../../../../utils/hooks/crud-hooks/useDeleteOne';
import { useRefresh } from '../../../../../utils/hooks/crud-hooks/useRefresh';

interface TeamCreateAndEditModalProps {
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  teamMemberId: string | null;
  setTeamMemberId: Dispatch<SetStateAction<string | null>>;
}

const TeamDeleteModal = ({
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  teamMemberId,
  setTeamMemberId,
}: TeamCreateAndEditModalProps) => {
  const refresh = useRefresh();

  const { mutate: deleteTeamMember, isLoading: isDeleteLoading } = useDeleteOne({
    resource: 'company-team-member',
  });

  const onSubmit = () => {
    deleteTeamMember(
      { id: teamMemberId as string },
      {
        onSuccess: () => {
          refresh();
          setTeamMemberId(null);
          setIsDeleteModalOpen(false);
        },
      },
    );
  };

  return (
    <ConfirmModal
      width="40%"
      confirmButtonText="Confirm"
      isLoading={isDeleteLoading}
      onCancel={() => setIsDeleteModalOpen(false)}
      title="Remove team member"
      isModalOpen={isDeleteModalOpen}
      setIsModalOpen={setIsDeleteModalOpen}
      automaticallyCloseModalOnButtonClick={false}
      onConfirm={onSubmit}
      iconProps={{
        icon: <PersonIcon sx={{ color: secondaryBlue[700] }} />,
        wrapperBackgroundColor: primaryLight[100],
      }}
      message="Are you sure that you want to permanently remove this team member?"
    />
  );
};

export default TeamDeleteModal;
