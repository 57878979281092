import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/project-icons/ArrowRightIcon.svg';
import CustomButton from '../../../components/button/CustomButton';
import { primaryLight } from '../../../utils/color';
import { LAYOUT_DEFAULTS } from '../../../utils/constants';
import { EnumPublicPagesImage } from '../../../utils/enums';
import { getPublicImageUrl } from '../../../utils/helpers';

const EvolveYourBusiness = () => {
  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        position: 'relative',
        bgcolor: 'primaryLight.100',
        px: { xs: 4, lg: 5 },
        py: { xs: 9, lg: 5, xl: 5 },
        pb: { xs: 3, sm: 6, lg: 5 },
        borderRadius: '20px',
        width: { xs: 'fit-content', xl: 'auto' },
        overflow: { xs: 'hidden', lg: 'visible' },
        mt: { xs: '360px', sm: '260px', md: '120px', lg: '60px' },
        my: 8,
        ...LAYOUT_DEFAULTS,
      }}
    >
      {/* Evolve Your Business text & description text & button */}
      <Stack spacing="36px" sx={{ maxWidth: { xs: '100%', lg: '55%' } }}>
        <Typography
          color="common.black"
          whiteSpace="pre-line"
          sx={{
            typography: { xs: 'publicHeading4', lg: 'publicHeading3' },
          }}
        >
          Evolve Your Business - Streamline, Simplify and Grow
        </Typography>

        <Typography
          color="common.black"
          variant="publicBody3Light"
          sx={{
            maxWidth: { xs: '100%', sm: '50%', lg: '90%' },
            zIndex: 10,
          }}
        >
          Managing projects and suppliers doesn’t have to be complex. With Spona, you get an
          all-in-one platform that is ready to use from day one. Forget about complicated setups and
          fragmented tools - Spona helps you sign digital contracts, set milestones, manage payments
          and get clear project insights hassle free.
          <br />
          <br />
          Our pre-assembled solution is designed to make your work life easier. Whether its’s
          handling contracts, tracking milestones, or automating payments, everything is streamlined
          in one place, so you can focus on growing your business, not managing chaos.
        </Typography>

        <Box
          alt="Mock-up of a laptop showing a screenshot of Spona’s tool "
          component="img"
          src={getPublicImageUrl(EnumPublicPagesImage.Laptop2)}
          sx={{
            display: { xs: 'block', sm: 'none' },
            // position: { xs: 'absolute' },
            bottom: 6,
            right: 10,
            width: { xs: '90%', lg: 'auto' },
          }}
        />
      </Stack>

      <Box
        alt="Mock-up of a laptop showing a screenshot of Spona’s tool "
        component="img"
        src={getPublicImageUrl(EnumPublicPagesImage.Laptop2)}
        sx={{
          display: { xs: 'none', sm: 'block' },
          position: { sm: 'absolute', xl: 'relative' },
          right: { xs: 10, bg: 0, lg: -85, xxl: -150 },
          width: '54%',
        }}
      />
    </Stack>
  );
};

export default EvolveYourBusiness;
