import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useFormContext } from 'react-hook-form';
import ControlledTextEditor from '../../../../components/custom-inputs/ControlledTextEditor';
import { CaseStudyEditorMenu } from '../../../../components/editor/menu-option-lists/menu-item-lists';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';
import { ReactComponent as TrophyIcon } from '../../../../assets/icons/layout-icons/trophy2-fill.svg';
import { ReactComponent as BullseyeIcon } from '../../../../assets/icons/layout-icons/BullseyeIcon.svg';
import { ReactComponent as BriefcaseCheckIcon } from '../../../../assets/icons/layout-icons/BriefcaseCheckIcon.svg';
import { ReactComponent as LightBulbIcon } from '../../../../assets/icons/layout-icons/LightBulbIcon.svg';
import { primaryDark } from '../../../../utils/color';

const PreviewStep = () => {
  const { getValues } = useFormContext();

  return (
    <Stack
      direction="column"
      spacing={1}
      width="70"
      border="1px solid"
      borderColor="secondaryBlue.100"
      sx={{
        background:
          'linear-gradient(240.98deg, rgba(230, 232, 255, 0.2) 0%, rgba(255, 234, 225, 0.2) 68.46%, rgba(255, 251, 234, 0.2) 99.58%)',
      }}
    >
      <Box
        sx={{ m: 4, borderRadius: '3px', maxWidth: '100%' }}
        component="img"
        src={URL.createObjectURL(getValues('file'))}
      />

      <Stack py={2} px={3}>
        <Stack direction="row" spacing={1}>
          <TrophyIcon fill={primaryDark[500]} />
          <BaseInputLabel text="Project goals" />
        </Stack>
        <ControlledTextEditor
          name="description.goals"
          items={CaseStudyEditorMenu}
          key="goals"
          isEditable={false}
          sx={{ '.ProseMirror': { color: '#384572' }, mt: 1.5 }}
        />
      </Stack>
      <Stack py={2} px={3}>
        <Stack direction="row" spacing={1}>
          <BullseyeIcon fill={primaryDark[500]} />
          <BaseInputLabel text="Challenges" />
        </Stack>
        <ControlledTextEditor
          isEditable={false}
          name="description.challenges"
          items={CaseStudyEditorMenu}
          key="challenges"
          sx={{ '.ProseMirror': { color: '#384572' }, mt: 1.5 }}
        />
      </Stack>
      <Stack py={2} px={3}>
        <Stack direction="row" spacing={1}>
          <LightBulbIcon fill={primaryDark[500]} />
          <BaseInputLabel text="solution" />
        </Stack>
        <ControlledTextEditor
          name="description.solutions"
          items={CaseStudyEditorMenu}
          isEditable={false}
          key="solutions"
          sx={{ '.ProseMirror': { color: '#384572' }, mt: 1.5 }}
        />
      </Stack>
      <Stack py={2} px={3}>
        <Stack direction="row" spacing={1}>
          <BriefcaseCheckIcon fill={primaryDark[500]} />
          <BaseInputLabel text="Results" />
        </Stack>
        <ControlledTextEditor
          isEditable={false}
          name="description.results"
          items={CaseStudyEditorMenu}
          key="results"
          sx={{ '.ProseMirror': { color: '#384572' }, mt: 1.5 }}
        />
      </Stack>
    </Stack>
  );
};

export default PreviewStep;
