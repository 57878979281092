import { IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/layout-icons/ArrowLeftShortFill.svg';

export interface BackButtonProps {
  onClick?: () => void;
  text?: string;
}

const BackButton = ({ onClick, text = 'Back' }: BackButtonProps) => (
  <Stack spacing={1.75} direction="row" alignItems="center">
    <IconButton
      onClick={() => onClick?.()}
      sx={{
        border: '1px solid',
        borderColor: 'primaryDark.300',
        width: '2.875rem',
        height: '2.875rem',
      }}
    >
      <ArrowLeftIcon />
    </IconButton>

    <Typography variant="heading4" color="primaryDark.500">
      {text}
    </Typography>
  </Stack>
);

export default BackButton;
