import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/project-icons/ArrowRightIcon.svg';
import { LAYOUT_DEFAULTS } from '../../utils/constants';
import { TextAndImageProps } from '../../views/PublicScreens/HowToManage/utils';
import CustomButton from '../button/CustomButton';

const TextAndImage = ({
  title,
  content,
  image,
  contentButton,
  sx,
  contentOrder = 'textThenImage',
  contentStyles,
  contentSpacing,
  alt,
}: Omit<TextAndImageProps, 'id'>) => {
  const navigate = useNavigate();

  return (
    <Stack>
      <>
        {contentOrder === 'textThenImage' && (
          <Stack
            spacing={12}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            sx={{ ...LAYOUT_DEFAULTS, ...sx }}
          >
            <Stack
              spacing={{ xs: 4, lg: 1, ...contentSpacing }}
              justifyContent="space-evenly"
              sx={{ maxWidth: { xs: '100%', md: '75%' }, ...contentStyles }}
            >
              <Typography sx={{ typography: { xs: 'publicHeading4', lg: 'publicHeading3' } }}>
                {title}
              </Typography>

              <Typography variant="publicBody4Regular" color="common.black">
                {content}
              </Typography>

              {contentButton && (
                <CustomButton
                  onClick={() => navigate(contentButton.to)}
                  variant="primaryText"
                  endIcon={
                    contentButton.withArrowRightIcon ? (
                      <ArrowRightIcon fill={contentButton.color} />
                    ) : null
                  }
                  sx={{ color: contentButton.color, fontSize: '1rem' }}
                >
                  {contentButton.text}
                </CustomButton>
              )}
            </Stack>

            <Box
              alt={alt}
              component="img"
              src={image}
              sx={{
                height: '100%',
                width: { xs: '80%', lg: 'auto' },
                alignSelf: { xs: 'center', lg: '' },
              }}
            />
          </Stack>
        )}

        {contentOrder === 'imageThenText' && (
          <Stack
            spacing={12}
            direction={{ xs: 'column-reverse', md: 'row' }}
            justifyContent="space-between"
            sx={{ ...LAYOUT_DEFAULTS, ...sx }}
          >
            <Box component="img" src={image} height="100%" alt={alt} />

            <Stack
              spacing={{ xs: 4, lg: 1, ...contentSpacing }}
              justifyContent="space-evenly"
              sx={{ maxWidth: { xs: '100%', md: '75%' }, ...contentStyles }}
            >
              <Typography sx={{ typography: { xs: 'publicHeading4', lg: 'publicHeading3' } }}>
                {title}
              </Typography>

              <Typography variant="publicBody4Regular" color="common.black">
                {content}
              </Typography>

              {contentButton && (
                <CustomButton
                  onClick={() => navigate(contentButton.to)}
                  variant="primaryText"
                  endIcon={
                    contentButton.withArrowRightIcon ? (
                      <ArrowRightIcon fill={contentButton.color} />
                    ) : null
                  }
                  sx={{ color: contentButton.color, fontSize: '1rem' }}
                >
                  {contentButton.text}
                </CustomButton>
              )}
            </Stack>
          </Stack>
        )}
      </>
    </Stack>
  );
};

export default TextAndImage;
