import { useState } from 'react';
import { ChannelDto } from 'tdc-web-backend/channels/schemas';
import CustomDropdown from '../../../components/custom-dropdown/CustomDropdown';
import { ReactComponent as PencilIcon } from '../../../assets/icons/chat-icons/pencil-fill.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/chat-icons/trash2-fill.svg';
import { ReactComponent as ChatBubbleIcon } from '../../../assets/icons/chat-icons/chat3-fill.svg';
import { primaryDark } from '../../../utils/color';
import DeleteChannelModal from '../Modals/DeleteChannelModal';
import CreateChannelModal from '../Modals/CreateChannelModal';
import EditChannelModal from '../Modals/EditChannelModal';

type ChannelDropdownProps = {
  channel: ChannelDto;
};

export default ({ channel }: ChannelDropdownProps) => {
  const [createOpen, setCreateOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  return (
    <>
      <CustomDropdown
        menuItemsContents={[
          {
            key: 'create',
            icon: (
              <ChatBubbleIcon
                fill={primaryDark[500]}
                style={{
                  width: 18,
                  height: 18,
                }}
              />
            ),
            text: 'Create',
            onClick: () => setCreateOpen(true),
          },
          {
            key: 'edit',
            icon: (
              <PencilIcon
                fill={primaryDark[500]}
                style={{
                  width: 18,
                  height: 18,
                }}
              />
            ),
            text: 'Edit',
            onClick: () => setEditOpen(true),
            disabled: !channel.admin,
          },
          {
            key: 'delete',
            icon: (
              <TrashIcon
                fill={primaryDark[500]}
                style={{
                  width: 18,
                  height: 18,
                }}
              />
            ),
            text: 'Delete',
            onClick: () => setDeleteOpen(true),
            disabled: !channel.admin,
          },
        ]}
      />
      <CreateChannelModal
        open={createOpen}
        onClose={() => setCreateOpen(false)}
        spaceId={channel.space}
      />
      <EditChannelModal open={editOpen} onClose={() => setEditOpen(false)} channel={channel} />
      <DeleteChannelModal
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        channelId={channel.id}
      />
    </>
  );
};
