/* eslint-disable no-param-reassign */
import {
  Button as MuiButton,
  Stack,
  SxProps,
  Theme,
  ToggleButton,
  Tooltip as TooltipMui,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import CustomTooltip from '../../../../components/tooltip/CustomTooltip';
import { memo, useEffect, useState } from 'react';
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  format,
  getDaysInMonth,
  isLastDayOfMonth,
} from 'date-fns';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
  TooltipProps,
  Label,
  BarChart,
  Bar,
} from 'recharts';
import { v1 as uuidv1 } from 'uuid';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { EnumCurrency } from 'tdc-web-backend/enums/enums';
import { formatCurrency, nFormatter } from '../../../../utils/helpers';
import CustomNoRowsOverlay from '../../../../components/custom-no-rows-overlay/CustomNoRowsOverlay';
import theme from '../../../../utils/theme';
import { primaryDark, primaryLight } from '../../../../utils/color';
import { ReactComponent as GraphCircle } from '../../../../assets/icons/graph-point.svg';

export interface ProjectPaymentGraphProps {
  milestones: MilestoneDto[];
  currency: EnumCurrency;
  sx?: SxProps<Theme> | undefined;
}

interface GraphMilestoneI {
  id?: string;
  name?: string;
  date: Date | null;
  paid: number;
  unpaid: number;
  paidMilestones?: string;
  unpaidMilestones?: string;
}

interface DateInterval {
  month: Date[];
  halfyear: Date[];
  year: Date[];
}

const ProjectPaymentGraph = ({ sx, currency, milestones }: ProjectPaymentGraphProps) => {
  const [array, setArray] = useState<GraphMilestoneI[]>([]);
  const [dateIntervals, setDateIntervals] = useState<DateInterval | null>(null);
  const [intervalType, setIntervalType] = useState<string>('year');

  const getAllDaysInCurrentMonth = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();

    const daysInCurrentMonth = getDaysInMonth(new Date(year, month));

    const allDatesInCurrentMonth = eachDayOfInterval({
      start: new Date(year, month, 1),
      end: new Date(year, month, daysInCurrentMonth),
    });

    return allDatesInCurrentMonth;
  };

  const getSixMonthsInterval = () => {
    const date = new Date();

    const twoMonthsBackTarget = new Date(date.getFullYear(), date.getMonth() - 2, date.getDate());
    const threeMonthsAheadTarget = new Date(
      date.getFullYear(),
      date.getMonth() + 3,
      date.getDate(),
    );

    const twoMonthsBackAndTheCurrentMonth = eachMonthOfInterval({
      start: new Date(twoMonthsBackTarget),
      end: new Date(),
    });

    const twoMonthsAhead = eachMonthOfInterval({
      start: new Date(date.getFullYear(), date.getMonth() + 1),
      end: new Date(threeMonthsAheadTarget),
    });

    const sixMonthsPeriod = twoMonthsBackAndTheCurrentMonth.concat(twoMonthsAhead);

    const allTheDatesBetweenStartAndEndOfSixMonthsPeriod = eachDayOfInterval({
      start: new Date(sixMonthsPeriod[0]),
      end: new Date(sixMonthsPeriod[sixMonthsPeriod.length - 1]),
    });

    return allTheDatesBetweenStartAndEndOfSixMonthsPeriod;
  };

  const getAllYearDatesInterval = () => {
    const firstDateOfTheYear = new Date(new Date().getFullYear(), 0, 1);
    const lastDateOfTheYear = new Date(new Date().getFullYear(), 11, 31);

    const allTheDatesBetweenStartAndEndOfYearPeriod = eachDayOfInterval({
      start: new Date(firstDateOfTheYear),
      end: new Date(lastDateOfTheYear),
    });

    return allTheDatesBetweenStartAndEndOfYearPeriod;
  };

  // Add mock data
  const mockData = getAllYearDatesInterval().map((day, i) => ({
    date: day,
    name: day,
    id: i,
    paid: 0,
    unpaid: 0,
  }));

  mockData[286].paid = 165000;
  mockData[46].unpaid = 200;
  mockData[326].paid = 175000;
  mockData[mockData.length - 1].paid = 350000;

  useEffect(() => {
    setDateIntervals({
      month: getAllDaysInCurrentMonth(),
      halfyear: getSixMonthsInterval(),
      year: getAllYearDatesInterval(),
    });
  }, []);

  useEffect(() => {
    const allMilestonesArray: GraphMilestoneI[] = [];

    if (milestones && dateIntervals !== null) {
      dateIntervals[intervalType as keyof typeof dateIntervals].forEach(
        (date: Date, dateIndex: number) => {
          milestones.forEach((milestone) => {
            if (format(date, 'P') === format(new Date(milestone?.paid as Date), 'P')) {
              allMilestonesArray.push({
                id: milestone.id,
                name: milestone.name,
                date,
                paid: milestone.budget,
                unpaid: 0,
              });
            }

            // add not paid milestones
            if (
              milestone?.paid === null &&
              // end is here used as (last?) expected date when paymend should arrive
              format(date, 'P') === format(new Date(milestone.end), 'P')
            ) {
              allMilestonesArray.push({
                id: milestone.id,
                name: milestone.name,
                date,
                paid: 0,
                unpaid: milestone.budget,
              });
            }
          });

          // days when no milestone is paid or planned to end
          // do not push if in allMilestonesArray is already date
          // OR do not duplicate dates
          if (allMilestonesArray[dateIndex]?.date !== date) {
            allMilestonesArray.push({
              // id: milestones[],
              // name: milestone[],
              date,
              paid: 0,
              unpaid: 0,
            });
          }
        },
      );

      const mergedTargetAndDuplicatesMilestones: GraphMilestoneI[] = [];

      const milestonesWhoseDateIsNotRepeated = allMilestonesArray.filter(
        (milestone, i, milestonesArray) => {
          const targetAndDuplicatesMilestones: GraphMilestoneI[] = [];

          // if a milestone's date is repeated
          // for each milestone, check if milestone's date is repeated twice in a row
          // OR in other words, find first index in the array whose date is equal to a milestone
          // date and compare that index to the current iterative milestone's index;
          // if they are not equal, it means that for the current iterative
          // milestone date is repeated
          if (
            milestonesArray.findIndex(
              (milestoneArrItem) => milestoneArrItem.date === milestone.date,
            ) !== i
          ) {
            // we never want to mutate the array directly, so we make a copy of it
            const copiedMilestonesArray = [...milestonesArray];

            // since milestones are sorted one after another, in chronological order,
            // for a duplicated milestone we can ALWAYS be certain that its previous milestone
            // is one with the same date, so in it we want to "merge" the duplicated milestone
            const targetMilestone = copiedMilestonesArray[i - 1];

            // remaining array starting from target milestone
            // const slicedArr = array.slice(targetMilestone);
            const slicedMilestonesArray = copiedMilestonesArray.slice(
              copiedMilestonesArray.findIndex((copiedItem) => copiedItem.date === milestone.date),
            );

            slicedMilestonesArray.forEach((milestone) => {
              // from the remaining array of duplicate milestones, get all the duplicates for
              // the current target milestone
              if (milestone.date === targetMilestone.date) {
                targetAndDuplicatesMilestones.push(milestone);
              }
            });

            const nonUndefinedDuplicates = targetAndDuplicatesMilestones.filter(
              (duplicate) => duplicate.name !== undefined,
            );

            const mergedTargetAndDuplicatesItem = nonUndefinedDuplicates.reduce(
              (result, current, index: number) => {
                if (current.unpaid === 0 && current.name) {
                  result.paidMilestones += `${current.name}, `;
                } else if (current.paid === 0 && current.name) {
                  result.unpaidMilestones += `${current.name}, `;
                }

                result.id = uuidv1();
                result.date = current.date;
                result.paid += current.paid;
                result.unpaid += current.unpaid;

                if (nonUndefinedDuplicates.length - 1 === index && result.unpaidMilestones) {
                  // remove hanging ', '
                  result.unpaidMilestones = result.unpaidMilestones.replace(/,\s*$/, '');
                }

                if (nonUndefinedDuplicates.length - 1 === index && result.paidMilestones) {
                  result.paidMilestones = result.paidMilestones.replace(/,\s*$/, '');
                }

                return result;
              },
              {
                id: '',
                unpaidMilestones: '',
                paidMilestones: '',
                date: null,
                paid: 0,
                unpaid: 0,
              },
            );

            mergedTargetAndDuplicatesMilestones.push(mergedTargetAndDuplicatesItem);
          }

          // return milestones whose date is not repeated
          return (
            milestonesArray.findIndex(
              (milestoneArrItem) => milestoneArrItem.date === milestone.date,
            ) === i
          );
        },
      );

      // in milestonesWhoseDateIsNotRepeated find indices at which date
      // is same as the date in the milestonesWhoseDateIsNotRepeated array
      // SO,
      // indices are indices at which in milestonesWhoseDateIsNotRepeated array
      // mergedTargetAndDuplicatesMilestones needs to get set
      // eslint-disable-next-line operator-linebreak
      const indicesOfWhereToPutMergedTargetAndDuplicatesMilestones =
        mergedTargetAndDuplicatesMilestones.map((mutatedMilestone) =>
          milestonesWhoseDateIsNotRepeated.findIndex((item) => item.date === mutatedMilestone.date),
        );

      const finalMilestones = [...milestonesWhoseDateIsNotRepeated];

      // then in the milestonesWhoseDateIsNotRepeated array
      // for each index check if indices' milestone is not undefined
      // OR in other words
      finalMilestones.forEach((item, index) => {
        // if not out of indicesOfWhereToPutMergedTargetAndDuplicatesMilestones
        if (indicesOfWhereToPutMergedTargetAndDuplicatesMilestones[index] !== undefined) {
          // target
          finalMilestones[indicesOfWhereToPutMergedTargetAndDuplicatesMilestones[index]] =
            mergedTargetAndDuplicatesMilestones[index];
        }
      });

      setArray(finalMilestones);
    }
  }, [milestones, intervalType, dateIntervals]);

  // get the greatest amount of paid/unpaid and use that as
  // yaxis datakey
  const getGreatestAmountOfPaidOrUnpaid = () => {
    const greatestPaidOrUnpaidMilestone: { paid: number; unpaid: number } = {
      paid: 0,
      unpaid: 0,
    };

    // first get all milestones whose paid and unpaid are not 0
    // then go through each of them and basically filter to find
    // the greatest amount of paid or unpaid by the help of
    // helper variable greatestPaidOrUnpaidMilestone
    array
      .filter((el) => el.paid !== 0 || el.unpaid !== 0)
      .forEach((item) => {
        if (item.paid > greatestPaidOrUnpaidMilestone?.paid) {
          greatestPaidOrUnpaidMilestone.paid = item.paid;
        }

        if (item.unpaid > greatestPaidOrUnpaidMilestone?.unpaid) {
          greatestPaidOrUnpaidMilestone.unpaid = item.unpaid;
        }
      });

    // get the highest value of paid/unpaid
    if (greatestPaidOrUnpaidMilestone) {
      if (greatestPaidOrUnpaidMilestone.paid > greatestPaidOrUnpaidMilestone.unpaid) {
        return 'paid';
      }

      return 'unpaid';
    }

    return null;
  };

  // if no milestones return false for mock graph
  const determineIfAllArePaid = () =>
    milestones?.length ? array.every((item) => item.unpaid === 0) : false;

  const handleChange = (event: React.MouseEvent<HTMLElement>, newIntervalType: string) => {
    // enforce that at least one button must be active
    if (newIntervalType !== null) {
      setIntervalType(newIntervalType);
    }
  };

  const toggleButtonStyles: SxProps<Theme> = {
    py: 0.1,
    px: 1.2,
    border: 'none',
    color: 'primaryDark.500',
    fontWeight: 600,
  };

  const toggleButtonActiveStyles: SxProps<Theme> = {
    color: 'primaryLight.500',
    backgroundColor: 'transparent',
  };

  const AreaDotRadius = 6.25;

  return (
    <Stack sx={{ ...sx, position: 'relative' }} width="100%" height="100%" justifyContent="center">
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        px={1.5}
        pb={1}
      >
        <Typography variant="heading4" color={'primaryDark.500'}>
          project payments
        </Typography>
        <ToggleButtonGroup
          color="secondary"
          value={intervalType}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
          sx={{
            alignSelf: 'end',
            width: 'fit-content',
            gap: 0,
            border: `1px solid ${theme.palette.secondaryBlue[100]}`,
            justifyContent: 'center',
          }}
        >
          <ToggleButton
            value="month"
            sx={{
              ...toggleButtonStyles,
              borderRight: `1px solid ${theme.palette.secondaryBlue[100]}`,
              '&.Mui-selected': { ...toggleButtonActiveStyles },
            }}
          >
            1M
          </ToggleButton>

          <ToggleButton
            value="halfyear"
            sx={{
              ...toggleButtonStyles,
              borderRight: `1px solid ${theme.palette.secondaryBlue[100]}`,
              '&.Mui-selected': { ...toggleButtonActiveStyles },
            }}
          >
            6M
          </ToggleButton>

          <ToggleButton
            value="year"
            sx={{ ...toggleButtonStyles, '&.Mui-selected': { ...toggleButtonActiveStyles } }}
          >
            1Y
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      <ResponsiveContainer width={'100%'} height="75%">
        <BarChart
          data={milestones?.length !== 0 ? array : mockData}
          style={{
            fontSize: '1rem',
            fontFamily: theme.typography.fontFamily,
            // marginLeft: -10,
          }}
          margin={{
            top: 20,
            right: 10,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id="paid" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#7F57FF" stopOpacity={1} />
              <stop offset="100%" stopColor="#7F57FF" stopOpacity={1} />
            </linearGradient>

            <linearGradient id="unpaid" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#808080" stopOpacity={1} />
              <stop offset="100%" stopColor="#808080" stopOpacity={1} />
            </linearGradient>
          </defs>

          <Legend
            wrapperStyle={{
              fontFamily: theme.typography.fontFamily,
              fontSize: '0.8rem',
              textTransform: 'capitalize',
            }}
            verticalAlign="bottom"
            height={15}
          />

          <CartesianGrid opacity={0.3} vertical={false} />
          <Tooltip
            active
            isAnimationActive={false}
            content={<CustomTooltipComponent currency={currency} />}
            wrapperStyle={{ outline: 'none', zIndex: 35, top: -50, left: -50 }}
          />

          {currency && (
            <YAxis
              // if there is no milestones, show paid for mock data
              dataKey={milestones?.length ? (getGreatestAmountOfPaidOrUnpaid() as string) : 'paid'}
              axisLine={false}
              tickLine={false}
              // tickCount={4}
              tick={{ fill: theme.palette.primaryDark[400] }}
              // this will return the currency in the k (kilo) format; e.g. $7.5k
              tickFormatter={(value) =>
                value.toLocaleString('en-US', {
                  style: 'currency',
                  currency,
                  notation: 'compact',
                  // minimumFractionDigits: 0,
                })
              }
              dx={-5}
              style={{ fontSize: '0.875rem', fontWeight: 600 }}
            />
          )}

          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            padding={{ left: 25, right: 25 }}
            tick={{ fill: theme.palette.primaryDark[400] }}
            scale="point"
            tickMargin={15}
            height={50}
            // show all ticks
            interval={0}
            tickFormatter={(value) => {
              // value is date

              if (intervalType === 'month') {
                if (value.getDate() === 1) {
                  return format(value, 'MMM d').toUpperCase();
                }

                // if not february
                if (value.getMonth() !== 1) {
                  if (value.getDate() % 10 === 0 && value.getDate() !== 30) {
                    return format(value, 'MMM d').toUpperCase();
                  }

                  if (
                    value.getDate() % 10 === 0 &&
                    value.getDate() === 30 &&
                    isLastDayOfMonth(value)
                  ) {
                    return format(value, 'MMM d').toUpperCase();
                  }

                  if (value.getDate() === 31 && isLastDayOfMonth(value)) {
                    return format(value, 'MMM d').toUpperCase();
                  }
                }

                // if february
                if (value.getMonth() === 1) {
                  if (value.getDate() % 10 === 0) {
                    return format(value, 'MMM d').toUpperCase();
                  }

                  if (value.getDate() === 28 || value.getDate() === 29) {
                    return format(value, 'MMM d').toUpperCase();
                  }
                }
              }

              if (intervalType === 'halfyear') {
                if (value.getDate() === 1) {
                  return format(value, 'MMM d').toUpperCase();
                }
              }

              if (intervalType === 'year') {
                if (value.getMonth() === 0 && value.getDate() === 1) {
                  return format(value, 'MMM d').toUpperCase();
                }

                if (value.getMonth() === 3 && value.getDate() === 1) {
                  return format(value, 'MMM d').toUpperCase();
                }

                if (value.getMonth() === 6 && value.getDate() === 1) {
                  return format(value, 'MMM d').toUpperCase();
                }

                if (value.getMonth() === 9 && value.getDate() === 1) {
                  return format(value, 'MMM d').toUpperCase();
                }

                if (value.getMonth() === 11 && value.getDate() === 31) {
                  return format(value, 'MMM d').toUpperCase();
                }
              }

              return '';
            }}
            // style={{ fontSize: '0.875rem' }}
            style={{ fontSize: '0.875rem', fontWeight: 600 }}
          />
          <Bar
            dataKey="paid"
            legendType="circle"
            type="monotone"
            stroke="#7F57FF"
            fill="#7F57FF"
            fillOpacity={1}
            isAnimationActive={false}
            strokeWidth={15}
            radius={[25, 25, 25, 25]}
            // activeDot={(payload) => {
            //   if (payload) {
            //     if (payload.payload.paid !== 0) {
            //       if (intervalType === 'halfyear') return <></>;
            //       if (intervalType === 'year') return <></>;
            //       return (
            //         <CustomTooltip
            //           title={payload.payload.paid}
            //           placement="top"
            //           sx={{ py: 1, px: 2, borderRadius: '5px', background: primaryDark[900] }}
            //         >
            //           <circle
            //             key={payload.cx}
            //             r={AreaDotRadius}
            //             stroke="#32009A"
            //             fill="#32009A"
            //             strokeWidth={AreaDotRadius}
            //             cx={payload.cx}
            //             cy={payload.cy}
            //           />
            //         </CustomTooltip>
            //       );
            //     }

            //     return <></>;
            //   }

            //   return <></>;
            // }}
          />
          {/* if all milestones are paid -> then hide this area chart! */}
          {!determineIfAllArePaid() && (
            <Bar
              dataKey="unpaid"
              legendType="circle"
              isAnimationActive={false}
              // activeDot={(payload) => {
              //   if (payload) {
              //     if (payload.payload.unpaid !== 0) {
              //       return (
              //         <CustomTooltip
              //           title={nFormatter(payload.payload.unpaid)}
              //           placement="top"
              //           sx={{ py: 1, px: 2, borderRadius: '5px', background: primaryDark[800] }}
              //         >
              //           <circle
              //             className="testing"
              //             key={payload.cx}
              //             r={AreaDotRadius}
              //             stroke="white"
              //             fill={primaryLight[500]}
              //             strokeWidth={AreaDotRadius}
              //             cx={payload.cx}
              //             cy={payload.cy}
              //           />
              //         </CustomTooltip>
              //       );
              //     }

              //     return <></>;
              //   }

              //   return <></>;
              // }}
              type="monotone"
              stroke="#C7B9FA"
              fillOpacity={1}
              strokeWidth={15}
              fill="#C7B9FA"
              radius={[25, 25, 25, 25]}
            />
          )}
        </BarChart>
      </ResponsiveContainer>

      {!milestones?.length && <CustomNoRowsOverlay />}
    </Stack>
  );
};

export default memo(ProjectPaymentGraph);

const CustomTooltipComponent = ({
  payload,
  active,
  currency,
}: { currency: EnumCurrency } & TooltipProps<number, string>) => {
  return <Typography color={'white'} variant="body3"></Typography>;
};
