import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import FreshchatWidget from '../components/freshchatWidget/FreshchatWidget';
import CookiesBanner from '../components/cookies-banner-modal/CookiesBanner';

const Root = () => {
  const { pathname } = useLocation();
  const [bannerOpen, setBannerOpen] = useState<boolean>(false);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Outlet />
      <FreshchatWidget />
      <CookiesBanner bannerOpen={bannerOpen} setBannerOpen={setBannerOpen} />
    </>
  );
};

export default Root;
