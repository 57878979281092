import { Dispatch, SetStateAction, useState } from 'react';
import { Alert, Divider, Stack, Typography } from '@mui/material';
import { mapMembershipRole, MembershipRole } from 'tdc-web-backend/enums/enums';
import { CreateUserInvitationDto } from 'tdc-web-backend/user-invitation/schemas';
import ErrorIcon from '@mui/icons-material/Error';
import ConfirmModal from '../../../../../../components/confirm-modal/ConfirmModal';
import useCreate from '../../../../../../utils/hooks/crud-hooks/useCreate';
import { ReactComponent as ClipboardCheckIcon } from '../../../../../../assets/icons/layout-icons/ClipboardCheckIconEditModal.svg';
import { primaryLight } from '../../../../../../utils/color';

export type ResendModalData = {
  email: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  role: MembershipRole;
};

type ResendModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onSuccessfulSubmit?: () => void;
  data: ResendModalData;
};

const ResendModal = ({ isOpen, setIsOpen, onSuccessfulSubmit, data }: ResendModalProps) => {
  const [error, setError] = useState<string | null>(null);

  const { mutate, isLoading } = useCreate<{}, CreateUserInvitationDto>({
    resource: 'user-invitation',
  });

  const onSubmit = () => {
    mutate(
      {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        jobTitle: data.jobTitle,
        role: data.role,
      },
      {
        onSuccess: () => {
          setError(null);
          setIsOpen(false);
          onSuccessfulSubmit?.();
        },
        onError: (err) => {
          setError(err.response?.data.message || err.message);
        },
      },
    );
  };

  return (
    <ConfirmModal
      isModalOpen={isOpen}
      automaticallyCloseModalOnButtonClick={false}
      onCancel={() => setIsOpen(false)}
      onConfirm={onSubmit}
      setIsModalOpen={() => null}
      title="Resend invitation"
      width="900px"
      confirmButtonText="Resend"
      iconProps={{
        icon: <ClipboardCheckIcon fill={primaryLight[500]} />,
        wrapperBackgroundColor: '#E3DBFC',
      }}
      isLoading={isLoading}
      showCancelButton={!isLoading}
      message={
        <>
          <Stack spacing={3}>
            {error && (
              <Alert severity="error" onClose={() => setError(null)}>
                {error}
              </Alert>
            )}
            <Stack
              direction="row"
              spacing={3}
              justifyContent="cener"
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Stack width="50%" spacing={3} justifyContent="space-between">
                <Stack>
                  <Typography variant="heading5" color="primaryDark.500">
                    Email
                  </Typography>
                  <Typography variant="bodyMedium3" color="primaryDark.800">
                    {data.email}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="heading5" color="primaryDark.500">
                    Job Title
                  </Typography>
                  <Typography variant="bodyMedium3" color="primaryDark.800">
                    {data.jobTitle}
                  </Typography>
                </Stack>
              </Stack>
              <Stack width="50%" spacing={3} justifyContent="space-between">
                <Stack>
                  <Typography variant="heading5" color="primaryDark.500">
                    First Name
                  </Typography>
                  <Typography variant="bodyMedium3" color="primaryDark.800">
                    {data.firstName}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="heading5" color="primaryDark.500">
                    Last Name
                  </Typography>
                  <Typography variant="bodyMedium3" color="primaryDark.800">
                    {data.lastName}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Divider />
            <Stack spacing={2.5}>
              <Stack spacing={0}>
                <Typography variant="heading4" color="primaryDark.500">
                  Role{'  '}
                </Typography>
                <Typography variant="bodyMedium3" color="primaryDark.800" component="span">
                  {mapMembershipRole.get(data.role)}
                </Typography>
              </Stack>
              <Typography variant="body3" color="primaryDark.700">
                {data.role === MembershipRole.Admin
                  ? `An Admin has full authority on the platform. They can add, edit, delete, and
                      view content, projects, and other users.`
                  : `An Editor can work on projects like an Admin but cannot access or modify
                      profile settings.`}
              </Typography>
            </Stack>
          </Stack>
        </>
      }
    />
  );
};

export default ResendModal;
