import { Stack } from '@mui/system';
import { Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/layout-icons/TrashIcon.svg';
import { secondaryBlue } from '../../../../utils/color';
import CustomButton from '../../../../components/button/CustomButton';
import { useRefresh } from '../../../../utils/hooks/crud-hooks/useRefresh';
import CustomModal from '../../../../components/modal/CustomModal';
import useDeleteOne from '../../../../utils/hooks/crud-hooks/useDeleteOne';

export interface DeletePitchModal {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  pitchId: string;
}

const DeletePitchModal = ({ open, setOpen, pitchId }: DeletePitchModal) => {
  const refresh = useRefresh();
  const { mutate: deletePitch } = useDeleteOne({
    resource: '/timed-project-pitches',
  });

  const handleDeletePitch = () => {
    deletePitch(
      {
        id: pitchId,
      },
      {
        onSuccess: () => {
          refresh();
          setOpen(false);
        },
      },
    );
  };

  return (
    <CustomModal open={open} onClose={() => setOpen(false)}>
      <Stack direction="column">
        <Stack direction="row" alignItems="center" mb={3}>
          <Stack
            width={40}
            height={40}
            mr={2}
            bgcolor="secondaryBlue.100"
            borderRadius={1}
            justifyContent="center"
            alignItems="center"
          >
            <TrashIcon width="20px" fill={secondaryBlue[700]} />
          </Stack>
          <Typography variant="heading4" color="primaryDark.600">
            Delete Pitch Draft
          </Typography>
        </Stack>
        <Stack
          py={2}
          sx={{
            borderTop: `1px solid ${secondaryBlue[100]}`,
            borderBottom: `1px solid ${secondaryBlue[100]}`,
          }}
        >
          <Typography color="primaryDark.500" variant="body2">
            Your project pitch draft will be permanently deleted. Are you sure you want to continue?
          </Typography>
        </Stack>
        <Stack direction="row" gap={3} mt={2} justifyContent="flex-end">
          <CustomButton onClick={() => setOpen(false)} variant="secondary">
            Cancel
          </CustomButton>
          <CustomButton onClick={handleDeletePitch} variant="primary">
            Confirm
          </CustomButton>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default DeletePitchModal;
