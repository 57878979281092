import { PaginationItem, SxProps, Theme } from '@mui/material';
import MuiPagination from '@mui/material/Pagination';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import { Dispatch, SetStateAction, memo, useMemo } from 'react';
import CustomButton from '../button/CustomButton';
import { secondaryBlue } from '../../utils/color';

export interface PaginationProps {
  totalRowsCount: number;
  numberOfRowsPerPage: number;
  setOffset: Dispatch<SetStateAction<number>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  sx?: SxProps<Theme>;
}

const Pagination = ({
  totalRowsCount,
  numberOfRowsPerPage,
  setOffset,
  currentPage,
  setCurrentPage,
  sx,
}: PaginationProps) => {
  const numberOfPages = useMemo(
    () => Math.ceil(totalRowsCount / numberOfRowsPerPage),
    [totalRowsCount, numberOfRowsPerPage],
  );

  const handleChange = (event: React.ChangeEvent<unknown>, newPageNumber: number) => {
    setCurrentPage(newPageNumber);
    setOffset((newPageNumber - 1) * numberOfRowsPerPage);
  };

  const NavigationButton = ({ direction }: { direction: 'previous' | 'next' }) => (
    <>
      {direction === 'previous' ? (
        <CustomButton
          startIcon={<WestIcon sx={{ width: '0.8rem', height: '0.8rem', alignItems: 'center' }} />}
          variant="secondaryText"
          sx={{ fontSize: '14px' }}
        >
          Previous
        </CustomButton>
      ) : (
        <CustomButton
          endIcon={<EastIcon sx={{ width: '0.8rem', height: '0.8rem', alignItems: 'center' }} />}
          variant="secondaryText"
          sx={{ fontSize: '14px' }}
        >
          Next
        </CustomButton>
      )}
    </>
  );

  return (
    <MuiPagination
      shape="rounded"
      page={currentPage}
      count={numberOfPages}
      onChange={handleChange}
      sx={{
        width: '100%',

        '&>ul>:first-child': { marginRight: 'auto' },
        '&>ul>:last-child': { marginLeft: 'auto' },

        '&>ul>:not(:first-child):not(:last-child)': {
          '& .MuiButtonBase-root': {
            color: 'primaryDark.500',
          },
          '& .Mui-selected': {
            backgroundColor: `${secondaryBlue[50]} !important`,
            '&>*:nth-child(2)>*': {
              color: 'primaryLight.500',
            },
            color: 'primaryLight.500',
            border: 'none',
          },
        },
        ...sx,
      }}
      renderItem={(item) => (
        <PaginationItem
          slots={{
            previous: () => <NavigationButton direction="previous" />,
            next: () => <NavigationButton direction="next" />,
          }}
          {...item}
        />
      )}
    />
  );
};

export default memo(Pagination);
