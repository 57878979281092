import { Card } from '@mui/material';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { EnumCurrency } from 'tdc-web-backend/enums/enums';
import ProjectPaymentGraph from './ProjectPaymentGraph';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import useAuth from '../../../../utils/hooks/useAuth';

const ProjectPaymentGraphCard = () => {
  const {
    authData: { userData: user },
  } = useAuth();

  const { data: milestones } = useGetMany<MilestoneDto>({
    enabled: !!user,
    resource: `milestones?company=${user?.membership?.company}`,
  });
  return (
    <Card
      elevation={0}
      sx={{
        width: '100%',
        height: '50vh',
        border: '1px solid',
        borderColor: 'secondaryBlue.100',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 1,
      }}
    >
      <ProjectPaymentGraph
        currency={EnumCurrency.Usd}
        milestones={milestones?.data.results as MilestoneDto[]}
      />
    </Card>
  );
};

export default ProjectPaymentGraphCard;
