import { Typography } from '@mui/material';
import { useBlocker } from 'react-router-dom';
import ConfirmModal from '../confirm-modal/ConfirmModal';

export interface INavigationBlockerProps {
  condition: boolean;
  title?: string;
  message?: string;
  confirmButtonText?: string;
}

const NavigationBlocker = ({
  condition,
  title = 'Unsaved changes',
  message = 'You have unsaved changes. Are you sure you want to leave this page?',
  confirmButtonText = 'Discard changes',
}: INavigationBlockerProps) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      condition && currentLocation.pathname !== nextLocation.pathname,
  );

  return (
    <ConfirmModal
      automaticallyCloseModalOnButtonClick={false}
      width="50%"
      isModalOpen={blocker.state === 'blocked'}
      setIsModalOpen={() => {
        blocker?.reset?.();
      }}
      title={title}
      message={
        <Typography color="primaryDark.500" lineHeight="21px">
          {message}
        </Typography>
      }
      confirmButtonText={confirmButtonText}
      onConfirm={() => {
        blocker?.proceed?.();
      }}
      showCancelButton
      onCancel={() => {
        blocker?.reset?.();
      }}
    />
  );
};

export default NavigationBlocker;
