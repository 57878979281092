import { Divider, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { FormProvider, useForm } from 'react-hook-form';
import { CompanyRole, EnumProjectMilestoneStatus } from 'tdc-web-backend/enums/enums';
import { ReactComponent as PaymentIcon } from '../../../../assets/icons/project-icons/PaymentIcon.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/project-icons/ArrowRightIcon.svg';
import useUserCompany from '../../../../utils/hooks/useUserCompany';
import CustomModal from '../../../../components/modal/CustomModal';
import CustomButton from '../../../../components/button/CustomButton';
import { primaryLight, secondaryBlue } from '../../../../utils/color';
import ControlledSelectInput from '../../../../components/custom-inputs/ControlledSelectInput';
import { convertDateNoYear } from '../../../../utils/helpers';
import StatusChipField from '../../../../components/StatusChipField/StatusChipField';
import InvoiceUploadModal from './InvoiceUploadModal';

type RequestPaymentModalProps = {
  data: MilestoneDto[];
};

const RequestPaymentModal = ({ data }: RequestPaymentModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [choosenMilestone, setChoosenMilestone] = useState<MilestoneDto>();
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const formMethods = useForm({
    mode: 'onChange',
  });

  const { formState } = formMethods;

  const onSubmit = (result: any) => {
    const milestoneObject = data.find((milestone) => milestone.id === result.milestone);
    setChoosenMilestone(milestoneObject);
    setIsInvoiceModalOpen(true);
    setIsOpen(false);
  };

  const userType = useUserCompany()?.roles;

  const isSeller =
    userType?.includes(CompanyRole.SellerService) || userType?.includes(CompanyRole.SellerTool);

  return (
    <>
      {isSeller && (
        <CustomButton
          onClick={() => setIsOpen(true)}
          variant="primaryLight"
          startIcon={<PaymentIcon fill={primaryLight[500]} />}
          className="react-joyride-step-14-project"
        >
          Request payment
        </CustomButton>
      )}
      <CustomModal open={isOpen} onClose={() => setIsOpen(false)}>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Stack direction="row" spacing={2} alignItems="center">
                <IconButton
                  sx={{
                    borderRadius: '4px',
                    bgcolor: 'secondaryBlue.100',
                    width: '3rem',
                    height: '3rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&:hover': {
                      bgcolor: 'secondaryBlue.100',
                    },
                  }}
                >
                  <PaymentIcon fill={secondaryBlue[700]} />
                </IconButton>
                <Typography variant="body2" fontWeight={600}>
                  Request payment
                </Typography>
              </Stack>
              <Divider />
              <Stack sx={{ mt: 2 }}>
                <Stack spacing={4} mt={2}>
                  <Typography variant="body2" color="primaryDark.500" sx={{ mb: 2 }}>
                    Select milestone you want to request payment for
                  </Typography>

                  <ControlledSelectInput
                    choices={data}
                    name="milestone"
                    required
                    label="Select milestone"
                    renderCustomMenuItem={(data) => (
                      <MenuItem
                        value={data.id}
                        key={data.id}
                        disabled={data.status !== EnumProjectMilestoneStatus.Approved}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                          height="4rem"
                        >
                          <Stack spacing={1}>
                            <Typography variant="body1" color="primaryDark.600">
                              {data.name}
                            </Typography>
                            <Typography
                              variant="heading4"
                              color="primaryDark.400"
                            >{`${convertDateNoYear(data.start)} - ${convertDateNoYear(
                              data.end,
                            )}`}</Typography>
                          </Stack>
                          <StatusChipField text={data.status} />
                        </Stack>
                      </MenuItem>
                    )}
                  />
                </Stack>
              </Stack>
              <Divider />
              <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
                <CustomButton
                  variant="secondary"
                  onClick={() => {
                    formMethods.reset();
                    setIsOpen(false);
                  }}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  disabled={!formState.isValid}
                  endIcon={<ArrowRightIcon fill="#fff" />}
                  type="submit"
                >
                  next
                </CustomButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </CustomModal>

      {/* render only if isInvoiceModalOpen */}
      {isInvoiceModalOpen && (
        <InvoiceUploadModal
          isOpen={isInvoiceModalOpen}
          setIsOpen={setIsInvoiceModalOpen}
          data={choosenMilestone as MilestoneDto}
        />
      )}
    </>
  );
};

export default RequestPaymentModal;
