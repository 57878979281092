import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routes from './routes';

import 'react-alice-carousel/lib/alice-carousel.css';
import './fonts.css';
import { NotificationProvider } from './utils/context/notification';
import MetaTags from './components/seo/MetaTags';

function App() {
  const router = createBrowserRouter(routes());

  const metaTags = {
    description:
      'If you want to streamline your digital project collaboration, management, and financing within a single platform, see what Spona can do for you.',
  };

  return (
    <>
      <MetaTags description={metaTags.description} />
      <NotificationProvider>
        <RouterProvider router={router} />
      </NotificationProvider>
    </>
  );
}

export default App;
