import { Stack } from '@mui/material';
import { primaryDark } from '../../../utils/color';
import CustomButton from '../../button/CustomButton';
import { ReactComponent as ArrowLeftShort } from '../../../assets/icons/arrow-left-short.svg';
import { ReactComponent as ArrowRightShort } from '../../../assets/icons/arrow-right-short.svg';
import { CustomBackAndNextButtons as CustomBackAndNextButtonsProps } from '../SingleSlideCarousel';

const CustomBackAndNextButtons = ({ back, next }: CustomBackAndNextButtonsProps) => (
  <Stack spacing={3} direction="row" sx={{ mt: 4 }}>
    <CustomButton
      variant="secondaryText"
      disabled={back.disabled}
      onClick={back.handleGoBack}
      startIcon={<ArrowLeftShort fill={primaryDark[500]} />}
      sx={{
        color: back.disabled ? `${primaryDark[300]} !important` : 'primaryDark.500',
      }}
    >
      BACK
    </CustomButton>

    <CustomButton
      variant="secondaryText"
      disabled={next.disabled}
      onClick={next.handleGoNext}
      endIcon={<ArrowRightShort fill={primaryDark[500]} />}
      sx={{
        color: next.disabled ? `${primaryDark[300]} !important` : 'primaryDark.500',
      }}
    >
      NEXT
    </CustomButton>
  </Stack>
);

export default CustomBackAndNextButtons;
