import { Box, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import { CompanyRole } from 'tdc-web-backend/enums/enums';
import { TaskStatus } from '../../../../components/task-status/TaskStatus';
import { formatCurrency } from '../../../../utils/helpers';
import CustomReferenceField from '../../../../components/custom-fields/CustomReferenceField';
import useUserCompany from '../../../../utils/hooks/useUserCompany';

type ActionIconProp = {
  routePath: string;
  className?: string;
};

export interface PaymentsListProps {
  payments: ProjectDto[];
}

const DetailsIcon = ({ routePath, className }: ActionIconProp) => {
  const navigate = useNavigate();
  return (
    <ChevronRightIcon
      className={className || undefined}
      sx={{ cursor: 'pointer' }}
      onClick={() => navigate(routePath)}
    />
  );
};

export function PaymentsList({ payments }: PaymentsListProps) {
  const userType = useUserCompany()?.roles;

  const getColabCompany = (value: any) =>
    userType?.[0] === CompanyRole.Buyer ? value?.seller : value?.buyer;

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.4,
      renderCell: (params: GridValueGetterParams) => <TaskStatus text={params.value} />,
    },
    {
      field: 'name',
      headerName: 'Project name',
      flex: 0.8,
      renderCell: (params: GridValueGetterParams) => (
        <Typography sx={{ fontSize: '0.875rem' }}>{params.value}</Typography>
      ),
    },
    {
      field: 'company',
      headerName: 'Partner company',
      flex: 0.8,
      renderCell: (params: GridValueGetterParams) => (
        <CustomReferenceField
          id={getColabCompany(params.row)}
          resource="companies"
          displayName="name"
          sx={{ fontSize: '0.875rem' }}
        />
      ),
    },

    {
      field: 'budget',
      headerName: 'Budget',
      flex: 0.5,
      renderCell: (params: GridValueGetterParams) => (
        <Typography sx={{ fontSize: '0.875rem' }}>
          {params.value ? formatCurrency(params.value, params.row.currency) : '-'}
        </Typography>
      ),
    },
    {
      field: 'Navigate',
      headerName: '',
      flex: 0.01,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridValueGetterParams) => (
        <DetailsIcon routePath={`./${params.row.id}`} className="react-joyride-step-5-payments" />
      ),
    },
  ];

  return (
    <Stack spacing={2}>
      <Typography variant="h5" fontSize="1.2rem" fontWeight={500}>
        Payment overview
      </Typography>

      <Box
        sx={{
          overflow: 'hidden',
          bgcolor: 'white',
          p: 0,
          borderRadius: '16px',
          border: '1px solid #C5C7C3',
        }}
        className="react-joyride-step-4-payments"
      >
        <DataGrid
          columns={columns}
          rows={payments}
          // autoHeight
          disableSelectionOnClick
          hideFooter
          sx={{ minHeight: '400px' }}
        />
      </Box>
    </Stack>
  );
}
