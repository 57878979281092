import { Breadcrumbs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { TaskDto } from 'tdc-web-backend/tasks/schemas';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import Link from '../../../components/link/Link';
import useReferenceOne from '../../../utils/hooks/crud-hooks/useReferenceOne';
import useAuth from '../../../utils/hooks/useAuth';

type Props = {};

export interface BreadcrumbI {
  id: string;
  title: string;
  to: string | null;
}

const BreadcrumbsNavigation = (props: Props) => {
  const location = useLocation();
  const params = useParams();
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbI[] | []>([]);
  const {
    authData: { userData },
  } = useAuth();

  const { data: taskData } = useReferenceOne<TaskDto>({
    resource: 'tasks',
    id: params.taskId,
    queryFilters: { company: userData?.id },
    enabled: !!params.taskId,
    refetchOnWindowFocus: false,
  });

  const { data: milestoneData } = useReferenceOne<MilestoneDto>({
    resource: 'milestones',
    id: params.milestoneId ? params.milestoneId : taskData?.milestone,
    enabled: !!params.milestoneId || !!taskData,
    refetchOnWindowFocus: false,
  });

  const { data: projectData } = useReferenceOne<ProjectDto>({
    resource: 'projects',
    id: params.projectId
      ? params.projectId
      : params.milestoneId
      ? milestoneData?.project
      : taskData?.project,
    enabled: !!params.projectId || !!milestoneData || !!taskData,
    refetchOnWindowFocus: false,
  });

  const path = location.pathname;

  useEffect(() => {
    if (location.pathname === '/dashboard') {
      setBreadcrumbs([]);
    }
    if (location.pathname === '/dashboard/end-to-end') {
      setBreadcrumbs([
        {
          id: 'projects',
          title: 'Projects',
          to: './end-to-end',
        },
      ]);
    }

    // if on project path
    if (params.projectId) {
      if (projectData) {
        setBreadcrumbs([
          {
            id: 'projects',
            title: 'Projects',
            to: './end-to-end',
          },
          {
            id: projectData.id,
            title: projectData.name,
            to: null,
          },
        ]);
      }
    }

    // if on contract path
    if (params.contractId) {
      if (projectData) {
        setBreadcrumbs([
          {
            id: 'projects',
            title: 'Projects',
            to: './end-to-end',
          },
          {
            id: params?.projectId as string,
            title: projectData.name,
            to: `./end-to-end/project/${projectData.id}`,
          },
          {
            id: `${params.projectId}/contract`,
            title: 'Contract',
            to: null,
          },
        ]);
      }
    }
    if (!params.contractId && location.pathname.includes('contract')) {
      if (projectData) {
        setBreadcrumbs([
          {
            id: 'projects',
            title: 'Projects',
            to: './end-to-end',
          },
          {
            id: params?.projectId as string,
            title: projectData.name,
            to: `./end-to-end/project/${projectData.id}`,
          },
          {
            id: `${params.projectId}/contract`,
            title: 'Contract',
            to: null,
          },
        ]);
      }
    }

    // if on milestone path
    if (params.milestoneId) {
      if (projectData && milestoneData) {
        setBreadcrumbs([
          {
            id: 'projects',
            title: 'Projects',
            to: './end-to-end',
          },
          {
            id: projectData.id,
            title: projectData.name,
            to: `./end-to-end/project/${projectData.id}`,
          },
          {
            id: milestoneData.id,
            title: milestoneData.name,
            to: null,
          },
        ]);
      }
    }

    // if on task path
    if (params.taskId) {
      if (taskData && milestoneData && projectData) {
        setBreadcrumbs([
          {
            id: 'projects',
            title: 'Projects',
            to: './end-to-end',
          },
          {
            id: projectData.id,
            title: projectData.name,
            to: `./end-to-end/project/${projectData.id}`,
          },
          {
            id: milestoneData.id,
            title: milestoneData.name,
            to: `./end-to-end/milestone/${milestoneData.id}`,
          },

          {
            id: taskData.id,
            title: taskData.name,
            to: null,
          },
        ]);
      }
    }

    if (path.includes('contracts')) {
      setBreadcrumbs([
        {
          id: 'projects',
          title: 'Projects',
          to: './end-to-end',
        },
        {
          id: 'all-contracts',
          title: 'Contracts',
          to: './end-to-end/contracts',
        },
      ]);
    }

    if (path.includes('contracts') && params.projectId) {
      setBreadcrumbs([
        {
          id: 'projects',
          title: 'Projects',
          to: './end-to-end',
        },
        {
          id: 'all-contracts',
          title: 'Contracts',
          to: './end-to-end/contracts',
        },
        {
          id: 'contract-detail-screen',
          title: 'Individual Contract',
          to: null,
        },
      ]);
    }

    if (path.includes('payments')) {
      setBreadcrumbs([
        {
          id: 'projects',
          title: 'Projects',
          to: './end-to-end',
        },
        {
          id: 'all-payments',
          title: 'Payments',
          to: './end-to-end/payments',
        },
      ]);
    }

    if (path.includes('payments') && params.paymentId) {
      setBreadcrumbs([
        {
          id: 'projects',
          title: 'Projects',
          to: './end-to-end',
        },
        {
          id: 'all-payments',
          title: 'Payments',
          to: './end-to-end/payments',
        },
        {
          id: 'payment-detail-screen',
          title: 'Individual Payment',
          to: null,
        },
      ]);
    }
  }, [params, location, taskData, projectData, milestoneData]);

  return (
    <Breadcrumbs
      separator="•"
      color="grey.600"
      aria-label="breadcrumb"
      sx={{ alignItems: 'center' }}
    >
      {/* <Stack alignItems="center" justifyContent="center">
        <Link
          color="grey.500"
          to="../dashboard"
          underline="none"
          sx={{ lineHeight: '1px' }}
        >
          <HomeLinkIcon fill="#79829B" />
        </Link>
      </Stack> */}

      {breadcrumbs?.map((breadcrumb: BreadcrumbI, index: number) => {
        const last = index === breadcrumbs.length - 1;

        return last ? (
          <Typography
            variant="body2"
            className={index === 0 ? 'react-joyride-step-1' : ''}
            color="grey.600"
            key={`./project/${breadcrumb.id}`}
          >
            {breadcrumb.title}
          </Typography>
        ) : (
          <Link
            variant="body2"
            color="grey.600"
            key={breadcrumb.id}
            to={breadcrumb.to ?? ''}
            underline="none"
          >
            {breadcrumb.title}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbsNavigation;
