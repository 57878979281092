import { IconButton, Stack, useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useState } from 'react';
import { ReactComponent as HamburgerMenuIcon } from '../../../assets/icons/layout-icons/HamburgerMenuIcon.svg';
import ExitIcon from '../../../assets/img/public-pages/exit.png';
import SponaLogo from '../../../assets/img/logo/spona-black.svg';
import { primaryDark } from '../../../utils/color';
import CustomButton from '../../../components/button/CustomButton';
import CustomLink from '../../../components/link/Link';
import theme from '../../../utils/theme';
import RightSideToolbar from '../../dashboard-navigation/appbar/RightSideToolbar';
import { LAYOUT_DEFAULTS } from '../../../utils/constants';
import LargeNavPages from './components/LargeNavPages';
import { pages } from './utils';
import MobileMenu from './components/MobileMenu';
import useAuth from '../../../utils/hooks/useAuth';

export const PUBLIC_APPBAR_HEIGHT = 77;
// on screens greater than or equal to 1920 (xxl), we want the navigation
// and footer to be wider than the rest of the content
const CUSTOM_LAYOUT_DEFAULTS = { mx: { ...LAYOUT_DEFAULTS.mx, xxl: 10 } };

const BuildMenuIcon = (open: boolean) =>
  open ? <Box component="img" src={ExitIcon} /> : <HamburgerMenuIcon fill={primaryDark[700]} />;

const LargeNavLoginAndSignupButtons = () => {
  const {
    authData: { accessToken },
  } = useAuth();
  const hasUser = accessToken !== null;

  return hasUser ? (
    <RightSideToolbar />
  ) : (
    <Stack direction="row" spacing={2}>
      <CustomButton variant="secondary" href="/login">
        Log in
      </CustomButton>

      <CustomButton href="/register">Sign up</CustomButton>
    </Stack>
  );
};

const Navigation = () => {
  const isGreaterThanLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <>
      <Stack>
        <AppBar
          color="inherit"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: PUBLIC_APPBAR_HEIGHT,
            position: 'fixed',
            boxShadow: '1px 10px 30px 0px rgba(56, 69, 114, 0.10)',
            zIndex: 1000001,
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              py: { xs: 1, sm: 2, lg: 2 },
              ...CUSTOM_LAYOUT_DEFAULTS,
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={{ xs: 'space-between', lg: 'start' }}
                width="100%"
              >
                <CustomLink to="/" onClick={() => setIsMobileMenuOpen(false)}>
                  <Box src={SponaLogo} sx={{ mr: { xs: 0, lg: 5 } }} component="img" />
                </CustomLink>

                {isGreaterThanLarge && <LargeNavPages pages={pages} />}

                {!isGreaterThanLarge && (
                  <IconButton sx={{ p: 0 }} onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                    {BuildMenuIcon(isMobileMenuOpen)}
                  </IconButton>
                )}
              </Stack>

              {isGreaterThanLarge && <LargeNavLoginAndSignupButtons />}
            </Stack>
          </Toolbar>
        </AppBar>
      </Stack>

      {/* mobile menu */}
      {!isGreaterThanLarge && (
        <MobileMenu
          isMobileMenuOpen={isMobileMenuOpen}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
          pages={pages}
        />
      )}
    </>
  );
};

export default Navigation;
