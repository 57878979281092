import { Card, Divider, IconButton, Stack, SxProps, Theme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { CompanyRole } from 'tdc-web-backend/enums/enums';
import SidebarCalendar from '../SidebarCalendar';
import { ReactComponent as BoxArrowUpRightLeft } from '../../../../../assets/icons/project-icons/BoxArrowUpRightLeft.svg';
import SidebarHelpBanner from '../../../../Dashboard/Homescreen/components/SidebarHelpBanner';
import SidebarProfileBanner from '../../../../Dashboard/Homescreen/components/SidebarProfileBanner';
import { primaryLight, secondaryBlue } from '../../../../../utils/color';
import CustomTooltip from '../../../../../components/tooltip/CustomTooltip';
import useUserCompany from '../../../../../utils/hooks/useUserCompany';
import useAuth from '../../../../../utils/hooks/useAuth';

export interface SidebarProps {
  sidebarData: any;
  sx?: SxProps<Theme> | undefined;
}

const Sidebar = ({ sidebarData, sx }: SidebarProps) => {
  const reminderArray = sidebarData.sidebarReminders || [];
  const messageArray = sidebarData.sidebarMessages?.messages || [];
  const { pathname } = useLocation();

  const companyRole = useUserCompany()?.roles;
  const {
    authData: { userData: user },
  } = useAuth();

  return (
    <Card
      sx={{
        borderRadius: '14px',
        backgroundColor: 'primaryDark.200',
        ...sx,
      }}
      elevation={0}
    >
      <Stack spacing={3}>
        {companyRole?.[0] === CompanyRole.SellerService && (
          <CustomTooltip placement="left" title="View Profile">
            <IconButton
              sx={{
                borderRadius: '3px',
                p: 0.6,
                width: '28px',
                height: '28px',
                alignSelf: 'end',
                bgcolor: primaryLight[50],
                '&:hover': {
                  bgcolor: primaryLight[100],
                },
              }}
              href={`/company/${user?.membership?.company}`}
              target="_blank"
            >
              <BoxArrowUpRightLeft fill={secondaryBlue[700]} />
            </IconButton>
          </CustomTooltip>
        )}
        {pathname.includes('dashboard') && <SidebarProfileBanner />}
        <Divider />
        <SidebarCalendar />
        <Divider />
        {pathname.includes('dashboard') && <SidebarHelpBanner />}
        {/* TODO: we need to define this */}
        {/* <SidebarRemindersWidget reminders={(reminderArray || []).slice(0, 2)} />
        <SidebarMessagesWidget messages={messageArray?.slice(0, 2)} /> */}
      </Stack>
    </Card>
  );
};

export default Sidebar;
