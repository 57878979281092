import { Divider, Stack, SxProps, Theme, Typography } from '@mui/material';
import { useState } from 'react';
import { secondaryBlue } from '../../utils/color';
import { truncate } from '../../utils/helpers';
import CustomModal from '../modal/CustomModal';
import { ReactComponent as ClipboardCheckIcon } from '../../assets/icons/layout-icons/ClipboardCheckIcon.svg';
import CustomButton from '../button/CustomButton';

interface DynamicDescriptionAndReadMoreWithModalProps {
  description?: string;
  numberOfLettersToDisplay?: number;
  onReadMoreClick?: () => void;
  title: string;
  sx?: SxProps<Theme> | undefined;
}

const twoRowDescriptionStyles = {
  background: 'white',
  height: '40px',
  position: 'relative',
  overflow: 'hidden',
};

const DynamicDescriptionAndReadMoreWithModal = ({
  description,
  title,
  numberOfLettersToDisplay = 103,
  onReadMoreClick,
  sx,
}: DynamicDescriptionAndReadMoreWithModalProps) => {
  const [isReadMoreModalOpen, setIsReadMoreModalOpen] = useState<boolean>(false);

  return (
    <>
      {/* modal */}
      <CustomModal
        open={isReadMoreModalOpen}
        onClose={() => setIsReadMoreModalOpen(false)}
        width="35%"
      >
        <Stack spacing={3}>
          <Stack spacing={2} direction="row" alignItems="center">
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                bgcolor: '#E5E8FF',
                width: 'fit-content',
                height: 'fit-content',
                borderRadius: '6px',
                p: 2,
              }}
            >
              <ClipboardCheckIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />
            </Stack>

            <Typography variant="heading3" color="primaryDark.600">
              {title}
            </Typography>
          </Stack>

          <Divider />

          <Typography color="primaryDark.500">{description}</Typography>

          <Divider />

          <CustomButton
            onClick={() => setIsReadMoreModalOpen(false)}
            variant="primary"
            sx={{ width: 120, alignSelf: 'end' }}
          >
            Close
          </CustomButton>
        </Stack>
      </CustomModal>

      {/* description and read more button */}
      <Stack spacing={2.5} sx={{ ...sx }}>
        <Stack spacing={1}>
          <Typography variant="heading4" color="primaryDark.500">
            description
          </Typography>

          <Typography
            variant="body2"
            color="primaryDark.600"
            width="85%"
            sx={twoRowDescriptionStyles}
          >
            {description && description.length <= numberOfLettersToDisplay
              ? description
              : truncate(description || '', numberOfLettersToDisplay)}
          </Typography>
        </Stack>

        {description && description.length > numberOfLettersToDisplay && (
          <Typography
            variant="heading4"
            color="primaryLight.500"
            sx={{ cursor: 'pointer', width: 'fit-content' }}
            onClick={() => {
              // potentional additional function if needed
              onReadMoreClick?.();
              setIsReadMoreModalOpen(true);
            }}
          >
            Read more
          </Typography>
        )}
      </Stack>
    </>
  );
};

export default DynamicDescriptionAndReadMoreWithModal;
