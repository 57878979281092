import { useEffect, useState } from 'react';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import useReferenceOne from './crud-hooks/useReferenceOne';
import useUserCompany from './useUserCompany';

const useGetCooperationCompany = (projectId?: string) => {
  const [cooperationCompanyId, setCooperationCompanyId] = useState<string | null>(null);
  const userCompany = useUserCompany();

  const { data: projectData } = useReferenceOne<ProjectDto>({
    resource: 'projects',
    id: projectId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const { data: cooperationCompanyData } = useReferenceOne<CompanyDto>({
    resource: 'companies',
    id: cooperationCompanyId as string,
    enabled: !!cooperationCompanyId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    // getting cooperation's id
    if (projectData !== null && userCompany !== null) {
      // if you're seller, it means your cooperation company is buyer
      if (projectData?.seller === userCompany?.id) {
        setCooperationCompanyId(projectData?.buyer);
      }

      // if you're buyer, it means your cooperation company is seller
      if (projectData?.buyer === userCompany?.id) {
        setCooperationCompanyId(projectData?.seller);
      }
    }
  }, [projectData, userCompany]);

  return cooperationCompanyData;
};

export default useGetCooperationCompany;
