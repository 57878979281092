import { Stack } from '@mui/material';
import ReusableHeroSection from '../../../components/public-screen-components/ReusableHeroSection';
import HowDoesItWork from '../../../components/public-screen-components/HowDoesItWork';
import SponaOptions from '../../../components/public-screen-components/SponaOptions';
import BecomeAPartOfSpona from '../../../components/public-screen-components/BecomeAPartOfSpona';
import { PUBLIC_APPBAR_HEIGHT } from '../../../layout/public-layout/navigation/Navigation';
import MetaTags from '../../../components/seo/MetaTags';
import { getPublicImageUrl } from '../../../utils/helpers';
import { EnumPublicPagesImage } from '../../../utils/enums';

const metaTags = {
  description: `See how easy it is to post a project and find service sellers who can deliver exactly what you need.`,
};

const TimedProjectsSubpage = () => (
  <>
    <MetaTags {...metaTags} />
    <Stack overflow="hidden" width="100%">
      <ReusableHeroSection
        image={getPublicImageUrl(EnumPublicPagesImage.TimedProjectSubageHero)}
        title="Your Next Big Project is Ahead"
        content="Post a project to find the digital service seller whose expertise and capabilities match your requirements the best. Start with a handy project template to structure your ideas quickly or get in touch with the Spona team to get help posting your project. Whether it’s your first or your hundredth project, Spona ensures a fully streamlined process."
        sx={{ height: { xs: `calc(89vh + ${PUBLIC_APPBAR_HEIGHT}px)`, lg: '87vh' } }}
        alt="Close-up of person’s hands going through marked paperwork"
      />

      <HowDoesItWork
        items={[
          {
            id: 'submit-your-project',
            title: 'Submit your project',
            content:
              'After you sign up, submit your project by website or by call - our team members at Spona can help guide you through the process. Use the project templates to define your requirements, from schedule, budget, scope to expertise.',
            image: getPublicImageUrl(EnumPublicPagesImage.SubmitYourProject),
            color: 'secondaryBlue.50',
            alt: 'Illustration of a project posting dashboard on Spona',
          },
          {
            id: 'review-pitches',
            title: 'Review pitches',
            content:
              'Review pitches from Spona’s community of vetted sellers. Seller profiles and verified case studies will give you the necessary background information on your candidates. ',
            image: getPublicImageUrl(EnumPublicPagesImage.ReviewPitches),
            color: 'secondaryBlue.50',
            alt: 'Illustration of a pitch review on spona',
          },
          {
            id: 'hire-a-seller',
            title: 'Hire a seller',
            content:
              'Approve your favorite pitches to proceed to the second round of negotiations, where you can contact the sellers to discuss their project strategy in more detail. The next step is choosing one seller and getting started on your project.',
            image: getPublicImageUrl(EnumPublicPagesImage.HireSeller),
            color: 'secondaryBlue.50',
            alt: 'Illustration of a digital contract on spona',
          },
        ]}
      />

      <SponaOptions
        title="Spona makes your projects..."
        optionsProps={[
          {
            id: 'time-efficient',
            title: 'Time efficient',
            content:
              'Spona eliminates all roadblocks and delays from the project creation and hiring process. Cut down the entire process and kickstart your project in a matter of days.',
            image: getPublicImageUrl(EnumPublicPagesImage.SponaMakesTimedProjects),
            alt: 'Person smiling and presenting strategy to her colleagues',
          },
          {
            id: 'easy-to-understand',
            title: 'Easy to understand',
            content:
              'Our project template lets you structure your needs easily and reach the kind of digital seller that would best suit your project. For everything else, our support team is here to help.',
            image: getPublicImageUrl(EnumPublicPagesImage.SponaMakesTimedProjects),
            alt: 'Person smiling and presenting strategy to her colleagues',
          },
          {
            id: 'risk-proof',
            title: 'Risk-proof',
            content:
              'Our community numbers thousands of reputable sellers with verified case studies showcasing their previous work. Make background checks simple and get the assurance that you’re hiring a professional for your project.',
            image: getPublicImageUrl(EnumPublicPagesImage.SponaMakesTimedProjects),
            alt: 'Person smiling and presenting strategy to her colleagues',
          },
        ]}
      />

      <BecomeAPartOfSpona
        title="Get project solutions today"
        bgcolor="secondaryBlue.101"
        color="common.black"
      />
    </Stack>
  </>
);
export default TimedProjectsSubpage;
