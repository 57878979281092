import { ReactElement } from 'react';
import { Navigate, NavigateProps } from 'react-router-dom';
import { MembershipRole, UserRoles } from 'tdc-web-backend/enums/enums';
import useAuth from '../../utils/hooks/useAuth';

export enum ProtectedRouteLevel {
  AccessToken = 'accessToken',
  Membership = 'membership',
  AgreementDate = 'agreementDate',
  AccessTokenFalse = 'accessTokenFalse',
  MembershipFalse = 'membershipFalse',
  AgreementDateFalse = 'agreementDateFalse',
  IsSponaAdmin = 'isSponaAdmin',
  IsOwner = 'isOwner',
  IsAdmin = 'isAdmin',
  IsEditor = 'isEditor',
}

interface IGenerateBooleanMatrixValues {
  accessToken: boolean;
  membership: boolean;
  agreementDate: boolean;
  isSponaAdmin: boolean;
  isOwner: boolean;
  isAdmin: boolean;
  isEditor: boolean;
}

const generateBooleanArray = (
  permissions: ProtectedRouteLevel[],
  values: IGenerateBooleanMatrixValues,
): boolean[] => {
  const arr: boolean[] = [];
  for (let j = 0; j < permissions.length; j++) {
    switch (permissions[j]) {
      case ProtectedRouteLevel.AccessToken:
        arr[j] = values.accessToken;
        break;
      case ProtectedRouteLevel.AccessTokenFalse:
        arr[j] = !values.accessToken;
        break;
      case ProtectedRouteLevel.Membership:
        arr[j] = values.membership;
        break;
      case ProtectedRouteLevel.MembershipFalse:
        arr[j] = !values.membership;
        break;
      case ProtectedRouteLevel.AgreementDate:
        arr[j] = values.agreementDate;
        break;
      case ProtectedRouteLevel.AgreementDateFalse:
        arr[j] = !values.agreementDate;
        break;
      case ProtectedRouteLevel.IsSponaAdmin:
        arr[j] = values.isSponaAdmin;
        break;
      case ProtectedRouteLevel.IsOwner:
        arr[j] = values.isOwner;
        break;
      case ProtectedRouteLevel.IsAdmin:
        arr[j] = values.isAdmin;
        break;
      case ProtectedRouteLevel.IsEditor:
        arr[j] = values.isEditor;
        break;
      default:
        break;
    }
  }
  return arr;
};

const generateBooleanMatrix = (
  permissions: ProtectedRouteLevel[][],
  values: IGenerateBooleanMatrixValues,
): boolean[][] => {
  const matrix: boolean[][] = [];
  for (let i = 0; i < permissions.length; i++) {
    matrix[i] = generateBooleanArray(permissions[i], values);
  }
  return matrix;
};

const evaluateBooleanArray = (arr: boolean[]): boolean => arr.every((value) => value);

const evaluateBooleanMatrix = (matrix: boolean[][]): boolean =>
  matrix.some((subArray) => evaluateBooleanArray(subArray));

interface INavigateOnVerificationFaild {
  condition: ProtectedRouteLevel[][];
  navigateProps: NavigateProps;
}

interface ProtectedRouteProps {
  children: JSX.Element;
  level: ProtectedRouteLevel[][];
  navigateOnVerificationFalse: INavigateOnVerificationFaild[] | NavigateProps;
}

const ProtectedRoute = ({
  children,
  level,
  navigateOnVerificationFalse,
}: ProtectedRouteProps): ReactElement => {
  const {
    authData: { accessToken, userData },
  } = useAuth();

  const navigate = <Navigate to="login" />;
  const isSponaAdmin =
    userData?.roles?.includes(UserRoles.Admin) && userData?.roles?.includes(UserRoles.Verified);
  const membership = userData?.membership;
  const agreementDate = userData?.agreementDate;
  const isOwner = userData?.membership?.role === MembershipRole.Owner;
  const isAdmin = userData?.membership?.role === MembershipRole.Admin;
  const isEditor = userData?.membership?.role === MembershipRole.Editor;

  const values: IGenerateBooleanMatrixValues = {
    accessToken: !!accessToken,
    membership: !!membership,
    agreementDate: !!agreementDate,
    isSponaAdmin: !!isSponaAdmin,
    isOwner: !!isOwner,
    isAdmin: !!isAdmin,
    isEditor: !!isEditor,
  };

  const matrix = generateBooleanMatrix(level, values);
  const isAllowed = evaluateBooleanMatrix(matrix);
  if (!isAllowed) {
    if (!Array.isArray(navigateOnVerificationFalse)) {
      return <Navigate {...navigateOnVerificationFalse} />;
    }

    for (let i = 0; i < navigateOnVerificationFalse.length; i++) {
      const { condition, navigateProps } = navigateOnVerificationFalse[i];
      const arr = generateBooleanMatrix(condition, values);
      const isTrue = evaluateBooleanMatrix(arr);
      if (isTrue) {
        return <Navigate {...navigateProps} />;
      }
    }

    return navigate;
  }

  return children;
};

export default ProtectedRoute;
