import { TextAndImageProps } from '../utils';
import { EnumPublicPagesImage } from '../../../../utils/enums';
import { getPublicImageUrl } from '../../../../utils/helpers';

export const textsAndImagesDigitalContracts: TextAndImageProps[] = [
  {
    id: 'sign-contracts-in-real-time',
    title: 'Create in Real Time',
    content:
      'Build your contract from the ground up with contract templates suited to your industry and project type. Efficiently translate your ideas into well-defined terms and get inspired with industry standards for related projects.',
    image: getPublicImageUrl(EnumPublicPagesImage.CreateInRealTimeBuyer),
    contentOrder: 'textThenImage',
    alt: 'Illustration of a contract review on spona',
  },
  {
    id: 'eliminate-legal-fees',
    title: 'Eliminate Legal Fees',
    content:
      'There is no need to get a lawyer to mediate the negotiations process - our contracts are fully legally binding. All you need is to agree to the terms of contract and submit it.',
    image: getPublicImageUrl(EnumPublicPagesImage.EliminateLegalFeesBuyer),
    contentOrder: 'imageThenText',
    alt: 'Illustration showing contract terms agreement on Spona platform.',
  },
  {
    id: 'sign-digitally',
    title: 'Sign Digitally',
    content:
      'No more printing and scanning your documents. Once you’re satisfied with your contract terms, your contract will be available for signing through DocuSign.',
    image: getPublicImageUrl(EnumPublicPagesImage.SignDigitallyBuyer),
    contentOrder: 'textThenImage',
    alt: 'Illustration showing digital signage on Spona',
  },
  {
    id: 'transparent-editing',
    title: 'Transparent Editing',
    content:
      'Even after they’ve been signed, project milestones are fully editable through annexes. Whenever a change is made, your seller will be notified to review and submit it.',
    image: getPublicImageUrl(EnumPublicPagesImage.TransparentEditingBuyer),
    contentOrder: 'imageThenText',
    alt: 'Illustration of editing contracts on Spona platform',
  },
  {
    id: 'access-at-any-time',
    title: 'Access at any Time ',
    content:
      'All of your contracts and contract annexes are stored and available for review and download from your account dashboard. Both parties get equal access to signed contracts, promoting transparent communication.',
    image: getPublicImageUrl(EnumPublicPagesImage.AccessAtAnyTimeBuyer),
    contentOrder: 'textThenImage',
    alt: 'Illustration of reviewing and downloading contracts on Spona platform',
  },
];

export const textsAndImagesPaymentProcessing: TextAndImageProps[] = [
  {
    id: 'smart-budget-allocation',
    title: 'Smart Budget Allocation',
    content:
      'Determine the amount of work that should be done for each project phase and easily redistribute your budget to account for any changes that might occur. ',
    image: getPublicImageUrl(EnumPublicPagesImage.SmartBudgetAllocationBuyer),
    contentOrder: 'textThenImage',
    alt: 'Illustration of the budget allocation  on Spona',
  },
  {
    id: 'automatic-notifications',
    title: 'Automatic Notifications',
    content:
      'You will be alerted when it’s time to make your payment, as well as when the process has been completed successfully, so you don’t have to worry about missed payments.',
    image: getPublicImageUrl(EnumPublicPagesImage.AutomaticNotificationsBuyer),
    contentOrder: 'imageThenText',
    alt: 'Notification on Spona',
  },
  {
    id: 'secure-processing',
    title: 'Secure Processing',
    content:
      'With Spona, you can be sure that your money is in safe hands. We are a guarantee for both parties and protect payment execution to ensure buyer-seller satisfaction.',
    image: getPublicImageUrl(EnumPublicPagesImage.SecureProcessingBuyer),
    contentOrder: 'textThenImage',
    alt: 'Secured payments illustration',
  },
  {
    id: 'transparent-transactions',
    title: 'Transparent Transactions',
    content:
      'Both you and your seller have an overview of all transactions with invoices available for reference and download on your account dashboards.',
    image: getPublicImageUrl(EnumPublicPagesImage.TransparentTransactionsBuyer),
    contentOrder: 'imageThenText',
    alt: 'Illustration of transactions overview on Spona',
  },
  {
    id: 'get-insights',
    title: 'Get Insights',
    content:
      'Check your current and forecasted costs across your current project (or multiple, if you’re handling more than one at the same time) to review your revenue immediately.',
    image: getPublicImageUrl(EnumPublicPagesImage.GetInsightsBuyer),
    contentOrder: 'textThenImage',
    alt: 'Illustration of a graph on Spona',
  },
];

export const textsAndImagesMilestonesAndTasks: TextAndImageProps[] = [
  {
    id: 'standardize-your-processes',
    title: 'Standardize Your Processes',
    content:
      'Across multiple projects, your task management methods will remain uniform, facilitating comparison and general management. You don’t have to get used to multiple platforms - everything is available on Spona.',
    image: getPublicImageUrl(EnumPublicPagesImage.StandardizeYourProcessesBuyer),
    contentOrder: 'textThenImage',
    alt: 'Illustration of managing tasks on spona',
  },
  {
    id: 'get-full-control',
    title: 'Get Full Control',
    content:
      'Segment larger project phases into tasks and subtasks to get daily status updates, and keep overall track of your project with milestones.',
    image: getPublicImageUrl(EnumPublicPagesImage.GetFullControlBuyer),
    contentOrder: 'imageThenText',
    alt: 'Illustration of tasks and sub-tasks on spona',
  },
  {
    id: 'resolve-issues',
    title: 'Resolve Issues',
    content:
      'In case of a problem, notify your digital service seller immediately by flagging the associated issues. Handle roadblocks before they escalate and keep track of the impact they have on your project.',
    image: getPublicImageUrl(EnumPublicPagesImage.ResolveIssuesBuyer),
    contentOrder: 'textThenImage',
    alt: 'Illustration of flagging the issue on Spona',
  },
  {
    id: 'progress-your-project',
    title: 'Progress Your Project',
    content:
      'Once your seller has finished work on a particular milestone, they will mark it as completed and notify you to review the work that has been done. This process is quick and intuitive and will allow you to progress seamlessly from one project phase to another.',
    image: getPublicImageUrl(EnumPublicPagesImage.ProgressYourProjectBuyer),
    contentOrder: 'imageThenText',
    alt: 'Illustration of the project progress on Spona',
  },
  {
    id: 'track-project-health',
    title: 'Track Project Health',
    content:
      'All major and minor updates to your tasks and milestones will be recorded in your project health - whether milestones were completed behind, ahead or right on time, how many issues were flagged and resolved, whether the cost on a milestone has increased, and more.',
    image: getPublicImageUrl(EnumPublicPagesImage.TrackProjectHealthBuyer),
    contentOrder: 'textThenImage',
    alt: 'Illustration of project milestones on Spona',
  },
];

export const textsAndImagesCommunication: TextAndImageProps[] = [
  {
    id: 'one-platform',
    title: 'One Platform',
    content:
      'Your seller is just a click away with our integrated messaging tool. If you’re handling multiple projects at once, get the benefit of gathering all of your contacts in one place.',
    image: getPublicImageUrl(EnumPublicPagesImage.OnePlatformBuyer),
    contentOrder: 'textThenImage',
    alt: 'Illustration of integrated messaging on Spona',
  },
  {
    id: 'quick-updates',
    title: 'Easy Updates',
    content:
      'Our automated systems for project progress, task tracking and payments execution complement traditional communications.',
    image: getPublicImageUrl(EnumPublicPagesImage.QuickUpdatesBuyer),
    contentOrder: 'imageThenText',
    alt: 'Illustration of automated system for project progress on Spona',
  },
  {
    id: 'accessible-support',
    title: 'Accessible Support',
    content:
      'Reduce your waiting times and get solutions right away by messaging our support team at Spona.',
    image: getPublicImageUrl(EnumPublicPagesImage.AccessibleSupportBuyer),
    contentOrder: 'textThenImage',
    alt: 'Support chat illustration od Spona',
  },
  {
    id: 'record-activities',
    title: 'Record Your Activities',
    content:
      'It’s easy for data to get lost when you’re using multiple sources of communication. Spona provides you with a quick solution - a digital record of all your messages, always accessible through your platform.',
    image: getPublicImageUrl(EnumPublicPagesImage.RecordYourActivitiesBuyer),
    contentOrder: 'imageThenText',
    alt: 'Illustration of the digital record of Spona user’s data',
  },
];

export const textsAndImagesMonitorYourKPIs: TextAndImageProps[] = [
  {
    id: 'make-data-driven-decisions',
    title: 'Make Data-Driven Decisions',
    content:
      'Get detailed, user-friendly reports that let you see exactly how you’re spending your money so that you can make smart business moves.',
    image: getPublicImageUrl(EnumPublicPagesImage.MakeDataDrivenDecisionsBuyer),
    contentOrder: 'textThenImage',
    alt: 'Illustration of a report on Spona',
  },
  {
    id: 'track-your-metrics',
    title: 'Track Your Key Metrics',
    content:
      'From schedule, scope to expense tracking, Spona project reports give you an overview into all of your project essentials.',
    image: getPublicImageUrl(EnumPublicPagesImage.TrackYourMetricsBuyer),
    contentOrder: 'imageThenText',
    alt: 'Calendar illustration on Spona',
  },
  {
    id: 'clear-communication',
    title: 'Clear Communication',
    content:
      'Stay aligned with your stakeholders, your internal team and your seller while cutting down on meetings, phone calls and emails.',
    image: getPublicImageUrl(EnumPublicPagesImage.ClearCommunicationBuyer),
    contentOrder: 'textThenImage',
    alt: 'Illustration of the connection between the internam team and other stakeholders',
  },
  {
    id: 'consolidate-projects',
    title: 'Consolidate Your Projects',
    content:
      'Get all your project data in one place. Get a detailed overview of all activities on one project, or compare different projects (across one or multiple agencies) to gain powerful insights.',
    image: getPublicImageUrl(EnumPublicPagesImage.ConsolidateYourProjectsBuyer),
    contentOrder: 'imageThenText',
    alt: 'Illustration of the data overview on Spona',
  },
  {
    id: 'reduce-data-micromanagement',
    title: 'Reduce Data Micromanagement',
    content:
      'Instead of managing multiple data sources, view all your key insights on one platform. Export your reports to easily share them with your team and external partners. ',
    image: getPublicImageUrl(EnumPublicPagesImage.ReduceDataMicromanagementBuyer),
    contentOrder: 'textThenImage',
    alt: 'Illustration of  reviewing key insights on Spona',
  },
];
