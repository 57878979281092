export const LAYOUT_DEFAULTS = {
  mx: {
    xs: `${24}px !important`,
    sm: `${40}px !important`,
    lg: `${80}px !important`,
    xxl: `${320}px !important`,
  },
};

export const PADDINGS = {
  XS: 2,
  SM: 4,
  MD: 6,
  LG: 12,
  XL: 20,
};
