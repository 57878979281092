import { Typography } from '@mui/material';
import Link from '../../../../components/link/Link';

const SignInLink = ({ disabled = false }: { disabled?: boolean }) => (
  <Typography
    sx={{
      alignSelf: 'center',
      width: { xs: 'fit-content', lg: '100%' },
      fontSize: '14px',
    }}
  >
    Already have an account?{' '}
    {disabled ? (
      'Sign In'
    ) : (
      <Link to="/login" color="secondary.main" sx={{ fontWeight: 'bold' }}>
        Sign In
      </Link>
    )}
  </Typography>
);

export default SignInLink;
