import React from 'react';
import { Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { secondaryBlue } from '../../../../utils/color';
import { ReactComponent as FlagIcon } from '../../../../assets/icons/chat-icons/flag-fill.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/icons/chat-icons/link.svg';
import Link from '../../../../components/link/Link';
import FramedIcon from '../../FramedIcon';

type MilestoneFlagSpecialProps = {
  entity: string;
  created: string;
  flaggedBy: string;
  link: string;
};

export default ({ entity, created, link, flaggedBy }: MilestoneFlagSpecialProps) => (
  <Stack gap={3} alignItems="flex-start">
    <Link to={link} target="_blank">
      <Stack alignItems="center" gap={3}>
        <Stack direction="row" alignItems="center" gap={3}>
          <Stack direction="row" alignItems="center" gap={1.5}>
            <FramedIcon Icon={FlagIcon} variant="pink" />
            <Typography variant="heading4" color="primaryDark.600">
              {entity} flagged
            </Typography>
          </Stack>
          <LinkIcon fill={secondaryBlue[700]} style={{ width: 16, height: 16 }} />
        </Stack>
      </Stack>
    </Link>
    <Stack direction="row" gap={3}>
      <Stack>
        <Typography variant="heading5" color="primaryDark.400">
          date
        </Typography>
        <Typography variant="bodyMedium3" color="primaryDark.600">
          {format(new Date(created), 'PPPP')}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="heading5" color="primaryDark.400">
          flagged by
        </Typography>
        <Typography variant="bodyMedium3" color="primaryDark.600">
          {flaggedBy}
        </Typography>
      </Stack>
    </Stack>
  </Stack>
);
