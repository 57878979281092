import { Box } from '@mui/system';
import { FormProvider, useForm } from 'react-hook-form';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { Dispatch, SetStateAction } from 'react';
import { EnumTechTool } from 'tdc-web-backend/enums/enums';
import ConfirmModal from '../../../../../../../components/confirm-modal/ConfirmModal';
import useUpdate from '../../../../../../../utils/hooks/crud-hooks/useUpdate';
import { ReactComponent as SuitcaseCheckIcon } from '../../../../../../../assets/icons/layout-icons/SuitcaseCheckIcon.svg';
import { primaryLight } from '../../../../../../../utils/color';
import { useRefresh } from '../../../../../../../utils/hooks/crud-hooks/useRefresh';
import { mapTechTool } from '../../../../../../../utils/enums';
import CustomInput from '../../../../../../../components/custom-input/CustomInput';
import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';

interface CompanyTechToolsEditModalProps {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CompanyTechToolsEditModal = ({
  isEditModalOpen,
  setIsEditModalOpen,
}: CompanyTechToolsEditModalProps) => {
  const { companyData } = useSellerHomescreenContext();
  const refresh = useRefresh();

  const methods = useForm<Pick<CompanyDto, 'techTools'>>({
    mode: 'onChange',
    defaultValues: {
      techTools: companyData?.techTools ?? ([] as EnumTechTool[]),
    },
  });

  const { mutate: update, isLoading } = useUpdate<CompanyDto, Partial<CompanyDto>>({
    resource: 'companies',
  });

  const onSubmit = (data: Pick<CompanyDto, 'techTools'>) => {
    update(
      { id: companyData?.id as string, data },
      {
        onSuccess: () => {
          refresh();
          setIsEditModalOpen(false);
        },
      },
    );
  };

  return (
    <ConfirmModal
      width="40%"
      title="Tech tools"
      onCancel={() => setIsEditModalOpen(false)}
      onConfirm={methods.handleSubmit(onSubmit)}
      isModalOpen={isEditModalOpen}
      setIsModalOpen={setIsEditModalOpen}
      confirmButtonText="Save"
      automaticallyCloseModalOnButtonClick={false}
      isLoading={isLoading}
      iconProps={{
        icon: <SuitcaseCheckIcon fill={primaryLight[500]} />,
        wrapperBackgroundColor: primaryLight[100],
      }}
      message={
        <FormProvider {...methods}>
          <Box component="form">
            <CustomInput
              input={<CustomInput.Autocomplete />}
              choices={mapTechTool}
              name="techTools"
              label="Tech tools"
              multiple
              placeholder="Select tech tools"
              sx={{ width: '100%' }}
            />
          </Box>
        </FormProvider>
      }
    />
  );
};
export default CompanyTechToolsEditModal;
