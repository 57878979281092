import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../components/button/CustomButton';
import { LAYOUT_DEFAULTS } from '../../../utils/constants';
import { PUBLIC_APPBAR_HEIGHT } from '../../../layout/public-layout/navigation/Navigation';
import useAuth from '../../../utils/hooks/useAuth';

const HomepageHero = () => {
  const {
    authData: { accessToken },
  } = useAuth();

  const hasUser = accessToken !== null;
  const theme = useTheme();
  const navigate = useNavigate();
  const isLessThanMedium = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack
      spacing={3}
      justifyContent="center"
      alignItems="center"
      sx={{
        ...LAYOUT_DEFAULTS,
        marginTop: `${PUBLIC_APPBAR_HEIGHT + 40}px`,
        textAlign: 'center',
        mb: '100px',
      }}
    >
      <Typography variant="publicBody2" sx={{ color: 'primaryLight.500' }}>
        Keep it together
      </Typography>
      {/* the keep it together text */}
      <Stack justifyContent="center" alignItems="center" width="fit-content">
        <Typography
          display={{ xs: 'grid', md: 'grid' }}
          sx={{
            typography: { xs: 'publicHeadline7', md: 'publicHeadline7', lg: 'publicHeadline7' },
            fontSize: { xs: '2.9rem', md: '4rem', lg: '4rem' },
            lineHeight: '106%',
          }}
        >
          Your Pre-Assembled Project{' '}
          <Typography
            component="span"
            sx={{
              position: 'relative',
              typography: { xs: 'publicHeadline7', md: 'publicHeadline7', lg: 'publicHeadline7' },
              fontSize: { xs: '2.9rem', md: '4rem', lg: '4rem' },
              lineHeight: '106%',
            }}
          >
            and Supplier Management Tool
            <Box
              sx={{
                backgroundColor: 'primaryLight.500',
                width: '100%',
                height: isLessThanMedium ? '0.5rem' : '1rem',
                borderRadius: '7rem',
                position: 'absolute',
                bottom: { xs: '-15px', md: '-20px' },
                left: 0,
                zIndex: -1,
              }}
            />
          </Typography>
        </Typography>
      </Stack>

      <Stack
        spacing={{ md: 8 }}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ pt: '1.5rem' }}
      >
        {/* about us text & company icons */}
        <Stack spacing={6} maxWidth={{ xs: '100%', xl: '60%' }}>
          <Stack spacing={4} alignItems="center">
            <Typography variant="publicBody3Regular" sx={{ zIndex: 9 }}>
              Forget complex setups. With Spona, you can manage projects and suppliers instantly -
              simple, effective and all in one place.
            </Typography>

            {!hasUser && (
              <CustomButton
                variant="primary"
                withNextArrow
                sx={{
                  width: { xs: '55%', md: '45%' },
                  py: { xs: '18px' },
                }}
                onClick={() => navigate('/register')}
              >
                Get started
              </CustomButton>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HomepageHero;
