import { Box, Card, Divider, LinearProgress, Stack, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { secondaryBlue } from '../../utils/color';
import Pagination from '../pagination/Pagination';
import CustomNoRowsOverlay from '../custom-no-rows-overlay/CustomNoRowsOverlay';
import { TableProps } from './interface';

const tableStyles = {
  '.MuiDataGrid-virtualScrollerRenderZone': {
    width: '100%',
  },
  '.MuiDataGrid-row': {
    border: '2px solid transparent',
    width: '100%',
    borderBottom: '1px solid',
    borderBottomColor: 'primaryDark.150',
    '&:hover': {
      boxShadow: 'none',
      background: secondaryBlue[50],
    },
  },
  '.Mui-selected': {
    border: '2px solid',
    borderColor: 'secondaryBlue.200',
    backgroundColor: `${secondaryBlue[50]} !important`,
    '&>*:nth-child(2)>*': {
      color: 'primaryLight.500',
    },
  },
};

const cardStyles = {
  overflow: 'visible',
  p: 0.5,
  px: 2,
  backgroundColor: 'common.white',
  border: '1px solid',
  borderColor: 'secondaryBlue.100',
  borderRadius: 2,
};

const BaseTable = ({
  rows,
  columns,
  height = '580px',
  sx,
  withElements = { withTitle: 'Table name' },
  components,
  totalRowsCount,
  setOffset,
  currentPage,
  setCurrentPage,
  baseUrl,
  numberOfRowsPerPage,
  loading,
  ...props
}: TableProps) => {
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);

  return (
    <Card elevation={0} sx={cardStyles}>
      <Stack sx={{ overflow: 'hidden', bgcolor: 'white', p: 0 }}>
        {/* additional elements: title, cta,... */}
        <Stack alignItems="center" direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          {withElements.withTitle && (
            <Typography variant="heading4" color="primaryDark.500">
              {withElements.withTitle}
            </Typography>
          )}

          {withElements.withCta && withElements.withCta}
        </Stack>

        <Divider sx={{ borderColor: 'primaryDark.250', m: 0 }} />

        {withElements.withTabs && withElements.withTabs}

        {/* data grid */}
        <Box sx={{ position: 'relative' }}>
          <DataGrid
            key={currentPage}
            loading={loading}
            pageSize={numberOfRowsPerPage}
            disableSelectionOnClick
            // TODO: implement
            // checkboxSelection
            // onFilterModelChange={onFilterChange}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                pageSize: numberOfRowsPerPage,
              },
            }}
            // hideFooter
            components={{
              LoadingOverlay: () => (
                <LinearProgress
                  sx={{
                    backgroundColor: 'secondaryBlue.200',
                    '& .MuiLinearProgress-bar': { backgroundColor: 'secondaryBlue.100' },
                  }}
                />
              ),
              Pagination: () => (
                <Pagination
                  totalRowsCount={totalRowsCount}
                  numberOfRowsPerPage={numberOfRowsPerPage}
                  setOffset={setOffset}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  // setCurrentPage={() => {
                  //   setCurrentPage
                  // }}
                  sx={{
                    py: 1.5,
                    mb: 2.5,
                    borderTop: '1px solid',
                    borderColor: 'primaryDark.250',
                  }}
                />
              ),
              // TODO: implement
              // Toolbar: TableToolbar,
              // FilterPanel: (data) => {
              //   console.log(data);
              //   return <p>123</p>;
              // },
              ...components,
            }}
            sx={{
              border: 'none',
              '& .MuiDataGrid-main': {
                height,
              },
              ...tableStyles,
              ...sx,
            }}
            componentsProps={
              {
                // TODO: implement
                // panel: {
                //   anchorEl: filterButtonEl,
                // },
                // toolbar: {
                //   setFilterButtonEl,
                // },
                // filterPanel: {
                //   filterFormProps: {
                //     // Customize inputs by passing props
                //     linkOperatorInputProps: {
                //       variant: 'outlined',
                //       size: 'small',
                //     },
                //     columnInputProps: {
                //       variant: 'outlined',
                //       size: 'small',
                //       sx: { mt: 'auto' },
                //     },
                //     operatorInputProps: {
                //       variant: 'outlined',
                //       size: 'small',
                //       sx: { mt: 'auto' },
                //     },
                //     valueInputProps: {
                //       InputComponentProps: {
                //         variant: 'outlined',
                //         size: 'small',
                //       },
                //     },
                //     deleteIconProps: {
                //       sx: {
                //         '& .MuiSvgIcon-root': { color: '#d32f2f' },
                //       },
                //     },
                //   },
                //   sx: {
                //     // Customize inputs using css selectors
                //     '& .MuiDataGrid-filterFormOperatorInput': { display: 'none' },
                //     '.MuiDataGrid-panel .MuiPaper-root': { backgroundColor: 'red' },
                //   },
                // },
              }
            }
            // disableVirtualization
            {...props}
          />

          {!rows?.length && !loading && <CustomNoRowsOverlay type="list" />}
        </Box>
      </Stack>
    </Card>
  );
};

export default BaseTable;
