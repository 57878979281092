import { FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { MembershipRole } from 'tdc-web-backend/enums/enums';
import { primaryDark, primaryLight } from '../../../../../../utils/color';
import { ReactComponent as RadioIcon } from '../../../../../../assets/icons/radio.svg';
import { ReactComponent as RadioCheckIcon } from '../../../../../../assets/icons/radio-check.svg';

const MembershipRoles = ({ disabled = false }: { disabled?: boolean }) => {
  const form = useFormContext();

  return (
    <Stack spacing={1.5}>
      <Typography variant="heading3" color="primaryDark.500">
        Role
      </Typography>
      <RadioGroup
        aria-labelledby="role-options"
        name="membershipRole"
        value={form.watch('membershipRole')}
        onChange={(_, newVisibility) => {
          if (newVisibility !== null) {
            form.setValue('membershipRole', newVisibility as MembershipRole);
          }
        }}
        sx={{
          flexDirection: 'column',
        }}
      >
        <Stack
          sx={{
            p: '0.5rem 0.75rem',
            borderRadius: '0.5rem',
            border: `1px solid ${primaryDark[200]}`,
          }}
        >
          <FormControlLabel
            value={MembershipRole.Editor}
            control={<Radio icon={<RadioIcon />} checkedIcon={<RadioCheckIcon />} />}
            label="Editor"
            sx={{
              '& .Mui-checked': {
                color: primaryLight[500],
              },
            }}
            componentsProps={{ typography: { variant: 'bodySemiBold2', pl: '15px' } }}
            disabled={disabled}
          />
          <Typography variant="body3" sx={{ pl: 5 }}>
            An Admin has full authority on the platform. They can add, edit, delete, and view
            content, projects, and other users.
          </Typography>
        </Stack>
        <Stack
          sx={{
            p: '0.5rem 0.75rem',
            borderRadius: '0.5rem',
            border: `1px solid ${primaryDark[200]}`,
            mt: 1.5,
          }}
        >
          <FormControlLabel
            value={MembershipRole.Admin}
            control={<Radio icon={<RadioIcon />} checkedIcon={<RadioCheckIcon />} />}
            label="Admin"
            sx={{
              '& .Mui-checked': {
                color: primaryLight[500],
              },
            }}
            componentsProps={{ typography: { variant: 'bodySemiBold2', pl: '15px' } }}
            disabled={disabled}
          />
          <Typography variant="body3" sx={{ pl: 5 }}>
            An Editor can work on projects like an Admin but cannot access or modify profile
            settings.
          </Typography>
        </Stack>
      </RadioGroup>
    </Stack>
  );
};

export default MembershipRoles;
