import { Stack, Typography } from '@mui/material';
import CustomButton from '../../../../components/button/CustomButton';
import { secondaryBlue, secondaryYellow } from '../../../../utils/color';
import CustomModal from '../../../../components/modal/CustomModal';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning.svg';
import { ReactComponent as PencilIcon } from '../../../../assets/icons/pencil.svg';

type ModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  handleSubmitCreate: () => void;
  setIsOpen: (value: boolean) => void;
  isAnnex: boolean;
};

const ContractUpdateModal = ({
  isLoading,
  isOpen,
  setIsOpen,
  handleSubmitCreate,
  isAnnex,
}: ModalProps) => (
  <CustomModal
    open={isOpen}
    onClose={() => {
      setIsOpen(false);
    }}
  >
    <Stack direction="column">
      <Stack direction="row" alignItems="center" mb={3}>
        {isAnnex ? (
          <Stack
            width={40}
            height={40}
            mr={2}
            bgcolor="secondaryBlue.100"
            borderRadius={1}
            justifyContent="center"
            alignItems="center"
          >
            <PencilIcon width="20px" fill={secondaryBlue[700]} />
          </Stack>
        ) : (
          <Stack
            width={40}
            height={40}
            mr={2}
            bgcolor="secondaryYellow.100"
            borderRadius={1}
            justifyContent="center"
            alignItems="center"
          >
            <WarningIcon width="20px" fill={secondaryYellow[700]} />
          </Stack>
        )}
        <Typography variant="heading4" color="primaryDark.600">
          {isAnnex ? 'Ammend contract' : 'Change requested'}
        </Typography>
      </Stack>
      <Stack
        py={2}
        sx={{
          borderTop: `1px solid ${secondaryBlue[100]}`,
          borderBottom: `1px solid ${secondaryBlue[100]}`,
        }}
      >
        <Typography color="primaryDark.500" variant="body2">
          {isAnnex
            ? 'Any edits to the contract must be made through an annex. Changes must be formally approved by both parties.'
            : 'The contract will be updated when the other party confirms the changes.'}
        </Typography>
      </Stack>
      <Stack mt={3} direction="row" gap={3} justifyContent="flex-end">
        <CustomButton onClick={handleSubmitCreate} loading={isLoading} variant="primary">
          Continue
        </CustomButton>
      </Stack>
    </Stack>
  </CustomModal>
);

export default ContractUpdateModal;
