import { Stack, Typography } from '@mui/material';
import AccordionGroups, {
  MyAccordionProps,
} from '../../../../components/public-screen-components/accordion-groups/AccordionGroups';
import CustomButton from '../../../../components/button/CustomButton';
import { primaryLight } from '../../../../utils/color';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/project-icons/ArrowRightIcon.svg';

export const accordianProps: MyAccordionProps[] = [
  {
    summary: 'Is there a limit to the number of projects we can manage through Spona?',
    details:
      'No, there are no limits to projects, milestones, or tasks that can be managed through Spona.',
  },
  {
    summary: 'What happens when my trial expires?',
    details:
      'Your account will be downgraded to the free version until you decide whether to continue to a paid subscription or not. A Spona representative will be able to talk you through your options.',
  },
  {
    summary: 'Which payment options do you accept?',
    details:
      'Get in touch with a Spona representative to discuss your subscription options. You can pay for monthly or annual subscriptions by credit card or bank transfer.',
  },
];

const PricingFaq = () => (
  <Stack justifyContent="space-between" direction={{ lg: 'row' }} gap={10} mt={10}>
    <Stack width="100%" gap={3}>
      <Typography variant="publicHeading3" color="primaryDark.900">
        Frequently asked questions
      </Typography>
      <Typography variant="publicBody4Regular" color="primaryDark.700" width="90%">
        Got more questions? We're here to answer them! Drop us a message any time.
      </Typography>
      <CustomButton
        variant="primaryText"
        endIcon={<ArrowRightIcon fill={primaryLight[500]} />}
        href="/contact-us"
      >
        Contact us
      </CustomButton>
    </Stack>
    <AccordionGroups group={accordianProps} />
  </Stack>
);

export default PricingFaq;
