import { IconButton, Stack, SxProps, Theme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ReactNode, useContext, useEffect } from 'react';
import NavigationDashboardContext from '../../layout/dashboard-navigation/NavigationDashboardContext';
import { shadeColor } from '../../utils/helpers';
import theme from '../../utils/theme';
import useCarouselScroll from '../../utils/hooks/useCarouselScroll';

export interface RenderCarouselItemProps<T> {
  item: T;
  sx?: SxProps<Theme> | undefined;
}

export interface CustomBackAndNextButtons {
  back: {
    handleGoBack: () => void;
    disabled: boolean;
  };
  next: {
    handleGoNext: () => void;
    disabled: boolean;
  };
}

export interface SingleSlideCarouselProps<T> {
  items: T[];
  activeIndexItem?: number;
  activeItemSx?: SxProps<Theme> | undefined;
  renderCarouselItem: ({ item, sx }: RenderCarouselItemProps<T>) => ReactNode;
  sx?: SxProps<Theme> | undefined;
  carouselItemWidth: number;
  renderCustomBackAndNextButtons?: ({ back, next }: CustomBackAndNextButtons) => ReactNode;
}

const SingleSlideCarousel = <T,>({
  items,
  activeIndexItem,
  activeItemSx,
  renderCarouselItem,
  sx,
  carouselItemWidth,
  renderCustomBackAndNextButtons,
}: SingleSlideCarouselProps<T>) => {
  const {
    carouselRef,
    scrollAmount,
    isFullyScrolled,
    canAllItemsFit,
    scrollToIndex,
    scrollPrev,
    scrollNext,
  } = useCarouselScroll({
    itemsCount: items.length,
    itemWidth: carouselItemWidth,
  });

  const currentNavigationDashboardWidth = useContext(NavigationDashboardContext);

  useEffect(() => {
    if (activeIndexItem !== undefined) {
      scrollToIndex(activeIndexItem);
    }
  }, [activeIndexItem, scrollToIndex]);

  return (
    <Stack
      ref={carouselRef}
      spacing={2}
      width={`calc(100vw - ${currentNavigationDashboardWidth}px - var(--scrollbar-width))`}
      flexWrap="nowrap"
      direction="row"
      sx={{
        overflowX: 'hidden',
        overflowY: 'visible',
        ...sx,
      }}
    >
      {!renderCustomBackAndNextButtons && (
        <IconButton
          onClick={() => {
            scrollPrev();
          }}
          sx={{
            visibility: scrollAmount > 0 ? 'visible' : 'hidden',
            zIndex: 4,
            position: 'absolute',
            left: 5,
            bottom: -7,
            backgroundColor: 'primaryDark.150',
            border: '1px solid',
            borderColor: 'primaryLight.100',
            width: '2.9rem',
            height: '2.9rem',
            display: canAllItemsFit ? 'none' : 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              backgroundColor: shadeColor(theme.palette.primaryDark[150], -5),
            },
          }}
        >
          <ChevronLeftIcon sx={{ color: 'primaryDark.600', fontSize: '2rem' }} />
        </IconButton>
      )}

      {items.map((item, index) =>
        renderCarouselItem({
          item,
          sx: index === activeIndexItem ? activeItemSx : undefined,
        }),
      )}

      {!renderCustomBackAndNextButtons && (
        <IconButton
          onClick={() => {
            scrollNext();
          }}
          sx={{
            visibility: isFullyScrolled ? 'hidden' : 'visible',
            position: 'absolute',
            right: 15,
            bottom: -7,
            backgroundColor: 'primaryDark.150',
            border: '1px solid',
            borderColor: 'primaryLight.100',
            width: '2.9rem',
            height: '2.9rem',
            display: canAllItemsFit ? 'none' : 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              backgroundColor: shadeColor(theme.palette.primaryDark[150], -5),
            },
          }}
        >
          <ChevronRightIcon sx={{ color: 'primaryDark.600', fontSize: '2rem' }} />
        </IconButton>
      )}

      <Stack sx={{ position: 'absolute', right: 15, bottom: -10 }}>
        {!canAllItemsFit &&
          renderCustomBackAndNextButtons &&
          renderCustomBackAndNextButtons({
            back: {
              handleGoBack: () => scrollPrev(),
              disabled: !(scrollAmount > 0),
            },
            next: {
              handleGoNext: () => scrollNext(),
              disabled: isFullyScrolled,
            },
          })}
      </Stack>
    </Stack>
  );
};

export default SingleSlideCarousel;
