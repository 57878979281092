import { Divider, IconButton, Stack, SxProps, Theme, Typography } from '@mui/material';
import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { ReactComponent as PencilIcon } from '../../../../../assets/icons/pencil-2.svg';
import { primaryLight } from '../../../../../utils/color';

interface CompanyDetailSubsectionProps {
  title: string;
  content:
    | ((
        isEditModalOpen: boolean,
        setIsEditModalOpen: Dispatch<SetStateAction<boolean>>,
      ) => ReactNode)
    | ReactNode;
  actionButton?: ReactNode | string;
  sx?: SxProps<Theme>;
}

export const EditIcon = ({ onClick }: { onClick?: () => void }) => (
  <IconButton onClick={onClick}>
    <PencilIcon fill={primaryLight[500]} style={{ width: 18, height: 18 }} />
  </IconButton>
);

const CompanyDetailSubsectionItem = ({
  title,
  content,
  actionButton = <EditIcon />,
  sx,
}: CompanyDetailSubsectionProps) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  return (
    <Stack spacing={1} sx={{ width: '100%' }}>
      <Stack spacing={0.5} sx={{ width: '100%' }}>
        {/* title & actionButton row */}
        <Stack direction="row" justifyContent="space-between" alignItems="center" height={34}>
          <Typography variant="heading4" color="primaryDark.500">
            {title}
          </Typography>

          <div onClick={() => setIsEditModalOpen(!isEditModalOpen)}>{actionButton}</div>
        </Stack>

        <Divider />
      </Stack>

      {typeof content === 'function' ? content(isEditModalOpen, setIsEditModalOpen) : content}
    </Stack>
  );
};

export default CompanyDetailSubsectionItem;
