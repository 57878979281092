import { Stack, useMediaQuery } from '@mui/material';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { useRef, useState } from 'react';
import YouAreYouGet from './components/YouAreYouGet';
import SponaOptions from '../../../components/public-screen-components/SponaOptions';
import ReusableHeroSection from '../../../components/public-screen-components/ReusableHeroSection';
import { primaryLight } from '../../../utils/color';
import CustomModal from '../../../components/modal/CustomModal';
import BecomeAPartOfSpona from '../../../components/public-screen-components/BecomeAPartOfSpona';
import OurPartners from './components/OurPartners';
import CustomButton from '../../../components/button/CustomButton';
import Testimonials from '../../../components/public-screen-components/Testimonials';
import testimonialsArray from './utils';
import { PUBLIC_APPBAR_HEIGHT } from '../../../layout/public-layout/navigation/Navigation';
import MetaTags from '../../../components/seo/MetaTags';
import { getPublicImageUrl } from '../../../utils/helpers';
import { EnumPublicPagesImage } from '../../../utils/enums';
import Partners from './components/Partners';
import SponaMediaContactForm from './components/SponaMediaContactForm/SponaMediaContactForm';
import PricingPlans from './components/PricingPlans/PricingPlans';

const metaTags = {
  title: `Spona - Grow your Advertising with Spona Media`,
  description: `Get unique advertising solutions for Google, Meta, TikTok, Twitter and Snapchat. Fast and Easy handeling. Grow your advertising today. Find out how.`,
};

const SponaMedia = () => {
  const [openModal, setOpenModal] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const isScreenHeightMoreThan1200 = useMediaQuery('(min-height:1200px)');
  const isScreenWidthMoreThan600 = useMediaQuery('(min-width:600px)');
  const isScreenWidthMoreThan1490 = useMediaQuery('(min-width:1490px)');
  const ref = useRef<HTMLDivElement>(null);

  const CalendlyModal = ({ openModal }: { openModal: boolean }) => (
    <CustomModal
      open={openModal}
      width="auto"
      paperStyleProps={{ p: { xs: 0, lg: '2rem' } }}
      sx={{ alignItems: 'end' }}
      onClose={() => setOpenModal(false)}
    >
      <>
        <InlineWidget
          styles={{
            height: isScreenHeightMoreThan1200 ? '600px' : '480px',
            width: isScreenWidthMoreThan1490
              ? '490px'
              : isScreenWidthMoreThan600
              ? '380px'
              : '310px',
          }}
          url={process.env.REACT_APP_CALENDLY_URL as string}
        />
        {showButton && (
          <CustomButton
            onClick={() => {
              setOpenModal(false);
              setShowButton(false);
            }}
          >
            Close
          </CustomButton>
        )}
      </>
    </CustomModal>
  );

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      setShowButton(true);
    },
  });

  return (
    <>
      <MetaTags {...metaTags} />
      <Stack overflow="hidden" width="100%">
        {openModal && <CalendlyModal openModal={openModal} />}

        <ReusableHeroSection
          title="Grow Your Advertising with agency ad account solutions!"
          content="Looking to enhance your social media advertising with agency ad account solutions? We provide unique agency ad accounts for Google, Snapchat, Twitter, TikTok, and Facebook, offering tailored advertising solutions that fit your needs and boost your campaigns."
          image={getPublicImageUrl(EnumPublicPagesImage.SponaMediaHeroAds)}
          alt="A person smiling and looking on a side while working on his computer."
          contentButton={{
            text: 'Contact us',
            color: '#f9f9f9',
            onClick: () => ref.current?.scrollIntoView({ behavior: 'smooth' }),
            withArrowRightIcon: true,
            variant: 'primary',
            sx: { width: 'fit-content' },
          }}
          sx={{ height: { xs: `calc(89vh + ${PUBLIC_APPBAR_HEIGHT}px)`, lg: '87vh' } }}
          titleSx={{
            fontSize: '63px !important',
          }}
        />

        <Partners />
        <SponaOptions
          title="How does it work?"
          optionsProps={[
            {
              id: 'rapid-business-growth',
              title: 'Rapid business growth',
              content:
                'Get access to exclusive agency ad accounts from day one and scale your spending as your business grows. With a minimal suspension rate, the Spona team ensures that your content meets all platform requirements, allowing you to focus on creativity without the stress of compliance issues.',
              image: getPublicImageUrl(EnumPublicPagesImage.SponaMediaHdiwMeta),
              alt: 'Drawing of a growing chart with blurred people at the workplace in the background.',
            },
            {
              id: 'comprehensive-support',
              title: 'Comprehensive support',
              content:
                'Spona Media clients benefit from full support from our dedicated team. Our expert media buying team provides personalized campaign suggestions to help you scale effectively, ensuring you make the most of the tools and platforms we partner with. Plus, you can enjoy professional support and solutions for any challenges along the way on partnered channels.',
              image: getPublicImageUrl(EnumPublicPagesImage.SponaMediaHdiwMeta),
              alt: 'Drawing of a growing chart with blurred people at the workplace in the background.',
            },
            {
              id: 'hassle-free-payments',
              title: 'Hassle-free payments',
              content:
                "Low-cost bank transfers let you fully focus on your business, without having to worry about credit card fees and other financial burdens. We also support payments in cryptocurrencies, so you can benefit from Spona Media whether you're a Web2 or Web3 business.",
              image: getPublicImageUrl(EnumPublicPagesImage.SponaMediaHdiwMeta),
              alt: 'Drawing of a growing chart with blurred people at the workplace in the background.',
            },
          ]}
          sx={{ mt: { xs: '0px', lg: '0px' }, mb: { xs: 3, md: 10 } }}
        />

        <YouAreYouGet />

        <PricingPlans />

        <SponaMediaContactForm formRef={ref} />

        <OurPartners />
      </Stack>
    </>
  );
};
export default SponaMedia;
