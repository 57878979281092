import { Chip, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { CalendarEventDto, UpdateAttendanceDto } from 'tdc-web-backend/events/schemas';
import { secondaryBlue } from '../../../../utils/color';
import { ReactComponent as MeetingIcon } from '../../../../assets/icons/chat-icons/3user.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/icons/chat-icons/link.svg';
import { ReactComponent as MeetingUnknown } from '../../../../assets/icons/chat-icons/meeting-unknown.svg';
import { ReactComponent as MeetingAccepted } from '../../../../assets/icons/chat-icons/meeting-accepted.svg';
import { ReactComponent as MeetingDeclined } from '../../../../assets/icons/chat-icons/meeting-declined.svg';
import FramedIcon from '../../FramedIcon';
import useGetOne from '../../../../utils/hooks/crud-hooks/useGetOne';
import CustomButton from '../../../../components/button/CustomButton';
import useUpdate from '../../../../utils/hooks/crud-hooks/useUpdate';
import ClipboardButton from '../../../../components/public-screen-components/ClipboardButton';

type MeetingSpecialProps = {
  title: string;
  eventId: string;
  buttons: boolean;
};

export default ({ title, eventId, buttons }: MeetingSpecialProps) => {
  const eventResponse = useGetOne<CalendarEventDto>({
    resource: `events/${eventId}`,
  });
  const event = eventResponse.data?.data;

  const { mutate: updateAttendance } = useUpdate<unknown, UpdateAttendanceDto>({
    resource: `/events`,
  });

  return event ? (
    <Stack gap={3} alignItems="flex-start">
      <Stack alignItems="center" gap={3}>
        <Stack direction="row" alignItems="center" gap={3}>
          <Stack direction="row" alignItems="center" gap={1.5}>
            <FramedIcon Icon={MeetingIcon} variant="blue" />
            <Typography variant="heading4" color="primaryDark.600">
              {title}
            </Typography>
          </Stack>
          {event?.link && (
            <a href={event.link} target="_blank" rel="noreferrer">
              <LinkIcon
                fill={secondaryBlue[700]}
                style={{
                  width: 16,
                  height: 16,
                }}
              />
            </a>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" gap={1}>
        <ClipboardButton text={event.link || ''} />
      </Stack>
      <Typography variant="bodyMedium2" color="primaryDark.600">
        {event.name}
      </Typography>
      <Stack direction="row" gap={3}>
        <Stack>
          <Typography variant="heading5" color="primaryDark.400">
            time
          </Typography>
          <Typography variant="bodyMedium3" color="primaryDark.600">
            {format(new Date(event?.start), 'p')} - {format(new Date(event?.end), 'p')}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="heading5" color="primaryDark.400">
            date
          </Typography>
          <Typography variant="bodyMedium3" color="primaryDark.600">
            {format(new Date(event?.start), 'PPPP')}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="heading5" color="primaryDark.400">
            created by
          </Typography>
          <Typography variant="bodyMedium3" color="primaryDark.600">
            {event.owner?.firstName} {event.owner?.lastName}
          </Typography>
        </Stack>
      </Stack>
      <Typography variant="body3" color="primaryDark.600">
        {event.description}
      </Typography>
      <Stack gap={1}>
        <Typography variant="heading5" color="primaryDark.500">
          participants
        </Typography>
        <Stack direction="row" gap={1}>
          {event.attendees.map((attendee: any) => (
            <Chip
              sx={{
                backgroundColor: 'primaryDark.600',
                color: '#fff',
                fontSize: '12px',
                lineHeight: '16px',
                letterSpacing: '0.05em',
              }}
              label={
                <Stack gap={1} direction="row" justifyContent="center">
                  <Typography variant="bodySemiBold4">
                    {attendee.user.firstName} {attendee.user.lastName}
                  </Typography>
                  {attendee.attending === true ? (
                    <MeetingAccepted />
                  ) : attendee.attending === false ? (
                    <MeetingDeclined />
                  ) : (
                    <MeetingUnknown />
                  )}
                </Stack>
              }
            />
          ))}
        </Stack>
      </Stack>
      {buttons && (
        <Stack
          gap={3}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          alignSelf="stretch"
        >
          <CustomButton
            variant="secondary"
            onClick={() => {
              updateAttendance(
                {
                  id: eventId,
                  suffix: 'attendances',
                  data: { attending: false },
                },
                { onSuccess: () => eventResponse.refetch() },
              );
            }}
          >
            decline
          </CustomButton>
          <CustomButton
            variant="primary"
            onClick={() => {
              updateAttendance(
                {
                  id: eventId,
                  suffix: 'attendances',
                  data: { attending: true },
                },
                { onSuccess: () => eventResponse.refetch() },
              );
            }}
          >
            accept
          </CustomButton>
        </Stack>
      )}
    </Stack>
  ) : null;
};
