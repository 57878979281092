import { Box, SxProps, Theme, Typography } from '@mui/material';
import Stack from '@mui/material/Stack/Stack';
import { useState } from 'react';
import { primaryLight } from '../../utils/color';
import { LAYOUT_DEFAULTS } from '../../utils/constants';

export interface SponaOptionsProps {
  title: string;
  optionsProps: { id: string; title: string; content: string; image: string; alt?: string }[];
  clickableOptionBorderLeftColor?: string;
  sx?: SxProps<Theme>;
}

export interface ClickableOptionProps {
  title: string;
  content: string;
  isClicked: boolean;
  onClick: () => void;
  borderLeftColor?: string;
}

const ClickableOption = ({
  title,
  content,
  isClicked,
  onClick,
  borderLeftColor,
}: ClickableOptionProps) => (
  <Stack
    spacing={3}
    sx={{
      borderLeft: isClicked ? `4px solid ${borderLeftColor}` : '4px solid transparent',
      mb: '3rem',
      pl: '1rem',
      opacity: isClicked ? '1' : '0.3',
      cursor: 'pointer',
    }}
    onClick={onClick}
  >
    <Typography variant="publicHeading4">{title}</Typography>

    <Typography variant="publicBody4Regular" color="common.black">
      {content}
    </Typography>
  </Stack>
);

const SponaOptions = ({
  title,
  optionsProps,
  clickableOptionBorderLeftColor = primaryLight[500],
  sx,
}: SponaOptionsProps) => {
  const [index, setIndex] = useState(0);

  return (
    <Stack
      spacing={5}
      justifyContent="center"
      alignItems="start"
      sx={{ ...LAYOUT_DEFAULTS, my: 10, ...sx }}
    >
      <Typography sx={{ typography: { xs: 'publicHeading4', lg: 'publicHeading3' } }}>
        {title}
      </Typography>

      <Stack spacing={16} alignItems="center" direction={{ xs: 'column', lg: 'row' }}>
        <Stack>
          {optionsProps.map(({ title, content, id }, idx: number) => (
            <ClickableOption
              key={id}
              title={title}
              content={content}
              isClicked={idx === index}
              onClick={() => setIndex(idx)}
              borderLeftColor={clickableOptionBorderLeftColor}
            />
          ))}
        </Stack>

        <Box
          alt={optionsProps[index].alt}
          key={optionsProps[index].image}
          component="img"
          src={optionsProps[index].image}
          sx={{ width: '35%', display: { xs: 'none', lg: 'block' } }}
        />
      </Stack>
    </Stack>
  );
};

export default SponaOptions;
