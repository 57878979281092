import { Card, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import theme from '../../../../utils/theme';
import { shadeColor } from '../../../../utils/helpers';
import { CalendarEventDto } from 'tdc-web-backend/events/schemas';
import { Box } from '@mui/system';
import CustomTooltip from '../../../../components/tooltip/CustomTooltip';

export interface SidebarCalendarItemProps {
  dayName: string;
  dateNumber: number;
  onClick: (currentDate: Date) => void;
  todayDate: Date;
  occurringEvents: CalendarEventDto[];
  currentDate: Date;
}

const SidebarCalendarItem = ({
  dayName,
  dateNumber,
  onClick,
  todayDate,
  currentDate,
  occurringEvents,
}: SidebarCalendarItemProps) => {
  const [isActive] = useState(false);

  const getTooltipText = () => {
    return !!occurringEvents?.length
      ? occurringEvents?.length === 1
        ? '1 event in calendar'
        : `${occurringEvents?.length} events in calendar`
      : '';
  };

  return (
    <CustomTooltip title={getTooltipText()}>
      <Stack alignItems={'center'} gap={0.5}>
        <Card
          elevation={0}
          sx={{
            overflow: 'visible',
            borderRadius: 1,
            // px: 0.5,
            // width: '2.1rem',
            backgroundColor: 'primaryDark.200',
            opacity: 1,
            ':last-child': {
              paddingBottom: '0',
            },
          }}
        >
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={2.5}
          >
            <Typography color="primaryDark.500" variant="heading5" fontWeight="600">
              {dayName}
            </Typography>

            <IconButton
              onClick={() => onClick(currentDate)}
              sx={{
                width: { md: 38, xl: 48 },
                height: { md: 38, xl: 48 },
                backgroundColor:
                  todayDate.toString().substring(0, 15) === currentDate.toString().substring(0, 15)
                    ? 'primaryLight.500'
                    : isActive
                    ? 'common.white'
                    : 'transparent',
                filter:
                  todayDate.toString().substring(0, 15) === currentDate.toString().substring(0, 15)
                    ? 'drop-shadow(3px 3px 12px rgba(109, 81, 246, 0.4))'
                    : 'none',
                '&:hover': {
                  backgroundColor:
                    todayDate.toString().substring(0, 15) ===
                    currentDate.toString().substring(0, 15)
                      ? `${shadeColor(theme.palette.primaryDark[500], 30)}`
                      : `${shadeColor(theme.palette.primaryLight[100], -5)}`,
                },
                '&:active': {
                  backgroundColor:
                    todayDate.toString().substring(0, 15) ===
                    currentDate.toString().substring(0, 15)
                      ? `${shadeColor(theme.palette.primaryDark[500], 30)}`
                      : `${shadeColor(theme.palette.primaryLight[200], -5)}`,
                },
              }}
            >
              <Typography
                color={
                  todayDate.toString().substring(0, 15) === currentDate.toString().substring(0, 15)
                    ? 'white'
                    : 'primaryDark.600'
                }
                variant="heading2"
              >
                {dateNumber < 10 && 0}
                {dateNumber}
              </Typography>
            </IconButton>
          </Stack>
        </Card>
        {!!occurringEvents?.length ? (
          <Box
            sx={{
              backgroundColor: 'primaryLight.500',
              borderRadius: '50%',
              width: 14,
              height: 14,
              border: '3px solid',
              borderColor: 'primaryLight.200',
            }}
          />
        ) : (
          <Box
            sx={{
              backgroundColor: 'transparent',
              borderRadius: '50%',
              width: 14,
              height: 14,
              border: '3px solid',
              borderColor: 'transparent',
            }}
          />
        )}
      </Stack>
    </CustomTooltip>
  );
};

export default SidebarCalendarItem;
