import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { BaseTimedProject } from 'tdc-web-backend/timed-projects/schemas';
import { TimedProjectContentDto } from 'tdc-web-backend/timed-project-contents/schemas';
import {
  EnumCurrency,
  EnumServiceProviderScope,
  EnumTimedProjectStatus,
} from 'tdc-web-backend/enums/enums';
import { ReactComponent as BoxArrowUpRightLeft } from '../../../../assets/icons/project-icons/BoxArrowUpRightLeft.svg';
import { ReactComponent as PencilIcon } from '../../../../assets/icons/layout-icons/PencilIcon.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/layout-icons/TrashIcon.svg';
import DateDisplayCard from '../../../../components/DateDisplayCard/DateDisplayCard';
import { formatCurrency } from '../../../../utils/helpers';
import useReferenceOne from '../../../../utils/hooks/crud-hooks/useReferenceOne';
import useGetOne from '../../../../utils/hooks/crud-hooks/useGetOne';
import { mapProviderScope } from '../../../../utils/enums';
import StatusChipField from '../../../../components/StatusChipField/StatusChipField';
import CustomDropdown from '../../../../components/custom-dropdown/CustomDropdown';
import { primaryDark } from '../../../../utils/color';
import DynamicDescriptionAndReadMoreWithModal from '../../../../components/dynamic-description-and-read-more-with-modal/DynamicDescriptionAndReadMoreWithModal';
import TimedProjectDrawer from '../../../../components/timed-project-drawer/TimedProjectDrawer';
import useDeleteOne from '../../../../utils/hooks/crud-hooks/useDeleteOne';
import useNotification from '../../../../utils/hooks/useNotification';

const TimedProjectsDetailsGeneral = () => {
  const params = useParams();
  const { notify } = useNotification();
  const navigate = useNavigate();
  const [showDrawer, setShowDrawer] = useState(false);

  const { data: timedProjectBase } = useReferenceOne<BaseTimedProject>({
    resource: 'timed-projects',
    id: params.projectId,
  });

  const { data: timedProjectContent } = useGetOne<TimedProjectContentDto>({
    resource: `timed-project-contents/${timedProjectBase?.content}`,
    enabled: !!timedProjectBase?.content,
  });

  const { mutate: deleteTimedProject } = useDeleteOne({
    resource: `timed-projects`,
  });

  const onDelete = () =>
    deleteTimedProject(
      { id: params.projectId || '' },
      {
        onSuccess: () => {
          notify.success('Timed project has been successfully deleted!');
          navigate('../');
        },
        onError: () => {
          notify.error('Something went wrong...');
        },
      },
    );

  const menu = [
    {
      key: 'edit',
      icon: <PencilIcon fill={primaryDark[500]} style={{ width: 18, height: 18 }} />,
      text: 'Edit',
      onClick: () => navigate(`/dashboard/timed-projects/${params.projectId}/edit`),
    },
    {
      key: 'delete',
      icon: <TrashIcon fill={primaryDark[500]} style={{ width: 18, height: 18 }} />,
      text: 'Delete',
      onClick: () => {},
    },
  ];

  // Hide the edit button if it's not a 'draft' or 'active'
  const editableStatuses = ['draft', 'active'];
  const dropdownMenuItems =
    timedProjectBase?.edit_status === 'draft' ||
    (timedProjectBase?.edit_status === null &&
      editableStatuses.includes(timedProjectBase?.status || ''))
      ? menu
      : menu.slice(1);

  return (
    <Stack direction="row">
      <Stack spacing={10} width="50%">
        <Stack spacing={1} direction="row">
          <Stack justifyContent="flex-end">
            <CustomDropdown menuItemsContents={dropdownMenuItems} />
          </Stack>
          <Stack spacing={0.5} pb="1px">
            <Typography variant="heading4" color="primaryDark.500">
              <StatusChipField text={timedProjectContent?.data.status as EnumTimedProjectStatus} />
            </Typography>
            <Typography
              variant="heading1"
              color="primaryDark.600"
              sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              fontSize={28}
            >
              {timedProjectContent?.data.body.overview.name}
              <IconButton
                sx={{
                  borderRadius: '3px',
                  bgcolor: '#E6E8FF',
                }}
                onClick={() => setShowDrawer(true)}
              >
                <BoxArrowUpRightLeft fill="#4051EC" />
              </IconButton>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack spacing={10} width="50%">
        <Stack spacing={1} direction="row" justifyContent="end">
          <DateDisplayCard
            date={timedProjectBase?.published ?? null}
            text="PUBLISHED ON"
            sx={{ width: '159px' }}
          />
          {(timedProjectBase?.status as EnumTimedProjectStatus) ===
          EnumTimedProjectStatus.Closed ? (
            <DateDisplayCard
              date={timedProjectBase?.closed ?? null}
              variant="end"
              text="CLOSED ON"
              sx={{ width: '159px' }}
            />
          ) : (
            <DateDisplayCard
              date={timedProjectBase?.pitchDeadline ?? null}
              variant="end"
              text="CLOSES ON"
              sx={{ width: '159px' }}
            />
          )}
        </Stack>
        <Stack spacing={1} direction="row">
          <Stack width="33%" spacing={1}>
            <Typography variant="heading4" color="primaryDark.500">
              seller location
            </Typography>
            <Typography variant="body1" color="primaryDark.700" fontWeight="600">
              {mapProviderScope.get(
                timedProjectContent?.data.body.skills.providerScope ??
                  EnumServiceProviderScope.Undefined,
              )}
            </Typography>
          </Stack>
          <Stack width="33%" spacing={1}>
            <Typography variant="heading4" color="primaryDark.500">
              project type
            </Typography>
            <Typography variant="body1" color="primaryDark.700" fontWeight="600">
              {timedProjectContent?.data.body.budget.recurring ? 'Recurring' : 'One-time'}
            </Typography>
          </Stack>
          <Stack width="33%" spacing={1}>
            <Typography variant="heading4" color="primaryDark.500">
              budget
            </Typography>

            <Typography variant="body1" color="primaryDark.600" fontWeight="600">
              {timedProjectContent?.data.body.budget.budget != null
                ? formatCurrency(
                    timedProjectContent?.data.body.budget.budget,
                    timedProjectContent?.data.body.budget.currency as EnumCurrency,
                  )
                : '-'}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <TimedProjectDrawer
        timedProject={timedProjectContent?.data}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
      />
    </Stack>
  );
};

export default TimedProjectsDetailsGeneral;
