/* eslint-disable import/no-unresolved */
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CustomFooterDto } from 'tdc-web-backend/custom-footer/schemas';
import { mapService } from 'tdc-web-backend/enums/enums';
import Link from '../../../../components/link/Link';
import CustomButton from '../../../../components/button/CustomButton';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';

export interface BrowseByServiceProps {
  link: string;
  type?: string;
}

const BrowseByService = ({ link, type = 'seller_archive' }: BrowseByServiceProps) => {
  const { data: response } = useGetMany<CustomFooterDto>({
    resource: `/custom-footer?type=${type}`,
  });

  const entries = response?.data.results[0]?.entries;
  return (
    <Stack
      alignItems="flex-start"
      spacing={{ xs: 3, md: 5 }}
      py={{ xs: 3, md: 7 }}
      sx={{ mx: { xs: '1rem', gr: 'auto' }, width: { gr: '80rem' } }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="publicHeading5"
          sx={{
            textAlign: { sx: 'start', sm: 'start', md: 'center' },
            flexGrow: 1,
          }}
        >
          Browse by services
        </Typography>
        <CustomButton variant="secondaryText" href={link}>
          View all
        </CustomButton>
      </Box>

      <Stack
        spacing={{ xs: 3, md: 16.25 }}
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'flex-start' }}
        justifyContent={{ sm: 'center' }}
        sx={{ width: '100%' }}
      >
        {entries?.map((category: any, index: number) => (
          <Box key={index}>
            <Typography
              variant="publicBody4"
              sx={{
                fontWeight: 600,
                color: 'primaryDark.900',
                lineHeight: '1.5rem',
              }}
            >
              {category.title}
            </Typography>
            <Stack spacing={1.5} key={category.title} pt={1.5}>
              {category.services?.map((service: any) => (
                <Link
                  to={`${link}?service=${service}`}
                  variant="publicBody5Regular"
                  key={service}
                  sx={{ fontWeight: 350, color: '#4F576E' }}
                >
                  {mapService.get(service)}
                </Link>
              ))}
            </Stack>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

export default BrowseByService;
