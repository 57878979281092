import { Stack, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const Error = ({ message = 'Something went wrong!' }: { message?: string }) => (
  <Stack
    spacing={1.5}
    direction="row"
    alignItems="center"
    justifyContent="flex-start"
    sx={{
      mt: '12px',
      borderRadius: '4px',
      borderColor: 'secondaryPink.700',
      backgroundColor: 'secondaryPink.100',
      p: 2,
    }}
  >
    <ErrorIcon
      sx={{
        color: 'secondaryPink.500',
      }}
    />
    <Typography variant="body3" color="secondaryPink.500">
      {message}
    </Typography>
  </Stack>
);

export default Error;
