import { Chip } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { primaryDark } from '../../../utils/color';

const ContractLockedChip = () => (
  <Chip
    icon={
      <LockOutlinedIcon
        sx={{ fontSize: '18px !important', color: `${primaryDark[500]} !important` }}
      />
    }
    label="Locked"
    sx={{
      ml: 2,
      px: 0.25,
      py: 0,
      height: 24,
      borderRadius: '3px',
      color: 'primaryDark.500',
      fontSize: '11px',
    }}
  />
);

export default ContractLockedChip;
