import { useNavigate } from 'react-router-dom';
import { ClickAwayListener, Drawer, IconButton, Stack, Typography } from '@mui/material';
import { TimedProjectContentDto } from 'tdc-web-backend/timed-project-contents/schemas';
import {
  EnumCountry,
  EnumIndustry,
  EnumService,
  EnumTechLanguage,
  EnumTechPlatform,
  EnumTechTool,
  mapIndustry,
  mapService,
  mapCountry,
  EnumCaseStudyStatus,
  CompanyRole,
} from 'tdc-web-backend/enums/enums';
import { CaseStudyContentDto } from 'tdc-web-backend/case-studies-content/schemas';
import { useState } from 'react';
import { mapProviderScope, mapTechLanguage, mapTechPlatform, mapTechTool } from '../../utils/enums';
import { APPBAR_HEIGHT } from '../../layout/dashboard-navigation/NavigationDashboard';
import CustomButton from '../button/CustomButton';
import CustomChip from '../custom-chip/CustomChip';
import Editor from '../editor/Editor';
import PreviewHeader from '../preview/PreviewHeader';
import PreviewText from '../preview/PreviewText';
import PreviewCompetitors from '../preview/PreviewCompetitors';
import DateDisplayCard from '../DateDisplayCard/DateDisplayCard';
import { ReactComponent as JournalIcon } from '../../assets/icons/journal.svg';
import { ReactComponent as PersonBoundingIcon } from '../../assets/icons/person-bounding.svg';
import { ReactComponent as TrophyIcon } from '../../assets/icons/trophy.svg';
import { ReactComponent as CashIcon } from '../../assets/icons/cash.svg';
import { ReactComponent as PersonLinesIcon } from '../../assets/icons/person-lines.svg';
import { ReactComponent as BoxArrowIcon } from '../../assets/icons/box-arrow.svg';
import { ReactComponent as CancelCircleIcon } from '../../assets/icons/project-icons/CancelCircleIcon.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/layout-icons/WarningAlertIcon.svg';
import { primaryDark } from '../../utils/color';
import useGetMany from '../../utils/hooks/crud-hooks/useGetMany';
import useUserCompany from '../../utils/hooks/useUserCompany';
import useAuth from '../../utils/hooks/useAuth';

interface IProps {
  onClose: () => void;
  open: boolean;
  timedProject: TimedProjectContentDto | undefined;
  drawerWidth?: string;
  withButtons?: boolean;
}

const sxDateCard = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  height: 'auto',
  '.MuiTypography-heading4': {
    fontSize: '12px',
  },
  '.MuiTypography-heading3': {
    fontSize: '14px',
  },
};

const sxDrawerElement = { flex: '1', borderBottom: '1px solid #E5E8FF' };

const TimedProjectDrawer = ({
  onClose,
  open,
  timedProject,
  drawerWidth = '40%',
  withButtons,
}: IProps) => {
  if (!timedProject) return null;

  const navigate = useNavigate();
  const {
    authData: { userData: currentUser },
  } = useAuth();

  const { budget, overview, timeline, skills, about } = timedProject.body;

  const projectType = budget.recurring ? 'Recurring' : 'One time';
  const providerScope = mapProviderScope.get(skills.providerScope) || '';
  const targetMarkets = about.targetMarkets?.map((key: EnumCountry) => mapCountry.get(key)) || [];
  const industries = about.industries.map((key: EnumIndustry) => mapIndustry.get(key));
  const services = skills.services.map((key: EnumService) => mapService.get(key));
  const platforms = skills.platforms.map((key: EnumTechPlatform) => mapTechPlatform.get(key));
  const languages = skills.languages.map((key: EnumTechLanguage) => mapTechLanguage.get(key));
  const tools = skills.tools.map((key: EnumTechTool) => mapTechTool.get(key));

  const { data: caseStudiesContentData } = useGetMany<CaseStudyContentDto>({
    resource: `case-studies-content?company=${currentUser?.membership?.company}`,
  });

  const caseStudiesContents = caseStudiesContentData?.data.results;

  const isAtleastOneCaseStudyVerified = caseStudiesContents?.some(
    (caseStudy) => caseStudy.status === EnumCaseStudyStatus.Verified,
  );

  const userCompany = useUserCompany();
  const isUserBuyer = userCompany?.roles?.includes(CompanyRole.Buyer);

  const [isDisplayed, setIsDisplayed] = useState(true);

  const handleOnClick = () => {
    setIsDisplayed(false);
  };

  return (
    <Drawer
      anchor="right"
      hideBackdrop
      PaperProps={{
        sx: {
          boxShadow: '1px 10px 30px rgba(56, 69, 114, 0.1)',
          width: drawerWidth,
          mt: `${APPBAR_HEIGHT}px`,
          height: `calc(100vh - ${APPBAR_HEIGHT}px)`,
        },
      }}
      open={open}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Stack
          sx={{
            padding: '24px',
          }}
        >
          {!isAtleastOneCaseStudyVerified && isDisplayed && !isUserBuyer && (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{
                backgroundColor: '#FFF2F4',
                borderBottomLeftRadius: '15px',
                borderBottomRightRadius: '15px',
              }}
              p={1.5}
              mb={2}
            >
              <WarningIcon fill="#DF5364" />
              <Typography sx={{ fontSize: '12px', lineHeight: '16px', fontWeight: 'bold' }}>
                You must have at least one client-verified case study to pitch for a project.
              </Typography>
              <IconButton onClick={handleOnClick} sx={{ p: 0, height: 'fit-content' }}>
                <CancelCircleIcon fill={primaryDark[700]} />
              </IconButton>
            </Stack>
          )}

          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography sx={{ display: 'block' }} variant="heading1" color="primaryDark.600">
              {overview.name}
            </Typography>
            <IconButton onClick={onClose} sx={{ p: 0, height: 'fit-content' }}>
              <CancelCircleIcon fill={primaryDark[500]} />
            </IconButton>
          </Stack>
          {withButtons && (
            <Stack direction="row" mt={2}>
              <CustomButton
                disabled={!isAtleastOneCaseStudyVerified}
                sx={{ mr: 1 }}
                variant="primary"
                endIcon={<BoxArrowIcon />}
                onClick={() =>
                  navigate('./create', {
                    state: {
                      timedProjectId: timedProject?.timedProject,
                      timedProjectName: overview.name,
                    },
                  })
                }
              >
                SUBMIT PITCH
              </CustomButton>
            </Stack>
          )}
          <Stack direction="row" sx={{ margin: '40px 0' }}>
            <DateDisplayCard
              sx={{
                ...sxDateCard,
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
                margin: '0 24px 0 0',
              }}
              variant="start"
              text="pitch deadline"
              date={timeline.pitchDeadline}
            />
            <DateDisplayCard
              sx={{ ...sxDateCard, borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
              variant="end"
              text="project start"
              date={timeline.projectStart}
            />
          </Stack>
          <Stack
            sx={{ padding: '0 16px 16px 16px', borderBottom: '1px solid #E5E8FF' }}
            direction="row"
            justifyContent="space-between"
          >
            <PreviewText label="seller location" value={providerScope} />
            <PreviewText label="project type" value={projectType} />
            <PreviewText label="budget" value={`$${budget.budget}`} />
          </Stack>
          <Stack direction="row">
            {!!services.length && (
              <Stack sx={{ ...sxDrawerElement, padding: '16px' }} spacing={1}>
                <Typography variant="heading4" color="primaryDark.500" sx={{ fontSize: '12px' }}>
                  required services
                </Typography>
                <Stack direction="row" flexWrap="wrap" gap="8px">
                  {services.map((service) => (
                    <CustomChip label={service} />
                  ))}
                </Stack>
              </Stack>
            )}
            {!!platforms.length && (
              <Stack sx={{ ...sxDrawerElement, padding: '16px' }} spacing={1}>
                <Typography variant="heading4" color="primaryDark.500" sx={{ fontSize: '12px' }}>
                  tech platforms
                </Typography>
                <Stack direction="row" flexWrap="wrap" gap="8px">
                  {platforms.map((platform) => (
                    <CustomChip label={platform} />
                  ))}
                </Stack>
              </Stack>
            )}
          </Stack>
          <Stack direction="row">
            {!!tools.length && (
              <Stack sx={{ ...sxDrawerElement, padding: '16px' }} spacing={1}>
                <Typography variant="heading4" color="primaryDark.500" sx={{ fontSize: '12px' }}>
                  tech tools
                </Typography>
                <Stack direction="row" flexWrap="wrap" gap="8px">
                  {tools.map((tool) => (
                    <CustomChip label={tool} />
                  ))}
                </Stack>
              </Stack>
            )}
            {!!languages.length && (
              <Stack sx={{ ...sxDrawerElement, padding: '16px' }} spacing={1}>
                <Typography variant="heading4" color="primaryDark.500" sx={{ fontSize: '12px' }}>
                  programming languages
                </Typography>
                <Stack direction="row" flexWrap="wrap" gap="8px">
                  {languages.map((language) => (
                    <CustomChip label={language} />
                  ))}
                </Stack>
              </Stack>
            )}
          </Stack>
          <Stack direction="row">
            {!!industries.length && (
              <Stack sx={{ ...sxDrawerElement, padding: '16px' }} spacing={1}>
                <Typography variant="heading4" color="primaryDark.500" sx={{ fontSize: '12px' }}>
                  industry
                </Typography>
                <Stack direction="row" flexWrap="wrap" gap="8px">
                  {industries.map((industry) => (
                    <CustomChip label={industry} />
                  ))}
                </Stack>
              </Stack>
            )}
            {!!targetMarkets.length && (
              <Stack sx={{ ...sxDrawerElement, padding: '16px' }} spacing={1}>
                <Typography variant="heading4" color="primaryDark.500" sx={{ fontSize: '12px' }}>
                  target market
                </Typography>
                <Stack direction="row" flexWrap="wrap" gap="8px">
                  {targetMarkets.map((targetMarket) => (
                    <CustomChip label={targetMarket} />
                  ))}
                </Stack>
              </Stack>
            )}
          </Stack>
          <Stack sx={{ ...sxDrawerElement, padding: '16px 0' }} spacing={2}>
            <PreviewHeader title="company description" icon={<JournalIcon />} />
            <Editor items={[]} isEditable={false} content={overview.description} />
          </Stack>
          <Stack sx={{ ...sxDrawerElement, padding: '16px 0' }} spacing={2}>
            <PreviewHeader title="project goals" icon={<TrophyIcon />} />
            <Editor items={[]} isEditable={false} content={about.goals} />
          </Stack>
          {about.audience && (
            <Stack sx={{ ...sxDrawerElement, padding: '16px 0' }} spacing={2}>
              <PreviewHeader title="target audience" icon={<PersonBoundingIcon />} />
              <Editor items={[]} isEditable={false} content={about.audience} />
            </Stack>
          )}
          {!!about.competitors.length && (
            <Stack sx={{ ...sxDrawerElement, padding: '16px 0' }} spacing={2}>
              <PreviewHeader title="main competitors" icon={<PersonLinesIcon />} />
              <PreviewCompetitors competitors={about.competitors} />
            </Stack>
          )}
          <Stack sx={{ flex: '1', padding: '16px 0' }} spacing={2}>
            <PreviewHeader title="budget description" icon={<CashIcon />} />
            <Editor items={[]} isEditable={false} content={budget.budgetDescription} />
          </Stack>
        </Stack>
      </ClickAwayListener>
    </Drawer>
  );
};

export default TimedProjectDrawer;
