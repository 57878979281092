import { Dispatch, SetStateAction } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { Box } from '@mui/material';
import { EnumTechLanguage } from 'tdc-web-backend/enums/enums';
import { ReactComponent as SuitcaseCheckIcon } from '../../../../../../../assets/icons/layout-icons/SuitcaseCheckIcon.svg';
import ConfirmModal from '../../../../../../../components/confirm-modal/ConfirmModal';
import { primaryLight } from '../../../../../../../utils/color';
import CustomInput from '../../../../../../../components/custom-input/CustomInput';
import { mapTechLanguage } from '../../../../../../../utils/enums';
import useUpdate from '../../../../../../../utils/hooks/crud-hooks/useUpdate';
import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import { useRefresh } from '../../../../../../../utils/hooks/crud-hooks/useRefresh';

interface CompanyTechLanguagesEditModalProps {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CompanyTechLanguagesEditModal = ({
  isEditModalOpen,
  setIsEditModalOpen,
}: CompanyTechLanguagesEditModalProps) => {
  const { companyData } = useSellerHomescreenContext();
  const refresh = useRefresh();

  const formMethods = useForm<Pick<CompanyDto, 'techLanguages'>>({
    defaultValues: {
      techLanguages: companyData?.techLanguages ?? ([] as EnumTechLanguage[]),
    },
    mode: 'onChange',
  });

  const { handleSubmit, getValues } = formMethods;

  const { mutate: update } = useUpdate<CompanyDto, Partial<CompanyDto>>({
    resource: 'companies',
  });

  const onSubmit = () => {
    update(
      {
        id: companyData?.id as CompanyDto['id'],
        data: { techLanguages: getValues('techLanguages') },
      },
      {
        onSuccess: () => {
          refresh();
          setIsEditModalOpen(false);
        },
      },
    );
  };

  return (
    <ConfirmModal
      width="40%"
      isModalOpen={isEditModalOpen}
      setIsModalOpen={setIsEditModalOpen}
      title="Programming languages"
      onCancel={() => setIsEditModalOpen(false)}
      automaticallyCloseModalOnButtonClick={false}
      onConfirm={handleSubmit(onSubmit)}
      confirmButtonText="Save"
      message={
        <FormProvider {...formMethods}>
          <Box component="form">
            <CustomInput
              input={<CustomInput.Autocomplete />}
              choices={mapTechLanguage}
              name="techLanguages"
              label="Programming languages"
              multiple
              placeholder="Programming languages"
              sx={{ width: '100%' }}
            />
          </Box>
        </FormProvider>
      }
      iconProps={{
        icon: <SuitcaseCheckIcon fill={primaryLight[500]} />,
        wrapperBackgroundColor: primaryLight[100],
      }}
    />
  );
};

export default CompanyTechLanguagesEditModal;
