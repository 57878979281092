import {
  Alert,
  Box,
  ClickAwayListener,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  Modal,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import {
  TimedProjectPitchContentDto,
  TimedProjectPitchMaterialsDto,
} from 'tdc-web-backend/timed-project-pitch-contents/schemas';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  CompanyRole,
  EnumScreenList,
  EnumTimedProjectPitchRejectFeedbackScope,
  EnumTimedProjectPitchStatus,
} from 'tdc-web-backend/enums/enums';
import { useNavigate } from 'react-router-dom';
import { BaseCaseStudy } from 'tdc-web-backend/case-studies/schemas';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { ReactComponent as BoxArrowUpRightLeft } from '../../../assets/icons/project-icons/BoxArrowUpRightLeft.svg';
import { APPBAR_HEIGHT } from '../../../layout/dashboard-navigation/NavigationDashboard';
import CustomButton from '../../../components/button/CustomButton';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { primaryDark, primaryLight } from '../../../utils/color';
import { ReactComponent as JournalIcon } from '../../../assets/icons/journal.svg';
import { ReactComponent as JournalsIcon } from '../../../assets/icons/journals-fill.svg';
import { ReactComponent as MapIcon } from '../../../assets/icons/map-fill.svg';
import { ReactComponent as PaperclipIcon } from '../../../assets/icons/paperclip.svg';
import { ReactComponent as FeedbackIcon } from '../../../assets/icons/feedback.svg';
import { ReactComponent as ButtonCheckIcon } from '../../../assets/icons/notifications/CheckmarkIcon.svg';
import CaseStudyCard from '../../../components/case-study-card/CaseStudyCard';
import StatusChipField from '../../../components/StatusChipField/StatusChipField';
import { formatDateFns, iconUrlForFile } from '../../../utils/helpers';
import StarBadge from '../../../components/star-badge/StarBadge';
import { ReactComponent as CheckIcon } from '../../../assets/icons/notifications/CheckmarkIcon.svg';
import useUserCompany from '../../../utils/hooks/useUserCompany';
import Editor from '../../../components/editor/Editor';
import FloatingNotification from '../../../components/notifications/bannerNotifications/FloatingNotification';
import SingleSlideCarousel, {
  RenderCarouselItemProps,
} from '../../../components/single-slide-carousel/SingleSlideCarousel';
import CustomBackAndNextButtons from '../../../components/single-slide-carousel/custom-back-and-next-buttons/CustomBackAndNextButtons';
import VerifiedChip from '../../../components/verified-chip/VerifiedChip';
import ClientReviews from '../../../components/client-reviews/ClientReviews';
import { ExtendedTimedProjectPitchContentDto } from '../pitch.interface';
import RejectPitchModal from '../../Dashboard/TimedProjects/TimedProjectsDetails/components/RejectPitchModal';
import CustomReferenceField from '../../../components/custom-fields/CustomReferenceField';
import CompanyAvatarAndName from '../../../components/company-avatar-and-name/CompanyAvatarAndName';
import { mapTimedProjectPitchStatus } from '../../../utils/enums';
import useReferenceOne from '../../../utils/hooks/crud-hooks/useReferenceOne';
import WithdrawModal from './components/WithdrawModal';
import BannerNotificationMapper from '../../../components/notifications/bannerNotifications/BannerNotificationMapper';
import ConfirmPitchModal, {
  ModalType,
} from '../../Dashboard/TimedProjects/TimedProjectsDetails/TimedProjectsDetailsPitchesPanel/ConfirmPitchModal';
import useUpdate from '../../../utils/hooks/crud-hooks/useUpdate';
import { useRefresh } from '../../../utils/hooks/crud-hooks/useRefresh';
import DeletePitchModal from './components/DeletePitchModal';

const CAROUSEL_ITEM_WIDTH = 270;

type PitchDetailsDrawerProps = {
  isDrawerOpen: boolean;
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  pitchContent: ExtendedTimedProjectPitchContentDto;
  isRejectModalOpen?: boolean;
  setIsRejectModalOpen?: Dispatch<SetStateAction<boolean>>;
  drawerWidth?: string;
  setCaseStudyId?: any;
  setIsCaseStudyDrawerOpen?: any;
};

type HeadingProps = {
  icon: ReactNode;
  title: string;
};

const Separator = () => <Stack height="1px" my={3} bgcolor="secondaryBlue.100" />;

const Heading = ({ icon, title }: HeadingProps) => (
  <Stack
    sx={{
      fill: primaryDark[500],
    }}
    mb={1.375}
    alignItems="center"
    direction="row"
  >
    {icon}
    <Typography color="primaryDark.500" variant="heading5" ml={1.375}>
      {title}
    </Typography>
  </Stack>
);

const PitchDetailsDrawer = ({
  isDrawerOpen,
  setIsDrawerOpen,
  pitchContent,
  isRejectModalOpen,
  setIsRejectModalOpen,
  drawerWidth = '40%',
  setCaseStudyId,
  setIsCaseStudyDrawerOpen,
}: PitchDetailsDrawerProps) => {
  const refresh = useRefresh();
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [withdrawModalOpen, setWithdrawModalOpen] = useState<boolean>(false);
  const [isOpenConfirmMoveModal, setIsOpenConfirmMoveModal] = useState<boolean>(false);
  const [confirmMoveModalType, setConfirmMoveModalType] = useState<ModalType | null>(null);

  const [patchStatus, setPatchStatus] = useState<'idle' | 'loading' | 'succeeded' | 'failed'>(
    'idle',
  );
  const [projectId, setProjectId] = useState<string | null>(null);

  const { mutate: mutateUpdatePitch } = useUpdate({
    resource: '/timed-project-pitches',
  });

  const updatePitchStatus = (newStatus: EnumTimedProjectPitchStatus) => {
    if (!pitchContent) {
      return;
    }
    setPatchStatus('loading');
    mutateUpdatePitch(
      {
        id: pitchContent?.timedProjectPitch,
        suffix: newStatus === EnumTimedProjectPitchStatus.SecondRound ? 'second-round' : 'hire',
      },
      {
        onError: () => {
          setPatchStatus('failed');
          refresh();
        },
        onSuccess: (data: any) => {
          if (data) {
            setProjectId(data.data.id);
          }
          setPatchStatus('succeeded');
          refresh();
        },
      },
    );
  };
  const [deletePitchModalOpen, setDeletePitchModalOpen] = useState<boolean>(false);

  const userCompany = useUserCompany();
  const isUserBuyer = userCompany?.roles?.includes(CompanyRole.Buyer);

  const feedbackType = new Map([
    [EnumTimedProjectPitchRejectFeedbackScope.Overall, 'Overall Presentation'],
    [EnumTimedProjectPitchRejectFeedbackScope.Strategy, 'Project Strategy'],
    [EnumTimedProjectPitchRejectFeedbackScope.Knowledge, 'Knowlage & Insight'],
    [EnumTimedProjectPitchRejectFeedbackScope.Budget, 'Budget'],
  ]);

  const feedback = pitchContent?.feedback?.map((item: TimedProjectPitchContentDto['feedback']) => ({
    title: feedbackType.get(item.type),
    rate: item.rate,
    description: item.description,
  }));

  const { data: companyData } = useReferenceOne<CompanyDto>({
    enabled: !!isUserBuyer && !!pitchContent?.seller,
    id: pitchContent?.seller as string,
    resource: 'companies',
  });

  return (
    <Drawer
      anchor="right"
      hideBackdrop
      PaperProps={{
        sx: {
          boxShadow: '1px 10px 30px rgba(56, 69, 114, 0.1)',
          width: drawerWidth,
          mt: `${APPBAR_HEIGHT}px`,
          height: `calc(100vh - ${APPBAR_HEIGHT}px)`,
        },
      }}
      open={isDrawerOpen}
    >
      <ClickAwayListener
        onClickAway={() => {
          setIsDrawerOpen(!isDrawerOpen);
        }}
      >
        <Stack spacing={3} p={3}>
          <BannerNotificationMapper screen={EnumScreenList.PitchDetailScreen} />

          <Stack>
            <Stack spacing={4}>
              {isUserBuyer ? (
                <Stack direction="row" alignItems="start" justifyContent="space-between">
                  <Stack spacing={1.8}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      {pitchContent?.isNew && (
                        <Stack
                          sx={{
                            py: 0.5,
                            px: 0.9,
                            bgcolor: 'secondaryBlue.100',
                            borderRadius: '3px',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography variant="publicBody6Regular" color="secondaryBlue.700">
                            NEW
                          </Typography>
                        </Stack>
                      )}

                      <Typography
                        variant="publicBody6Regular"
                        color="primaryDark.500"
                        sx={{ textTransform: 'uppercase' }}
                      >
                        {mapTimedProjectPitchStatus.get(pitchContent?.status)}
                      </Typography>
                    </Stack>

                    <Stack spacing={2} direction="row" alignItems="center">
                      <CompanyAvatarAndName
                        companyName={companyData?.name ?? ''}
                        companyAvatar={companyData?.avatar ?? ''}
                        companyAvatarWidth={50}
                        companyAvatarHeight={50}
                        companyNameTypographyVariant="heading3"
                      />

                      <IconButton
                        href={`/company/${pitchContent?.seller}`}
                        target="_blank"
                        sx={{
                          borderRadius: '3px',
                          bgcolor: 'secondaryBlue.100',
                        }}
                      >
                        <BoxArrowUpRightLeft fill="#4051EC" />
                      </IconButton>
                    </Stack>
                  </Stack>

                  <IconButton onClick={() => setIsDrawerOpen(false)}>
                    <CancelIcon sx={{ color: 'primaryDark.500', fontSize: '1.3rem' }} />
                  </IconButton>
                </Stack>
              ) : (
                <Stack>
                  {/* chip, date & close button */}
                  <Stack direction="row" alignItems="center" mb={1}>
                    <StatusChipField text={pitchContent?.status} />

                    <Typography variant="heading4" color="primaryDark.600" ml={1.25}>
                      {formatDateFns(pitchContent?.published)}
                    </Typography>

                    <IconButton
                      sx={{ ml: 'auto', mr: '-8px' }}
                      onClick={() => setIsDrawerOpen(false)}
                    >
                      <CancelIcon sx={{ color: 'primaryDark.500', fontSize: '1.3rem' }} />
                    </IconButton>
                  </Stack>

                  <Typography mb={5} variant="heading2" color="primaryDark.600">
                    {pitchContent?.timedProjectContentCurrent.body.overview.name}
                  </Typography>
                </Stack>
              )}

              {/* buttons */}
              <Stack direction="row" alignItems="center">
                {isUserBuyer ? (
                  // item?.status === EnumTimedProjectPitchStatus.SecondRoundRejected
                  <>
                    {[
                      EnumTimedProjectPitchStatus.FirstRound,
                      EnumTimedProjectPitchStatus.SecondRound,
                    ].includes(pitchContent?.status) && (
                      <>
                        <CustomButton
                          sx={{ mr: 2 }}
                          variant="primaryLight"
                          // passes the 'true' state to TimedProjectsDetails
                          // which activates the first modal popup for rejection of the pitch
                          onClick={() => {
                            setIsRejectModalOpen?.(!isRejectModalOpen);
                          }}
                        >
                          Reject
                        </CustomButton>
                        <CustomButton
                          sx={{ mr: 2 }}
                          variant="primary"
                          onClick={() => {
                            setIsOpenConfirmMoveModal(true);
                            setConfirmMoveModalType(
                              pitchContent?.status === EnumTimedProjectPitchStatus.FirstRound
                                ? 'confirm'
                                : 'hire',
                            );
                          }}
                          endIcon={<ButtonCheckIcon fill={primaryLight[50]} />}
                        >
                          {pitchContent?.status === EnumTimedProjectPitchStatus.FirstRound
                            ? 'Select'
                            : 'Hire'}
                        </CustomButton>
                      </>
                    )}
                    {[
                      EnumTimedProjectPitchStatus.FirstRoundRejected,
                      EnumTimedProjectPitchStatus.SecondRoundRejected,
                    ].includes(pitchContent?.status) &&
                      !('feedback' in pitchContent) && (
                        <CustomButton
                          sx={{ mr: 2 }}
                          variant="primary"
                          onClick={() => {
                            setIsRejectModalOpen?.(!isRejectModalOpen);
                          }}
                        >
                          Leave Feedback
                        </CustomButton>
                      )}
                    {/* Needs to be implemented
                    <CustomButton variant="primary">
                      Select
                      <CheckIcon fill="white" style={{ marginLeft: 6 }} />
                    </CustomButton> */}
                  </>
                ) : (
                  <>
                    {pitchContent?.status === EnumTimedProjectPitchStatus.Chosen ||
                    pitchContent?.status === EnumTimedProjectPitchStatus.FirstRoundRejected ||
                    pitchContent?.status ===
                      EnumTimedProjectPitchStatus.SecondRoundRejected ? null : pitchContent?.status ===
                      EnumTimedProjectPitchStatus.Draft ? (
                      <CustomButton
                        sx={{
                          mr: 1,
                          border: 'solid 1px',
                          borderColor: '#6D51F6',
                          backgroundColor: 'white',
                        }}
                        variant="primaryLight"
                        onClick={() => setDeletePitchModalOpen(true)}
                      >
                        DELETE
                      </CustomButton>
                    ) : (
                      <CustomButton
                        sx={{ mr: 1 }}
                        variant="secondary"
                        onClick={() => setWithdrawModalOpen(true)}
                      >
                        WITHDRAW
                      </CustomButton>
                    )}

                    {pitchContent?.status === EnumTimedProjectPitchStatus.Chosen ||
                    pitchContent?.status === EnumTimedProjectPitchStatus.FirstRoundRejected ||
                    pitchContent?.status ===
                      EnumTimedProjectPitchStatus.SecondRoundRejected ? null : (
                      <CustomButton
                        sx={{
                          color: 'primaryDark.500',
                          border: 'none',
                        }}
                        disabled={
                          pitchContent?.editStatus === EnumTimedProjectPitchStatus.Pending ||
                          pitchContent?.status === EnumTimedProjectPitchStatus.Pending
                        }
                        onClick={() => navigate(`${pitchContent?.timedProjectPitch}/edit`)}
                        endIcon={<EditIcon fill={primaryLight[50]} />}
                        variant="secondary"
                      >
                        EDIT
                      </CustomButton>
                    )}
                  </>
                )}
              </Stack>
            </Stack>

            <Separator />

            {/* feedback */}
            {pitchContent && 'feedback' in pitchContent && (
              <>
                <Heading title="client review" icon={<FeedbackIcon />} />

                <ClientReviews feedback={feedback} />

                <Separator />
              </>
            )}

            {/* team desc */}
            <Heading title="Team description" icon={<JournalIcon />} />

            {pitchContent && pitchContent?.overview && pitchContent?.overview.description && (
              <Editor items={[]} isEditable={false} content={pitchContent?.overview.description} />
            )}

            <Separator />

            {/* top level strat */}
            <Heading title="Top level strategy" icon={<MapIcon />} />

            {pitchContent && pitchContent?.overview && pitchContent?.overview.strategy && (
              <Editor items={[]} isEditable={false} content={pitchContent?.overview.strategy} />
            )}

            <Separator />

            {pitchContent && pitchContent?.caseStudies.length > 0 && (
              <>
                <Heading title="Case study portfolio" icon={<JournalsIcon />} />

                {/* case studies carousel */}
                <Stack position="relative" width="100%">
                  <SingleSlideCarousel
                    items={pitchContent?.caseStudies || []}
                    // items={mockCaseStudies || []}
                    carouselItemWidth={CAROUSEL_ITEM_WIDTH}
                    activeIndexItem={0}
                    sx={{
                      width: '100%',
                      pb: '30px',
                      ml: '-16px',
                      '&>*:nth-child(1)': {
                        marginLeft: '18px',
                      },
                    }}
                    renderCustomBackAndNextButtons={({ back, next }) => (
                      <CustomBackAndNextButtons back={back} next={next} />
                    )}
                    renderCarouselItem={({
                      item,
                    }: RenderCarouselItemProps<TimedProjectPitchContentDto['caseStudies']>) => {
                      if (item) {
                        return (
                          <CaseStudyCard
                            onClick={(data) => {
                              setIsCaseStudyDrawerOpen(true);
                              setCaseStudyId(data.id);
                            }}
                            sx={{ minWidth: CAROUSEL_ITEM_WIDTH, maxWidth: CAROUSEL_ITEM_WIDTH }}
                            id={item.id}
                            topChip={<VerifiedChip />}
                            bottomComponent={
                              <CustomReferenceField
                                displayName="name"
                                id={item.id}
                                resource="case-studies"
                                renderComponent={(data: BaseCaseStudy) => (
                                  <StarBadge rating={data.score as number} />
                                )}
                              />
                            }
                            coverImg={item.file}
                            caseStudyName={item.title}
                            companyLogo={item.seller.avatar}
                            companyName={item.companyName}
                          />
                        );
                      }

                      return null;
                    }}
                  />
                </Stack>

                <Separator />
              </>
            )}

            <Heading title="Related materials" icon={<PaperclipIcon />} />

            {/* materials list */}
            <List>
              {pitchContent?.materials.map((material: TimedProjectPitchMaterialsDto) => (
                <ListItemButton
                  component="a"
                  href={material?.file || ''}
                  sx={{
                    px: 0,
                  }}
                  download
                >
                  <Stack>
                    <Stack mb={0.75} direction="row" alignItems="center">
                      <Box
                        width={10}
                        height={10}
                        sx={{
                          border: `2px solid ${primaryDark[300]}`,
                          borderRadius: '50%',
                          bgcolor: 'primaryDark.500',
                          mr: 1.25,
                        }}
                      />
                      <Typography variant="body3" color="primaryDark.600">
                        {material.fileName}
                      </Typography>
                    </Stack>

                    <Stack direction="row" alignItems="center" pl={1.25}>
                      <Box
                        component="img"
                        sx={{ width: 30, height: 30 }}
                        src={(() => iconUrlForFile(material?.file))()}
                        mr={1.25}
                      />
                      <Typography variant="body3" fontWeight="600" color="primaryDark.600">
                        {material.fileName}
                      </Typography>
                    </Stack>
                  </Stack>
                </ListItemButton>
              ))}
            </List>
          </Stack>
          <RejectPitchModal
            isOpen={isRejectModalOpen as boolean}
            setIsOpen={setIsRejectModalOpen as Dispatch<SetStateAction<boolean>>}
            pitch={pitchContent}
          />
          <WithdrawModal
            open={withdrawModalOpen as boolean}
            setOpen={setWithdrawModalOpen as Dispatch<SetStateAction<boolean>>}
            pitchId={pitchContent?.timedProjectPitch}
          />
          {isOpenConfirmMoveModal && confirmMoveModalType && (
            <ConfirmPitchModal
              type={confirmMoveModalType}
              isOpen={isOpenConfirmMoveModal}
              handleOnClose={() => {
                setIsOpenConfirmMoveModal(false);
                setConfirmMoveModalType(null);
                setPatchStatus('idle');
              }}
              handleConfirm={() => {
                updatePitchStatus(
                  confirmMoveModalType === 'confirm'
                    ? EnumTimedProjectPitchStatus.SecondRound
                    : EnumTimedProjectPitchStatus.Hired,
                );
              }}
              patchStatus={patchStatus}
              projectId={projectId}
            />
          )}
          <DeletePitchModal
            open={deletePitchModalOpen as boolean}
            setOpen={setDeletePitchModalOpen as Dispatch<SetStateAction<boolean>>}
            pitchId={pitchContent?.timedProjectPitch}
          />
        </Stack>
      </ClickAwayListener>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setShowSnackbar(false)} severity="info" sx={{ width: '100%' }}>
          Feature not yet implemented.
        </Alert>
      </Snackbar>
    </Drawer>
  );
};

export default PitchDetailsDrawer;
