import { CompanyRole } from 'tdc-web-backend/enums/enums';
import useUserCompany from '../../../utils/hooks/useUserCompany';
import BuyerHomescreen from './BuyerHomescreen/BuyerHomescreen';
import SellerHomescreen from './SellerHomescreen/SellerHomescreen';
import LoadingIndicator from '../../../components/loading-indicator/LoadingIndicator';

type Props = {};

const Homescreen = (props: Props) => {
  const userCompany = useUserCompany();
  const isBuyer = userCompany?.roles?.includes(CompanyRole.Buyer);
  return userCompany ? isBuyer ? <BuyerHomescreen /> : <SellerHomescreen /> : <LoadingIndicator />;
};

export default Homescreen;
