import { Avatar, AvatarGroup, Box, Popover, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
  ChannelDto,
  SetChannelAdminDto,
  UserChatChannelMembershipDto,
} from 'tdc-web-backend/channels/schemas';
import useGetMany from '../../../utils/hooks/crud-hooks/useGetMany';
import CustomButton from '../../../components/button/CustomButton';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';
import useGetOne from '../../../utils/hooks/crud-hooks/useGetOne';
import useAuth from '../../../utils/hooks/useAuth';

export default ({ channelId }: { channelId: string }) => {
  const {
    authData: { userData },
  } = useAuth();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'members-popover' : undefined;

  const membershipsResponse = useGetMany<UserChatChannelMembershipDto>({
    resource: `channels/${channelId}/memberships`,
  });
  const memberships = membershipsResponse.data?.data.results ?? [];

  const channel = useGetOne<ChannelDto>({
    resource: `channels/${channelId}`,
  }).data?.data;

  const { mutate } = useCreate<unknown, SetChannelAdminDto>({
    resource: `channels/${channelId}/set-admin`,
  });

  const setAdmin = (user: string, admin: boolean) => {
    mutate(
      {
        user,
        admin,
      },
      {
        onSuccess: () => {
          membershipsResponse.refetch();
        },
      },
    );
  };

  const handleClick = (event: any) => {
    membershipsResponse.refetch();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        onClick={handleClick}
        sx={{ cursor: 'pointer' }}
      >
        <Typography color="primaryDark.400" variant="body3">
          {memberships.length} {memberships.length === 1 ? 'member' : 'members'}
        </Typography>
        <AvatarGroup>
          {memberships.map((membership) => (
            <Avatar sx={{ width: '24px', height: '24px' }}>{membership.user.firstName[0]}</Avatar>
          ))}
        </AvatarGroup>
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <Stack sx={{ borderRadius: '12px', width: '300px', height: '500px' }}>
          <Stack direction="column" overflow="scroll">
            {memberships.map((membership) => (
              <Box
                px={2.5}
                py={2}
                sx={{
                  '&:hover': {
                    backgroundColor: 'secondaryBlue.50',
                  },
                }}
              >
                <Stack direction="row" alignItems="center" gap={1}>
                  <Avatar>{membership.user.firstName[0]}</Avatar>
                  <Stack direction="column">
                    <Typography
                      variant="bodySemiBold2"
                      color="primaryDark.600"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {membership.user.firstName} {membership.user.lastName}
                    </Typography>
                  </Stack>
                  {channel?.admin && membership.user.id !== userData?.id ? (
                    <Box ml="auto">
                      {membership.admin ? (
                        <CustomButton
                          variant="secondary"
                          onClick={() => setAdmin(membership.user.id, false)}
                        >
                          Dismiss Admin
                        </CustomButton>
                      ) : (
                        <CustomButton onClick={() => setAdmin(membership.user.id, true)}>
                          Make Admin
                        </CustomButton>
                      )}
                    </Box>
                  ) : null}
                </Stack>
              </Box>
            ))}
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};
