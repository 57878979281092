import { Stack, Typography, useMediaQuery } from '@mui/material';
import { useState, useEffect } from 'react';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { TaskDto } from 'tdc-web-backend/tasks/schemas';
import {
  CompanyApprovalStatus,
  CompanyRole,
  EnumCurrency,
  EnumProjectMilestoneStatus,
  EnumProjectTaskStatus,
  EnumScreenList,
} from 'tdc-web-backend/enums/enums';
import { useNavigate } from 'react-router-dom';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { ContractDto } from 'tdc-web-backend/contracts/schemas';
import ProjectListTable from './ProjectListTable';
import RevenueChart from '../ProjectDetail/RevenueChart/RevenueChart';
import useGetMany from '../../../utils/hooks/crud-hooks/useGetMany';
import { ReactComponent as DocumentIcon } from '../../../assets/icons/content/DocumentIcon.svg';
import { IUpcomingDeadline } from '../../../utils/types';
import CustomList, { ColumnDefs } from '../../../components/custom-list/list/CustomList';
import useGetCooperationCompany from '../../../utils/hooks/useGetCooperationCompany';
import { formatCurrency, formatDateFns, truncate } from '../../../utils/helpers';
import StatusChipField from '../../../components/StatusChipField/StatusChipField';
import { ReactComponent as ContractDocumentIcon } from '../../../assets/icons/project-icons/ContractDocumentIcon.svg';
import CustomReferenceField from '../../../components/custom-fields/CustomReferenceField';
import theme from '../../../utils/theme';
import CustomScrollFade from '../../../components/custom-scroll-fade/CustomScrollFade';
import { secondaryBlue } from '../../../utils/color';
import { DASHBOARD_ELEMENTS_SPACING } from '../../Dashboard/Main';
import useReferenceOne from '../../../utils/hooks/crud-hooks/useReferenceOne';
import useUserCompany from '../../../utils/hooks/useUserCompany';
import BannerNotificationMapper from '../../../components/notifications/bannerNotifications/BannerNotificationMapper';
import DashboardCard from '../../../components/dashboard-card/EndToEndCard.tsx/DashboardCard';
import CustomTooltip from '../../../components/tooltip/CustomTooltip';
import useAuth from '../../../utils/hooks/useAuth';

const EndToEndProjectList = () => {
  const navigate = useNavigate();

  const isLessThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isBetweenMdLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isBetweenLgGr = useMediaQuery(theme.breakpoints.between('lg', 'gr'));
  const isBetweenGrXl = useMediaQuery(theme.breakpoints.between('gr', 'xl'));
  const isBetweenXlXxl = useMediaQuery(theme.breakpoints.between('xl', 'xxl'));
  const isBiggerThanXl = useMediaQuery(theme.breakpoints.up('xxl'));
  const [showDocumentIcon, setShowDocumentIcon] = useState<boolean>(true);

  useEffect(() => {
    if (isLessThanLarge) {
      setShowDocumentIcon(false);
    } else {
      setShowDocumentIcon(true);
    }
  }, [isLessThanLarge]);

  const userCompany = useUserCompany();
  const isUserBuyer = userCompany?.roles?.includes(CompanyRole.Buyer);
  const {
    authData: { userData },
  } = useAuth();
  const [sortedMilestonesAndTasks, setSortedMilestonesAndTasks] =
    useState<Array<IUpcomingDeadline> | null>(null);

  const { data: contractData } = useGetMany<ContractDto>({
    resource: `/contracts/?company=${userData?.membership?.company}&limit=3&orderBy=-updated`,
    enabled: !!userData,
    refetchOnWindowFocus: false,
  });

  const { data: milestoneData } = useGetMany<MilestoneDto>({
    resource: `/milestones/?company=${userData?.membership?.company}`,
    enabled: !!userData,
    refetchOnWindowFocus: false,
  });

  const { data: taskData } = useGetMany<TaskDto>({
    resource: `/tasks/?company=${userData?.membership?.company}`,
    enabled: !!userData,
    refetchOnWindowFocus: false,
  });

  const sortMilestonesAndTasksDatesDescManner = () => {
    if (milestoneData && taskData) {
      const milestonesAndTasks: Array<{
        id: string;
        name: string;
        end: Date;
        status?: EnumProjectMilestoneStatus | EnumProjectTaskStatus;
        type: string;
      }> = [];
      for (let i = 0; i < milestoneData.data.results.length; i += 1) {
        milestonesAndTasks.push({
          id: milestoneData.data.results[i].id,
          status: milestoneData.data.results[i].status,
          name: milestoneData.data.results[i].name,
          end: milestoneData.data.results[i].end,
          type: 'milestone',
        });
      }

      for (let i = 0; i < taskData.data.results.length; i += 1) {
        milestonesAndTasks.push({
          id: taskData.data.results[i].milestone as string,
          status: taskData.data.results[i].status,
          name: taskData.data.results[i].name,
          end: taskData.data.results[i].end,
          type: 'task',
        });
      }

      return milestonesAndTasks.sort(
        (a, b) => new Date(a.end).getTime() - new Date(b.end).getTime(),
      );
    }

    return null;
  };
  useEffect(() => {
    if (milestoneData && taskData) {
      setSortedMilestonesAndTasks(sortMilestonesAndTasksDatesDescManner());
    }
  }, [milestoneData, taskData]);

  const columnsPaymentSummary: ColumnDefs<MilestoneDto>[] = [
    {
      field: 'name',
      flex: 0.6,
      icon: showDocumentIcon ? <DocumentIcon fill="primaryDark.600" /> : undefined,
      valueFormatter: (params) => {
        const cooperationCompanyData = useGetCooperationCompany(params.project);
        return cooperationCompanyData?.name;
      },
    },
    {
      field: 'budget',
      flex: 0.4,
      columnsStyle: { textAlign: 'right', fontWeight: 400, variant: 'body2' },
      valueFormatter: (params) => formatCurrency(params.budget, params.currency as EnumCurrency),
    },
    {
      field: 'paymentStatus',
      flex: 0.01,
      columnsStyle: { textAlign: 'right' },
      renderColumn: (params) => (
        <StatusChipField text={params.paymentStatus} componentReturnType="dot" />
      ),
    },
  ];

  const columnsDeadlines: ColumnDefs<IUpcomingDeadline>[] = [
    {
      field: 'name',
      flex: 0.6,
    },
    {
      field: 'end',
      flex: 0.4,
      columnsStyle: { textAlign: 'right', fontWeight: 400, variant: 'body2' },
      valueFormatter: (params) => {
        if (params.end !== null) {
          return formatDateFns(params.end);
        }

        return '-';
      },
    },
    {
      field: 'status',
      flex: 0.01,
      columnsStyle: { textAlign: 'right' },
      renderColumn: (params) => (
        <StatusChipField text={params.status as string} componentReturnType="dot" />
      ),
    },
  ];

  const contractColumns: ColumnDefs<ContractDto>[] = [
    {
      field: 'name',
      flex: 1,
      icon: <ContractDocumentIcon fill={secondaryBlue[500]} />,
      iconStyle: { mb: '1.2rem' },
      secondaryField: (params) => (
        <CustomReferenceField
          resource="projects"
          id={params.project as string}
          displayName="name"
          variant="heading4"
          color="primaryDark.400"
          renderComponent={(data) => {
            let truncateLength = 26;
            if (isBetweenMdLg) {
              truncateLength = 62;
            }
            if (isBetweenLgGr) {
              truncateLength = 26;
            }
            if (isBetweenGrXl) {
              truncateLength = 42;
            }
            if (isBetweenXlXxl) {
              truncateLength = 55;
            }
            if (isBiggerThanXl) {
              truncateLength = 80;
            }
            return (
              <CustomTooltip title={data.name} placement="top">
                <Typography variant="body2" color="primaryDark.400">
                  {truncate(data.name || '', truncateLength)}
                </Typography>
              </CustomTooltip>
            );
          }}
        />
      ),
    },
    {
      field: 'status',
      flex: 0.5,
      align: 'end',
      renderColumn: (params) => <StatusChipField text={params.status} componentReturnType="chip" />,
    },
  ];

  return (
    <Stack spacing={6.5} direction="column" width="100%">
      <BannerNotificationMapper screen={EnumScreenList.DashboardHome} />
      <Stack spacing={DASHBOARD_ELEMENTS_SPACING}>
        <Stack
          height={{ sm: '100%', lg: 250 }}
          spacing={3}
          direction={{ sm: 'column', lg: 'row' }}
          justifyContent="space-between"
        >
          <DashboardCard
            headline="Payment summary"
            link="./payments"
            mainCardClassName="react-joyride-step-5"
            sx={{ width: { sm: '100%', lg: '49%' }, pb: 1.5 }}
            renderComponentSx={{ height: '100%', overflowY: 'auto' }}
            renderComponent={
              <CustomList<MilestoneDto>
                items={milestoneData?.data?.results.slice(-3) ?? []}
                columns={columnsPaymentSummary}
              />
            }
          />

          <DashboardCard
            headline="Upcoming deadlines"
            sx={{ width: { sm: '100%', lg: '49%' }, pb: 1.5 }}
            mainCardClassName="react-joyride-step-6"
            renderComponentSx={{ height: '100%', overflowY: 'auto' }}
            renderComponent={
              <CustomList<IUpcomingDeadline>
                items={sortedMilestonesAndTasks?.slice(-3) ?? []}
                onClick={(data) => navigate(`./milestone/${data.id}`)}
                columns={columnsDeadlines}
              />
            }
          />
        </Stack>

        <Stack
          height={{ sm: '100%', lg: 323 }}
          spacing={DASHBOARD_ELEMENTS_SPACING}
          direction={{ sm: 'column', lg: 'row' }}
          justifyContent="space-between"
        >
          <DashboardCard
            headline={isUserBuyer ? 'Payments' : 'Earnings'}
            mainCardClassName="react-joyride-step-7"
            sx={{ width: { sm: '100%', lg: '49%' }, height: { sm: 296, lg: '100%' } }}
            renderComponent={
              <RevenueChart
                milestones={milestoneData?.data.results as MilestoneDto[]}
                currency={milestoneData?.data.results[0]?.currency as EnumCurrency}
              />
            }
            renderComponentSx={{ height: '100%', width: '100%' }}
          />

          <DashboardCard
            headline="Contracts"
            link="./contracts"
            mainCardClassName="react-joyride-step-8"
            sx={{
              position: 'relative',
              width: { sm: '100%', lg: '49%' },
              height: { sm: 296, lg: '100%' },
            }}
            renderComponentSx={{ height: '100%', width: '100%' }}
            renderComponent={
              <CustomList<ContractDto>
                items={contractData?.data.results ?? []}
                columns={contractColumns}
                onClick={(data) => {
                  navigate('./contracts', { state: data });
                }}
                sx={{ gap: 0 }}
              />
            }
          />
        </Stack>
      </Stack>
      <ProjectListTable />
      <CustomScrollFade />
    </Stack>
  );
};

export default EndToEndProjectList;
