import { IconButton, Stack, Tabs, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TabContext } from '@mui/lab';
import { Box } from '@mui/system';
import Joyride from 'react-joyride';
import { ContractDto } from 'tdc-web-backend/contracts/schemas';
import { CompanyRole, EnumTutorialStatus } from 'tdc-web-backend/enums/enums';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import ContractTabPanel, { ContractTab } from './ContractTabularComponents';
import useJoyride from '../../../../utils/hooks/joyride-hooks/useJoyride';
import { ContractsDetailDrawerJoyride } from '../../../../utils/hooks/joyride-hooks/ContractsDetailScreenJoyride';
import JoyrideTooltip from '../../../../utils/hooks/joyride-hooks/JoyrideTooltip';
import useUserCompany from '../../../../utils/hooks/useUserCompany';
import CustomSortingMenu from '../../../../components/custom-sorting-menu/CustomSortingMenu';
import useAuth from '../../../../utils/hooks/useAuth';

const sortingMap = new Map([
  ['created', 'Date created - First'],
  ['-created', 'Date created - Last'],
  ['name', 'Alphabetical A-Z'],
  ['-name', 'Alphabetical Z-A'],
  ['signed', 'Last signed - First'],
  ['-signed', 'Last signed - Last'],
]);

const firstSortingOption = sortingMap.keys().next().value;

const ContractListScreen = () => {
  const {
    authData: { userData },
  } = useAuth();
  const isBuyer = useUserCompany()?.roles?.includes(CompanyRole.Buyer);
  const [selectedTab, setSelectedTab] = useState('-1');
  const [sort, setSort] = useState<string>(firstSortingOption as string);
  const navigate = useNavigate();

  const { data: contractsAllResponse } = useGetMany<ContractDto>({
    resource: `/contracts?company=${userData?.membership?.company}&type=contract&orderBy=${sort}`,
    enabled: !!userData,
  });

  const { data: contractsActiveResponse } = useGetMany<ContractDto>({
    resource: `/contracts?company=${userData?.membership?.company}&type=contract&orderBy=${sort}&status=active`,
    enabled: !!userData,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const { data: contractsNegotiationResponse } = useGetMany<ContractDto>({
    resource: `/contracts?company=${userData?.membership?.company}&type=contract&orderBy=${sort}&status=negotiation`,
    enabled: !!userData,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const { data: contractsAcceptedResponse } = useGetMany<ContractDto>({
    resource: `/contracts?company=${userData?.membership?.company}&type=contract&orderBy=${sort}&status=accepted`,
    enabled: !!userData,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const { data: contractsCanceledResponse } = useGetMany<ContractDto>({
    resource: `/contracts?company=${userData?.membership?.company}&type=contract&orderBy=${sort}&status=canceled`,
    enabled: !!userData,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    event.stopPropagation();
    setSelectedTab(newValue);
  };

  // joyride for ContractDetail gets triggered from the hook useJoyride itself
  // because it gets called upper in elements tree
  const { joyride, runJoyride, tutorials, updateJoyride, update } = useJoyride();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  // trigger for when you open drawer; should occur only once overall
  useEffect(() => {
    if (tutorials) {
      if (!tutorials.includes(EnumTutorialStatus.IndividualContractDetail)) {
        // setTimeout() is here to prevent runJoyride() firing off before
        // the DOM for ContractDetailsDrawer gets loaded
        setTimeout(() => {
          runJoyride();
          update(EnumTutorialStatus.IndividualContractDetail);
        }, 400);
      }
    }
  }, [isDrawerOpen, tutorials]);

  return (
    <>
      {/* joyride for drawer elements */}
      {/* we don't need a conditional render because by default elements
      (classes) for ContractsDetailDrawerJoyride are not rendered by default on mount,
      they are shown after you click on a ContractCardItem and open ContractDetailsDrawer */}
      <Joyride
        steps={ContractsDetailDrawerJoyride(isBuyer ?? false)}
        run={joyride.run}
        scrollDuration={400}
        scrollToFirstStep
        showSkipButton
        disableOverlayClose
        disableScrolling={
          joyride.steps[joyride.stepIndex] && joyride.steps[joyride.stepIndex].disableScrolling
        }
        stepIndex={joyride.stepIndex}
        callback={(e) => updateJoyride(e)}
        continuous
        tooltipComponent={(props) => JoyrideTooltip(props)}
      />

      <Stack spacing={2}>
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <IconButton
            onClick={() => navigate('../')}
            sx={{
              border: '1px solid',
              borderColor: 'primaryDark.300',
              mr: '24px',
              width: '45px',
              height: '45px',
            }}
          >
            <ArrowBack sx={{ height: '0.75em' }} />
          </IconButton>
          <Typography sx={{ textTransform: 'uppercase' }}>back</Typography>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Stack direction="column" sx={{ width: '100%' }}>
            <Box sx={{ width: '100%', height: 'fit-content', position: 'relative' }}>
              <TabContext value={selectedTab}>
                <Stack sx={{ width: '100%' }}>
                  <Stack
                    sx={{ width: '100%' }}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Tabs
                      className="react-joyride-step-1-contracts"
                      value={selectedTab}
                      onChange={handleTabChange}
                      aria-label="secondary tabs example"
                      sx={{
                        mt: 2,
                        p: 0,
                        zIndex: 1,
                      }}
                      TabIndicatorProps={{
                        style: { display: 'none' },
                      }}
                    >
                      <ContractTab
                        resultCount={contractsAllResponse?.data.count as number}
                        value="-1"
                        selectedTab={selectedTab}
                        label="All"
                      />
                      <ContractTab
                        resultCount={contractsActiveResponse?.data.count as number}
                        value="-2"
                        selectedTab={selectedTab}
                        label="Active"
                        className="react-joyride-step-2-contracts"
                      />
                      <ContractTab
                        resultCount={contractsNegotiationResponse?.data.count as number}
                        value="-3"
                        selectedTab={selectedTab}
                        label="Negotiation"
                        className="react-joyride-step-3-contracts"
                      />
                      <ContractTab
                        resultCount={contractsAcceptedResponse?.data.count as number}
                        value="-4"
                        selectedTab={selectedTab}
                        label="Accepted"
                        className="react-joyride-step-4-contracts"
                      />
                      <ContractTab
                        resultCount={contractsCanceledResponse?.data.count as number}
                        value="-5"
                        selectedTab={selectedTab}
                        label="Canceled"
                        className="react-joyride-step-5-contracts"
                      />
                    </Tabs>
                    <CustomSortingMenu sortingMap={sortingMap} setSort={setSort} sort={sort} />
                  </Stack>
                  <ContractTabPanel
                    value="-1"
                    data={contractsAllResponse?.data.results}
                    onCardClick={(open) => setIsDrawerOpen(open)}
                  />

                  <ContractTabPanel
                    data={contractsActiveResponse?.data.results}
                    value="-2"
                    onCardClick={(open) => setIsDrawerOpen(open)}
                  />

                  <ContractTabPanel
                    data={contractsNegotiationResponse?.data.results}
                    value="-3"
                    onCardClick={(open) => setIsDrawerOpen(open)}
                  />

                  <ContractTabPanel
                    data={contractsNegotiationResponse?.data.results}
                    value="-4"
                    onCardClick={(open) => setIsDrawerOpen(open)}
                  />

                  <ContractTabPanel
                    data={contractsNegotiationResponse?.data.results}
                    value="-5"
                    onCardClick={(open) => setIsDrawerOpen(open)}
                  />
                </Stack>
              </TabContext>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ContractListScreen;
