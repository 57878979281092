import { Dispatch, SetStateAction } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as ErrorAlertIcon } from '../../../assets/icons/layout-icons/ErrorAlertIcon.svg';
import { secondaryBlue, secondaryPink } from '../../../utils/color';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import ConfirmModal from '../../confirm-modal/ConfirmModal';

const modalsContent = {
  success: {
    title: 'Successfully subscribed',
    text: (
      <>
        Thank you for subscribing to our newsletter! <br /> <br />
        Check your inbox for a welcome email from us which includes helpful information to get you
        started.
      </>
    ),
    icon: <CheckIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />,
    wrapperBackgroundColor: secondaryBlue[100],
  },
  error: {
    title: 'Error',
    text: <>Something went wrong.Please try again later.</>,
    icon: <ErrorAlertIcon fill={secondaryPink[700]} style={{ width: 24, height: 24 }} />,
    wrapperBackgroundColor: secondaryPink[100],
  },
};

type SubscribeModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  type: 'success' | 'error';
  onConfirm?: () => void;
};

const SubscribeModal = ({ isOpen, setIsOpen, type, onConfirm }: SubscribeModalProps) => (
  <ConfirmModal
    isModalOpen={isOpen}
    setIsModalOpen={setIsOpen}
    title={modalsContent[type].title}
    message={
      <Typography color="primaryDark.500" lineHeight="21px">
        {modalsContent[type].text}
      </Typography>
    }
    confirmButtonText="OK"
    onConfirm={onConfirm}
    iconProps={{
      icon: modalsContent[type].icon,
      wrapperBackgroundColor: modalsContent[type].wrapperBackgroundColor,
    }}
    showCancelButton={false}
  />
);
export default SubscribeModal;
