import { Dispatch, SetStateAction } from 'react';
import ConfirmModal from '../../../../../../../components/confirm-modal/ConfirmModal';
import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import { ReactComponent as SuitcaseCheckIcon } from '../../../../../../../assets/icons/layout-icons/SuitcaseCheckIcon.svg';
import { primaryLight } from '../../../../../../../utils/color';
import { FormProvider, useForm } from 'react-hook-form';
import { useRefresh } from '../../../../../../../utils/hooks/crud-hooks/useRefresh';
import { Stack } from '@mui/system';
import CustomInput from '../../../../../../../components/custom-input/CustomInput';
import CustomChip from '../../../../../../../components/custom-chip/CustomChip';
import useUpdate from '../../../../../../../utils/hooks/crud-hooks/useUpdate';
import { ReactComponent as PlusIcon } from '../../../../../../../assets/icons/button-icons/PlusIcon-Seller-EditModal.svg';
import CustomButton from '../../../../../../../components/button/CustomButton';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';

interface CompanyCertificationsEditModalProps {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
}

interface FormValues extends Pick<CompanyDto, 'certifications'> {
  newCertification: string;
}

const CompanyCertificationsEditModal = ({
  isEditModalOpen,
  setIsEditModalOpen,
}: CompanyCertificationsEditModalProps) => {
  const { companyData } = useSellerHomescreenContext();
  const refresh = useRefresh();
  const formMethods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      certifications: companyData?.certifications || [],
      newCertification: '',
    },
  });

  const { handleSubmit, setValue, getValues, watch } = formMethods;

  const certifications = watch('certifications') || [];
  const newCertificationValue = watch('newCertification');

  const handleAddCertification = () => {
    const newCertification = getValues('newCertification') || '';
    const certifications = getValues('certifications') || [];
    if (certifications.includes(newCertification.trim())) {
      formMethods.setError('newCertification', {
        type: 'manual',
        message: 'Certification already added!',
      });
      return;
    }
    const updatedCertifications = [...certifications, newCertification.trim()];
    setValue('certifications', updatedCertifications);
    setValue('newCertification', '');
  };

  const handleRemoveCertification = (index: number) => {
    const certifications = getValues('certifications') || [];
    const updatedCertifications = certifications.filter((_, i) => i !== index);
    setValue('certifications', updatedCertifications);
  };

  const { mutate, isLoading } = useUpdate<ResponseType, Partial<CompanyDto>>({
    resource: 'companies',
  });

  const onSubmit = (data: FormValues) => {
    mutate(
      {
        id: companyData?.id as string,
        data: { certifications: data.certifications },
      },
      {
        onSuccess: () => {
          refresh();
          setIsEditModalOpen(false);
        },
      },
    );
  };

  return (
    <ConfirmModal
      width="40%"
      confirmButtonText="Save"
      onCancel={() => setIsEditModalOpen(false)}
      title="Certifications"
      isModalOpen={isEditModalOpen}
      setIsModalOpen={setIsEditModalOpen}
      automaticallyCloseModalOnButtonClick={false}
      isLoading={isLoading}
      iconProps={{
        icon: <SuitcaseCheckIcon fill={primaryLight[500]} />,
        wrapperBackgroundColor: primaryLight[100],
      }}
      onConfirm={handleSubmit(onSubmit)}
      message={
        <FormProvider {...formMethods}>
          <Stack direction="column" gap={1}>
            <CustomInput
              name="newCertification"
              input={<CustomInput.Text />}
              placeholder="Type certificate name"
              label="Certificate name"
              sx={{ minHeight: 'none' }}
            />
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {certifications.map((certification: string, index: number) => (
                <CustomChip
                  key={`${certification}-${index}`}
                  label={certification}
                  onDelete={() => handleRemoveCertification(index)}
                />
              ))}
            </Stack>

            <CustomButton
              variant="primaryLight"
              sx={{
                boxShadow: 'none',
                alignSelf: 'flex-end',
                mt: '10px',
              }}
              disabled={!newCertificationValue || newCertificationValue.trim() === ''}
              onClick={handleAddCertification}
              startIcon={<PlusIcon />}
            >
              Add Certification
            </CustomButton>
          </Stack>
        </FormProvider>
      }
    />
  );
};

export default CompanyCertificationsEditModal;
