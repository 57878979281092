import { Typography, Box, useMediaQuery } from '@mui/material';
import { memo } from 'react';
import { primaryDark } from '../../../../../utils/color';
import HelloUserSmallImage from '../../../../../assets/img/hello-user-new.svg';
// import HelloUserBigImage from '../../../../../assets/img/hello-user-new.svg';
import { useSellerHomescreenContext } from '../SellerHomescreenContext';
import theme from '../../../../../utils/theme';

const HelloBanner = () => {
  const { user } = useSellerHomescreenContext();

  //leaving former variables in case of new picture or more changes
  // const isLessThanExtraLarge = useMediaQuery(theme.breakpoints.down('xl'));
  // const imageSrc = isLessThanExtraLarge ? HelloUserSmallImage : HelloUserBigImage;

  return (
    <Box sx={{ position: 'relative' }}>
      <Box component="img" src={HelloUserSmallImage} sx={{ height: '100%', width: '100%' }} />

      {/* text & speech box */}
      <Box
        sx={{
          position: 'absolute',
          bottom: { md: '6%', xl: '7%' },
          left: { md: '25%', xl: '20%' },
          top: { md: '65%', xl: '70%' },
          width: '100%',
        }}
      >
        {/* <Typography
          sx={{
            color: 'common.white',
            typography: { md: 'publicBody4Light', xl: 'publicBody3Light' },
          }}
        >
          Hope you have a good day
        </Typography> */}

        {/* speech box */}
        <Box>
          <Box
            sx={{
              position: 'absolute',
              top: { md: '-186%', xl: '-155%' },
              left: { md: '-7%', xl: '-3%' },
              backgroundColor: 'primaryDark.900',
              color: '#fff',
              padding: '10px',
              borderRadius: '10px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              '::before': {
                content: '""',
                position: 'absolute',
                left: '15px',
                bottom: '-14px',
                borderWidth: '20px 20px 0',
                borderStyle: 'solid',
                borderColor: `${primaryDark[900]} transparent transparent transparent`,
              },
            }}
          >
            <Typography
              sx={{
                typography: 'publicHeading5',
                fontSize: { md: '18px', xl: '22px' },
              }}
            >
              Hello, {user?.firstName}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(HelloBanner);
