import type { RouteObject } from 'react-router-dom';
import { CompanyRole } from 'tdc-web-backend/enums/enums';
import Layout from './layout/public-layout/Layout';
import ChatScreen from './views/Chat/ChatScreen';
import PostRegistration from './views/Company/PostRegistration/PostRegistration';
import Dashboard from './views/Dashboard/Dashboard';
import ProjectDetailScreen from './views/EndToEnd/ProjectDetail/ProjectDetailScreen/ProjectDetailScreen';
import MilestoneDetailScreen from './views/EndToEnd/Milestone/DetailScreen/MilestoneDetailScreen';
import EndToEndProjectList from './views/EndToEnd/E2Ehomescreen/EndToEndProjectList';
import Homepage from './views/Home/Homepage';
import NotFound from './views/NotFound/NotFound';
import ContractListScreen from './views/EndToEnd/Contracts/contractList/ContractListScreen';
import EndToEnd from './views/EndToEnd/EndToEnd';
import Admin from './views/Admin/Admin';
import AdminHome from './views/Admin/AdminHome';
import AdminEndToEnd from './views/Admin/end-to-end/AdminEndToEnd';
import AdminFlagging from './views/Admin/end-to-end/flagging/AdminFlagging';
import AdminPayments from './views/Admin/end-to-end/payments/AdminPayments';
import AdminCreateProject from './views/Admin/end-to-end/projects/create-project/AdminCreateProject';
import NotificationScreen from './components/notifications/NotificationScreen';
import AllPaymentsScreen from './views/EndToEnd/Payments/PaymentsList/AllPaymentsScreen';
import AdminProjectList from './views/Admin/end-to-end/projects/project-list/AdminProjectList';
import AdminProjectDetails from './views/Admin/end-to-end/projects/project-details/AdminProjectDetails';
import AdminCompanyList from './views/Admin/companies/company-list/AdminCompanyList';
import CompanyDetails from './views/Admin/companies/company-details/CompanyDetails';
import AdminCompanyEdit from './views/Admin/companies/company-edit/CompanyEdit';
import ProjectPaymentDetails from './views/EndToEnd/Payments/PaymentDetails/ProjectPaymentDetails';
import AdminContractDetailScreen from './views/Admin/end-to-end/contracts/AdminContractDetails';
import ContractEditor from './views/EndToEnd/Contract/ContractEditor';
import PitchDetailScreen from './views/Pitch/DetailScreen/PitchDetailScreen';
import Pitch from './views/Pitch/Pitch';
import CalendarScreen from './views/Calendar/CalendarScreen';
import TimedProjects from './views/Dashboard/TimedProjects/TimedProjects';
import CreateTimedProject from './views/Dashboard/TimedProjects/create/CreateTimedProject';
import HowToHire from './views/PublicScreens/HowToHire/HowToHire';
import TimedProjectsSubpage from './views/PublicScreens/TimedProjectSubpage/TimedProjectSubpage';
import SponaConnectSubpage from './views/PublicScreens/SponaConnectSubpage/SponaConnectSubpage';
import HowToFindWork from './views/PublicScreens/HowToFindWork/HowToFindWork';
import CaseStudies from './views/Dashboard/CaseStudies/CaseStudies';
import HowToManageSeller from './views/PublicScreens/HowToManage/HowToManageSeller';
import CaseStudiesCreate from './views/Dashboard/CaseStudies/CaseStudiesCreate/CaseStudiesCreate';
import TimedProjectsDetails from './views/Dashboard/TimedProjects/TimedProjectsDetails/TimedProjectsDetails';
import TimedProjectsDetailsAdmin from './views/Admin/timed-project/TimedProjectsDetailsAdmin';
import ContactUs from './views/PublicScreens/ContactUs/ContactUs';
import AboutUs from './views/PublicScreens/AboutUs/AboutsUs';
import CaseStudiesListTable from './views/Admin/case-study/CaseStudies';
import TimedProjectsListTable from './views/Admin/timed-project/TimedProjects';
import TermsOfServicePage from './views/PublicScreens/TermsOfService/TermsOfServicePage';
import WorkWithUs from './views/PublicScreens/WorkWithUs/WorkWithUs';
import SponaMedia from './views/PublicScreens/SponaMedia/SponaMedia';
import CaseStudiesDetails from './views/Admin/case-study/CaseStudiesDetails';
import CreatePitch from './views/Pitch/create/CreatePitch';
import PitchingList from './views/Admin/pitching/PitchingList';
import PitchingDetails from './views/Admin/pitching/PitchingDetails';
import CaseStudyReviews from './views/Admin/case-study-review/CaseStudyReviews';
import CaseStudyReviewDetails from './views/Admin/case-study-review/CaseStudyReviewDetails';
import CaseStudyReview from './views/Dashboard/CaseStudies/CaseStudyReview/unregistered/CaseStudyReview';
import CaseStudyReviewForm from './views/Dashboard/CaseStudies/CaseStudyReview/registered/CaseStudyReviewBuyerForm';
import CaseStudyReviewFormPublic from './views/Dashboard/CaseStudies/CaseStudyReview/unregistered/CaseStudyReviewBuyerForm';
import HowToManageBuyer from './views/PublicScreens/HowToManage/HowToManageBuyer';
import HowToManage from './views/PublicScreens/HowToManage/HowToManage';
import DigitalContractsSeller from './views/PublicScreens/HowToManage/seller-subpages/DigitalContracts';
import PaymentProcessingSeller from './views/PublicScreens/HowToManage/seller-subpages/PaymentProcessing';
import MilestonesAndTasksSeller from './views/PublicScreens/HowToManage/seller-subpages/MilestonesAndTasks';
import CommunicationSeller from './views/PublicScreens/HowToManage/seller-subpages/Communication';
import MonitorYourKPIsSeller from './views/PublicScreens/HowToManage/seller-subpages/MonitorYourKPIs';
import DigitalContractsBuyer from './views/PublicScreens/HowToManage/buyer-subpages/DigitalContracts';
import PaymentProcessingBuyer from './views/PublicScreens/HowToManage/buyer-subpages/PaymentProcessing';
import MilestonesAndTasksBuyer from './views/PublicScreens/HowToManage/buyer-subpages/MilestonesAndTasks';
import CommunicationBuyer from './views/PublicScreens/HowToManage/buyer-subpages/Communication';
import MonitorYourKPIsBuyer from './views/PublicScreens/HowToManage/buyer-subpages/MonitorYourKPIs';
import TermsOfServicePageOutlet from './views/PublicScreens/TermsOfService/TermsOfServicePageOutlet';
import Account from './views/Account/Account';
import LoginSection from './views/Account/Login/LoginSection';
import RegisterSection from './views/Account/Register/RegisterSection';
import VerifyEmailSection from './views/Account/VerifyEmail/VerifyEmailSection';
import ActivateAccountSection from './views/Account/ActivateAccount/ActivateAccountSection';
import ForgotPasswordSection from './views/Account/ForgotPassword/ForgotPasswordSection';
import PasswordChangeSection from './views/Account/NewPassword/components/PasswordChangeSection';
import Homescreen from './views/Dashboard/Homescreen/Homescreen';
import AdminCompanyCreateNew from './views/Admin/companies/company-create/AdminCompanyCreateNew';
import PitchingAdminCreate from './views/Admin/pitching/PitchingAdminCreate';
import CaseStudiesAdminCreate from './views/Admin/case-study/CaseStudiesAdminCreate';
import AdminCreateTimedProjects from './views/Admin/timed-project/timed-projects-create/AdminCreateTimedProjects';
import ConfirmAccountLeftSideSection from './views/Account/ConfirmAccount/ConfirmAccountLeftSideSection';
import ConfirmAccountSection from './views/Account/ConfirmAccount/ConfirmAccountSection';
import FooterEdit from './views/Admin/footer/FooterEdit';
import CaseStudyArchive from './views/PublicScreens/Archives/CaseStudyArchive/CaseStudyArchive';
import CaseStudySubarchive from './views/PublicScreens/Archives/CaseStudyArchive/CaseStudySubarchive';
import SellerArchiveEdit from './views/Admin/archives/SellerArchiveEdit';
import SellerArchiveList from './views/Admin/archives/SellerArchiveList';
import CaseStudyArchiveEdit from './views/Admin/archives/CaseStudyArchiveEdit';
import CaseStudyArchiveList from './views/Admin/archives/CaseStudyArchiveList';
import CaseStudyDetails from './views/PublicScreens/CaseStudy/CaseStudyDetails';
import SellerArchive from './views/PublicScreens/Archives/SellerArchive/SellerArchive';
import SellerSubarchive from './views/PublicScreens/Archives/SellerArchive/SellerSubarchive';
import CompanyProfilePublicScreen from './views/PublicScreens/CompanyProfilePublicScreen/CompanyProfilePublicScreen';
import Team from './views/Dashboard/Homescreen/Team/Team';
import Ebook from './views/PublicScreens/Ebook/Ebook';
import BlogList from './views/Admin/blogs/BlogList';
import BlogCreateEdit from './views/Admin/blogs/BlogCreateEdit';
import BlogPreview from './views/Admin/blogs/BlogPreview';
import Blog from './views/PublicScreens/Blog/Blog';
import BlogArchive from './views/PublicScreens/Archives/BlogArchive/BlogArchive';
import Newsletter from './views/Admin/newsletter/Newsletter';
import FeaturedBlogs from './views/Admin/featured-blogs/FeaturedBlogs';
import BuyerPricing from './views/PublicScreens/Pricing/BuyerPricing';
import SellerPricing from './views/PublicScreens/Pricing/SellerPrcing';
import EmbracingAI from './views/PublicScreens/Ebook/components/EmbracingAI';
import B2bInfographic from './views/PublicScreens/Ebook/components/B2bInfographic';
import ProjectRequest from './views/EndToEnd/ProjectRequest/ProjectRequest';
import ProjectRequests from './views/Admin/project-requests/ProjectRequests';
import AdminProjectRequest from './views/Admin/project-requests/ProjectRequest/ProjectRequest';
import SponaInfobip from './views/PublicScreens/Ebook/components/SponaInfobip';
import Settings from './views/Dashboard/Settings/Settings';
import Billing from './views/Dashboard/Settings/components/Billing';
import { default as AccountSettings } from './views/Dashboard/Settings/components/Account';
import Members from './views/Dashboard/Settings/components/Members/Members';
import Security from './views/Dashboard/Settings/components/Security';
import Root from './layout/Root';
import ProtectedRoute, { ProtectedRouteLevel } from './components/protected-route/ProtectedRoute';
import AcceptInvitation from './views/Account/AcceptInvitation/AcceptInvitation';
import Member from './views/Dashboard/Settings/components/Members/Member/Member';

const routes = (): RouteObject[] => [
  {
    element: <Root />,
    children: [
      {
        element: <Layout />,
        children: [
          {
            path: '*',
            element: <NotFound />,
          },
        ],
      },
      {
        path: '/',
        element: <Layout />,
        children: [
          {
            index: true,
            element: <Homepage />,
          },
          {
            path: 'case-study-archive',
            element: <CaseStudyArchive />,
          },
          {
            path: 'case-study-subarchive',
            element: <CaseStudySubarchive />,
          },
          {
            path: 'case-study/:caseStudyId',
            element: <CaseStudyDetails />,
          },
          {
            path: 'blog/:blogSlug',
            element: <Blog />,
          },
          {
            path: 'seller-archive',
            element: <SellerArchive />,
          },
          {
            path: 'seller-subarchive',
            element: <SellerSubarchive />,
          },
          {
            path: 'blog-archive',
            element: <BlogArchive />,
          },
          {
            path: 'how-to-manage-seller',
            element: <HowToManage />,
            children: [
              {
                index: true,
                element: <HowToManageSeller />,
              },
              {
                path: 'digital-contracts',
                element: <DigitalContractsSeller />,
              },
              {
                path: 'payment-processing',
                element: <PaymentProcessingSeller />,
              },
              {
                path: 'milestones-and-tasks',
                element: <MilestonesAndTasksSeller />,
              },
              {
                path: 'communication',
                element: <CommunicationSeller />,
              },
              {
                path: 'monitor-your-kpis',
                element: <MonitorYourKPIsSeller />,
              },
              {
                path: 'pricing',
                children: [{ index: true, element: <SellerPricing /> }],
              },
            ],
          },
          {
            path: 'how-to-manage-buyer',
            element: <HowToManage />,
            children: [
              {
                index: true,
                element: <HowToManageBuyer />,
              },
              {
                path: 'digital-contracts',
                element: <DigitalContractsBuyer />,
              },
              {
                path: 'payment-processing',
                element: <PaymentProcessingBuyer />,
              },
              {
                path: 'milestones-and-tasks',
                element: <MilestonesAndTasksBuyer />,
              },
              {
                path: 'communication',
                element: <CommunicationBuyer />,
              },
              {
                path: 'monitor-your-kpis',
                element: <MonitorYourKPIsBuyer />,
              },
              {
                path: 'pricing',
                children: [{ index: true, element: <BuyerPricing type="manage" /> }],
              },
              {
                path: 'seller-archive',
                element: <SellerArchive />,
              },
              {
                path: 'seller-subarchive',
                element: <SellerSubarchive />,
              },
            ],
          },
          {
            path: 'how-to-hire',
            children: [
              {
                index: true,
                element: <HowToHire />,
              },
              {
                path: 'spona-connect',
                element: <SponaConnectSubpage />,
              },
              {
                path: 'timed-projects',
                element: <TimedProjectsSubpage />,
              },
              {
                path: 'pricing',
                children: [{ index: true, element: <BuyerPricing type="hire" /> }],
              },
            ],
          },
          {
            path: '/contact-us',
            element: <ContactUs />,
          },
          {
            path: 'company/:companyId',
            element: <CompanyProfilePublicScreen />,
          },
          {
            path: '/how-to-find-work',
            element: <HowToFindWork />,
          },
          {
            path: '/about-us',
            element: <AboutUs />,
          },
          {
            path: 'e-book',
            element: <Ebook />,
            children: [
              { path: 'embracing-ai', element: <EmbracingAI /> },
              { path: 'b2b-infographic', element: <B2bInfographic /> },
              { path: 'innovative-agency-growth-strategies', element: <SponaInfobip /> },
            ],
          },
          // FIXME: commented out for version 1
          // {
          //   path: '/enterprise',
          //   element: <Enterprise />,
          // },
          {
            path: 'terms-of-service',
            element: <TermsOfServicePage />,
            children: [
              {
                path: 'privacy-policy',
                element: <TermsOfServicePageOutlet />,
              },
              {
                path: 'spona-digital-contract-terms',
                element: <TermsOfServicePageOutlet />,
              },
              {
                path: 'cookie-policy',
                element: <TermsOfServicePageOutlet />,
              },
            ],
          },
          {
            path: '/work-with-us',
            element: <WorkWithUs />,
          },
          {
            path: '/spona-media',
            element: <SponaMedia />,
          },
          // FIXME: commented out for version 1
          // {
          //   path: '/faq',
          //   element: <Faq />,
          // },
          {
            path: '/case-study/:caseStudyId/submit-review',
            element: <CaseStudyReview />,
          },
          {
            path: '/case-study-review-submit/:caseStudyId/:token/:contentId',
            element: <CaseStudyReviewForm />,
          },
          {
            path: '/case-study-review-submit-public/:caseStudyId/:token/:contentId',
            element: <CaseStudyReviewFormPublic />,
          },
        ],
      },
      {
        path: 'login',
        element: (
          <ProtectedRoute
            level={[
              [ProtectedRouteLevel.AccessTokenFalse],
              [ProtectedRouteLevel.AgreementDateFalse],
            ]}
            navigateOnVerificationFalse={{ to: '/dashboard' }}
          >
            <Account
              accountSection={<LoginSection />}
              accountSectionWrapperSx={{
                height: { xs: '100%', lg: 'auto' },
                justifyContent: 'space-between',
              }}
            />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <Account
                accountSection={<LoginSection />}
                checkForScreenWidth={false}
                accountSectionWrapperSx={{
                  height: { xs: '100%', lg: '70%' },
                  pt: { xs: '40px', lg: 0 },
                }}
              />
            ),
          },
        ],
      },
      {
        path: 'register',
        element: (
          <ProtectedRoute
            level={[[ProtectedRouteLevel.AccessTokenFalse]]}
            navigateOnVerificationFalse={{ to: '/' }}
          >
            <Account
              accountSection={<RegisterSection />}
              accountSectionWrapperSx={{
                height: { xs: '100%', lg: '70%' },
                pt: { xs: '40px', lg: 0 },
              }}
            />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <Account
                accountSection={<RegisterSection />}
                checkForScreenWidth={false}
                accountSectionWrapperSx={{
                  height: { xs: '100%', lg: '70%' },
                  pt: { xs: '40px', lg: 0 },
                }}
              />
            ),
          },
        ],
      },
      {
        path: 'accept-invitation',
        element: (
          <ProtectedRoute
            level={[[ProtectedRouteLevel.AccessTokenFalse]]}
            navigateOnVerificationFalse={{ to: '/dashboard' }}
          >
            <Account
              accountSection={<AcceptInvitation />}
              accountSectionWrapperSx={{
                height: { xs: '100%', lg: '70%' },
                pt: { xs: '40px', lg: 0 },
              }}
            />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <Account
                accountSection={<AcceptInvitation />}
                checkForScreenWidth={false}
                accountSectionWrapperSx={{
                  height: { xs: '100%', lg: '70%' },
                  pt: { xs: '40px', lg: 0 },
                }}
              />
            ),
          },
        ],
      },
      {
        path: 'confirm-account',
        element: (
          <ProtectedRoute
            level={[[ProtectedRouteLevel.AccessTokenFalse]]}
            navigateOnVerificationFalse={{ to: '/' }}
          >
            <Account
              leftSection={<ConfirmAccountLeftSideSection />}
              accountSection={<ConfirmAccountSection />}
              accountSectionWrapperSx={{
                height: { xs: '100%', lg: 'auto' },
                justifyContent: 'space-between',
              }}
            />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <Account
                leftSection={<ConfirmAccountLeftSideSection />}
                accountSection={<ConfirmAccountSection />}
                checkForScreenWidth={false}
                accountSectionWrapperSx={{
                  height: { xs: '100%' },
                  pt: { xs: '40px' },
                }}
              />
            ),
          },
        ],
      },
      {
        path: 'verify',
        element: (
          <ProtectedRoute
            level={[[ProtectedRouteLevel.AccessTokenFalse]]}
            navigateOnVerificationFalse={{ to: '/' }}
          >
            <Account
              accountSection={<VerifyEmailSection />}
              accountSectionWrapperSx={{
                height: { lg: '70%' },
                pt: { lg: 0 },
              }}
            />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <Account
                accountSection={<VerifyEmailSection />}
                checkForScreenWidth={false}
                accountSectionWrapperSx={{
                  height: { xs: '100%' },
                  pt: { xs: '40px' },
                }}
              />
            ),
          },
        ],
      },
      {
        path: 'activate',
        element: (
          <ProtectedRoute
            level={[[ProtectedRouteLevel.AccessTokenFalse]]}
            navigateOnVerificationFalse={{ to: '/' }}
          >
            <Account
              accountSection={<ActivateAccountSection />}
              accountSectionWrapperSx={{
                height: { lg: '70%' },
                pt: { lg: 0 },
              }}
            />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <Account
                accountSection={<ActivateAccountSection />}
                checkForScreenWidth={false}
                accountSectionWrapperSx={{
                  height: { xs: '100%' },
                  pt: { xs: '40px' },
                }}
              />
            ),
          },
        ],
      },
      {
        path: 'reset-password',
        element: (
          <ProtectedRoute
            level={[[ProtectedRouteLevel.AccessTokenFalse]]}
            navigateOnVerificationFalse={{ to: '/' }}
          >
            <Account
              accountSection={<ForgotPasswordSection />}
              leftSection={<PasswordChangeSection />}
              accountSectionWrapperSx={{
                height: { lg: '70%' },
                pt: { lg: 0 },
              }}
            />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <Account
                accountSection={<ForgotPasswordSection />}
                leftSection={<PasswordChangeSection />}
                checkForScreenWidth={false}
                accountSectionWrapperSx={{
                  height: { xs: '100%' },
                  pt: { xs: '40px' },
                }}
              />
            ),
          },
        ],
      },
      {
        path: 'dashboard',
        element: (
          // showSidebar={false} showChat={true} padding={true} bgColor="#FFF"
          <ProtectedRoute
            level={[
              [
                ProtectedRouteLevel.Membership,
                ProtectedRouteLevel.AgreementDate,
                ProtectedRouteLevel.AccessToken,
              ],
            ]}
            navigateOnVerificationFalse={[
              {
                condition: [
                  [ProtectedRouteLevel.AccessTokenFalse],
                  [ProtectedRouteLevel.AgreementDateFalse],
                ],
                navigateProps: { to: '/login' },
              },
              {
                condition: [[ProtectedRouteLevel.MembershipFalse]],
                navigateProps: { to: '/dashboard/create-company' },
              },
            ]}
          >
            <Dashboard showSidebar={false} bgColor="#FFF" />
          </ProtectedRoute>
        ),
        children: [
          {
            path: 'calendar',
            element: <CalendarScreen />,
          },
          {
            path: 'notifications',
            element: <NotificationScreen />,
          },
          {
            path: 'end-to-end',
            element: <EndToEnd />,
            children: [
              { path: 'project-request', element: <ProjectRequest /> },
              {
                path: 'project/:projectId/contract',
                element: <ContractEditor sx={{ width: '75%' }} />,
              },
              {
                path: 'project/:projectId/contract/:contractId',
                element: <ContractEditor sx={{ width: '75%' }} />,
              },
              { path: 'contracts', element: <ContractListScreen /> },
              { path: 'milestone/:milestoneId', element: <MilestoneDetailScreen /> },
            ],
          },
          {
            path: 'timed-projects',
            children: [
              { path: 'create', element: <CreateTimedProject /> },
              { path: ':projectId/edit', element: <CreateTimedProject /> },
              { path: ':projectId', element: <TimedProjectsDetails /> },
            ],
          },
          {
            path: 'case-studies',
            children: [
              { index: true, element: <CaseStudies /> },
              { path: 'create', element: <CaseStudiesCreate /> },
              { path: 'edit/:caseStudyId', element: <CaseStudiesCreate /> },
            ],
          },
          {
            path: 'pitches',
            children: [{ path: 'create', element: <CreatePitch type="create" /> }],
          },
        ],
      },
      {
        path: 'dashboard',
        element: (
          <ProtectedRoute
            level={[[ProtectedRouteLevel.AccessToken, ProtectedRouteLevel.AgreementDate]]}
            navigateOnVerificationFalse={{ to: '/login' }}
          >
            <Dashboard showSidebar={false} showChat={false} padding={false} />
          </ProtectedRoute>
        ),
        children: [
          {
            path: 'chat/:channelId',
            element: <ChatScreen />,
          },
          {
            path: 'chat',
            element: <ChatScreen />,
          },
          {
            path: 'create-company',
            element: (
              <PostRegistration />
              // <ProtectedRoute
              //   level={[[ProtectedRouteLevel.MembershipFalse]]}
              //   navigateOnVerificationFalse={{ to: '/dashboard' }}
              // >
              // </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'dashboard',
        element: (
          <ProtectedRoute
            level={[
              [
                ProtectedRouteLevel.AccessToken,
                ProtectedRouteLevel.AgreementDate,
                ProtectedRouteLevel.Membership,
              ],
            ]}
            navigateOnVerificationFalse={[
              {
                condition: [
                  [ProtectedRouteLevel.AccessTokenFalse],
                  [ProtectedRouteLevel.AgreementDateFalse],
                ],
                navigateProps: { to: '/login' },
              },
              {
                condition: [[ProtectedRouteLevel.MembershipFalse]],
                navigateProps: { to: '/dashboard/create-company' },
              },
            ]}
          >
            <Dashboard />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <Homescreen />,
          },
          {
            path: 'achievements',
            element: <p>Not implemented yet.</p>,
          },
          {
            path: 'team',
            element: <Team />,
          },
          {
            path: 'end-to-end',
            element: <EndToEnd />,
            children: [
              { index: true, element: <EndToEndProjectList /> },
              { path: 'project/:projectId', element: <ProjectDetailScreen /> },
              { path: 'payments', element: <AllPaymentsScreen /> },
              { path: 'payments/:paymentId', element: <ProjectPaymentDetails /> },
            ],
          },
          {
            path: 'pitches',
            element: <Pitch />,
            children: [
              { index: true, element: <PitchDetailScreen /> },
              //
              { path: 'create', element: <CreatePitch type="create" /> },
              { path: ':timedProjectPitchId/edit', element: <CreatePitch type="edit" /> },
            ],
          },
          {
            path: 'settings',
            element: <Settings />,
            children: [
              {
                index: true,
                element: <AccountSettings />,
              },
              { path: 'account', element: <AccountSettings /> },
              {
                path: 'billing',
                element: (
                  <ProtectedRoute
                    level={[[ProtectedRouteLevel.IsOwner], [ProtectedRouteLevel.IsAdmin]]}
                    navigateOnVerificationFalse={{ to: '/dashboard' }}
                  >
                    <Billing />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'members',
                element: (
                  <ProtectedRoute
                    level={[[ProtectedRouteLevel.IsOwner], [ProtectedRouteLevel.IsAdmin]]}
                    navigateOnVerificationFalse={{ to: '/dashboard' }}
                  >
                    <Members />
                  </ProtectedRoute>
                ),
              },
              { path: 'security', element: <Security /> },
            ],
          },
          {
            path: 'settings/members/:userId',
            element: (
              <ProtectedRoute
                level={[[ProtectedRouteLevel.IsOwner], [ProtectedRouteLevel.IsAdmin]]}
                navigateOnVerificationFalse={{ to: '/dashboard' }}
              >
                <Member />
              </ProtectedRoute>
            ),
          },
          {
            path: 'timed-projects',
            children: [{ index: true, element: <TimedProjects /> }],
          },
        ],
      },
      {
        path: 'admin',
        element: (
          <ProtectedRoute
            level={[[ProtectedRouteLevel.IsSponaAdmin]]}
            navigateOnVerificationFalse={{ to: '/login' }}
          >
            <Admin />
          </ProtectedRoute>
        ),
        children: [
          { index: true, element: <AdminHome /> },
          {
            path: 'blogs',
            children: [
              { index: true, element: <BlogList /> },
              { path: 'create', element: <BlogCreateEdit /> },
              { path: ':blogId/edit', element: <BlogCreateEdit /> },
              { path: ':blogId/view', element: <BlogPreview /> },
            ],
          },

          {
            path: 'featured-blogs',
            element: <FeaturedBlogs />,
          },
          {
            path: 'newsletter',
            children: [{ index: true, element: <Newsletter /> }],
          },
          {
            path: 'case-study',
            children: [
              { index: true, element: <CaseStudiesListTable /> },
              { path: ':caseStudyId', element: <CaseStudiesDetails /> },
              //
              { path: 'create', element: <CaseStudiesAdminCreate /> },
              { path: 'edit/:caseStudyId', element: <CaseStudiesAdminCreate /> },
            ],
          },
          {
            path: 'seller-archive',
            children: [
              { index: true, element: <SellerArchiveList /> },
              { path: ':archiveId', element: <SellerArchiveEdit /> },
            ],
          },
          {
            path: 'case-study-archive',
            children: [
              { index: true, element: <CaseStudyArchiveList /> },
              { path: ':archiveId', element: <CaseStudyArchiveEdit /> },
            ],
          },
          {
            path: 'footer/:footerType',
            children: [{ index: true, element: <FooterEdit /> }],
          },
          {
            path: 'pitching',
            children: [
              { index: true, element: <PitchingList /> },
              { path: ':pitchId', element: <PitchingDetails /> },
              { path: 'create', element: <PitchingAdminCreate type="create" /> },
              { path: ':timedProjectPitchId/edit', element: <PitchingAdminCreate type="edit" /> },
            ],
          },
          {
            path: 'timed-projects',
            children: [
              { index: true, element: <TimedProjectsListTable /> },
              { path: ':timedProjectId', element: <TimedProjectsDetailsAdmin /> },
              { path: 'create-timed-project', element: <AdminCreateTimedProjects /> },
              { path: 'edit/:timedProjectId', element: <AdminCreateTimedProjects /> },
            ],
          },
          {
            path: 'case-study-review',
            children: [
              { index: true, element: <CaseStudyReviews /> },
              { path: ':reviewId', element: <CaseStudyReviewDetails /> },
            ],
          },
          {
            path: 'end-to-end',
            element: <AdminEndToEnd />,
            children: [
              { path: 'flagging', element: <AdminFlagging /> },
              { path: 'payments', element: <AdminPayments /> },
              { path: 'create-project', element: <AdminCreateProject /> },
              { path: 'projects', element: <AdminProjectList /> },
              { path: 'projects/:projectId', element: <AdminProjectDetails /> },
              { path: 'projects/:projectId/contracts', element: <AdminContractDetailScreen /> },
              {
                path: 'project-requests',
                element: <ProjectRequests />,
              },
              {
                path: 'project-requests/:projectRequestId',
                element: <AdminProjectRequest />,
              },
            ],
          },
          {
            path: 'companies',
            element: <AdminEndToEnd />,
            children: [
              {
                path: 'sos',
                element: (
                  <AdminCompanyList
                    key={CompanyRole.SellerService}
                    companyType={CompanyRole.SellerService}
                  />
                ),
              },
              {
                path: 'tos',
                element: (
                  <AdminCompanyList
                    key={CompanyRole.SellerTool}
                    companyType={CompanyRole.SellerTool}
                  />
                ),
              },
              {
                path: 'buyer',
                element: (
                  <AdminCompanyList key={CompanyRole.Buyer} companyType={CompanyRole.Buyer} />
                ),
              },
              { path: ':companyId', element: <CompanyDetails /> },
              { path: ':companyId/edit', element: <AdminCompanyEdit /> },
              { path: 'create-company', element: <AdminCompanyCreateNew /> },
            ],
          },
        ],
      },
    ],
  },
];
export default routes;
