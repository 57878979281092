import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Divider, Stack, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BaseUserDto } from 'tdc-web-backend/users/shemas';
import {
  CalendarEventDto,
  CreateCalendarEventDto,
  UpdateCalendarEventDto,
} from 'tdc-web-backend/events/schemas';
import ControlledDateInput from '../../../components/custom-inputs/ControlledDateInput';
import { ReactComponent as PencilIcon } from '../../../assets/icons/pencil.svg';
import ControlledTextInput from '../../../components/custom-inputs/ControlledTextInput';
import useGetMany from '../../../utils/hooks/crud-hooks/useGetMany';
import CustomModal from '../../../components/modal/CustomModal';
import CustomButton from '../../../components/button/CustomButton';
import { secondaryBlue } from '../../../utils/color';
import ControlledTimeInput from '../../../components/custom-inputs/ControlledTimeInput';
import BaseInputLabel from '../../../components/custom-inputs/BaseInputLabel';
import ControlledSelectInput from '../../../components/custom-inputs/ControlledSelectInput';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';
import useUpdate from '../../../utils/hooks/crud-hooks/useUpdate';
import { useRefresh } from '../../../utils/hooks/crud-hooks/useRefresh';
import useAuth from '../../../utils/hooks/useAuth';

export interface UserType {
  id: string;
  firstName?: string;
  lastName?: string;
}

export interface EventParticipant {
  user: UserType;
  attending?: boolean | null;
}

export interface EventMeetingProps {
  onCancel: () => void;
  open: boolean;
  start?: Date;
  end?: Date;
  attendees?: EventParticipant[];
  eventEditDetails?: CalendarEventDto;
  meetingChannel?: string;
}

const defaultFormValues = {
  date: new Date(),
  start: new Date(),
  end: new Date(),
  name: '',
  description: '',
  attendees: [] as EventParticipant[],
};

export function EventMeetingModal({
  start,
  end,
  open,
  eventEditDetails,
  onCancel,
  meetingChannel,
}: EventMeetingProps) {
  const {
    authData: { userData: currentUser },
  } = useAuth();
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: defaultFormValues,
  });

  const { mutate: createEvent } = useCreate<CalendarEventDto, CreateCalendarEventDto>({
    resource: '/events',
  });
  const { mutate: updateEvent } = useUpdate<CalendarEventDto, UpdateCalendarEventDto>({
    resource: '/events',
  });
  const refresh = useRefresh();

  const {
    setValue,
    reset,
    formState: { isValid },
    handleSubmit,
    watch,
  } = formMethods;

  const { data: userData } = useGetMany<BaseUserDto>({ resource: '/users/?collaborating=true' });

  const attendees = userData?.data.results || [];
  const attendeesWithCurrentUser = (currentUser && attendees.concat([currentUser])) || [];
  const options = useMemo(
    () => new Map(attendeesWithCurrentUser.map((item) => [item.id, item])),
    [attendeesWithCurrentUser.length],
  );

  const displayMenuItem = (obj: BaseUserDto) => `${obj.firstName} ${obj.lastName}`;

  const mergeTimeInputValue = (time: string, date: string) => {
    const dateValue = new Date(date);
    const timeValue = new Date(time);
    return new Date(
      dateValue.getFullYear(),
      dateValue.getMonth(),
      dateValue.getDate(),
      timeValue.getHours(),
      timeValue.getMinutes(),
      timeValue.getSeconds(),
    );
  };

  useEffect(() => {
    if (!eventEditDetails) {
      setValue('date', start || new Date());
      setValue('start', start || new Date());
      setValue('end', end || new Date());
      setValue('name', '');
      setValue('description', '');
      setValue('attendees', [] as EventParticipant[]);
    }
  }, [start, end]);

  useEffect(() => {
    if (eventEditDetails) {
      reset({
        date: new Date(eventEditDetails.start),
        start: new Date(eventEditDetails.start),
        end: new Date(eventEditDetails.end),
        attendees: eventEditDetails.attendees?.map((a: EventParticipant) => a.user.id) || [],
        name: eventEditDetails.name,
        description: eventEditDetails.description,
      });
    }
  }, [eventEditDetails]);

  const handleEventCreate = (data: any) => {
    const mergedStart = mergeTimeInputValue(data.start, data.date);
    const mergedEnd = mergeTimeInputValue(data.end, data.date);
    const attendeesIdList = data.attendees.map((user: string) => ({ id: user }));

    const newData = {
      name: data.name,
      description: data.description,
      start: mergedStart,
      end: mergedEnd,
      attendees: attendeesIdList,
      meetingChannel,
    };

    if (!eventEditDetails) {
      createEvent(newData, {
        onSuccess: () => {
          refresh();
          onCancel();
        },
      });
    } else {
      updateEvent(
        {
          id: eventEditDetails.id,
          data: newData,
        },
        {
          onSuccess: () => {
            refresh();
            onCancel();
          },
        },
      );
    }
  };

  return (
    <CustomModal
      open={open}
      onClose={() => {
        reset({ ...defaultFormValues });
        onCancel();
      }}
      width="44%"
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleEventCreate)}>
            <Stack direction="column" spacing={2.5}>
              <Stack direction="row" alignItems="center">
                <Stack
                  width={40}
                  height={40}
                  mr={2}
                  bgcolor="secondaryBlue.100"
                  borderRadius={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <PencilIcon width="20px" fill={secondaryBlue[700]} />
                </Stack>
                <Typography variant="heading5">
                  {eventEditDetails ? 'Edit Event' : 'New Event'}
                </Typography>
              </Stack>
              <Divider />
              <Stack direction="column" spacing={3}>
                <Stack direction="row">
                  <Stack direction="column" sx={{ width: '100%' }}>
                    <ControlledTextInput
                      placeholder="Add  event name"
                      name="name"
                      required
                      label="Event title"
                    />
                  </Stack>
                </Stack>
                <Stack>
                  <BaseInputLabel text="When" />
                  <Stack direction="row" spacing={2} justifyContent="space-between">
                    <ControlledDateInput name="date" placeholder="Start date" />
                    <ControlledTimeInput name="start" placeholder="Start time" />
                    <ControlledTimeInput
                      name="end"
                      placeholder="End time"
                      minTime={new Date(watch('start'))}
                    />
                  </Stack>
                </Stack>
                <ControlledSelectInput
                  name="attendees"
                  label="Participants"
                  placeholder="Add Participants"
                  choices={options}
                  transformLabelFn={displayMenuItem}
                  multiple
                  withChips
                  required
                />
                <ControlledTextInput
                  variant="outlined"
                  name="description"
                  placeholder="DESCRIPTION"
                  multiline
                  minRows={2}
                  maxRows={4}
                  required
                />
              </Stack>
              <Divider />
              <Stack direction="row" spacing={2} justifyContent="end">
                <CustomButton
                  variant="secondary"
                  onClick={() => {
                    onCancel();
                    reset({ ...defaultFormValues });
                  }}
                >
                  Cancel
                </CustomButton>
                <CustomButton disabled={!isValid} type="submit">
                  Submit
                </CustomButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </LocalizationProvider>
    </CustomModal>
  );
}
