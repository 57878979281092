import { IconButton, Stack, TextField, TextFieldProps, Typography, Box } from '@mui/material';
import { ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import ErrorIcon from '@mui/icons-material/Error';
import { ReactElement } from 'react';
import { secondaryBlue, secondaryPink } from '../../utils/color';
import BaseInputLabel from './BaseInputLabel';

export type TBaseTextInput = {
  name?: string;
  required?: boolean;
  textFieldBackground?: string;
  field?: ControllerRenderProps<FieldValues, string>;
  icon?: ReactElement;
  params?: TextFieldProps;
  onIconClick?: () => void;
  labelAdornment?: JSX.Element | null;
  sxBox?: any; // object with styles for Box component
  showErrorAlways?: boolean;
} & TextFieldProps;

const BaseTextInput = ({
  name = '',
  required,
  label,
  field,
  icon,
  textFieldBackground = '#FFF',
  params,
  onIconClick,
  labelAdornment,
  showErrorAlways,
  ...props
}: TBaseTextInput) => {
  const { getFieldState } = useFormContext();

  const fieldState = getFieldState(name);

  const showError = fieldState.error && (showErrorAlways || fieldState.isTouched);

  const styles = {
    '.MuiButtonBase-root': {
      display: 'none',
    },
    '.MuiInputBase-formControl': {
      border: `1px solid ${showError ? secondaryPink[100] : secondaryBlue[100]}`,
      color: props.disabled ? 'primaryDark.300' : 'primaryDark.600',
      backgroundColor: showError ? 'secondaryPink.100' : 'transparent',
      paddingRight: icon ? 4 : 0,
      fontSize: '14px',
      '&:not(.Mui-disabled)': {
        '&:hover': {
          borderColor: 'primaryLight.200',
        },
      },
    },
    input: {
      fontSize: '14px',
      padding: '0.844rem 0.75rem',
      pointerEvents: props.disabled ? 'none' : 'all',
    },
    '.Mui-focused': {
      borderColor: 'primaryLight.500',
    },
    '.MuiFormHelperText-root': {
      marginLeft: 0,
      boxShadow: 'none !important',
      color: 'secondaryPink.500',
      marginTop: '0 !important',
      lineHeight: 1,
    },
    fieldset: {
      border: '0 !important',
    },
    '.Mui-disabled': {
      backgroundColor: 'primaryDark.200',
      color: 'primaryDark.300',
      border: 'none',
    },
  };

  return (
    <Box sx={{ ...props.sx, ...props.sxBox }}>
      {label && (
        <BaseInputLabel
          text={label as string}
          adornment={labelAdornment}
          required={required}
          sx={{ color: props.disabled ? 'primaryDark.300' : 'primaryDark.500' }}
        />
      )}
      <Stack
        sx={{
          position: 'relative',
          ...styles,
          ...props.sx,
        }}
      >
        <TextField
          label=""
          variant="outlined"
          required={required}
          name={name}
          fullWidth
          onChange={props.onChange}
          InputProps={{
            readOnly: props.disabled,
            endAdornment: icon ? (
              <IconButton
                onClick={onIconClick}
                sx={{
                  position: 'absolute',
                  display: 'flex !important',
                  right: 8,
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                {icon}
              </IconButton>
            ) : showError ? (
              <ErrorIcon
                sx={{
                  color: 'secondaryPink.500',
                  mr: 1,
                }}
              />
            ) : null,
            ...props.InputProps,
          }}
          {...params}
          {...props}
          {...field}
          helperText={
            <>
              {showError ? (
                <Typography
                  color="secondaryPink.500"
                  variant="body4"
                  sx={{ position: 'absolute', mt: 1 }}
                >
                  {(fieldState?.error?.message?.length ?? 0) > 0
                    ? fieldState?.error?.message
                    : 'This field is required'}
                </Typography>
              ) : (
                ''
              )}
            </>
          }
        />
      </Stack>
    </Box>
  );
};

export default BaseTextInput;
