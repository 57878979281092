import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useState } from 'react';
import CustomButton from '../../button/CustomButton';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/project-icons/ArrowRightIcon.svg';
import { primaryLight } from '../../../utils/color';

const style = { border: 'none', boxShadow: 'none' };

export interface MyAccordionProps {
  summary: string;
  details: string;
  link?: string;
  href?: string;
}
interface AccordionGroupsProps {
  group: MyAccordionProps[];
  image?: string;
}
const AccordionGroups = ({ group, image }: AccordionGroupsProps) => {
  const [expanded, setExpanded] = useState<string | false>('accordion0');

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = (isOpen: boolean) => setOpen(isOpen);

  const BuildAccordion = () =>
    group.map((e: MyAccordionProps, index: number) => (
      <Accordion
        key={index}
        expanded={expanded === `accordion${index}`}
        onChange={handleChange(`accordion${index}`)}
        sx={{
          m: 0,
          ...style,
          '&.MuiAccordion-root::before': {
            content: 'unset',
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMore sx={{ color: expanded === `accordion${index}` ? '#fff' : '#000' }} />
          }
          aria-controls="panel1d-content"
          id="panel1d-header"
          sx={{
            backgroundColor: 'primaryDark.200',
            '&.Mui-expanded': {
              backgroundColor: 'primaryLight.500',
              color: '#fff',
            },
            expandIcon: {
              color: '#fff',
              backgroundColor: '#fff',
            },
            borderRadius: '0.3rem',
            ...style,
          }}
        >
          <Typography sx={{ typography: { xs: 'publicBody4Light', sm: 'publicBody3Light' } }}>
            {e.summary}
          </Typography>
        </AccordionSummary>

        <AccordionDetails
          sx={{
            backgroundColor: 'transparent',
            mt: 1,
            pb: 1,
            ...style,
          }}
        >
          <Stack>
            <Typography variant="publicBody4Light">{e.details}</Typography>

            {e.link && e.href && (
              <CustomButton
                onClick={() => handleOpen(true)}
                sx={{ maxWidth: 'fit-content', pt: 1 }}
                variant="primaryText"
                endIcon={<ArrowRightIcon fill={primaryLight[500]} />}
              >
                {e.link}
              </CustomButton>
            )}

            {/* <Box
              src={image}
              component="img"
              sx={{
                width: { xs: '100%', sm: '50%' },
                mt: '16px !important',
                display: { xs: 'block', lg: 'none' },
                margin: '0 auto',
              }}
            /> */}
          </Stack>
        </AccordionDetails>
      </Accordion>
    ));

  return (
    <Stack width="100%" spacing={3}>
      {BuildAccordion()}
    </Stack>
  );
};

export default AccordionGroups;
