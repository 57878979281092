import { Stack, Typography } from '@mui/material';
import CustomButton from '../../../../components/button/CustomButton';
import { secondaryBlue } from '../../../../utils/color';
import CustomModal from '../../../../components/modal/CustomModal';
import { ReactComponent as ContractDocumentIcon } from '../../../../assets/icons/project-icons/ContractDocumentIcon.svg';

type ModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  setIsOpen: (value: boolean) => void;
  handleSubmitCreate: () => void;
};

const ContractCreateModal = ({ isLoading, isOpen, setIsOpen, handleSubmitCreate }: ModalProps) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <CustomModal open={isOpen} onClose={handleClose}>
      <Stack direction="column">
        <Stack direction="row" alignItems="center" mb={3}>
          <Stack
            width={40}
            height={40}
            mr={2}
            bgcolor="secondaryBlue.100"
            borderRadius={1}
            justifyContent="center"
            alignItems="center"
          >
            <ContractDocumentIcon width="20px" fill={secondaryBlue[700]} />
          </Stack>
          <Typography variant="heading4" color="primaryDark.600">
            Contract under review
          </Typography>
        </Stack>
        <Stack
          py={2}
          sx={{
            borderTop: `1px solid ${secondaryBlue[100]}`,
            borderBottom: `1px solid ${secondaryBlue[100]}`,
            alignItems: 'center',
          }}
        >
          <Typography color="primaryDark.500" variant="body2">
            The other party will now be invited to review the contract and add any necessary
            information.
          </Typography>
        </Stack>
        <Stack mt={3} gap={2} direction="row" justifyContent="flex-end">
          <CustomButton onClick={() => setIsOpen(false)} variant="secondary">
            Cancel
          </CustomButton>
          <CustomButton onClick={handleSubmitCreate} loading={isLoading} variant="primary">
            Continue
          </CustomButton>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default ContractCreateModal;
