import { Typography, Stack, Divider, Switch } from '@mui/material';
import { ReactComponent as SettingsIcon } from '../../assets/icons/notifications/SettingsIcon.svg';
import { primaryLight } from '../../utils/color';
import CustomModal from '../modal/CustomModal';
import CustomButton from '../button/CustomButton';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateGoogleAnalytics } from '../../utils/helpers';

interface CookiesModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setBannerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
declare global {
  interface Window {
    [key: string]: any;
  }
}

const CookiesModal = ({ isModalOpen, setIsModalOpen, setBannerOpen }: CookiesModalProps) => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const gaConsent = localStorage.getItem('ga-consent');
    if (gaConsent !== null) {
      const isAccepted = gaConsent === 'accepted';
      updateGoogleAnalytics(isAccepted);
      setChecked(isAccepted);
    }
  }, []);

  const handleAnalyticsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem('ga-consent', event.target.checked ? 'accepted' : 'declined');
    updateGoogleAnalytics(event.target.checked);
    setChecked(event.target.checked);
  };

  const handleAcceptAll = () => {
    localStorage.setItem('ga-consent', 'accepted');
    updateGoogleAnalytics(true);
    setIsModalOpen(false);
    setBannerOpen(false);
    setChecked(true);
  };

  const handleAcceptNecessary = () => {
    localStorage.setItem('ga-consent', 'declined');
    updateGoogleAnalytics(false);
    setIsModalOpen(false);
    setBannerOpen(false);
    setChecked(false);
  };

  const handleNavigateToPolicy = () => {
    navigate('/terms-of-service/cookie-policy');
    setIsModalOpen(false);
  };

  return (
    <CustomModal open={isModalOpen} width="45%">
      <Stack gap={2} sx={{ height: 'auto', maxHeight: '700px', overflowY: 'auto', pr: '3px' }}>
        <Stack gap={2} direction="row" alignItems="center" justifyContent="flex-start">
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              bgcolor: '#E3DBFC',
              width: 'fit-content',
              height: 'fit-content',
              borderRadius: '6px',
              px: 2,
              py: 1,
            }}
          >
            <SettingsIcon fill={primaryLight[500]} style={{ width: 22, height: 22 }} />
          </Stack>
          <Typography variant="heading4" color="primaryDark.600">
            Privacy Overview
          </Typography>
        </Stack>
        <Divider />
        <Typography color="primaryDark.500">
          This website uses cookies so we can provide you with the best user experience possible.
          Cookie information is stored in your browser and performs functions such as recognizing
          you when you return to our website and helping our team understand which sections of the
          website you find the most interesting and useful.
        </Typography>

        <Stack direction="column" gap={1}>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="primaryDark.500" variant="publicBody4">
              Essential
            </Typography>
            <CustomButton
              variant="secondaryText"
              sx={{ color: 'primaryDark.500', fontFamily: 'Gellix-Regular', fontWeight: 350 }}
            >
              Always Active
            </CustomButton>
          </Stack>
          <Typography color="primaryDark.500">
            Necessary cookies are required to enable the basic features of this site, such as
            providing secure log-in or adjusting your consent preferences. These cookies do not
            store any personally identifiable data.
          </Typography>
        </Stack>

        <Stack direction="column" gap={1}>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="primaryDark.500" variant="publicBody4">
              Analytics
            </Typography>
            <Switch
              checked={checked}
              onChange={handleAnalyticsChange}
              sx={{
                '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
                  color: '#34A854',
                },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                  backgroundColor: '#F5FED4',
                },
                '& .MuiSwitch-thumb': {
                  color: '#79829B',
                },
                '& .MuiSwitch-track': {
                  backgroundColor: '#BFC6D7',
                },
              }}
            />
          </Stack>
          <Typography color="primaryDark.500">
            Analytical cookies are used to understand how visitors interact with the website. These
            cookies help provide information on metrics such as the number of visitors, bounce rate,
            traffic source, etc.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Typography color="primaryDark.500" variant="publicBody4">
            Cookie Policy
          </Typography>
          <Typography color="primaryDark.500">
            More information about our{' '}
            <CustomButton
              onClick={handleNavigateToPolicy}
              sx={{ textDecoration: 'underline' }}
              variant="secondaryText"
            >
              Cookie Policy
            </CustomButton>
          </Typography>
        </Stack>

        <Divider />
        <Stack
          direction={{ xs: 'column', sm: 'column', lg: 'row' }}
          alignContent="space-between"
          gap={3}
          justifyContent="flex-end"
        >
          <CustomButton onClick={handleAcceptAll}>Accept All</CustomButton>
          <CustomButton
            sx={{ minWidth: '205px' }}
            variant="secondary"
            onClick={handleAcceptNecessary}
          >
            Accept Only Necessary
          </CustomButton>
        </Stack>
      </Stack>
    </CustomModal>
  );
};
export default CookiesModal;
