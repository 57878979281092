import { Divider, Stack, Typography } from '@mui/material';
import { CaseStudyContentDto } from 'tdc-web-backend/case-studies-content/schemas';
import {
  EnumCaseStudyStatus,
  mapCountry,
  mapIndustry,
  mapProjectSize,
  mapService,
} from 'tdc-web-backend/enums/enums';
import { Box } from '@mui/system';
import ChipGroup from './CaseStudyChipGroup';
import { mapTechLanguage, mapTechPlatform, mapTechTool } from '../../../../utils/enums';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';
import { primaryDark } from '../../../../utils/color';
import { CaseStudyEditorMenu } from '../../../../components/editor/menu-option-lists/menu-item-lists';
import Editor from '../../../../components/editor/Editor';
import { ReactComponent as TrophyIcon } from '../../../../assets/icons/layout-icons/trophy2-fill.svg';
import { ReactComponent as BullseyeIcon } from '../../../../assets/icons/layout-icons/BullseyeIcon.svg';
import { ReactComponent as BriefcaseCheckIcon } from '../../../../assets/icons/layout-icons/BriefcaseCheckIcon.svg';
import { ReactComponent as LightBulbIcon } from '../../../../assets/icons/layout-icons/LightBulbIcon.svg';
import { ReactComponent as FeedbackIcon } from '../../../../assets/icons/feedback.svg';
import ClientReviews from '../../../../components/client-reviews/ClientReviews';
import CustomReferenceField from '../../../../components/custom-fields/CustomReferenceField';
import { stripTags } from '../../../../utils/helpers';

type CaseStudiesContentDetailsProps = {
  data: CaseStudyContentDto;
};

const CaseStudiesContentDetails = ({ data }: CaseStudiesContentDetailsProps) => {
  const feedbackTitle = ['Execution', 'Schedule', 'Budget', 'Recommend to others'];
  const score = data?.review?.score;
  const oldPlatformReview = data?.review?.oldPlatform;

  const feedback = [
    {
      title: 'Execution',
      rate: score?.qualityScore,
      description: stripTags(score?.qualityText as string),
    },
    {
      title: 'Budget',
      rate: score?.budgetScore,
      description: stripTags(score?.budgetText as string),
    },
    {
      title: 'Schedule',
      rate: score?.scheduleScore,
      description: stripTags(score?.scheduleText as string),
    },
    {
      title: 'Recommend to others',
      rate: score?.recommendationScore,
      description: stripTags(score?.recommendationText as string),
    },
  ];

  return (
    <Stack width="100%">
      {data.review && data.status === EnumCaseStudyStatus.Verified && (
        <Stack width="100%" py={4}>
          <Stack
            width="100%"
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            pb={2}
          >
            <Stack
              sx={{
                fill: primaryDark[500],
              }}
              mb={1.375}
              alignItems="center"
              direction="row"
            >
              <FeedbackIcon />
              <Typography color="primaryDark.500" variant="heading5" ml={1.375}>
                Client Review
              </Typography>
            </Stack>
            <Typography
              color="primaryDark.500"
              variant="body3"
              mr={1.375}
              sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
            >
              Total Score
              <CustomReferenceField
                displayName="score"
                id={data.caseStudy}
                resource="case-studies"
                variant="heading3"
                color="secondaryBlue.700"
              />
            </Typography>
          </Stack>
          {!oldPlatformReview && (
            <>
              <Typography mb={1.5} color="primaryDark.600" variant="body3">
                &quot;{data?.review?.summarization}&quot;
              </Typography>
              <ClientReviews feedback={feedback} />
            </>
          )}
        </Stack>
      )}
      <Stack direction="row" width="100%" py={2}>
        <ChipGroup label="services" choices={mapService} values={data?.details?.services} />
        {data?.details.platforms?.length !== 0 && (
          <ChipGroup
            label="tech platforms"
            choices={mapTechPlatform}
            values={data?.details?.platforms}
          />
        )}
      </Stack>

      {data?.details.tools?.length === 0 && data?.details.languages?.length === 0 ? (
        <Divider sx={{ display: 'none' }} />
      ) : (
        <Divider />
      )}

      <Stack direction="row" width="100%" py={2}>
        {data?.details.tools?.length !== 0 && (
          <ChipGroup label="tech tools" choices={mapTechTool} values={data?.details?.tools} />
        )}

        {data?.details.languages?.length !== 0 && (
          <ChipGroup
            label="Programming languages"
            choices={mapTechLanguage}
            values={data?.details?.languages}
          />
        )}
      </Stack>

      <Divider />
      <Stack direction="row" width="100%" py={2}>
        <ChipGroup label="industry" choices={mapIndustry} values={data?.details?.industries} />
        <ChipGroup
          label="Target market"
          choices={mapCountry}
          values={data?.details?.geographyFocus}
        />
      </Stack>
      <Divider />
      <Stack width="100%" py={2}>
        <BaseInputLabel text="Project size" />
        <Typography variant="heading4">
          {data?.details?.projectSize ? mapProjectSize.get(data.details.projectSize) : '--'}
        </Typography>
      </Stack>
      <Box sx={{ m: 4, borderRadius: '3px', maxWidth: '100%' }} component="img" src={data?.file} />
      <Divider />
      <Stack py={2} spacing={1}>
        <Stack direction="row" spacing={1}>
          <TrophyIcon fill={primaryDark[500]} />
          <BaseInputLabel text="Project goals" />
        </Stack>
        <Editor
          items={CaseStudyEditorMenu}
          content={data?.description?.goals}
          isEditable={false}
          key="goals"
        />
      </Stack>
      <Divider />
      <Stack py={2} spacing={1}>
        <Stack direction="row" spacing={1}>
          <BullseyeIcon fill={primaryDark[500]} />
          <BaseInputLabel text="challenges" />
        </Stack>
        <Editor
          items={CaseStudyEditorMenu}
          content={data?.description?.challenges}
          isEditable={false}
          key="challenges"
        />
      </Stack>
      <Divider />
      <Stack py={2} spacing={1}>
        <Stack direction="row" spacing={1}>
          <LightBulbIcon fill={primaryDark[500]} />
          <BaseInputLabel text="solution" />
        </Stack>
        <Editor
          items={CaseStudyEditorMenu}
          content={data?.description?.solutions}
          isEditable={false}
          key="solutions"
        />
      </Stack>
      <Divider />
      <Stack py={2} mb={8} spacing={1}>
        <Stack direction="row" spacing={1}>
          <BriefcaseCheckIcon fill={primaryDark[500]} />
          <BaseInputLabel text="Results" />
        </Stack>
        <Editor
          items={CaseStudyEditorMenu}
          content={data?.description?.results}
          isEditable={false}
          key="results"
        />
      </Stack>
    </Stack>
  );
};

export default CaseStudiesContentDetails;
