import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import { Stack } from '@mui/system';
import CustomChip from '../../../../../../../components/custom-chip/CustomChip';
import { EnumTechTool } from 'tdc-web-backend/enums/enums';
import { mapTechTool } from '../../../../../../../utils/enums';
import { Dispatch, SetStateAction } from 'react';
import CompanyTechToolsEditModal from '../edit/CompanyTechToolsEditModal';
import { truncate } from '../../../../../../../utils/helpers';

interface CompanyTechToolsProps {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CompanyTechTools = ({ isEditModalOpen, setIsEditModalOpen }: CompanyTechToolsProps) => {
  const { companyData, chipLabelTruncateValue } = useSellerHomescreenContext();

  return (
    <Stack
      direction="row"
      gap={1}
      flexWrap="wrap"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      {companyData?.techTools?.map((tool: EnumTechTool) => (
        <CustomChip
          key={tool}
          tooltipTitle={mapTechTool.get(tool)}
          label={
            tool && truncate(mapTechTool.get(tool) as string, chipLabelTruncateValue as number)
          }
          sx={{ width: 'fit-content', textTransform: 'capitalize' }}
        />
      ))}

      {companyData && isEditModalOpen && (
        <CompanyTechToolsEditModal
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
        />
      )}
    </Stack>
  );
};

export default CompanyTechTools;
