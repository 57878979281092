import { Stack, Typography } from '@mui/material';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import { secondaryOrange } from '../../../../utils/color';
import ReusableHeroSection from '../../../../components/public-screen-components/ReusableHeroSection';
import TextAndImage from '../../../../components/public-screen-components/TextAndImage';
import HowMuchDoesItCost from '../components/HowMuchDoesItCost';
import BecomeAPartOfSpona from '../../../../components/public-screen-components/BecomeAPartOfSpona';
import { textsAndImagesMonitorYourKPIs } from './utils';
import { PUBLIC_APPBAR_HEIGHT } from '../../../../layout/public-layout/navigation/Navigation';
import MetaTags from '../../../../components/seo/MetaTags';
import { getPublicImageUrl } from '../../../../utils/helpers';
import { EnumPublicPagesImage } from '../../../../utils/enums';

const metaTags = {
  description: `See how you can pull reports on your successes with quick KPIs that are easy to produce and even easier to read.`,
};

const MonitorYourKPIs = () => (
  <>
    <MetaTags {...metaTags} />
    <Stack overflow="hidden" width="100%">
      <ReusableHeroSection
        title="Monitor Your KPIs"
        content="Get essential project data that will help you organize your work, provide better time estimates, quickly pinpoint your areas of improvement and engender future insights to improve all facets of your business in the future. Spona’s seamlessly integrated project reporting supports your management activities and provides you with results that you need, when you need them."
        image={getPublicImageUrl(EnumPublicPagesImage.MonitorYourKPIs)}
        sx={{ height: { xs: `calc(79vh + ${PUBLIC_APPBAR_HEIGHT}px)`, lg: '87vh' } }}
        alt="Close up of a hand pointing at statistic data on a personal computer"
      />

      <Stack spacing={10} sx={{ mt: { xs: '-60px', lg: 12 } }}>
        {textsAndImagesMonitorYourKPIs.map(({ title, content, image, contentOrder, alt }) => (
          <TextAndImage
            key={content}
            alt={alt}
            title={title}
            content={content}
            image={image}
            contentOrder={contentOrder}
            contentStyles={{ justifyContent: 'center' }}
            contentSpacing={{ xs: 2, lg: 3 }}
          />
        ))}
      </Stack>

      <HowMuchDoesItCost
        firstCard={{
          title: 'Traditional Reports',
          sx: { alignItems: 'start' },
          bgcolor: 'white',
          color: 'black',
          content: (
            <Stack spacing={1.5}>
              <Stack spacing={1.5} direction="row" alignItems="center">
                <CancelSharpIcon />

                <Typography>Require additional tools or services</Typography>
              </Stack>

              <Stack spacing={1.5} direction="row" alignItems="center">
                <CancelSharpIcon />

                <Typography>Promote data micromanagement</Typography>
              </Stack>

              <Stack spacing={1.5} direction="row" alignItems="center">
                <CancelSharpIcon />

                <Typography>Complicate instead of streamlining</Typography>
              </Stack>
            </Stack>
          ),
        }}
        secondCard={{
          title: 'With Spona',
          sx: { alignItems: 'start' },
          bgcolor: secondaryOrange[101],
          color: 'black',
          content: (
            <Stack spacing={1.5}>
              <Stack spacing={1.5} direction="row" alignItems="center">
                <CheckCircleSharpIcon sx={{ color: 'secondaryOrange.501' }} />

                <Typography>Get all your data in one place</Typography>
              </Stack>

              <Stack spacing={1.5} direction="row" alignItems="center">
                <CheckCircleSharpIcon sx={{ color: 'secondaryOrange.501' }} />

                <Typography>Improve communication across the board</Typography>
              </Stack>

              <Stack spacing={1.5} direction="row" alignItems="center">
                <CheckCircleSharpIcon sx={{ color: 'secondaryOrange.501' }} />

                <Typography>Facilitate learning and make smart decisions</Typography>
              </Stack>
            </Stack>
          ),
        }}
      />

      <BecomeAPartOfSpona
        title="Get started with End to End"
        bgcolor="secondaryYellow.100"
        color="black"
      />
    </Stack>
  </>
);

export default MonitorYourKPIs;
