import { NotificationDto } from 'tdc-web-backend/notifications/schemas';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/system';
import { EnumNotificationSubcategory, EnumScreenList } from 'tdc-web-backend/enums/enums';
import { getBannerNotificationData } from '../getNotificationRoute';
import FloatingNotification from './FloatingNotification';
import useGetMany from '../../../utils/hooks/crud-hooks/useGetMany';
import { FloatingNotificationColorEnum } from '../../../utils/enums';

type Props = {
  screen: EnumScreenList;
  // If notification needs a custom function than pass prop customAction
  // Currently we dont have more than one modal trigger notification per screen
  // but this should be done differently if we would to have more than one
  customAction?: {
    action: (notification: NotificationDto) => void;
    subcategory: EnumNotificationSubcategory;
  };
};

const BannerNotificationMapper = ({ screen, customAction }: Props) => {
  const navigate = useNavigate();

  const { data: floatingNotifications } = useGetMany<NotificationDto>({
    resource: `notifications/banner?screen=${screen}`,
  });

  return (
    <Stack gap={2}>
      {floatingNotifications?.data.results
        ?.slice(0, 3)
        .reverse()
        ?.map((notification) => {
          const bannerData = getBannerNotificationData(notification);
          // console.log(bannerData);
          return (
            <FloatingNotification
              key={notification.id}
              notificationText={bannerData?.body}
              color={bannerData?.type as keyof typeof FloatingNotificationColorEnum}
              id={notification.id}
              action={
                bannerData?.route
                  ? {
                      text: bannerData.customText || 'Click here',
                      actionOnClick: () => {
                        // If notification needs a custom function than pass prop customAction
                        // Currently we dont have more than one modal trigger notification per screen
                        // but this should be done differently if we would to have more than one
                        customAction?.subcategory === notification.subcategory
                          ? customAction.action(notification)
                          : navigate(bannerData?.route as string);
                      },
                      position: 'afterNotificationText',
                    }
                  : undefined
              }
            />
          );
        })}
    </Stack>
  );
};

export default BannerNotificationMapper;
