import { Stack, Typography } from '@mui/material';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import { secondaryBlue } from '../../../../utils/color';
import ReusableHeroSection from '../../../../components/public-screen-components/ReusableHeroSection';
import TextAndImage from '../../../../components/public-screen-components/TextAndImage';
import HowMuchDoesItCost from '../components/HowMuchDoesItCost';
import BecomeAPartOfSpona from '../../../../components/public-screen-components/BecomeAPartOfSpona';
import { textsAndImagesDigitalContracts } from './utils';
import { PUBLIC_APPBAR_HEIGHT } from '../../../../layout/public-layout/navigation/Navigation';
import MetaTags from '../../../../components/seo/MetaTags';
import { getPublicImageUrl } from '../../../../utils/helpers';
import { EnumPublicPagesImage } from '../../../../utils/enums';

const metaTags = {
  description: `Outsourcing a project shouldn't cost you money in legal fees. Get fully-customizable standard contract templates.`,
};

const DigitalContracts = () => (
  <>
    <MetaTags {...metaTags} />
    <Stack overflow="hidden" width="100%">
      <ReusableHeroSection
        title="Digital Contracts"
        content="Structuring your contract milestone by milestone has never been easier with Spona’s digital contracts. Simplify the planning process and specify your terms in collaboration with your seller to get started with project execution as quickly as possible."
        image={getPublicImageUrl(EnumPublicPagesImage.DigitalContract)}
        sx={{ height: { xs: `calc(70vh + ${PUBLIC_APPBAR_HEIGHT}px)`, lg: '87vh' } }}
        alt="Close up of a person typing on a computer with a pop up illustration of digital documents"
      />

      <Stack spacing={10} sx={{ mt: { xs: '-76px', lg: 12 } }}>
        {textsAndImagesDigitalContracts.map(({ title, content, image, contentOrder, alt }) => (
          <TextAndImage
            key={content}
            title={title}
            content={content}
            image={image}
            contentOrder={contentOrder}
            contentStyles={{ justifyContent: 'center' }}
            contentSpacing={{ xs: 2, lg: 3 }}
            alt={alt}
          />
        ))}
      </Stack>

      <HowMuchDoesItCost
        firstCard={{
          title: 'Traditional Contracts',
          sx: { alignItems: 'start' },
          bgcolor: 'white',
          color: 'black',
          content: (
            <Stack spacing={1.5}>
              <Stack spacing={1.5} direction="row" alignItems="center">
                <CancelSharpIcon />

                <Typography>Time-consuming</Typography>
              </Stack>

              <Stack spacing={1.5} direction="row" alignItems="center">
                <CancelSharpIcon />

                <Typography>Incur legal fees</Typography>
              </Stack>

              <Stack spacing={1.5} direction="row" alignItems="center">
                <CancelSharpIcon />

                <Typography>Potential for information loss</Typography>
              </Stack>
            </Stack>
          ),
        }}
        secondCard={{
          title: 'With Spona',
          sx: { alignItems: 'start' },
          bgcolor: secondaryBlue[101],
          color: 'black',
          content: (
            <Stack spacing={1.5}>
              <Stack spacing={1.5} direction="row" alignItems="center">
                <CheckCircleSharpIcon sx={{ color: 'secondaryBlue.501' }} />

                <Typography>Quick and easy with templates</Typography>
              </Stack>

              <Stack spacing={1.5} direction="row" alignItems="center">
                <CheckCircleSharpIcon sx={{ color: 'secondaryBlue.501' }} />

                <Typography>Update terms in real-time</Typography>
              </Stack>

              <Stack spacing={1.5} direction="row" alignItems="center">
                <CheckCircleSharpIcon sx={{ color: 'secondaryBlue.501' }} />

                <Typography>Eliminate legal fees</Typography>
              </Stack>
            </Stack>
          ),
        }}
      />

      <BecomeAPartOfSpona
        title="Get started with End to End"
        bgcolor="secondaryYellow.100"
        color="black"
      />
    </Stack>
  </>
);

export default DigitalContracts;
