import { Typography } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { CompanyRole, EnumCurrency } from 'tdc-web-backend/enums/enums';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { TaskStatus } from '../../../../components/task-status/TaskStatus';
import { formatCurrency } from '../../../../utils/helpers';
import { GeneralPaymentTableProps } from '../../E2Ehomescreen/GeneralPaymentTable/GeneralPaymentTable';
import useUserCompany from '../../../../utils/hooks/useUserCompany';

const RecentPayments = ({ rows }: GeneralPaymentTableProps) => {
  const userType = useUserCompany()?.roles;

  const getColabCompany = (value: MilestoneDto) =>
    userType?.[0] === CompanyRole.Buyer ? value?.sellerName : value?.buyerName;

  const columns: GridColDef[] = [
    {
      field: 'paymentStatus',
      headerName: 'Status',
      flex: 0.4,
      renderCell: (params: GridValueGetterParams) => <TaskStatus text={params.value} />,
    },
    {
      field: 'company',
      headerName: 'Company',
      flex: 0.8,
      renderCell: (params: GridValueGetterParams) => (
        <Typography sx={{ fontSize: '0.875rem' }}>{getColabCompany(params.row)}</Typography>
      ),
    },
    {
      field: 'budget',
      headerName: 'Amount',
      flex: 0.5,
      renderCell: (params: GridValueGetterParams) => (
        <Typography sx={{ fontSize: '0.875rem' }}>
          {formatCurrency(params.value, EnumCurrency.Usd)}
        </Typography>
      ),
    },
  ];

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      rowHeight={70}
      disableSelectionOnClick
      hideFooter
      sx={{ height: 284 }}
    />
  );
};

export default RecentPayments;
