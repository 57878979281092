import { BlogCategoryDto } from 'tdc-web-backend/blog-categories/schemas';
import { Stack } from '@mui/material';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import CustomInput from '../../../../components/custom-input/CustomInput';
import CustomChip from '../../../../components/custom-chip/CustomChip';
import { primaryDark } from '../../../../utils/color';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';

type CategoriesProps = {
  viewMode?: boolean;
  values?: string[];
};

const Categories = ({ viewMode, values }: CategoriesProps) => {
  const { data: categories } = useGetMany<BlogCategoryDto>({
    resource: '/blog-categories?',
    enabled: !viewMode,
  });

  const mapa = new Map(
    viewMode ? [] : categories?.data.results.map((category) => [category.id, category.name]),
  );
  return (
    <>
      {viewMode ? (
        <Stack spacing={0}>
          <BaseInputLabel text="Categories" sx={{ mb: '0.8rem' }} />
          <Stack
            direction="row"
            gap={1}
            flexWrap="wrap"
            overflow="hidden"
            sx={{
              maxHeight: 'max-content',
            }}
          >
            {values?.map((item: string) => (
              <CustomChip
                label={item}
                sx={{
                  pointerEvents: 'none',
                  color: primaryDark[600],
                  fontSize: '0.875rem',
                  fontWeight: 600,
                }}
              />
            ))}
          </Stack>
        </Stack>
      ) : (
        <CustomInput
          input={<CustomInput.Select />}
          name="categories"
          placeholder="Select"
          choices={mapa}
          label="Categories"
          multiple
          withChips
          sx={{
            width: '100%',
            '& .MuiInputBase-root': {
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
            },
          }}
          required
        />
      )}
    </>
  );
};
export default Categories;
