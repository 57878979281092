import {
  Select,
  MenuItem,
  Typography,
  Stack,
  OutlinedInput,
  ListSubheader,
  InputAdornment,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { GridSearchIcon } from '@mui/x-data-grid';
import { useState } from 'react';
import { useCustomInputContext } from '../CustomInputContext';
import { primaryDark } from '../../../utils/color';
import CustomChip from '../../custom-chip/CustomChip';

const CustomInputSearchSelect = () => {
  const { field, placeholder, useFormContext, choices, multiple, name, withChips } =
    useCustomInputContext();
  const [searchText, setSearchText] = useState('');
  const { getValues, setValue, trigger } = useFormContext();

  const fieldValue = getValues(name);

  return (
    <Stack>
      <Select
        {...field}
        displayEmpty
        multiple={multiple}
        IconComponent={ExpandMoreIcon}
        onClose={() => setSearchText('')}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          disablePortal: true,
          disableScrollLock: true,
          keepMounted: false,
          PaperProps: {
            sx: {
              '& .MuiList-padding': {
                pt: 0,
              },
            },
          },
        }}
        renderValue={(selected) => (
          <Typography fontSize="14px" sx={{ color: primaryDark[600] }}>
            {placeholder}
          </Typography>
        )}
      >
        <ListSubheader
          sx={{
            px: 0,
          }}
        >
          <OutlinedInput
            autoFocus
            placeholder="Search"
            fullWidth
            endAdornment={
              <InputAdornment position="start">
                <GridSearchIcon />
              </InputAdornment>
            }
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {(() => {
          if (choices && choices instanceof Map) {
            return Array.from(choices, ([key, value]: [string | number, string]) => {
              if (value.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))
                return (
                  <MenuItem
                    key={key}
                    value={key}
                    sx={{ justifyContent: 'flex-start !important', alignItems: 'center' }}
                  >
                    {multiple && (
                      <>
                        <CheckBoxOutlinedIcon
                          className="MenuItemCheckedIcon"
                          sx={{ color: 'primaryLight.500', width: 22, height: 22, mr: 0.85 }}
                        />

                        <CheckBoxOutlineBlankOutlinedIcon
                          className="MenuItemUncheckedIcon"
                          sx={{ color: 'primaryDark.300', width: 22, height: 22, mr: 0.85 }}
                        />
                      </>
                    )}
                    {value}
                  </MenuItem>
                );
              return null;
            });
          }

          return null;
        })()}
      </Select>

      {withChips && fieldValue?.length ? (
        <Stack direction="row" sx={{ flexWrap: 'wrap', gap: '10px', mt: '16px' }}>
          {fieldValue.map((val: string) => {
            const chipLabel = choices instanceof Map ? choices.get(val) : '';
            return (
              <CustomChip
                key={val}
                label={chipLabel}
                onDelete={() => {
                  setValue(
                    name,
                    fieldValue.filter((item: string) => item !== val),
                  );
                  trigger(name);
                }}
              />
            );
          })}
        </Stack>
      ) : null}
    </Stack>
  );
};

export default CustomInputSearchSelect;
