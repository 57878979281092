import { Modal, Box, Paper, useMediaQuery, Typography, Stack, Divider } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import theme from '../../utils/theme';
import Button from '../button/Button';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { secondaryBlue } from '../../utils/color';

interface GoToBiggerScreenModalProps {
  onClick?: () => void;
  withGradientBackdrop?: boolean;
  isGoToBiggerScreenModalOpen?: boolean;
  automaticallyShowModalIfBelowTreshold?: boolean;
}

const gradientBackdropDefaultStyles = {
  background:
    'linear-gradient(240.98deg, rgba(230, 232, 255, 0.9) 0%, rgba(255, 234, 225, 0.9) 68.46%, rgba(255, 251, 234, 0.9) 99.58%)',
};

const GoToBiggerScreenModal = ({
  onClick,
  withGradientBackdrop = true,
  isGoToBiggerScreenModalOpen = false,
  automaticallyShowModalIfBelowTreshold = true,
}: GoToBiggerScreenModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const isLessThanMedium = useMediaQuery(theme.breakpoints.down('md'));

  const [, setHeight] = useState(0);

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  useEffect(() => {
    if (isGoToBiggerScreenModalOpen === true) {
      setIsModalOpen(true);
    }
  }, [isGoToBiggerScreenModalOpen]);

  useEffect(() => {
    if (automaticallyShowModalIfBelowTreshold) {
      if (isLessThanMedium) {
        setIsModalOpen(true);
      } else {
        setIsModalOpen(false);
      }
    }
  }, [isLessThanMedium]);

  return (
    <Modal
      open={isModalOpen}
      hideBackdrop
      sx={withGradientBackdrop ? gradientBackdropDefaultStyles : undefined}
      disableAutoFocus
    >
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '360px',
          minWidth: '280px',
          border: '2px solid',
          borderColor: '#4051EC',
          borderRadius: '6px',
        }}
      >
        <Paper ref={measuredRef} sx={{ height: '100%', p: '2rem' }}>
          <Stack height="100%" justifyContent="center" spacing={4}>
            <Stack justifyContent="center" alignItems="center">
              <Stack
                sx={{
                  backgroundColor: '#E3E7F0',
                  width: '54px',
                  height: '54px',
                  borderRadius: '8px',
                  position: 'relative',
                }}
                justifyContent="center"
                alignItems="center"
              >
                <InfoIcon fill={secondaryBlue[700]} width="33px" height="33px" />
              </Stack>
            </Stack>

            <Divider />

            <Stack direction="row">
              <Typography fontSize="1rem" fontWeight="500">
                Please view Spona on a device with a larger screen to experience full site
                functionality.
              </Typography>
            </Stack>

            <Stack spacing={2}>
              <Divider />

              <Button
                onButtonClick={() => onClick?.() || setIsModalOpen(false)}
                fontWeight="500"
                variant="contained"
                color="secondary"
                setUpperCase
                sx={{
                  width: 120,
                  alignSelf: 'center',
                  backgroundColor: '#6D51F6',
                  borderRadius: '4px',
                  minWidth: '100%',
                }}
              >
                Ok
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Box>
    </Modal>
  );
};

export default GoToBiggerScreenModal;
