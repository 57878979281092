import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { ReactComponent as LinkedIn } from '../../../../assets/icons/social/icon-social-linkedin-square.svg';
import { primaryLight } from '../../../../utils/color';
import TeamMemberCard from './TeamMemberCard';

export interface ExecutiveCardProps {
  img: string;
  name: string;
  linkedIn: string;
  content: string;
  title: string;
  bgcolor?: string;
  imgSx?: SxProps<Theme>;
  alt?: string;
}

const ExecutiveCard = ({
  img,
  name,
  linkedIn,
  content,
  title,
  bgcolor = primaryLight[100],
  imgSx,
  alt,
}: ExecutiveCardProps) => (
  <Stack
    bgcolor={bgcolor}
    p={{ lg: '2.5rem', md: '2.5rem', xs: '1.5rem' }}
    spacing={4}
    borderRadius="20px"
    justifyContent="space-between"
    width={{ lg: '50%', md: '50%', xs: '100%' }}
  >
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={{ xs: 0, sm: 3, md: 3, lg: 3 }}
    >
      <Stack direction="row" spacing={2} alignItems={{ xs: 'end', sm: 'center' }}>
        <TeamMemberCard name={name} img={img} alt={alt} imgSx={imgSx} sx={{ maxWidth: '200px' }} />

        <Typography
          sx={{
            maxWidth: { xs: '30%', lg: '100%' },
            typography: { xs: 'publicHeading5', lg: 'publicHeading4' },
          }}
        >
          {name}
        </Typography>
      </Stack>

      <Stack sx={{ display: { xs: 'none', md: 'flex' } }}>
        <a href={linkedIn} target="_blank" rel="noreferrer">
          <LinkedIn fill="#000000" />
        </a>{' '}
      </Stack>
    </Stack>

    <Typography variant="publicBody4Regular">{content}</Typography>

    <Typography variant="publicBody4">{title}</Typography>

    <Stack sx={{ display: { xs: 'flex', md: 'none' } }}>
      <a href={linkedIn} target="_blank" rel="noreferrer">
        <LinkedIn fill="#000000" />
      </a>{' '}
    </Stack>
  </Stack>
);

export default ExecutiveCard;
