import { Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import theme from '../../../utils/theme';
import { LAYOUT_DEFAULTS } from '../../../utils/constants';
import { whyUsArray, whyUsCarouselElements } from '../utils';
import CustomAliceCarousel from '../../../components/custom-alice-carousel/CustomAliceCarousel';

const responsive = {
  [theme.breakpoints.values.xs]: { items: 1 },
  [theme.breakpoints.values.sm]: { items: 2 },
  [theme.breakpoints.values.md]: { items: 3 },
  [theme.breakpoints.values.lg]: { items: 2 },
  [theme.breakpoints.values.xl]: { items: 3 },
  [theme.breakpoints.values.xxl]: { items: 2 },
};

const WhyUs = () => {
  const [index, setIndex] = useState<number>(0);

  return (
    <Stack
      pt={2}
      pb={8}
      sx={{
        backgroundColor: 'primaryDark.200',
        position: 'relative',
      }}
    >
      <Stack
        spacing={{ xs: 7, lg: 20 }}
        direction={{ xs: 'column', lg: 'row' }}
        alignItems="center"
        justifyContent="start"
        sx={{ ...LAYOUT_DEFAULTS, position: 'relative' }}
      >
        <Stack
          spacing={2.5}
          width={{ xs: '100%', lg: '60%' }}
          minHeight={{ xs: '123px', sm: '82px', md: 0, lg: '308px' }}
          minWidth="310px"
          justifyContent="center"
        >
          <Typography
            maxWidth={{
              xs: '100%',
              lg: '90%',
            }}
            variant="publicHeading3"
            fontSize={{ xs: '2rem', lg: '3rem' }}
          >
            {whyUsArray[index].subtitle}
          </Typography>
        </Stack>

        <Box sx={{ width: '100%', position: { xs: 'relative', lg: 'initial' } }}>
          <CustomAliceCarousel
            responsive={responsive}
            items={whyUsCarouselElements}
            onChangedSlide={(index: number) => setIndex(index)}
            buttonStyles={{
              top: '50%',
              transform: 'translateY(-50%)',
              right: { xs: '-4%', md: '-1%', lg: '0%', xxl: '-15%' },
            }}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default WhyUs;
