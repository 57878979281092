import { Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import theme from '../../../../../../utils/theme';
import CustomButton from '../../../../../../components/button/CustomButton';

const GetDemoManage = () => {
  const isLessThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Stack
      justifyContent="space-between"
      width="100%"
      height="100%"
      px={2}
      p={{ lg: 10 }}
      py={10}
      direction={{ lg: 'row' }}
      gap={10}
      mt={10}
      bgcolor="primaryLight.50"
    >
      <Stack width="100%" gap={3} justifyContent={{ lg: 'center' }} alignItems={{ lg: 'center' }}>
        <Typography
          variant="publicHeading3"
          color="primaryDark.900"
          sx={{
            textAlign: { lg: 'center' },
          }}
        >
          Get a demo
        </Typography>
        <Typography
          variant="publicBody4Regular"
          color="primaryDark.700"
          sx={{
            textAlign: { lg: 'center' },
          }}
        >
          Not sure which plan is right for you? Let us talk you through the options
        </Typography>
        <CustomButton
          sx={{ width: 'fit-content' }}
          href={process.env.REACT_APP_CALENDLY_URL as string}
        >
          Book your demo
        </CustomButton>
      </Stack>
      {isLessThanLarge ? (
        <Divider flexItem>Or</Divider>
      ) : (
        <Divider orientation="vertical" flexItem>
          Or
        </Divider>
      )}
      <Stack width="100%" gap={3} justifyContent={{ lg: 'center' }} alignItems={{ lg: 'center' }}>
        <Typography
          variant="publicHeading3"
          color="primaryDark.900"
          sx={{
            textAlign: { lg: 'center' },
          }}
        >
          Start a free trial
        </Typography>
        <Typography
          variant="publicBody4Regular"
          color="primaryDark.700"
          sx={{
            textAlign: { lg: 'center' },
          }}
        >
          We offer a 30-day free trial to all companies interested in using our End-to-End Project
          Management tool
        </Typography>
        <CustomButton
          sx={{ width: 'fit-content' }}
          variant="primaryLight"
          href={process.env.REACT_APP_CALENDLY_URL as string}
        >
          Try for free
        </CustomButton>
      </Stack>
    </Stack>
  );
};

export default GetDemoManage;
