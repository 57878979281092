/* eslint-disable react/no-unused-prop-types */
import { Box, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { SxProps } from '@mui/system';
import nika from '../../../../assets/img/public-pages/work-with-us-nika.png';
import tihor from '../../../../assets/img/public-pages/work-with-us-tihor.png';
import { LAYOUT_DEFAULTS } from '../../../../utils/constants';
import theme from '../../../../utils/theme';

interface OpinionsProps {
  id: string;
  img: string;
  text: string;
  name: string;
  profession: string;
  order: 'imageThenText' | 'textThenImage';
  sx?: SxProps<Theme>;
  spacing?: number;
  alt?: string;
}

const opinionsList: OpinionsProps[] = [
  {
    id: 'opinion1',
    text: 'There is a truly flat hierarchy within Spona, and it is very fulfilling to be able to offer your opinions and communicate openly. Each and every team member can and does contribute our innovative product - it is a great learning opportunity! And being able to build connections and work with talented businesses and agencies across the globe is something that really keeps me going.',
    name: 'Nika Ujević Beusan',
    profession: 'Sales Account & Partnerships Manager',
    img: nika,
    order: 'imageThenText',
    alt: 'Nika Ujević-Beusan, Global Sales Manager at Spona',
  },
  {
    id: 'opinion2',
    text: 'I have acquired a lot of new skills and knowledge since joining Spona. The atmosphere is positive and the environment is constructive. Someone is always happy to help with any questions I may have. Spona is a great place for anyone who wants to gain an enormous amount of experience and progress in their career!',
    name: 'Tihor Nezić',
    profession: 'Front-End Developer',
    img: tihor,
    order: 'textThenImage',
    sx: { width: '100%', flexDirection: { xs: 'column-reverse', md: 'row' } },
    spacing: 3,
    alt: 'Tihor Nezić, Frontend Developer at Spona',
  },
];

const Opinions = () => {
  const isLessGreaterThanMedium = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Stack spacing={4} sx={{ ...LAYOUT_DEFAULTS, mt: 10 }}>
      {opinionsList.map(
        ({ id, img, text, name, profession, order, sx, spacing, alt }: OpinionsProps) => (
          <Stack
            key={id}
            spacing={{ xs: 3 }}
            alignItems="center"
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            sx={{ ...sx }}
          >
            {/* image */}
            {order === 'imageThenText' && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={{ xs: 3 }}
                sx={{ width: { xs: '100%', md: '50%', lg: '30%', xxl: '30%' } }}
              >
                <Box
                  component="img"
                  alt={alt}
                  src={img}
                  sx={{
                    width: { xs: '50%', md: '80%' },
                    height: '50%',
                    objectFit: 'cover',
                  }}
                />

                {/* small profession text */}
                <Stack sx={{ display: { xs: 'inline', md: 'none' } }}>
                  <Typography component="p" variant="publicBody5Regular" color="common.black">
                    {name}
                  </Typography>

                  <Typography variant="publicBody6Regular" color="primaryDark.600">
                    {profession}
                  </Typography>
                </Stack>
              </Stack>
            )}

            {/* big profession text */}
            <Stack spacing={3} width={{ xs: '100%', md: '55%', lg: '55%' }} mt={spacing}>
              <Typography
                sx={{
                  typography: { xs: 'publicBody4Regular', md: 'publicBody3Regular' },
                }}
                fontSize="18px !important"
              >
                {text}
              </Typography>

              <Stack sx={{ display: { xs: 'none', md: 'inline' } }}>
                <Typography component="p" variant="publicBody4Regular">
                  {name}
                </Typography>

                <Typography component="p" variant="publicBody4Regular" color="primaryDark.600">
                  {profession}
                </Typography>
              </Stack>
            </Stack>

            {/* image */}
            {order === 'textThenImage' && (
              <Stack
                spacing={3}
                alignItems="center"
                justifyContent={{ xs: 'space-between', md: 'end' }}
                sx={{
                  width: { xs: '100%', md: '50%', lg: '30%', xxl: '30%' },
                  flexDirection: { xs: 'row-reverse', md: 'reverse' },
                }}
              >
                <Box
                  component="img"
                  alt={alt}
                  src={img}
                  sx={{
                    width: { xs: '50%', md: '80%' },
                    height: '50%',
                    objectFit: 'cover',
                  }}
                />

                {/* small profession text */}
                {!isLessGreaterThanMedium && (
                  <Stack sx={{ display: { xs: 'inline', md: 'none' } }}>
                    <Typography component="p" variant="publicBody5Regular" color="common.black">
                      {name}
                    </Typography>

                    <Typography variant="publicBody6Regular" color="primaryDark.600">
                      {profession}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        ),
      )}
    </Stack>
  );
};

export default Opinions;
