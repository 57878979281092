import { createTheme } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { Color, PaletteColor, Theme } from '@mui/material';
import { createBreakpoints } from '@mui/system';
import React from 'react';
import {
  primaryDark,
  primaryLight,
  secondaryBlue,
  secondaryGreen,
  secondaryPink,
  secondaryYellow,
  gradient,
  secondaryGray,
  secondaryOrange,
} from './color';
import CustomUnsortedIcon from '../components/custom-unsorted-icon/CustomUnsortedIcon';

// color palette types
type PalettePrimaryLight = Pick<PaletteColor, 'main' | 'contrastText'> &
  Pick<Color, 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900>;

type PalettePrimaryDark = Pick<PaletteColor, 'main' | 'contrastText'> &
  Pick<
    Color & { 150: string; 250: string },
    100 | 150 | 200 | 250 | 300 | 400 | 500 | 600 | 700 | 800 | 900
  >;

type PaletteSecondaryPink = Pick<PaletteColor, 'main' | 'contrastText'> &
  Pick<Color, 100 | 200 | 500 | 700>;

type PaletteSecondaryGreen = Pick<PaletteColor, 'main' | 'contrastText'> &
  Pick<
    Color & { 301: string; 401: string; 601: string; 701: string; 801: string; 901: string },
    100 | 200 | 301 | 401 | 500 | 601 | 700 | 701 | 801 | 901
  >;

type PaletteSecondaryBlue = Pick<PaletteColor, 'main' | 'contrastText'> &
  Pick<
    Color & {
      50: string;
      101: string;
      201: string;
      301: string;
      401: string;
      501: string;
      601: string;
      701: string;
      801: string;
      901: string;
    },
    50 | 100 | 101 | 200 | 201 | 301 | 401 | 500 | 501 | 601 | 700 | 701 | 801 | 901
  >;

type PaletteSecondaryYellow = Pick<PaletteColor, 'main' | 'contrastText'> &
  Pick<
    Color & { 301: string; 401: string; 601: string; 701: string; 801: string; 901: string },
    100 | 200 | 301 | 401 | 500 | 601 | 700 | 701 | 801 | 901
  >;

type PaletteSecondaryGray = Pick<PaletteColor, 'main' | 'contrastText'> &
  Pick<Color, 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900>;

type PaletteSecondaryOrange = Pick<PaletteColor, 'main' | 'contrastText'> &
  Pick<
    Color & {
      50: string;
      101: string;
      201: string;
      301: string;
      401: string;
      501: string;
      601: string;
      701: string;
      801: string;
      901: string;
    },
    50 | 101 | 201 | 301 | 401 | 501 | 601 | 701 | 801 | 901
  >;

type gradient = Pick<PaletteColor, 'main' | 'contrastText'> &
  Pick<
    Color & {
      1: string;
      2: string;
      3: string;
      4: string;
      5: string;
      6: string;
      7: string;
      8: string;
      9: string;
      10: string;
      11: string;
      12: string;
    },
    1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  >;

// declare new color palettes
declare module '@mui/material/styles' {
  interface Palette {
    primaryLight: Palette['primary'];
    primaryDark: Palette['primary'];
    secondaryPink: Palette['primary'];
    secondaryGreen: Palette['primary'];
    secondaryBlue: Palette['primary'];
    secondaryYellow: Palette['primary'];
    secondaryGray: Palette['primary'];
    secondaryOrange: Palette['primary'];
    gradient: Palette['primary'];
  }
  interface PaletteOptions {
    primaryLight: PaletteOptions['primary'];
    primaryDark: PaletteOptions['primary'];
    secondaryPink: PaletteOptions['primary'];
    secondaryGreen: PaletteOptions['primary'];
    secondaryBlue: PaletteOptions['primary'];
    secondaryYellow: PaletteOptions['primary'];
    secondaryGray: Palette['primary'];
    secondaryOrange: Palette['primary'];
    gradient: PaletteOptions['primary'];
  }
}

// make colors available when using theme.palette notation
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    primaryLight: PalettePrimaryLight;
    primaryDark: PalettePrimaryDark;
    secondaryPink: PaletteSecondaryPink;
    secondaryGreen: PaletteSecondaryGreen;
    secondaryBlue: PaletteSecondaryBlue;
    secondaryYellow: PaletteSecondaryYellow;
    secondaryGray: PaletteSecondaryGray;
    secondaryOrange: PaletteSecondaryOrange;
    gradient: gradient;
  }

  interface PaletteOptions {
    primaryLight: PalettePrimaryLight;
    primaryDark: PalettePrimaryDark;
    secondaryPink: PaletteSecondaryPink;
    secondaryGreen: PaletteSecondaryGreen;
    secondaryBlue: PaletteSecondaryBlue;
    secondaryYellow: PaletteSecondaryYellow;
    secondaryGray: PaletteSecondaryGray;
    secondaryOrange: PaletteSecondaryOrange;
    gradient: gradient;
  }
}

// declare new breakpoint
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    bg: true;
    gr: true;
    xxl: true;
  }
}

// declare new typography variants
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    headline1: true;
    headline2: true;
    headline3: true;
    headline4: true;
    headline5: true;
    headline6: true;
    headline7: true;
    //
    heading1: true;
    heading2: true;
    heading3: true;
    heading4: true;
    heading5: true;
    //
    body3: true;
    body4: true;

    bodyMedium1: true;
    bodyMedium2: true;
    bodyMedium3: true;
    bodyMedium4: true;

    bodySemiBold1: true;
    bodySemiBold2: true;
    bodySemiBold3: true;
    bodySemiBold4: true;

    publicHeadline1: true;
    publicHeadline2: true;
    publicHeadline3: true;
    publicHeadline4: true;
    publicHeadline5: true;
    publicHeadline6: true;
    publicHeadline7: true;
    publicHeadline8: true;
    publicHeadline9: true;
    publicHeadline10: true;
    publicHeadline10Medium: true;

    publicHeading1: true;
    publicHeading2: true;
    publicHeading3: true;
    publicHeading4: true;
    publicHeading5: true;

    publicBody1: true;
    publicBody1Regular: true;
    publicBody1Light: true;
    publicBody2: true;
    publicBody2Regular: true;
    publicBody2Light: true;
    publicBody3: true;
    publicBody3Regular: true;
    publicBody3Light: true;
    publicBody4: true;
    publicBody4Regular: true;
    publicBody4Light: true;
    publicBody5: true;
    publicBody5Regular: true;
    publicBody5Light: true;
    publicBody6: true;
    publicBody6Regular: true;
    publicBody6Light: true;
  }
}

// extend default typography names with additional ones
export interface ExtendedTypographyOptions extends TypographyOptions {
  headline1: React.CSSProperties;
  headline2: React.CSSProperties;
  headline3: React.CSSProperties;
  headline4: React.CSSProperties;
  headline5: React.CSSProperties;
  headline6: React.CSSProperties;
  headline7: React.CSSProperties;
  //
  heading1: React.CSSProperties;
  heading2: React.CSSProperties;
  heading3: React.CSSProperties;
  heading4: React.CSSProperties;
  heading5: React.CSSProperties;
  //
  body3: React.CSSProperties;
  body4: React.CSSProperties;

  bodyMedium1: React.CSSProperties;
  bodyMedium2: React.CSSProperties;
  bodyMedium3: React.CSSProperties;
  bodyMedium4: React.CSSProperties;

  bodySemiBold1: React.CSSProperties;
  bodySemiBold2: React.CSSProperties;
  bodySemiBold3: React.CSSProperties;
  bodySemiBold4: React.CSSProperties;

  publicHeadline1: React.CSSProperties;
  publicHeadline2: React.CSSProperties;
  publicHeadline3: React.CSSProperties;
  publicHeadline4: React.CSSProperties;
  publicHeadline5: React.CSSProperties;
  publicHeadline6: React.CSSProperties;
  publicHeadline7: React.CSSProperties;
  publicHeadline8: React.CSSProperties;
  publicHeading1: React.CSSProperties;
  publicHeading2: React.CSSProperties;
  publicHeading3: React.CSSProperties;
  publicHeading4: React.CSSProperties;
  publicHeading5: React.CSSProperties;

  publicBody1: React.CSSProperties;
  publicBody1Regular: React.CSSProperties;
  publicBody1Light: React.CSSProperties;
  publicBody2: React.CSSProperties;
  publicBody2Regular: React.CSSProperties;
  publicBody2Light: React.CSSProperties;
  publicBody3: React.CSSProperties;
  publicBody3Regular: React.CSSProperties;
  publicBody3Light: React.CSSProperties;
  publicBody4: React.CSSProperties;
  publicBody4Regular: React.CSSProperties;
  publicBody4Light: React.CSSProperties;
  publicBody5: React.CSSProperties;
  publicBody5Regular: React.CSSProperties;
  publicBody5Light: React.CSSProperties;
  publicBody6: React.CSSProperties;
  publicBody6Regular: React.CSSProperties;
  publicBody6Light: React.CSSProperties;
  publicHeadline9: React.CSSProperties;
  publicHeadline10: React.CSSProperties;
  publicHeadline10Medium: React.CSSProperties;
}

const breakpoints = createBreakpoints({});

const theme: Theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          textTransform: 'uppercase',
          py: 0.5,
          px: 1.25,
          borderRadius: '3px',
          boxShadow: '5px 15px 25px rgba(0, 0, 0, 0.08)',
          background: primaryDark[600],
          whiteSpace: 'normal',
          border: 'none',
        },
        arrow: {
          color: primaryDark[600],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variant: 'body2',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          paddingRight: 2,

          '&:hover': {
            '.MuiInputAdornment-outlined': {
              borderLeftColor: '#212121',
            },
          },

          '&.Mui-focused': {
            '.MuiInputAdornment-outlined': {
              borderLeftColor: '#212121',
              borderLeftWidth: '2px',
            },
          },
        },
      },
    },
    // MuiStep: {
    //   styleOverrides: {
    //     root: {
    //       paddingLeft: 0,
    //       paddingRight: 14,
    //     },
    //   },
    // },
    // FIXME: do we want this here?
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: '56px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        disableColumnMenu: true,
        components: {
          ColumnUnsortedIcon: CustomUnsortedIcon,
        },
      },
      styleOverrides: {
        root: {
          '&>.MuiDataGrid-main': {
            border: 'none !important',
            borderBottom: '5px solid red',
            '&>.MuiDataGrid-columnHeaders': {
              borderBottom: 'none',
            },

            '& div div div div >.MuiDataGrid-cell': {
              borderBottom: 'none',
            },
          },
          '.MuiDataGrid-columnHeader': {
            paddingLeft: 1,
            border: 'none',
          },
          '.MuiDataGrid-root': {
            outline: 'none !important',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none !important',
          },
          '.MuiDataGrid-row': {
            borderRadius: '6px',
            transition: 'all 120ms ease-out',
            '&:hover': {
              cursor: 'pointer',
              background: '#FFFFFF',
              boxShadow: '6px 6px 15px rgba(56, 69, 114, 0.1)',
            },
          },
          '.MuiDataGrid-columnHeaderTitle': {
            fontFamily: 'Outfit',
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '0.05em',
            textTransform: 'uppercase',
            color: '#79829B',
          },
          '& .MuiTypography-root': {
            [breakpoints.down('lg')]: {
              fontSize: '0.875rem !important',
            },
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none !important',
          },
          // '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
          //   outline: 'none !important',
          // },
          // '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
          //   outline: 'none !important',
          // },
          // '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
          //   padding: 0,
          // },
          // '&.MuiDataGrid-root .MuiDataGrid-columnHeaders': {
          //   padding: '0 10px',
          // },
          // '.MuiDataGrid-columnHeaders': {
          //   borderTopLeftRadius: '16px',
          //   borderTopRightRadius: '16px',
          // },
          // '.MuiDataGrid-iconButtonContainer': {
          //   visibility: 'visible',
          //   width: '0 !important',
          // },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: breakpoints.values.xs,
      sm: breakpoints.values.sm,
      md: breakpoints.values.md,
      // bg for big
      bg: 1024,
      lg: breakpoints.values.lg,
      // gr for great
      gr: 1440,
      xl: breakpoints.values.xl,
      xxl: 1920,
    },
  },
  palette: {
    // FIXME:
    // both primary and secondary should get deleted after ALL instances
    // throughout the app where they are used are fixed and corrected to the
    // new palette names and its appropriate colors; but for now leave them
    primary: {
      main: '#27004F',
      light: '#6324FF',
      dark: 'rgba(98, 36, 255, 0.8)',
      contrastText: '#F6F6F6',
    },
    secondary: {
      main: '#7F57FF',
    },
    //
    primaryLight: {
      main: primaryLight[300],
      50: primaryLight[50],
      100: primaryLight[100],
      200: primaryLight[200],
      300: primaryLight[300],
      400: primaryLight[400],
      500: primaryLight[500],
      600: primaryLight[600],
      700: primaryLight[700],
      800: primaryLight[800],
      900: primaryLight[900],
      contrastText: '#fff',
    },
    primaryDark: {
      main: primaryDark[300],
      100: primaryDark[100],
      150: primaryDark[150],
      200: primaryDark[200],
      250: primaryDark[250],
      300: primaryDark[300],
      400: primaryDark[400],
      500: primaryDark[500],
      600: primaryDark[600],
      700: primaryDark[700],
      800: primaryDark[800],
      900: primaryDark[900],
      contrastText: '#fff',
    },
    secondaryPink: {
      main: secondaryPink[200],
      100: secondaryPink[100],
      200: secondaryPink[200],
      500: secondaryPink[500],
      700: secondaryPink[700],
      contrastText: '#fff',
    },
    secondaryGreen: {
      main: secondaryGreen[200],
      100: secondaryGreen[100],
      200: secondaryGreen[200],
      301: secondaryGreen[301],
      401: secondaryGreen[401],
      500: secondaryGreen[500],
      601: secondaryGreen[601],
      700: secondaryGreen[700],
      701: secondaryGreen[701],
      801: secondaryGreen[801],
      901: secondaryGreen[901],
      contrastText: '#fff',
    },
    secondaryBlue: {
      main: secondaryBlue[200],
      50: secondaryBlue[50],
      100: secondaryBlue[100],
      101: secondaryBlue[101],
      200: secondaryBlue[200],
      201: secondaryBlue[201],
      301: secondaryBlue[301],
      401: secondaryBlue[401],
      500: secondaryBlue[500],
      501: secondaryBlue[501],
      601: secondaryBlue[601],
      700: secondaryBlue[700],
      701: secondaryBlue[701],
      801: secondaryBlue[801],
      901: secondaryBlue[901],
      contrastText: '#fff',
    },
    secondaryYellow: {
      main: secondaryYellow[200],
      100: secondaryYellow[100],
      200: secondaryYellow[200],
      301: secondaryYellow[301],
      401: secondaryYellow[401],
      500: secondaryYellow[500],
      601: secondaryYellow[601],
      700: secondaryYellow[700],
      701: secondaryYellow[701],
      801: secondaryYellow[801],
      901: secondaryYellow[901],
      contrastText: '#fff',
    },
    secondaryGray: {
      main: secondaryGray[300],
      100: secondaryGray[100],
      200: secondaryGray[200],
      300: secondaryGray[300],
      400: secondaryGray[400],
      500: secondaryGray[500],
      600: secondaryGray[600],
      700: secondaryGray[700],
      800: secondaryGray[800],
      900: secondaryGray[900],
      contrastText: '#fff',
    },
    secondaryOrange: {
      main: secondaryOrange[101],
      50: secondaryOrange[50],
      101: secondaryOrange[101],
      201: secondaryOrange[201],
      301: secondaryOrange[301],
      401: secondaryOrange[401],
      501: secondaryOrange[501],
      601: secondaryOrange[601],
      701: secondaryOrange[701],
      801: secondaryOrange[801],
      901: secondaryOrange[901],
      contrastText: '#fff',
    },
    gradient: {
      main: gradient[1],
      1: gradient[1],
      2: gradient[2],
      3: gradient[3],
      4: gradient[4],
      5: gradient[5],
      6: gradient[6],
      7: gradient[7],
      8: gradient[8],
      9: gradient[9],
      10: gradient[10],
      11: gradient[11],
      12: gradient[12],
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: ['Gellix-Regular', 'CooperBT', 'sans-serif', 'cursive'].join(','),
    button: {
      textTransform: 'none',
      fontSize: '0.95rem',
    },
    // FIXME:
    // same goes for typography variants; all of those old definitions
    // should get deleted after ALL instances
    // throughout the app where they are used are fixed and corrected to the
    // new typography variants
    h1: {
      fontSize: '3rem',
      fontWeight: 400,
      fontFamily: 'CooperBT',
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 400,
      fontFamily: 'CooperBT',
    },
    h3: {
      fontSize: '2.25rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.6rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1.15rem',
      fontWeight: 400,
    },
    h6: {
      fontSize: '1.2',
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 300,
      fontSize: '0.85rem',
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: '0.92rem',
    },
    //
    // headlines
    headline1: {
      fontFamily: 'CooperBT',
      fontWeight: 400,
      fontSize: '3rem',
    },
    headline2: {
      fontFamily: 'CooperBT',
      fontWeight: 400,
      fontSize: '2.25rem',
    },
    headline3: {
      fontFamily: 'CooperBT',
      fontWeight: 400,
      fontSize: '1.75rem',
    },
    headline4: {
      fontFamily: 'CooperBT',
      fontWeight: 400,
      // default - all screen widths above the xl breakpoint (above 1536px)
      fontSize: '1.375rem',
      // all screen widths below the xl breakpoint (1536px)
      [breakpoints.down('xl')]: {
        fontSize: '1.25rem',
      },
    },
    headline5: {
      fontFamily: 'CooperBT',
      fontWeight: 400,
      fontSize: '1.25rem',
      [breakpoints.down('xl')]: {
        fontSize: '1.125rem',
      },
    },
    headline6: {
      fontFamily: 'CooperBT',
      fontWeight: 400,
      fontSize: '1.125rem',
      [breakpoints.down('xl')]: {
        fontSize: '1rem',
      },
    },
    headline7: {
      fontFamily: 'CooperBT',
      fontWeight: 400,
      fontSize: '1rem',
      [breakpoints.down('xl')]: {
        fontSize: '0.875rem',
      },
    },
    // headings
    heading1: {
      fontFamily: 'Gellix-SemiBold',
      fontWeight: 600,
      fontSize: '1.75rem',
      textTransform: 'uppercase',
      letterSpacing: '0.02rem',
    },
    heading2: {
      fontFamily: 'Gellix-SemiBold',
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: '0.02rem',
      lineHeight: '1.3125rem',
      fontSize: '1.375rem',
      [breakpoints.down('xl')]: {
        fontSize: '1.25rem',
      },
    },
    heading3: {
      fontFamily: 'Gellix-SemiBold',
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: '0.02rem',
      lineHeight: '1.3125rem',
      fontSize: '1rem',
      // [breakpoints.down('xl')]: {
      //   fontSize: '0.875rem',
      // },
    },
    heading4: {
      fontFamily: 'Gellix-SemiBold',
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: '0.05rem',
      lineHeight: '1rem',
      fontSize: '0.875rem',
      // [breakpoints.down('xl')]: {
      //   fontSize: '0.75rem',
      // },
    },
    heading5: {
      fontFamily: 'Gellix-SemiBold',
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: '0.05em',
      lineHeight: '1rem',
      fontSize: '0.75rem',
      [breakpoints.down('xl')]: {
        fontSize: '0.6875rem',
      },
    },
    // bodies
    body1: {
      fontFamily: 'Gellix-Regular',
      fontWeight: 400,
      lineHeight: '1.3125rem',
      letterSpacing: '0.03rem',
      fontSize: '1.125rem',
    },
    body2: {
      fontFamily: 'Gellix-Regular',
      fontWeight: 400,
      lineHeight: '1.3125rem',
      letterSpacing: '0.03rem',
      fontSize: '1rem',
    },
    body3: {
      fontFamily: 'Gellix-Regular',
      fontWeight: 400,
      lineHeight: '1rem',
      fontSize: '0.875rem',
    },
    body4: {
      fontFamily: 'Gellix-Regular',
      fontWeight: 400,
      lineHeight: '1rem',
      fontSize: '0.75rem',
    },
    // bodies
    bodyMedium1: {
      fontFamily: 'Gellix-Medium',
      lineHeight: '1.3125rem',
      fontSize: '1.125rem',
    },
    bodyMedium2: {
      fontFamily: 'Gellix-Medium',
      lineHeight: '1.3125rem',
      fontSize: '1rem',
    },
    bodyMedium3: {
      fontFamily: 'Gellix-Medium',
      lineHeight: '1.125rem',
      fontSize: '0.875rem',
    },
    bodyMedium4: {
      fontFamily: 'Gellix-Medium',
      lineHeight: '1rem',
      fontSize: '0.75rem',
    },
    // bodies
    bodySemiBold1: {
      fontFamily: 'Gellix-SemiBold',
      lineHeight: '1.3125rem',
      fontSize: '1.125rem',
    },
    bodySemiBold2: {
      fontFamily: 'Gellix-SemiBold',
      lineHeight: '1.3125rem',
      fontSize: '1rem',
    },
    bodySemiBold3: {
      fontFamily: 'Gellix-SemiBold',
      lineHeight: '1rem',
      fontSize: '0.875rem',
    },
    bodySemiBold4: {
      fontFamily: 'Gellix-SemiBold',
      lineHeight: '1rem',
      fontSize: '0.75rem',
    },

    // ***PUBLIC SCREEN FONTS***
    // headlines
    publicHeadline1: {
      fontFamily: 'Gellix-SemiBold',
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: '10.3rem',
      letterSpacing: '0.04rem',
      lineHeight: '106%',
    },
    publicHeadline2: {
      fontFamily: 'Gellix-SemiBold',
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: '8.5rem',
      letterSpacing: '0.45rem',
      lineHeight: '111%',
    },
    publicHeadline3: {
      fontFamily: 'Gellix-SemiBold',
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: '8.3rem',
      letterSpacing: '0.3325rem',
      lineHeight: '130%',
    },
    publicHeadline4: {
      fontFamily: 'Gellix-SemiBold',
      fontWeight: 600,
      fontSize: '7.9rem',
      letterSpacing: '0.04rem',
      lineHeight: '116%',
    },
    publicHeadline5: {
      fontFamily: 'Gellix-SemiBold',
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: '7.4rem',
      letterSpacing: '0.2975rem',
      lineHeight: '114%',
    },
    publicHeadline6: {
      fontFamily: 'Gellix-SemiBold',
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: '5.8rem',
      letterSpacing: '0.2325rem',
      lineHeight: '120%',
    },
    publicHeadline7: {
      fontFamily: 'Gellix-SemiBold',
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: '4.3rem',
      letterSpacing: '0.1725rem',
      lineHeight: '121%',
    },
    publicHeadline8: {
      fontFamily: 'Gellix-SemiBold',
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: '3.9rem',
      letterSpacing: '0.1575rem',
      lineHeight: '121%',
    },
    publicHeadline9: {
      fontFamily: 'Gellix-SemiBold',
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: '3rem',
      letterSpacing: '0.12rem',
      lineHeight: '121%',
    },
    publicHeadline10: {
      fontFamily: 'Gellix-SemiBold',
      fontSize: '2rem',
      fontWeight: 600,
      fontStyle: 'normal',
      letterSpacing: '0.08rem',
      lineHeight: '121%',
    },
    publicHeadline10Medium: {
      fontFamily: 'Gellix-SemiBold',
      fontSize: '2rem',
      fontWeight: 500,
      fontStyle: 'normal',
      letterSpacing: '0.08rem',
      lineHeight: '121%',
    },

    // headings
    publicHeading1: {
      fontFamily: 'CooperBT',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '6rem',
      letterSpacing: '0.24rem',
      lineHeight: 'normal',
    },
    publicHeading2: {
      fontFamily: 'CooperBT',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '4rem',
      letterSpacing: '0.16rem',
      lineHeight: '120%',
    },
    publicHeading3: {
      fontFamily: 'CooperBT',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '3rem',
      letterSpacing: '0.12rem',
      lineHeight: '128%',
    },
    publicHeading4: {
      fontFamily: 'CooperBT',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '2rem',
      letterSpacing: '0.08rem',
      lineHeight: '103%',
    },
    publicHeading5: {
      fontFamily: 'CooperBT',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '1.5rem',
      letterSpacing: '0.059375rem',
      lineHeight: '140%',
    },

    // bodies
    publicBody1: {
      fontFamily: 'Gellix-SemiBold',
      fontWeight: 600,
      fontStyle: 'normal',
      letterSpacing: '0.08rem',
      fontSize: '2rem',
      lineHeight: '125%',
    },
    publicBody1Regular: {
      fontFamily: 'Gellix-Regular',
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: '0.08rem',
      fontSize: '2rem',
      lineHeight: '100%',
    },
    publicBody1Light: {
      fontFamily: 'Gellix-Light',
      fontWeight: 300,
      fontStyle: 'normal',
      letterSpacing: '0.08rem',
      fontSize: '2rem',
      lineHeight: '180%',
    },
    publicBody2: {
      fontFamily: 'Gellix-SemiBold',
      fontWeight: 550,
      fontStyle: 'normal',
      letterSpacing: '0.03rem',
      fontSize: '1.5rem',
      lineHeight: 'normal',
    },
    publicBody2Regular: {
      fontFamily: 'Gellix-Regular',
      fontWeight: 350,
      fontStyle: 'normal',
      letterSpacing: '0.059375rem',
      fontSize: '1.5rem',
      lineHeight: 'normal',
    },
    publicBody2Light: {
      fontFamily: 'Gellix-Light',
      fontWeight: 300,
      fontStyle: 'normal',
      letterSpacing: '0.059375rem',
      fontSize: '1.5rem',
      lineHeight: 'normal',
    },
    publicBody3: {
      fontFamily: 'Gellix-SemiBold',
      fontWeight: 600,
      fontStyle: 'normal',
      letterSpacing: '0.05rem',
      fontSize: '1.25rem',
      lineHeight: '180%',
    },
    publicBody3Regular: {
      fontFamily: 'Gellix-Regular',
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: '0.05rem',
      fontSize: '1.25rem',
      lineHeight: '145%',
    },
    publicBody3Light: {
      fontFamily: 'Gellix-Light',
      fontWeight: 300,
      fontStyle: 'normal',
      letterSpacing: '0.05rem',
      fontSize: '1.25rem',
      lineHeight: '180%',
    },
    publicBody4: {
      fontFamily: 'Gellix-SemiBold',
      fontWeight: 600,
      fontStyle: 'normal',
      letterSpacing: '0.04rem',
      fontSize: '1rem',
      lineHeight: '180%',
    },
    publicBody4Regular: {
      fontFamily: 'Gellix-Regular',
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: '0.04rem',
      fontSize: '1rem',
      lineHeight: '150%',
    },
    publicBody4Light: {
      fontFamily: 'Gellix-Light',
      fontWeight: 300,
      fontStyle: 'normal',
      letterSpacing: '0.04rem',
      fontSize: '1rem',
      lineHeight: '180%',
    },
    publicBody5: {
      fontFamily: 'Gellix-SemiBold',
      fontWeight: 600,
      fontStyle: 'normal',
      letterSpacing: '0.0375rem',
      fontSize: '0.9375rem',
      lineHeight: '180%',
    },
    publicBody5Regular: {
      fontFamily: 'Gellix-Regular',
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: '0.0375rem',
      fontSize: '0.9375rem',
      lineHeight: '180%',
    },
    publicBody5Light: {
      fontFamily: 'Gellix-Light',
      fontWeight: 300,
      fontStyle: 'normal',
      letterSpacing: '0.0375rem',
      fontSize: '0.9375rem',
      lineHeight: '180%',
    },
    publicBody6: {
      fontFamily: 'Gellix-SemiBold',
      fontWeight: 600,
      fontStyle: 'normal',
      letterSpacing: '0.0375rem',
      fontSize: '0.875rem',
      lineHeight: '128%',
    },
    publicBody6Regular: {
      fontFamily: 'Gellix-Regular',
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: '0.0375rem',
      fontSize: '0.875rem',
      lineHeight: '128%',
    },
    publicBody6Light: {
      fontFamily: 'Gellix-Light',
      fontWeight: 300,
      fontStyle: 'normal',
      letterSpacing: '0.0375rem',
      fontSize: '0.875rem',
      lineHeight: '128%',
    },
  } as ExtendedTypographyOptions,
});

export default theme;
