import { Calendar, View, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import './sass/styles.scss';
import { useCallback, useState } from 'react';
import { CalendarEventDto } from 'tdc-web-backend/events/schemas';
import { EnumEventType, EnumScreenList } from 'tdc-web-backend/enums/enums';
import { EventMeetingModal } from './Modals/EventMeeting';
import { EventDetailModal } from './Modals/EventDetailsModal';
import useGetMany from '../../utils/hooks/crud-hooks/useGetMany';
import DeleteEventModal from './Modals/DeleteEventModal';
import CalendarToolbar from './CalendarToolbar';
import CalendarCustomEvent from './CalendarCustomEvent';
import CalendarCustomWeekEvent from './CalendarCustomWeekEvent';
import CalendarCustomDayEvent from './CalendarCustomDayEvent';
import CalendarCustomAgendaEvent from './CalendarCustomAgendaEvent';
import BannerNotificationMapper from '../../components/notifications/bannerNotifications/BannerNotificationMapper';
import { useSearchParams } from 'react-router-dom';
import useAuth from '../../utils/hooks/useAuth';

const BigCalendar = () => {
  const locales = {
    'en-US': enUS,
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
    getDay,
    locales,
  });

  const [activeEvent, setActiveEvent] = useState<CalendarEventDto | null>(null);
  const [eventDetailModalOpen, setEventDetailModalOpen] = useState<boolean>(false);
  const [createEvent, setCreateEvent] = useState<boolean>(false);
  const [newEventData, setNewEventData] = useState<Record<string, Date> | undefined>(undefined);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [shouldSelectBeDisabled, setShouldSelectBeDisabled] = useState<boolean>(true);
  let [searchParams, setSearchParams] = useSearchParams({
    date: new Date().toISOString(),
    viewType: 'month',
  });

  const openEditModal = () => {
    setCreateEvent(true);
    setEventDetailModalOpen(false);
  };
  const openDeleteModal = () => {
    setDeleteModalOpen(true);
    setEventDetailModalOpen(false);
  };

  const {
    authData: { userData: activeUser },
  } = useAuth();

  const { data: calendarEvent } = useGetMany<CalendarEventDto>({
    resource: `/calendar/${activeUser?.id && `?user=${activeUser?.id}`}`,
    refetchOnWindowFocus: false,
  });

  const handleSelectSlot = useCallback(({ start, end }) => {
    setActiveEvent(null);
    setCreateEvent(true);
    setNewEventData({
      start,
      end,
    });
  }, []);

  return (
    <>
      <BannerNotificationMapper screen={EnumScreenList.CaseStudyScreen} />
      {activeEvent && (
        <EventDetailModal
          onClose={() => {
            setEventDetailModalOpen(false);
            // setActiveEvent(null);
          }}
          open={eventDetailModalOpen}
          event={activeEvent as CalendarEventDto}
          openEditModal={openEditModal}
          openDeleteModal={openDeleteModal}
        />
      )}
      <DeleteEventModal
        open={deleteModalOpen}
        event={activeEvent as CalendarEventDto}
        onCancel={() => {
          setDeleteModalOpen(false);
          setActiveEvent(null);
        }}
      />
      <EventMeetingModal
        eventEditDetails={activeEvent as CalendarEventDto}
        start={newEventData?.start}
        end={newEventData?.end}
        open={createEvent}
        onCancel={() => {
          setCreateEvent(false);
          setActiveEvent(null);
        }}
      />
      <Calendar<CalendarEventDto>
        localizer={localizer}
        events={calendarEvent?.data.results}
        startAccessor={(event) =>
          event.type === EnumEventType.Event ? new Date(event.start) : new Date(event.end)
        }
        endAccessor={(event) => new Date(event.end)}
        titleAccessor="name"
        onSelectSlot={!shouldSelectBeDisabled ? handleSelectSlot : () => {}}
        onView={(view) =>
          view !== 'month' ? setShouldSelectBeDisabled(false) : setShouldSelectBeDisabled(true)
        }
        selectable={!shouldSelectBeDisabled}
        formats={{
          dayFormat: (date, culture, localizer) => localizer!.format(date, 'dd iiii'),
        }}
        onSelectEvent={(event) => {
          setEventDetailModalOpen(true);
          setActiveEvent(event);
        }}
        components={{
          toolbar: CalendarToolbar,
          month: {
            event: CalendarCustomEvent,
          },
          week: {
            event: CalendarCustomWeekEvent,
          },
          day: {
            event: CalendarCustomDayEvent,
          },
          agenda: {
            event: CalendarCustomAgendaEvent,
          },
        }}
        defaultView={searchParams.get('viewType') as View}
        defaultDate={searchParams.get('date') as string}
        // defaultDate={'2023-10-21T00:00:00.000Z'}
      />
    </>
  );
};

export default BigCalendar;
