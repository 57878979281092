import { Stack } from '@mui/material';
import { useState } from 'react';
import { EnumUserInvitationStatus } from 'tdc-web-backend/enums/enums';
import Invitations from './components/Invitations/Invitations';
import Team from './components/Team';
import useTable from '../../../../../components/table/useTable';
import useAuth from '../../../../../utils/hooks/useAuth';
import AddModal from './components/AddModal';
import ResendModal, { ResendModalData } from './components/ResendModal';
import RevokeModal from './components/RevokeModal';

const Members = () => {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isResendOpen, setIsResendOpen] = useState(false);
  const [isRevokeOpen, setIsRevokeOpen] = useState(false);
  const [resendData, setResendData] = useState<ResendModalData | null>(null);
  const [invitationId, setInvitationId] = useState<string | null>(null);
  const {
    authData: { userData },
  } = useAuth();

  const invitationData = useTable({
    resourceUrl: `user-invitation`,
    queryParams: `companyId=${userData?.membership?.company}`,
    withTabs: true,
    chipFilterQueryParams: [
      {
        title: 'Pending',
        search: { status: EnumUserInvitationStatus.Pending },
      },
      {
        title: 'Accepted',
        search: { status: EnumUserInvitationStatus.Accepted },
      },
      {
        title: 'Revoked',
        search: { status: EnumUserInvitationStatus.Revoked },
      },
    ],
  });

  return (
    <>
      <Stack gap={3} spacing={3}>
        <Team onAddNewClick={() => setIsAddOpen(true)} />
        <Invitations
          data={invitationData}
          onResendSelected={(data) => {
            setResendData(data);
            setIsResendOpen(true);
          }}
          onRevokeSelected={(id) => {
            setInvitationId(id);
            setIsRevokeOpen(true);
          }}
        />
      </Stack>
      {isAddOpen && (
        <AddModal
          isOpen={isAddOpen}
          setIsOpen={setIsAddOpen}
          onSuccessfulSubmit={() => {
            invitationData.refetch();
            invitationData.refetchQueries();
          }}
        />
      )}
      {isResendOpen && resendData && (
        <ResendModal
          isOpen={isResendOpen}
          setIsOpen={(e) => {
            setIsResendOpen(e);
            setResendData(null);
          }}
          onSuccessfulSubmit={() => invitationData.refetch()}
          data={resendData}
        />
      )}
      {isRevokeOpen && invitationId && (
        <RevokeModal
          isOpen={isRevokeOpen}
          setIsOpen={(e) => {
            setIsRevokeOpen(e);
            setInvitationId(null);
          }}
          onSuccessfulSubmit={() => {
            invitationData.refetch();
            invitationData.refetchQueries();
          }}
          invitationId={invitationId}
        />
      )}
    </>
  );
};

export default Members;
