import { Dispatch, SetStateAction, useState } from 'react';
import { Alert, Stack, Typography } from '@mui/material';
import ConfirmModal from '../../../../../../components/confirm-modal/ConfirmModal';
import { ReactComponent as ClipboardCheckIcon } from '../../../../../../assets/icons/layout-icons/ClipboardCheckIconEditModal.svg';
import { primaryLight } from '../../../../../../utils/color';
import useUpdate from '../../../../../../utils/hooks/crud-hooks/useUpdate';

type RevokeModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onSuccessfulSubmit?: () => void;
  invitationId: string;
};

const RevokeModal = ({ isOpen, setIsOpen, onSuccessfulSubmit, invitationId }: RevokeModalProps) => {
  const [error, setError] = useState<string | null>(null);

  const { mutate: revoke, isLoading } = useUpdate({
    resource: 'user-invitation',
  });

  const onSubmit = () => {
    revoke(
      {
        id: invitationId,
        suffix: 'revoke',
      },
      {
        onSuccess: () => {
          setError(null);
          setIsOpen(false);
          onSuccessfulSubmit?.();
        },
        onError: (err) => {
          setError((err as any).response?.data.message || (err as any).message);
        },
      },
    );
  };

  return (
    <ConfirmModal
      isModalOpen={isOpen}
      automaticallyCloseModalOnButtonClick={false}
      onCancel={() => setIsOpen(false)}
      onConfirm={onSubmit}
      setIsModalOpen={() => null}
      title="Revoke invite"
      width="900px"
      confirmButtonText="Revoke"
      iconProps={{
        icon: <ClipboardCheckIcon fill={primaryLight[500]} />,
        wrapperBackgroundColor: '#E3DBFC',
      }}
      isLoading={isLoading}
      showCancelButton={!isLoading}
      message={
        <Stack spacing={3}>
          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}
          <Typography variant="body2">
            Are you sure you want to cancel the invitation for this user? They will no longer be
            able to log in to the application using the code we sent.
          </Typography>
        </Stack>
      }
    />
  );
};

export default RevokeModal;
