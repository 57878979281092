export enum CardSizeEnum {
  Normal = 'normal',
  Small = 'small',
  Wide = 'wide',
  WideSmall = 'wideSmall',
}

export enum EndToEndDetailScreenTypeEnum {
  Project = 'project',
  Milestone = 'milestone',
  Task = 'task',
  Subtask = 'subtask',
}

export enum FlagTypeEnum {
  Flag = 'flag',
  Unflag = 'unflag',
}

export enum FloatingNotificationColorEnum {
  green = '#F5FED4',
  orange = '#FEF9D8',
  red = '#FFF2F4',
  blue = '#E5E8FF',
}
export enum ImageType {
  Unpublished = 'unpublished',
  Published = 'published',
}

export enum PitchType {
  TimedProject,
  PredefinedProject,
}

export enum SellerType {
  Service = 'seller_service',
  Tool = 'seller_tool',
}

export enum TempStatusEnum {
  Draft = 'Draft',
  Active = 'Active',
  Paid = 'Paid',
  Finished = 'Finished',
  In_progress = 'In progress',
  InProgress = 'In_progress',
  Paused = 'Paused',
  Cancelled = 'Cancelled',
  Pending = 'Pending',
  Due = 'Due',
}

export enum EnumPublicPagesImage {
  AboutOffice = 'about_office',
  AbaoutUsHero = 'about-us-hero',
  AccessAtAnyTimeBuyer = 'access-at-any-time-buyer',
  AccessAtAnyTimeSeller = 'access-at-any-time-seller',
  AccessibleSupportBuyer = 'accessible-support-buyer',
  AccessibleSupportSeller = 'accessible-support-seller',
  ArchiveHero = 'archive-hero',
  AutomaticNotificationsBuyer = 'automatic-notifications-buyer',

  BuildYourProjectBig = 'build_your_project_big',
  BuildYourProjectSmall = 'build_your_project_small',
  BuildYourProjectTeam = 'build-your-project-team',

  CaseStudyArchiveHero = 'case-study-archive-hero',
  CaseStudyArchiveMacbook = 'case-study-archive-macbook',
  ClearCommunicationBuyer = 'clear-communication-buyer',
  ClearCommunicationSeller = 'clear-communication-seller',
  Communication = 'communication',
  CompleteMilestonesSeller = 'complete-milestones-seller',
  ConfirmAccountBuyer = 'confirm-account-buyer',
  ConfirmAccountBuyerxl = 'confirm-account-buyerxl',
  ConfirmAccountSeller = 'confirm-account-seller',
  ConfirmAccountSellerxl = 'confirm-account-sellerxl',
  ConsolidateYourProjectsBuyer = 'consolidate-your-projects-buyer',
  ConsolidateYourProjectsSeller = 'consolidate-your-projects-seller',
  CreateProfile = 'create-profile',
  CreateInRealTimeBuyer = 'create-in-real-time-buyer',
  CreateInRealTimeSeller = 'create-in-real-time-seller',

  DoItYourself = 'do-it-yourself',
  DigitalContract = 'digital-contract',
  Dublin = 'dublin',
  DublinMobile = 'dublin-mobile',

  EasyResourcePlanningSeller = 'easy-resource-planning-seller',
  EliminateLegalFeesBuyer = 'eliminate-legal-fees-buyer',
  EliminateLegalFeesSeller = 'eliminate-legal-fees-seller',

  GetInsightsBuyer = 'get-insights-buyer',
  GetInsightsSeller = 'get-insights-seller',
  GetFullControlBuyer = 'get-full-control-buyer',
  GridForSellers = 'grid-for-sellers',
  GridProjectBenefits = 'grid-project-benefits',

  HireSeller = 'hire-seller',
  HomeContracts = 'home-contracts',
  HomeFeatures = 'home-features',
  HomeE2E = 'home-e2e',
  HomeHealth = 'home-health',
  HomeMap = 'home-map',
  HomeMapSvg = 'home-map-svg',
  HomeMilestones = 'home-milestones',
  HomePayments = 'home-payments',
  HomeSimplify = 'home-simplify',
  HowToHireHero = 'how-to-hire-hero',
  HowToFindWorkHero = 'how-to-find-work-hero',
  Hungary = 'hungary',
  HungaryMobile = 'hungary-mobile',

  KeepInTouchBuyer = 'keep_in_touch_buyer',
  KeepInTouchSeller = 'keep_in_touch_seller',

  Laptop = 'laptop',
  Laptop2 = 'laptop2',
  LetSponaHandleIt = 'let-spona-handle-it',
  LetSponaHandleItHero = 'let-spona-handle-it-hero',
  LetSponaHandleItSmall = 'let-spona-handle-it-small',
  ListYourRequirements = 'list-your-requirements',

  MacbookSellerArchive = 'macbook-seller-archive',
  MakeDataDrivenDecisionsBuyer = 'make-data-driven-decisions-buyer',
  MakeDataDrivenDecisionsSeller = 'make-data-driven-decisions-seller',
  ManageMilestonesAndTasksBuyer = 'manage_milestones_and_tasks_buyer',
  ManageMilestonesAndTasksSeller = 'manage_milestones_and_tasks_seller',
  MilestonesAndTasksHero = 'milestones-and-tasks-hero',
  MonitorYourKPIs = 'monitor-your-kpis',

  NewPasswordChangeSection = 'new-password-change-section',

  Office = 'office',
  Office2 = 'office2',
  Office3 = 'office3',
  Office4 = 'office4',
  Office5 = 'office5',
  OnePlatformBuyer = 'one-platform-buyer',
  OnePlatformSeller = 'one-platform-seller',
  OurJourneyCarousel = 'our-journey-carousel',
  OurJourneyCarousel2 = 'our-journey-carousel2',
  OurJourneyCarousel3 = 'our-journey-carousel3',
  OurJourneyCarousel4 = 'our-journey-carousel4',
  OurJourneyCarousel5 = 'our-journey-carousel5',
  OurJourneyCarousel6 = 'our-journey-carousel6',
  OurJourneyCarousel7 = 'our-journey-carousel7',
  OurJourneyCarousel8 = 'our-journey-carousel8',
  OurJourneyCarouselMobile = 'our-journey-carousel-mobile',
  OurJourneyCarouselMobile2 = 'our-journey-carousel-mobile2',
  OurJourneyCarouselMobile3 = 'our-journey-carousel-mobile3',
  OurJourneyCarouselMobile4 = 'our-journey-carousel-mobile4',
  OurJourneyCarouselMobile5 = 'our-journey-carousel-mobile5',
  OurJourneyCarouselMobile6 = 'our-journey-carousel-mobile6',
  OurJourneyCarouselMobile7 = 'our-journey-carousel-mobile7',
  OurJourneyCarouselMobile8 = 'our-journey-carousel-mobile8',

  PaymentSuccessful = 'payment-successful',
  People1 = 'people1',
  People2 = 'people2',
  People3 = 'people3',
  ProgressYourProjectBuyer = 'progress-your-project-buyer',

  ProjectReportsBuyer = 'project_reports_buyer',
  ProjectReportsSeller = 'project_reports_seller',
  PublishCaseStudy = 'publish-case-study',

  QuickUpdatesBuyer = 'quick-updates-buyer',
  QuickUpdatesSeller = 'quick-updates-seller',

  RecordYourActivitiesBuyer = 'record-your-activities-buyer',
  RecordYourActivitiesSeller = 'record-your-activities-seller',
  ReduceDataMicromanagementBuyer = 'reduce-data-micromanagement-buyer',
  ReduceDataMicromanagementSeller = 'reduce-data-micromanagement-seller',
  ResolveIssuesBuyer = 'resolve-issues-buyer',
  ResolveIssuesSeller = 'resolve-issues-seller',
  ReviewYourCandidates = 'review-your-candidates',
  ReviewPitches = 'review-pitches',

  SaveYourTimeAndMoney = 'save-your-time-and-money',
  SecureProcessingBuyer = 'secure-processing-buyer',
  SecureProcessingSeller = 'secure-processing-seller',
  ShareYourProgressSeller = 'share-your-progress-seller',
  SignContractsBuyer = 'sign_contracts_buyer',
  SignContractsSeller = 'sign_contracts_seller',
  SignDigitallyBuyer = 'sign-digitally-buyer',
  SignDigitallySeller = 'sign-digitally-seller',
  SmartBudgetAllocationBuyer = 'smart-budget-allocation-buyer',
  SmDigitalTurbine = 'sm-digital-turbine',
  SmGoogleAds = 'sm-google-ads',
  SmMeta = 'sm-meta',
  SmReddit = 'sm-reddit',
  SmSnapchat = 'sm-snapchat',
  SmSpotify = 'sm-spotify',
  SmTiktok = 'sm-tiktok',
  SmX = 'sm-x',
  SponaDashboard = 'spona-dashboard',
  SponaGives = 'spona-gives',
  SponaInNumbers = 'spona-in-numbers',
  SponaMakes = 'spona-makes',
  SponaMakesSubpage = 'spona-makes-subpage',
  SponaMakesTimedProjects = 'spona-makes-timed-projects',
  SponaMediaHero = 'spona-media-hero',
  SponaMediaHdiwMeta = 'spona-media-hdiw-meta',
  SponaMediaHeroAds = 'spona-media-hero-ads',
  StayInSyncSeller = 'stay-in-sync-seller',
  StandardizeYourProcessesBuyer = 'standardize-your-processes-buyer',
  StandardizeYourProcessesSeller = 'standardize-your-processes-seller',
  SubmitYourPitch = 'submit-your-pitch',
  SubmitYourProject = 'submit-your-project',

  TimedProjectSubageHero = 'timed-project-subpage-hero',
  TrackAndProcessPaymentsBuyer = 'track_and_process_payments_buyer',
  TrackAndProcessPaymentsSeller = 'track_and_process_payments_seller',
  TrackProjectHealthBuyer = 'track-project-health-buyer',
  TrackProjectHealthSeller = 'track-project-health-seller',

  TrackYourMetricsBuyer = 'track-your-metrics-buyer',
  TransparentEditingBuyer = 'transparent-editing-buyer',
  TransparentEditingSeller = 'transparent-editing-seller',
  TransparentPaymentsSeller = 'transparent-payments-seller',
  TransparentTransactionsBuyer = 'transparent-transactions-buyer',
  TransparentTransactionsSeller = 'transparent-transactions-seller',

  UK = 'uk',
  UKMobile = 'uk-mobile',
  USA = 'usa',
  USAMobile = 'usa-mobile',

  Zagreb = 'zagreb',
  ZagrebMobile = 'zagreb-mobile',
}

export const mapPublicPagesImagesUrls = new Map([
  [EnumPublicPagesImage.AboutOffice, 'about-office.png'],
  [EnumPublicPagesImage.AbaoutUsHero, 'about-us-hero.png'],
  [EnumPublicPagesImage.AccessibleSupportBuyer, 'accessible-support-buyer.svg'],
  [EnumPublicPagesImage.AccessibleSupportSeller, 'accessible-support-seller.svg'],
  [EnumPublicPagesImage.AccessAtAnyTimeBuyer, 'access-at-any-time-buyer.svg'],
  [EnumPublicPagesImage.AccessAtAnyTimeSeller, 'access-at-any-time-seller.svg'],
  [EnumPublicPagesImage.ArchiveHero, 'archive-hero.jpg'],
  [EnumPublicPagesImage.AutomaticNotificationsBuyer, 'automatic-notifications-buyer.svg'],

  [EnumPublicPagesImage.BuildYourProjectBig, 'build-your-projects-big.png'],
  [EnumPublicPagesImage.BuildYourProjectSmall, 'build-your-projects-small.png'],
  [EnumPublicPagesImage.BuildYourProjectTeam, 'build-your-project-team.png'],

  [EnumPublicPagesImage.CaseStudyArchiveHero, 'case-study-archive-hero.jpg'],
  [EnumPublicPagesImage.CaseStudyArchiveMacbook, 'case_study_archive_macbook.png'],
  [EnumPublicPagesImage.ClearCommunicationBuyer, 'clear-communication-buyer.svg'],
  [EnumPublicPagesImage.ClearCommunicationSeller, 'clear-communication-seller.svg'],
  [EnumPublicPagesImage.Communication, 'communication.png'],
  [EnumPublicPagesImage.CompleteMilestonesSeller, 'complete-milestones-seller.svg'],
  [EnumPublicPagesImage.ConfirmAccountBuyer, 'confirm-account-buyer.png'],
  [EnumPublicPagesImage.ConfirmAccountBuyerxl, 'confirm-account-buyerxl.png'],
  [EnumPublicPagesImage.ConfirmAccountSeller, 'confirm-account-seller.png'],
  [EnumPublicPagesImage.ConfirmAccountSellerxl, 'confirm-account-sellerxl.png'],
  [EnumPublicPagesImage.ConsolidateYourProjectsBuyer, 'consolidate-your-projects-buyer.svg'],
  [EnumPublicPagesImage.ConsolidateYourProjectsSeller, 'consolidate-your-projects-seller.svg'],
  [EnumPublicPagesImage.CreateProfile, 'create-profile.svg'],
  [EnumPublicPagesImage.CreateInRealTimeBuyer, 'create-in-real-time-buyer.svg'],
  [EnumPublicPagesImage.CreateInRealTimeSeller, 'create-in-real-time-seller.svg'],

  [EnumPublicPagesImage.DoItYourself, 'do-it-yourself.svg'],
  [EnumPublicPagesImage.DigitalContract, 'digital-contract.png'],
  [EnumPublicPagesImage.Dublin, 'dublin.jpg'],
  [EnumPublicPagesImage.DublinMobile, 'dublin-mobile.jpg'],

  [EnumPublicPagesImage.EasyResourcePlanningSeller, 'easy-resource-planning-seller.svg'],
  [EnumPublicPagesImage.EliminateLegalFeesBuyer, 'eliminate-legal-fees-buyer.svg'],
  [EnumPublicPagesImage.EliminateLegalFeesSeller, 'eliminate-legal-fees-seller.svg'],

  [EnumPublicPagesImage.GetFullControlBuyer, 'get-full-control-buyer.svg'],
  [EnumPublicPagesImage.GetInsightsBuyer, 'get-insights-buyer.svg'],
  [EnumPublicPagesImage.GetInsightsSeller, 'get-insights-seller.svg'],
  [EnumPublicPagesImage.GridForSellers, 'grid-for-sellers.png'],
  [EnumPublicPagesImage.GridProjectBenefits, 'grid-project-benefits.png'],

  [EnumPublicPagesImage.HireSeller, 'hire-a-seller.svg'],
  [EnumPublicPagesImage.HomeContracts, 'home-contracts.png'],
  [EnumPublicPagesImage.HomeFeatures, 'home-features.png'],
  [EnumPublicPagesImage.HomeE2E, 'home-e2e.png'],
  [EnumPublicPagesImage.HomeHealth, 'home-health.png'],
  [EnumPublicPagesImage.HomeMap, 'home-map.png'],
  [EnumPublicPagesImage.HomeMapSvg, 'home-map.svg'],
  [EnumPublicPagesImage.HomeMilestones, 'home-milestones.png'],
  [EnumPublicPagesImage.HomePayments, 'home-payment.png'],
  [EnumPublicPagesImage.HomeSimplify, 'home-simplify.png'],
  [EnumPublicPagesImage.HowToHireHero, 'how-to-hire-hero.png'],
  [EnumPublicPagesImage.HowToFindWorkHero, 'how-to-find-work-hero.png'],
  [EnumPublicPagesImage.Hungary, 'hungary.jpg'],
  [EnumPublicPagesImage.HungaryMobile, 'hungary-mobile.jpg'],

  [EnumPublicPagesImage.KeepInTouchBuyer, 'keep-in-touch-buyer.svg'],
  [EnumPublicPagesImage.KeepInTouchSeller, 'keep-in-touch-seller.svg'],

  [EnumPublicPagesImage.Laptop, 'laptop.png'],
  [EnumPublicPagesImage.Laptop2, 'laptop2.png'],
  [EnumPublicPagesImage.LetSponaHandleIt, 'let-spona-handle-it.svg'],
  [EnumPublicPagesImage.LetSponaHandleItHero, 'let-spona-handle-it-hero.png'],
  [EnumPublicPagesImage.LetSponaHandleItSmall, 'let-spona-handle-it-small.png'],
  [EnumPublicPagesImage.ListYourRequirements, 'list-your-requirements.svg'],

  [EnumPublicPagesImage.MacbookSellerArchive, 'macbook-seller-archive.png'],
  [EnumPublicPagesImage.MakeDataDrivenDecisionsBuyer, 'make-data-driven-decisions-buyer.svg'],
  [EnumPublicPagesImage.MakeDataDrivenDecisionsSeller, 'make-data-driven-decisions-seller.svg'],
  [EnumPublicPagesImage.ManageMilestonesAndTasksBuyer, 'manage-milestones-and-tasks-buyer.svg'],
  [EnumPublicPagesImage.ManageMilestonesAndTasksSeller, 'manage-milestones-and-tasks-seller.svg'],
  [EnumPublicPagesImage.MilestonesAndTasksHero, 'milestones-and-tasks-hero.png'],
  [EnumPublicPagesImage.MonitorYourKPIs, 'monitor-your-kpis.png'],

  [EnumPublicPagesImage.NewPasswordChangeSection, 'new-password-side-image.jpg'],

  [EnumPublicPagesImage.Office, 'office.png'],
  [EnumPublicPagesImage.Office2, 'office2.png'],
  [EnumPublicPagesImage.Office3, 'office3.png'],
  [EnumPublicPagesImage.Office4, 'office4.png'],
  [EnumPublicPagesImage.Office5, 'office5.png'],
  [EnumPublicPagesImage.OnePlatformBuyer, 'one-platform-buyer.svg'],
  [EnumPublicPagesImage.OnePlatformSeller, 'one-platform-seller.svg'],
  [EnumPublicPagesImage.OurJourneyCarousel, 'our-journey-carousel.png'],
  [EnumPublicPagesImage.OurJourneyCarousel2, 'our-journey-carousel2.png'],
  [EnumPublicPagesImage.OurJourneyCarousel3, 'our-journey-carousel3.png'],
  [EnumPublicPagesImage.OurJourneyCarousel4, 'our-journey-carousel4.png'],
  [EnumPublicPagesImage.OurJourneyCarousel5, 'our-journey-carousel5.png'],
  [EnumPublicPagesImage.OurJourneyCarousel6, 'our-journey-carousel6.png'],
  [EnumPublicPagesImage.OurJourneyCarousel7, 'our-journey-carousel7.png'],
  [EnumPublicPagesImage.OurJourneyCarousel8, 'our-journey-carousel8.png'],
  [EnumPublicPagesImage.OurJourneyCarouselMobile, 'our-journey-carousel-mobile.png'],
  [EnumPublicPagesImage.OurJourneyCarouselMobile2, 'our-journey-carousel-mobile2.png'],
  [EnumPublicPagesImage.OurJourneyCarouselMobile3, 'our-journey-carousel-mobile3.png'],
  [EnumPublicPagesImage.OurJourneyCarouselMobile4, 'our-journey-carousel-mobile4.png'],
  [EnumPublicPagesImage.OurJourneyCarouselMobile5, 'our-journey-carousel-mobile5.png'],
  [EnumPublicPagesImage.OurJourneyCarouselMobile6, 'our-journey-carousel-mobile6.png'],
  [EnumPublicPagesImage.OurJourneyCarouselMobile7, 'our-journey-carousel-mobile7.png'],
  [EnumPublicPagesImage.OurJourneyCarouselMobile8, 'our-journey-carousel-mobile8.png'],

  [EnumPublicPagesImage.PaymentSuccessful, 'payment-successful.png'],
  [EnumPublicPagesImage.People1, 'people1.png'],
  [EnumPublicPagesImage.People2, 'people2.png'],
  [EnumPublicPagesImage.People3, 'people3.png'],
  [EnumPublicPagesImage.ProgressYourProjectBuyer, 'progress-your-project-buyer.svg'],
  [EnumPublicPagesImage.ProjectReportsBuyer, 'project-reports-buyer.svg'],
  [EnumPublicPagesImage.ProjectReportsSeller, 'project-reports-seller.svg'],
  [EnumPublicPagesImage.PublishCaseStudy, 'publish-case-study.svg'],

  [EnumPublicPagesImage.QuickUpdatesBuyer, 'quick-updates-buyer.svg'],
  [EnumPublicPagesImage.QuickUpdatesSeller, 'quick-updates-seller.svg'],

  [EnumPublicPagesImage.RecordYourActivitiesBuyer, 'record-your-activities-buyer.svg'],
  [EnumPublicPagesImage.RecordYourActivitiesSeller, 'record-your-activities-seller.svg'],
  [EnumPublicPagesImage.ReduceDataMicromanagementBuyer, 'reduce-data-micromanagement-buyer.svg'],
  [EnumPublicPagesImage.ReduceDataMicromanagementSeller, 'reduce-data-micromanagement-seller.svg'],
  [EnumPublicPagesImage.ResolveIssuesBuyer, 'resolve-issues-buyer.svg'],
  [EnumPublicPagesImage.ResolveIssuesSeller, 'resolve-issues-seller.svg'],
  [EnumPublicPagesImage.ReviewPitches, 'review-pitches.svg'],
  [EnumPublicPagesImage.ReviewYourCandidates, 'review-your-candidates.svg'],

  [EnumPublicPagesImage.SaveYourTimeAndMoney, 'save-your-time-and-money.svg'],
  [EnumPublicPagesImage.SecureProcessingBuyer, 'secure-processing-buyer.svg'],
  [EnumPublicPagesImage.SecureProcessingSeller, 'secure-processing-seller.svg'],
  [EnumPublicPagesImage.ShareYourProgressSeller, 'share-your-progress-seller.svg'],
  [EnumPublicPagesImage.SignContractsBuyer, 'sign-contracts-buyer.svg'],
  [EnumPublicPagesImage.SignContractsSeller, 'sign-contracts-seller.svg'],
  [EnumPublicPagesImage.SignDigitallyBuyer, 'sign-digitally-buyer.svg'],
  [EnumPublicPagesImage.SignDigitallySeller, 'sign-digitally-seller.svg'],
  [EnumPublicPagesImage.SmartBudgetAllocationBuyer, 'smart-budget-allocation-buyer.svg'],
  [EnumPublicPagesImage.SmDigitalTurbine, 'sm-digital-turbine.svg'],
  [EnumPublicPagesImage.SmGoogleAds, 'sm-google-ads.svg'],
  [EnumPublicPagesImage.SmMeta, 'sm-meta.svg'],
  [EnumPublicPagesImage.SmReddit, 'sm-reddit.svg'],
  [EnumPublicPagesImage.SmSnapchat, 'sm-snapchat.svg'],
  [EnumPublicPagesImage.SmSpotify, 'sm-spotify.svg'],
  [EnumPublicPagesImage.SmTiktok, 'sm-tiktok.svg'],
  [EnumPublicPagesImage.SmX, 'sm-x.svg'],
  [EnumPublicPagesImage.SponaDashboard, 'login-carousel-img1.png'],
  [EnumPublicPagesImage.SponaGives, 'spona-gives.png'],
  [EnumPublicPagesImage.SponaInNumbers, 'spona-in-numbers.svg'],
  [EnumPublicPagesImage.SponaMakes, 'spona-makes.png'],
  [EnumPublicPagesImage.SponaMakesTimedProjects, 'spona-makes-timed-projects.png'],
  [EnumPublicPagesImage.SponaMakesSubpage, 'spona-makes-subpage.png'],
  [EnumPublicPagesImage.SponaMediaHero, 'spona-media-hero.png'],
  [EnumPublicPagesImage.SponaMediaHdiwMeta, 'spona-media-hdiw-meta.png'],
  [EnumPublicPagesImage.SponaMediaHeroAds, 'spona-media-hero-ads.png'],
  [EnumPublicPagesImage.StandardizeYourProcessesBuyer, 'standardize-your-processes-buyer.svg'],
  [EnumPublicPagesImage.StandardizeYourProcessesSeller, 'standardize-your-processes-seller.svg'],
  [EnumPublicPagesImage.StayInSyncSeller, 'stay-in-sync-seller.svg'],
  [EnumPublicPagesImage.SubmitYourPitch, 'submit-your-pitch.svg'],
  [EnumPublicPagesImage.SubmitYourProject, 'submit-your-project.svg'],

  [EnumPublicPagesImage.TimedProjectSubageHero, 'timed-project-subpage-hero.png'],
  [EnumPublicPagesImage.TrackAndProcessPaymentsBuyer, 'track-and-process-payments-buyer.svg'],
  [EnumPublicPagesImage.TrackAndProcessPaymentsSeller, 'track-and-process-payments-seller.svg'],

  [EnumPublicPagesImage.TrackProjectHealthBuyer, 'track-project-health-buyer.svg'],
  [EnumPublicPagesImage.TrackProjectHealthSeller, 'track-project-health-seller.svg'],
  [EnumPublicPagesImage.TrackYourMetricsBuyer, 'track-your-metrics-buyer.svg'],
  [EnumPublicPagesImage.TransparentEditingBuyer, 'transparent-editing-buyer.svg'],
  [EnumPublicPagesImage.TransparentEditingSeller, 'transparent-editing-seller.svg'],
  [EnumPublicPagesImage.TransparentPaymentsSeller, 'transparent-payments-seller.svg'],
  [EnumPublicPagesImage.TransparentTransactionsBuyer, 'transparent-transactions-buyer.svg'],
  [EnumPublicPagesImage.TransparentTransactionsSeller, 'transparent-transactions-seller.svg'],

  [EnumPublicPagesImage.UK, 'uk.jpg'],
  [EnumPublicPagesImage.UKMobile, 'uk-mobile.jpg'],
  [EnumPublicPagesImage.USA, 'usa.jpg'],
  [EnumPublicPagesImage.USAMobile, 'usa-mobile.jpg'],

  [EnumPublicPagesImage.Zagreb, 'zagreb.jpg'],
  [EnumPublicPagesImage.ZagrebMobile, 'zagreb-mobile.jpg'],
]);

import {
  EnumCountry,
  EnumProjectHourlyRate,
  EnumProjectSize,
  EnumService,
  EnumCurrency as Currency,
  EnumProjectType,
  EnumCurrency,
  EnumProjectMilestoneStatus,
  EnumProjectStatus,
  PaymentStatus,
  EnumProjectContractStatus,
  EnumTimedProjectStatus,
  EnumTimedProjectPitchStatus,
  EnumTechPlatform,
  EnumTechTool,
  EnumTechLanguage,
  EnumServiceProviderScope,
  EnumCaseStudyStatus,
  EnumEventType,
  mapCompanyApprovalStatus,
  mapBlogStatus,
  mapProjectRequestStatus,
  CompanyApprovalStatus,
  EnumBlogStatus,
  EnumProjectRequestStatus,
  EnumUserInvitationStatus,
  mapUserInvitationStatus,
} from 'tdc-web-backend/enums/enums';

export const mapUserClaim = new Map([
  ['foo', 'Foo'],
  ['bar', 'Bar'],
  ['baz', 'Baz'],
]);

export const mapUserGroup = new Map([
  ['foo', 'Foo'],
  ['bar', 'Bar'],
  ['baz', 'Baz'],
  ['foobar', 'FooBar'],
  ['foobaz', 'FooBaz'],
  ['barbaz', 'BarBaz'],
  ['foobarbaz', 'FooBarBaz'],
]);

export const mapCompanyClaim = new Map([
  ['foo', 'Foo'],
  ['bar', 'Bar'],
  ['baz', 'Baz'],
]);

export const mapCompanyGroup = new Map([
  ['foo', 'Foo'],
  ['bar', 'Bar'],
  ['baz', 'Baz'],
  ['foobar', 'FooBar'],
  ['foobaz', 'FooBaz'],
  ['barbaz', 'BarBaz'],
  ['foobarbaz', 'FooBarBaz'],
]);

export const mapPositionClaim = new Map([
  ['foo', 'Foo'],
  ['bar', 'Bar'],
  ['baz', 'Baz'],
]);

export const mapPositionGroup = new Map([
  ['foo', 'Foo'],
  ['bar', 'Bar'],
  ['baz', 'Baz'],
  ['foobar', 'FooBar'],
  ['foobaz', 'FooBaz'],
  ['barbaz', 'BarBaz'],
  ['foobarbaz', 'FooBarBaz'],
]);

export const mapTimeDifference = new Map([
  ['Two weeks', 'Two weeks'],
  ['One month', 'One month'],
  ['I am flexible', 'I am flexible'],
]);

export const mapPaymentOccurrence = new Map([
  ['Recurring', 'Recurring'],
  ['One time project', 'One time project'],
]);

export const mapArticleType = new Map([
  ['Analysis', 'Analysis'],
  ['Blog', 'Blog'],
  ['Ebook', 'E-book'],
  ['Editorial', 'Editorial'],
  ['Guide', 'Guide'],
  ['Infographic', 'Infographic'],
  ['Interview', 'Interview'],
  ['News', 'News'],
  ['Presentation', 'Presentation'],
  ['Press_Release', 'Press Release'],
  ['Research', 'Research'],
  ['Showcase', 'Showcase'],
  ['Tutorial', 'Tutorial'],
]);

export const mapLocationType = new Map([
  ['Headquarters', 'Headquarters'],
  ['Regional_Headquarters', 'Regional Headquarters'],
  ['Branch_Office', 'Branch Office'],
  ['Subsidiary', 'Subsidiary'],
]);

export const mapProjectType = new Map([
  [EnumProjectType.OneTime, 'One time'],
  [EnumProjectType.Retainer, 'Retainer'],
]);

export const mapPublicationStatus = new Map([
  ['Draft', 'Draft'],
  ['Awaiting_approval', 'Awaiting approval'],
  ['Rejected', 'Rejected'],
  ['Private', 'Private'],
  ['Public', 'Public'],
]);

export const mapTechLanguage = new Map([
  [EnumTechLanguage.Apl, 'APL'],
  [EnumTechLanguage.Assembly, 'Assembly'],
  [EnumTechLanguage.C, 'C'],
  [EnumTechLanguage.Csharp, 'C#'],
  [EnumTechLanguage.Cpp, 'C++'],
  [EnumTechLanguage.Clojure, 'Clojure'],
  [EnumTechLanguage.Cobol, 'COBOL'],
  [EnumTechLanguage.Crystal, 'Crystal'],
  [EnumTechLanguage.Dart, 'Dart'],
  [EnumTechLanguage.Delphi, 'Delphi'],
  [EnumTechLanguage.Elixir, 'Elixir'],
  [EnumTechLanguage.Erlang, 'Erlang'],
  [EnumTechLanguage.Fsharp, 'F#'],
  [EnumTechLanguage.Go, 'Go'],
  [EnumTechLanguage.Groovy, 'Groovy'],
  [EnumTechLanguage.Haskell, 'Haskell'],
  [EnumTechLanguage.HtmlAndCss, 'HTML & CSS'],
  [EnumTechLanguage.Java, 'Java'],
  [EnumTechLanguage.JavaScript, 'JavaScript'],
  [EnumTechLanguage.Julia, 'Julia'],
  [EnumTechLanguage.Kotlin, 'Kotlin'],
  [EnumTechLanguage.Lisp, 'Lisp'],
  [EnumTechLanguage.Matlab, 'MATLAB'],
  [EnumTechLanguage.ObjectiveC, 'Objective-C'],
  [EnumTechLanguage.Perl, 'Perl'],
  [EnumTechLanguage.Php, 'PHP'],
  [EnumTechLanguage.PowerShell, 'PowerShell'],
  [EnumTechLanguage.Python, 'Python'],
  [EnumTechLanguage.R, 'R'],
  [EnumTechLanguage.Ruby, 'Ruby'],
  [EnumTechLanguage.Rust, 'Rust'],
  [EnumTechLanguage.Scala, 'Scala'],
  [EnumTechLanguage.Sql, 'SQL'],
  [EnumTechLanguage.Swift, 'Swift'],
  [EnumTechLanguage.TypeScript, 'TypeScript'],
  [EnumTechLanguage.UnixShell, 'Unix shell'],
  [EnumTechLanguage.VisualBasic, 'Visual Basic'],
]);

export const mapTechPlatform = new Map([
  [EnumTechPlatform.AmazonWebServices, 'Amazon Web Services'],
  [EnumTechPlatform.Android, 'Android'],
  [EnumTechPlatform.Azure, 'Azure'],
  [EnumTechPlatform.DigitalOcean, 'DigitalOcean'],
  [EnumTechPlatform.Docker, 'Docker'],
  [EnumTechPlatform.GoogleCloud, 'Google Cloud'],
  [EnumTechPlatform.Heroku, 'Heroku'],
  [EnumTechPlatform.IbmCloud, 'IBM Cloud'],
  [EnumTechPlatform.IOs, 'iOS'],
  [EnumTechPlatform.Kubernetes, 'Kubernetes'],
  [EnumTechPlatform.Linux, 'Linux'],
  [EnumTechPlatform.Lxc, 'LXC'],
  [EnumTechPlatform.MacOs, 'MacOS'],
  [EnumTechPlatform.OracleCloud, 'Oracle Cloud'],
  [EnumTechPlatform.SalesforcePlatform, 'Salesforce Platform'],
  [EnumTechPlatform.Windows, 'Windows'],
]);

export const mapTechTool = new Map([
  [EnumTechTool.DotNet, '.NET'],
  [EnumTechTool.Angular, 'Angular'],
  [EnumTechTool.AngularJs, 'AngularJS'],
  [EnumTechTool.ApacheHBase, 'Apache HBase'],
  [EnumTechTool.ApacheSpark, 'Apache Spark'],
  [EnumTechTool.AsPdotNet, 'ASP.NET'],
  [EnumTechTool.BackboneJs, 'Backbone.js'],
  [EnumTechTool.Bootstrap, 'Bootstrap'],
  [EnumTechTool.CakePhp, 'CakePHP'],
  [EnumTechTool.Cassandra, 'Cassandra'],
  [EnumTechTool.Cordova, 'Cordova'],
  [EnumTechTool.Django, 'Django'],
  [EnumTechTool.Drupal, 'Drupal'],
  [EnumTechTool.Elasticsearch, 'Elasticsearch'],
  [EnumTechTool.Electron, 'Electron'],
  [EnumTechTool.EmberJs, 'Ember.js'],
  [EnumTechTool.Express, 'Express'],
  [EnumTechTool.FastApi, 'FastAPI'],
  [EnumTechTool.Firebase, 'Firebase'],
  [EnumTechTool.Flask, 'Flask'],
  [EnumTechTool.Flutter, 'Flutter'],
  [EnumTechTool.Gatsby, 'Gatsby'],
  [EnumTechTool.Hadoop, 'Hadoop'],
  [EnumTechTool.IbmDb2, 'IBM Db2'],
  [EnumTechTool.Joomla, 'Joomla'],
  [EnumTechTool.JQuery, 'jQuery'],
  [EnumTechTool.Laravel, 'Laravel'],
  [EnumTechTool.Meteor, 'Meteor'],
  [EnumTechTool.MicrosoftSqlServer, 'Microsoft SQL Server'],
  [EnumTechTool.MongoDb, 'MongoDB'],
  [EnumTechTool.MySqlOrMariaDb, 'MySQL / MariaDB'],
  [EnumTechTool.NextJs, 'Next.js'],
  [EnumTechTool.NodeJs, 'Node.js'],
  [EnumTechTool.NumPy, 'NumPy'],
  [EnumTechTool.OracleDatabase, 'Oracle Database'],
  [EnumTechTool.Pandas, 'Pandas'],
  [EnumTechTool.Play, 'Play'],
  [EnumTechTool.PostgreSql, 'PostgreSQL'],
  [EnumTechTool.PyTorch, 'PyTorch'],
  [EnumTechTool.Qt, 'Qt'],
  [EnumTechTool.Quasar, 'Quasar'],
  [EnumTechTool.React, 'React'],
  [EnumTechTool.ReactNative, 'React Native'],
  [EnumTechTool.Redis, 'Redis'],
  [EnumTechTool.RubyOnRails, 'Ruby on Rails'],
  [EnumTechTool.SailsJs, 'Sails.js'],
  [EnumTechTool.Spring, 'Spring'],
  [EnumTechTool.SqLite, 'SQLite'],
  [EnumTechTool.Struts, 'Struts'],
  [EnumTechTool.Svelte, 'Svelte'],
  [EnumTechTool.Symfony, 'Symfony'],
  [EnumTechTool.TensorFlow, 'TensorFlow'],
  [EnumTechTool.Unity, 'Unity'],
  [EnumTechTool.UnrealEngine, 'Unreal_Engine'],
  [EnumTechTool.VueJs, 'Vue.js'],
  [EnumTechTool.WordPress, 'WordPress'],
]);

export const mapVerificationStatus = new Map([
  ['Unverified', 'Unverified'],
  ['Verified', 'Verified'],
  ['TDA_Verified', 'TDA Verified'],
]);

export const mapApplicationStatus = new Map([
  ['Open', 'Open'],
  ['Closed', 'Closed'],
]);

export const mapProjectOutlineStatus = new Map([
  ['Pitching', 'Pitching'],
  ['Negotiation', 'Negotiation'],
  ['Finished', 'Finished'],
  ['Cancelled', 'Cancelled'],
]);

export const mapPromotableEntities = new Map([
  ['Article', 'Article'],
  ['Case_Study', 'Case Study'],
  ['Tool', 'Tool'],
  ['Predefined_Project', 'Predefined Project'],
  ['Timed_Project', 'Timed Project'],
]);

export const mapPitchStatus = new Map([
  ['Project', 'Project'],
  ['Round_1', 'Round 1'],
  ['Round_2', 'Round 2'],
]);

export const mapClaimCompany = new Map([
  ['Approved_Private', 'Approved Private'],
  ['Approved_Public', 'Approved Public'],
  ['Buyer', 'Buyer'],
  ['Seller_Product', 'Seller Product'],
  ['Seller_Service', 'Seller Service'],
]);

export const mapProjectStatus = new Map([
  [EnumProjectStatus.InProgress, 'In progress'],
  [EnumProjectStatus.Completed, 'Completed'],
  [EnumProjectStatus.Pending, 'Pending'],
  [EnumProjectStatus.Canceled, 'Canceled'],
]);

export const mapProductType = new Map([
  ['Foo', 'Foo'],
  ['Bar', 'Bar'],
  ['Baz', 'Baz'],
]);

export const mapSubtaskStatus = new Map([
  ['inProgress', 'In progress'],
  ['complete', 'Complete'],
  ['pending', 'Pending'],
  ['Pending', 'Pending'],
  ['Completed', 'Completed'],
  ['Canceled', 'Canceled'],
]);

export const mapWorkStatus = new Map([
  ['Finished', 'Finished'],
  ['In_progress', 'In progress'],
  ['Paused', 'Paused'],
  ['Pending', 'Pending'],
]);

/*
  TODO: This is just a temp placeholder, refactor this
  afterwards!
*/
export const mapTempStatusEnum = new Map([
  ['Draft', 'Draft'],
  ['Active', 'Active'],
  ['Paid', 'Paid'],
  ['Finished', 'Finished'],
  ['In_progress', 'In progress'],
  ['Paused', 'Paused'],
  ['Cancelled', 'Cancelled'],
  ['Pending', 'Pending'],
  ['Due', 'Due'],
]);

export const mapTime = new Map([
  ['0:30', '0:30'],
  ['1:00', '1:00'],
  ['1:30', '1:30'],
  ['2:00', '2:00'],
  ['2:30', '2:30'],
  ['3:00', '3:00'],
  ['3:30', '3:30'],
  ['4:00', '4:00'],
  ['4:30', '4:30'],
  ['5:00', '5:00'],
  ['5:30', '5:30'],
  ['6:00', '6:00'],
  ['6:30', '6:30'],
  ['7:00', '7:00'],
  ['7:30', '7:30'],
  ['8:00', '8:00'],
  ['8:30', '8:30'],
  ['9:00', '9:00'],
  ['9:30', '9:30'],
  ['10:00', '10:00'],
  ['10:30', '10:30'],
  ['11:00', '11:00'],
  ['11:30', '11:30'],
  ['12:00', '12:00'],
  ['12:30', '12:30'],
  ['13:00', '13:00'],
  ['13:30', '13:30'],
  ['14:00', '14:00'],
  ['14:30', '14:30'],
  ['15:00', '15:00'],
  ['15:30', '15:30'],
  ['16:00', '16:00'],
  ['16:30', '16:30'],
  ['17:00', '17:00'],
  ['17:30', '17:30'],
  ['18:00', '18:00'],
  ['18:30', '18:30'],
  ['19:00', '19:00'],
  ['19:30', '19:30'],
  ['20:00', '20:00'],
  ['20:30', '20:30'],
  ['21:00', '21:00'],
  ['21:30', '21:30'],
  ['22:00', '22:00'],
  ['22:30', '22:30'],
  ['23:00', '23:00'],
  ['23:30', '23:30'],
  ['00:00', '00:00'],
]);

export const mapProjectPaymentStatus = new Map([
  [PaymentStatus.InProgress, 'In progress'],
  [PaymentStatus.Late, 'Late'],
  [PaymentStatus.Paid, 'Paid'],
]);
export const mapProjectContractStatus = new Map([
  [EnumProjectContractStatus.Canceled, 'Canceled'],
  [EnumProjectContractStatus.Accepted, 'Accepted'],
  [EnumProjectContractStatus.Draft, 'Draft'],
  [EnumProjectContractStatus.Active, 'Active'],
  [EnumProjectContractStatus.Negotiation, 'Negotiation'],
]);

export const mapProjectMilestoneStatus = new Map([
  [EnumProjectMilestoneStatus.Pending, 'Pending'],
  [EnumProjectMilestoneStatus.InProgress, 'In progress'],
  [EnumProjectMilestoneStatus.Completed, 'Completed'],
  [EnumProjectMilestoneStatus.Approved, 'Approved'],
  [EnumProjectMilestoneStatus.Negotiation, 'Negotiation'],
  [EnumProjectMilestoneStatus.UnderReview, 'Under review'],
]);

export const mapTimedProjectStatus = new Map([
  [EnumTimedProjectStatus.Pending, 'Pending'],
  [EnumTimedProjectStatus.Draft, 'Draft'],
  [EnumTimedProjectStatus.Active, 'Active'],
  [EnumTimedProjectStatus.Closed, 'Closed'],
]);
export const mapCaseStudyStatus = new Map([
  [EnumCaseStudyStatus.Pending, 'Pending'],
  [EnumCaseStudyStatus.Draft, 'Draft'],
  [EnumCaseStudyStatus.Archived, 'Archived'],
  [EnumCaseStudyStatus.Published, 'Published'],
  [EnumCaseStudyStatus.Verified, 'Verified'],
]);

export const mapDuration = new Map([
  ['0:30', '0:30'],
  ['1:00', '1:00'],
  ['1:30', '1:30'],
  ['2:00', '2:00'],
  ['2:30', '2:30'],
  ['3:00', '3:00'],
  ['3:30', '3:30'],
  ['4:00', '4:00'],
  ['4:30', '4:30'],
  ['5:00', '5:00'],
  ['5:30', '5:30'],
  ['6:00', '6:00'],
  ['6:30', '6:30'],
  ['7:00', '7:00'],
  ['7:30', '7:30'],
  ['8:00', '8:00'],
  ['8:30', '8:30'],
  ['9:00', '9:00'],
  ['9:30', '9:30'],
  ['10:00', '10:00'],
  ['10:30', '10:30'],
  ['11:00', '11:00'],
  ['11:30', '11:30'],
  ['12:00', '12:00'],
  ['12:30', '12:30'],
  ['13:00', '13:00'],
  ['13:30', '13:30'],
  ['14:00', '14:00'],
  ['14:30', '14:30'],
  ['15:00', '15:00'],
  ['15:30', '15:30'],
  ['16:00', '16:00'],
  ['16:30', '16:30'],
  ['17:00', '17:00'],
  ['17:30', '17:30'],
  ['18:00', '18:00'],
  ['18:30', '18:30'],
  ['19:00', '19:00'],
  ['19:30', '19:30'],
  ['20:00', '20:00'],
  ['20:30', '20:30'],
  ['21:00', '21:00'],
  ['21:30', '21:30'],
  ['22:00', '22:00'],
  ['22:30', '22:30'],
  ['23:00', '23:00'],
  ['23:30', '23:30'],
  ['24:00', '24:00'],
]);

export const mapRecurringInterval = new Map([
  [2, '2'],
  [3, '3'],
  [4, '4'],
  [5, '5'],
  [6, '6'],
  [7, '7'],
  [8, '8'],
  [9, '9'],
  [10, '10'],
  [11, '11'],
  [12, '12'],
]);

export enum CardTypeEnum {
  ToolCard = 'Tool_Card',
  ServicePackageCard = 'Service_Package_Card',
  PriceCard = 'Price_Card',
  CompanyCard = 'Company_Card',
  CaseStudyCard = 'Case_Study_Card',
  TimedProjectCard = 'Timed_Project_Card',
  ArticleCard = 'Article_Card',
}

export enum CarouselType {
  CASE_STUDY = 'Case_study',
  SERVICE_PACKAGE = 'Service_package',
}

export enum EnumDeleteModalType {
  Task = 'task',
  Subtask = 'subtask',
  Milestone = 'milestone,',
}
export enum EnumPaymentType {
  Paid = 'Paid',
  In_progress = 'In progress',
  Late = 'Late',
}

export enum EnumModalActionType {
  Delete = 'Delete',
  Cancel = 'Cancel',
  Confirm = 'Confirm',
}

export const mapTaskStatus = new Map([
  ['pending', 'Pending'],
  ['in_progress', 'In progress'],
  ['completed', 'Completed'],
  ['canceled', 'Canceled'],
]);

export const mapMilestoneStatus = new Map([
  ['Pending', 'Pending'],
  ['In_progress', 'In progress'],
  ['Completed', 'Completed'],
  ['Canceled', 'Canceled'],
  ['Unapproved', 'Unapproved'],
]);

export const mapTimedProjectPitchStatus = new Map([
  [EnumTimedProjectPitchStatus.Draft, 'Draft'],
  [EnumTimedProjectPitchStatus.Pending, 'Pending'],
  [EnumTimedProjectPitchStatus.FirstRound, 'Round 1'],
  [EnumTimedProjectPitchStatus.SecondRound, 'Round 2'],
  [EnumTimedProjectPitchStatus.FirstRoundRejected, 'Rejected'],
  [EnumTimedProjectPitchStatus.SecondRoundRejected, 'Rejected'],
  [EnumTimedProjectPitchStatus.FirstRoundWithdrawn, 'Withdrawn'],
  [EnumTimedProjectPitchStatus.SecondRoundWithdrawn, 'Withdrawn'],
  [EnumTimedProjectPitchStatus.Chosen, 'Chosen'],
  [EnumTimedProjectPitchStatus.Hired, 'Hired'],
  [EnumTimedProjectPitchStatus.Archived, 'Archived'],
]);

export const currencyMapper = (currencyName: EnumCurrency) => {
  switch (currencyName) {
    case EnumCurrency.Usd:
      return '$';
    case EnumCurrency.Hrk:
      return 'kn';
    case EnumCurrency.Btc:
      return 'BTC';
    case EnumCurrency.Eth:
      return 'ETH';
    case EnumCurrency.Inr:
      return 'inr';
    default:
      return null;
  }
};

export const mapProviderScope = new Map([
  [EnumServiceProviderScope.Global, 'Global'],
  [EnumServiceProviderScope.Local, 'Local'],
  [EnumServiceProviderScope.Undefined, "Don't mind"],
]);

enum States {
  'AL' = 'AL',
  'AK' = 'AK',
  'AS' = 'AS',
  'AZ' = 'AZ',
  'AR' = 'AR',
  'CA' = 'CA',
  'CO' = 'CO',
  'CT' = 'CT',
  'DE' = 'DE',
  'DC' = 'DC',
  'FM' = 'FM',
  'FL' = 'FL',
  'GA' = 'GA',
  'GU' = 'GU',
  'HI' = 'HI',
  'ID' = 'ID',
  'IL' = 'IL',
  'IN' = 'IN',
  'IA' = 'IA',
  'KS' = 'KS',
  'KY' = 'KY',
  'LA' = 'LA',
  'ME' = 'ME',
  'MH' = 'MH',
  'MD' = 'MD',
  'MA' = 'MA',
  'MI' = 'MI',
  'MN' = 'MN',
  'MS' = 'MS',
  'MO' = 'MO',
  'MT' = 'MT',
  'NE' = 'NE',
  'NV' = 'NV',
  'NH' = 'NH',
  'NJ' = 'NJ',
  'NM' = 'NM',
  'NY' = 'NY',
  'NC' = 'NC',
  'ND' = 'ND',
  'MP' = 'MP',
  'OH' = 'OH',
  'OK' = 'OK',
  'OR' = 'OR',
  'PW' = 'PW',
  'PA' = 'PA',
  'PR' = 'PR',
  'RI' = 'RI',
  'SC' = 'SC',
  'SD' = 'SD',
  'TN' = 'TN',
  'TX' = 'TX',
  'UT' = 'UT',
  'VT' = 'VT',
  'VI' = 'VI',
  'VA' = 'VA',
  'WA' = 'WA',
  'WV' = 'WV',
  'WI' = 'WI',
  'WY' = 'WY',
}

export const mapStates = new Map([
  [States.AK, 'Alaska'],
  [States.AL, 'Alabama'],
  [States.AR, 'Arkansas'],
  [States.AS, 'American Samoa'],
  [States.AZ, 'Arizona'],
  [States.CA, 'California'],
  [States.CO, 'Colorado'],
  [States.CT, 'Connecticut'],
  [States.DC, 'District Of Columbia'],
  [States.DE, 'Delaware'],
  [States.FL, 'Florida'],
  [States.GA, 'Georgia'],
  [States.GU, 'Guam'],
  [States.HI, 'Hawaii'],
  [States.IA, 'Iowa'],
  [States.ID, 'Idaho'],
  [States.IL, 'Illinois'],
  [States.IN, 'Indiana'],
  [States.KS, 'Kansas'],
  [States.KY, 'Kentucky'],
  [States.LA, 'Louisiana'],
  [States.MA, 'Massachusetts'],
  [States.MD, 'Maryland'],
  [States.ME, 'Maine'],
  [States.MI, 'Michigan'],
  [States.MN, 'Minnesota'],
  [States.MO, 'Missouri'],
  [States.MP, 'Northern Mariana Islands'],
  [States.MS, 'Mississippi'],
  [States.MT, 'Montana'],
  [States.NC, 'North Carolina'],
  [States.ND, 'North Dakota'],
  [States.NE, 'Nebraska'],
  [States.NH, 'New Hampshire'],
  [States.NJ, 'New Jersey'],
  [States.NM, 'New Mexico'],
  [States.NV, 'Nevada'],
  [States.NY, 'New York'],
  [States.OH, 'Ohio'],
  [States.OK, 'Oklahoma'],
  [States.OR, 'Oregon'],
  [States.PA, 'Pennsylvania'],
  [States.PR, 'Puerto Rico'],
  [States.RI, 'Rhode Island'],
  [States.SC, 'South Carolina'],
  [States.SD, 'South Dakota'],
  [States.TN, 'Tennessee'],
  [States.TX, 'Texas'],
  [States.UT, 'Utah'],
  [States.VA, 'Virginia'],
  [States.VI, 'Virgin Islands'],
  [States.VT, 'Vermont'],
  [States.WA, 'Washington'],
  [States.WI, 'Wisconsin'],
  [States.WV, 'West Virginia'],
  [States.WY, 'Wyoming'],
]);

export const mapEventTypes = new Map([
  [EnumEventType.Event, 'Meeting'],
  [EnumEventType.Milestone, 'Milestone due'],
  [EnumEventType.Payment, 'Payment due'],
  [EnumEventType.PitchDeadline, 'Pitching closes '],
  [EnumEventType.Subtask, 'Subtask due'],
  [EnumEventType.Task, 'Task due'],
]);

export const concatinatedAllStatusesMap = new Map<unknown, string>([
  ...Array.from(mapProjectMilestoneStatus.entries()),
  ...Array.from(mapProjectContractStatus.entries()),
  ...Array.from(mapProjectStatus.entries()),
  ...Array.from(mapProjectPaymentStatus.entries()),
  ...Array.from(mapTempStatusEnum.entries()),
  ...Array.from(mapTimedProjectStatus.entries()),
  ...Array.from(mapTimedProjectPitchStatus.entries()),
  ...Array.from(mapCaseStudyStatus.entries()),
  ...Array.from(mapCompanyApprovalStatus.entries()),
  ...Array.from(mapBlogStatus.entries()),
  ...Array.from(mapProjectRequestStatus.entries()),
  ...Array.from(mapUserInvitationStatus.entries()),
]);

export const EnumAllStatuses = {
  ...EnumProjectContractStatus,
  ...EnumProjectMilestoneStatus,
  ...EnumProjectStatus,
  ...PaymentStatus,
  ...EnumTimedProjectStatus,
  ...EnumTimedProjectPitchStatus,
  ...EnumCaseStudyStatus,
  ...CompanyApprovalStatus,
  ...EnumBlogStatus,
  ...EnumProjectRequestStatus,
  ...EnumUserInvitationStatus,
};
