import { Checkbox, Divider, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/button/Button';
import CustomModal from '../../../../components/modal/CustomModal';
import useAuth from '../../../../utils/hooks/useAuth';

interface ModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const TermsAndConditionsModal = ({ isOpen, setIsOpen }: ModalProps) => {
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();
  const {
    authData: { userData, accessToken: token },
    acceptTerms,
    isLoadingAcceptTerms,
  } = useAuth();

  useEffect(() => {
    if (userData !== null && userData.agreementDate === null) return setIsOpen(true);
    return setIsOpen(false);
  }, [userData]);

  const handleConfirm = () => {
    if (userData && token) {
      acceptTerms({
        onSuccess: () => {
          setIsOpen(false);
          navigate('/dashboard/end-to-end', { replace: true });
        },
      });
    } else {
      setIsOpen(false);
    }
  };

  return (
    <CustomModal open={isOpen}>
      <Stack spacing={2}>
        <Stack>
          <Typography
            sx={{
              fontSize: '1.25em',
              fontWeight: 500,
            }}
          >
            Important!
          </Typography>
        </Stack>
        <Stack direction="row">
          <Checkbox color="secondary" onChange={() => setDisabled(!disabled)} />
          <Typography
            sx={{
              mt: 3,
            }}
          >
            To start using Spona, you must first agree to our&nbsp;
            <a
              style={{ color: '#32009A', textDecoration: 'none' }}
              href="/terms-of-service"
              target="_blank"
              rel="noreferrer"
            >
              Terms of service
            </a>{' '}
            and{' '}
            <a
              href="/terms-of-service/privacy-policy"
              style={{ color: '#32009A', textDecoration: 'none' }}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{' '}
            by checking the box.
          </Typography>
        </Stack>
        <Divider />
        <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
          <Button
            variant="contained"
            color="secondary"
            disabled={isLoadingAcceptTerms || disabled}
            onButtonClick={() => handleConfirm()}
            sx={{
              borderRadius: '100px',
              fontWeight: 500,
            }}
          >
            Confirm
          </Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};
export default TermsAndConditionsModal;
