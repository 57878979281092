import { Stack } from '@mui/material';
import ErrorMessage from './ErrorMessage';
import SignInLink from './SignInLink';

const InvalidToken = ({ message = 'Invalid token' }: { message?: string }) => (
  <Stack spacing={10}>
    <ErrorMessage message={message} />
    <SignInLink />
  </Stack>
);

export default InvalidToken;
