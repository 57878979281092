import { Dispatch, SetStateAction, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert, Divider, Stack, Typography } from '@mui/material';
import { MembershipRole } from 'tdc-web-backend/enums/enums';
import { CreateUserInvitationDto } from 'tdc-web-backend/user-invitation/schemas';
import ErrorIcon from '@mui/icons-material/Error';
import CustomInput from '../../../../../../components/custom-input/CustomInput';
import ConfirmModal from '../../../../../../components/confirm-modal/ConfirmModal';
import useCreate from '../../../../../../utils/hooks/crud-hooks/useCreate';
import { ReactComponent as ClipboardCheckIcon } from '../../../../../../assets/icons/layout-icons/ClipboardCheckIconEditModal.svg';
import { primaryLight } from '../../../../../../utils/color';
import MembershipRoles from './MembershipRoles';

export type AddModalData = {
  email: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  role: MembershipRole;
};

type AddModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onSuccessfulSubmit?: () => void;
  data?: AddModalData;
};

const AddModal = ({ isOpen, setIsOpen, onSuccessfulSubmit }: AddModalProps) => {
  const [error, setError] = useState<string | null>(null);
  const form = useForm({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      jobTitle: '',
      membershipRole: MembershipRole.Editor,
    },
  });

  const { mutate, isLoading } = useCreate<{}, CreateUserInvitationDto>({
    resource: 'user-invitation',
  });

  const onSubmit = () => {
    mutate(
      {
        email: form.getValues('email'),
        firstName: form.getValues('firstName'),
        lastName: form.getValues('lastName'),
        jobTitle: form.getValues('jobTitle'),
        role: form.getValues('membershipRole'),
      },
      {
        onSuccess: () => {
          setError(null);
          form.reset();
          setIsOpen(false);
          onSuccessfulSubmit?.();
        },
        onError: (err) => {
          setError(err.response?.data.message || err.message);
        },
      },
    );
  };

  return (
    <ConfirmModal
      isModalOpen={isOpen}
      automaticallyCloseModalOnButtonClick={false}
      onCancel={() => setIsOpen(false)}
      onConfirm={form.handleSubmit(onSubmit)}
      setIsModalOpen={() => null}
      title="Invite new team member"
      width="900px"
      confirmButtonText="Send invitation"
      iconProps={{
        icon: <ClipboardCheckIcon fill={primaryLight[500]} />,
        wrapperBackgroundColor: '#E3DBFC',
      }}
      isLoading={isLoading}
      showCancelButton={!isLoading}
      message={
        <FormProvider {...form}>
          <form>
            <Stack spacing={3}>
              {error && (
                <Alert severity="error" onClose={() => setError(null)}>
                  {error}
                </Alert>
              )}
              <Stack
                direction="row"
                spacing={3}
                justifyContent="cener"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Stack width="50%" spacing={3} justifyContent="space-between">
                  <CustomInput
                    input={<CustomInput.Text />}
                    name="email"
                    type="email"
                    label="* Email"
                    placeholder="Enter email"
                    required
                    disabled={isLoading}
                    validationRegEx={{
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: 'Invalid email address',
                    }}
                  />
                  <CustomInput
                    input={<CustomInput.Text />}
                    placeholder="Enter job title"
                    name="jobTitle"
                    label="Job Title"
                    disabled={isLoading}
                  />
                </Stack>
                <Stack width="50%" spacing={3} justifyContent="space-between">
                  <CustomInput
                    input={<CustomInput.Text />}
                    placeholder="Enter first name"
                    name="firstName"
                    label="First Name"
                    disabled={isLoading}
                  />
                  <CustomInput
                    input={<CustomInput.Text />}
                    placeholder="Enter last name"
                    name="lastName"
                    label="Last Name"
                    disabled={isLoading}
                  />
                </Stack>
              </Stack>
              <Divider />
              <MembershipRoles disabled={isLoading} />
            </Stack>
          </form>
        </FormProvider>
      }
    />
  );
};

export default AddModal;
