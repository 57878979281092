import { Box, SxProps, Theme, Typography } from '@mui/material';
import Stack from '@mui/material/Stack/Stack';
import { primaryLight } from '../../utils/color';
import { LAYOUT_DEFAULTS } from '../../utils/constants';

export interface HowDoesItWorkProps {
  id: string;
  title: string;
  content: string;
  image: string;
  color?: string;
  alt?: string;
}

export interface ItemProp {
  items: HowDoesItWorkProps[];
  sx?: SxProps<Theme>;
}

const HowDoesItWorkItem = ({
  title,
  image,
  content,
  color = primaryLight[100],
  alt,
}: Omit<HowDoesItWorkProps, 'id'>) => (
  <Box
    sx={{
      bgcolor: color,
      borderRadius: '20px',
      px: { xs: 2.5, lg: 9 },
      pt: { xs: 4.5, lg: 1 },
    }}
  >
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent="center"
      alignItems="center"
      spacing={{ xs: 0, lg: 8 }}
    >
      <Stack>
        <Typography variant="publicHeading4" pb="1rem">
          {title}
        </Typography>

        <Typography variant="publicBody4Regular" maxWidth="90%">
          {content}
        </Typography>
      </Stack>

      <Box
        alt={alt}
        key={image}
        component="img"
        src={image}
        sx={{
          maxWidth: { xs: '100%', md: 'auto' },
          borderRadius: '20px 20px 0px 0px',
          pt: '2rem',
        }}
      />
    </Stack>
  </Box>
);

const HowDoesItWork = ({ items, sx }: ItemProp) => (
  <Stack
    spacing={4}
    sx={{
      mt: { xs: -7, lg: '110px' },
      ...sx,
      ...LAYOUT_DEFAULTS,
    }}
    justifyContent="center"
    alignItems="center"
  >
    <Typography
      sx={{
        typography: { xs: 'publicHeading4', lg: 'publicHeading3' },
        alignSelf: { xs: 'center', lg: 'start' },
      }}
      textAlign="center"
    >
      How Does it Work?
    </Typography>

    <Stack spacing={4} width="100%">
      {items.map(({ title, content, image, color, alt, id }: HowDoesItWorkProps) => (
        <HowDoesItWorkItem
          key={id}
          title={title}
          content={content}
          image={image}
          color={color}
          alt={alt}
        />
      ))}
    </Stack>
  </Stack>
);
export default HowDoesItWork;
